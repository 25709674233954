import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ProgramAddComponent } from './program-add/program-add.component';
import { ProgramEditComponent } from './program-edit/program-edit.component';
import { ProgramListComponent } from './program-list/program-list.component';
import { AdminComponent } from './admin.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { UserListComponent } from './user-list/user-list.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { RequestUserListComponent } from './request-user-list/request-user-list.component';
import { UserAddComponent } from './user-add/user-add.component';
import { ManagerVerificationComponent } from './manager-verification/manager-verification.component';
import { ManagerVDuplicateComponent } from './manager-v-duplicate/manager-v-duplicate.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { UserHomeComponent } from './user-home/user-home.component';
import { ReportingPeriodComponent } from './reporting-period/reporting-period.component';
import { AuthGuardService } from '../services/auth-guard.service';



const adminRoutes: Routes = [
  //{ path: 'admin', component: AdminComponent },    
  {
    path: 'admin',
    canActivate: [AuthGuardService],
    component: AdminComponent,
    children: [
      {
        path: 'programadd',
        component: ProgramAddComponent
      },
      {
        path: 'programedit',
        component: ProgramEditComponent
      },
      {
        path: 'programedit/:id',
        component: ProgramEditComponent
      },
      {
        path: 'programlist',
        component: ProgramListComponent
      },
      {
        path: 'changepassword',
        component: ChangepasswordComponent
      },
      {
        path: 'userlist',
        component: UserListComponent
      },
      {
        path: 'useradd',
        component: UserAddComponent
      },
      {
        path: 'requestuserlist',
        component: RequestUserListComponent
      },
      {
        path: 'verificationlist',
        component: ManagerVerificationComponent
      },
      {
        path: 'managerduplicate/:id',
        component: ManagerVDuplicateComponent
      },
      {
        path: 'emailtemplate',
        component: EmailTemplateComponent
      },
      {
        path: 'userhome',
        component: UserHomeComponent
      },
      {
        path: 'reportingperiod',
        component: ReportingPeriodComponent
      },
      {
        path: '',
        component: AdminDashboardComponent
      },
    ]
  },
];

@NgModule({
  imports: [
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ScrollingModule,
    RouterModule.forChild(adminRoutes)
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule {
}
