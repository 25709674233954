import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { AccountComponent } from './account.component';
import { AccountRequestComponent } from './account-request/account-request.component';
import { GeneralRequestComponent } from './general-request/general-request.component';
import { VerifymanagerComponent } from './verifymanager/verifymanager.component';
import { UserpasswordResetComponent } from './userpassword-reset/userpassword-reset.component';
import { AuthGuardGuestService } from '../services/auth-guard-guest.service';



const accountRoutes: Routes = [
  {
    path: 'account',
    component: AccountComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'login/:logout',
        component: LoginComponent
      },
      {
        path: 'forgetpassword',
        component: ForgetpasswordComponent
      },
      {
        path: 'accountrequest',
        component: AccountRequestComponent
      },
      {
        path: 'generalrequest',
        component: GeneralRequestComponent
      },
      {
        path: 'verifymanager/:key',
        component: VerifymanagerComponent
      },

      {
        path: 'verifymanager',
        component: VerifymanagerComponent
      },
      {
        path: 'passwordreset',
        component: UserpasswordResetComponent
      },
      {
        path: '',
        component: LoginComponent
      },     
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(accountRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AccountRoutingModule {
}
