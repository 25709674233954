  <div class="input-group-prepend p-2" mat-raised-button color="primary">
    <!--<button mat-raised-button color="primary">Refresh</button>-->
    <button mat-raised-button color="primary" (click)="btnBackClick()">Back</button>
  </div>
  <div class="pl-4 mat-elevation-z8" [formGroup]="rtpdEntry">
    <div class="row">
      <div class="col-sm-2 p-0 pt-2 pb-2">
        <mat-form-field class="example-full-width">
          <input matInput maxlength="2" type="number" placeholder="Month(MM)" formControlName="mm" required>
          <mat-error *ngIf="hasError('mm', 'required')">Month is required!</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-2 p-0 pt-2 pb-2">
        <mat-form-field class="example-full-width">
          <input matInput maxlength="2" type="number" placeholder="Year(YY)" name="dd" formControlName="dd" required>
          <mat-error *ngIf="hasError('dd', 'required')">Year is required!</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-4 p-0 pt-2 pb-2">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Description" name="description" formControlName="description" maxlength="50" required>
          <mat-error *ngIf="hasError('description', 'required')">Description is required!</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-4 p-0 pt-2 pb-2">
        <mat-form-field class="example-full-width">
          <input matInput [matDatepicker]="pickera" placeholder="Complete Date(MM/DD/YYYY)" formControlName="completedate" required>
          <mat-datepicker-toggle matSuffix [for]="pickera"></mat-datepicker-toggle>
          <mat-datepicker #pickera></mat-datepicker>
          <mat-error *ngIf="hasError('completedate', 'required')">Completeion Date is required!</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-5 p-0 pt-2 pb-2">
        <section class="pt-4">
          <mat-checkbox class="example-margin" color="primary" formControlName="isannual">Is the RP annual?</mat-checkbox>
        </section>
      </div>
      <div class="col-sm-5 p-0 pt-2 pb-2">
        <section class="pt-4">
          <mat-checkbox class="example-margin" color="primary" formControlName="active">Active</mat-checkbox>
        </section>
      </div>
      <div class="col-sm-2 p-0 pt-4 pb-2">
        <section class="pt-0 example-button-row">
          <button name="add" mat-raised-button (click)="btnAddClick()" [disabled]="!rtpdEntry.valid" [hidden]="isAdd" color="primary">Add</button>
          <button name="edit" mat-raised-button (click)="btnEditClick()" [disabled]="!rtpdEntry.valid" [hidden]="isEdit" color="primary">Save</button>
          <button name="clear" mat-raised-button (click)="btnCancelClick()" color="primary">Cancel</button>
        </section>
      </div>
    </div>
  </div>

  <div class="mat-elevation-z8 pb-1">
    <table mat-table [dataSource]="dataSource" style="width:100%" matSort>
      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <h6 class="text-primary">Action</h6> </th>
        <td mat-cell *matCellDef="let element" class="flex-nowrap p-0">
          <div class="row col-sm-12">
            <div class="col-sm-6 p-2">
              <button (click)="onEdit(element)" color="primary" mat-stroked-button routerLinkActive="active">Edit</button>
            </div>
            <!--<div class="col-sm-6 p-2">
              <button (click)="onDelete(element.id)" color="primary" mat-stroked-button routerLinkActive="active">Delete</button>
            </div>-->
          </div>
        </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="mm">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <h6 class="text-primary">Report Period</h6></th>
        <td mat-cell *matCellDef="let element">{{element.mm + "/" + element.dd }} ({{ (element.isannual === "True") ? 'A' : 'Q' }})</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><h6 class="text-primary">Description</h6> </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>
      <!-- Symbol Column -->
      <ng-container matColumnDef="completedate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><h6 class="text-primary"> Complete Date </h6> </th>
        <td mat-cell *matCellDef="let element"> {{element.completedate}} </td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <h6 class="text-primary">Status</h6> </th>
        <td mat-cell *matCellDef="let element"> {{element.active == '0'? 'Inactive':'Active' }} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>

  <!--<div class="row" *ngFor="let item of reportingPeriod">
    <div class="col-sm-2">
      {{item.mm + "/" + item.dd}}
    </div>
    <div class="col-sm-5">
      {{item.description}}
    </div>
    <div class="col-sm-2">
      {{item.completedate}}
    </div>
    <div class="col-sm-1">
      {{item.active}}
    </div>
    <div class="col-sm-2">

    </div>
  </div>-->

