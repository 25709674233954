<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<div class="row p-3">
  <form class="col-lg-12 p-0 shadow-lg dataentry-card rounded  dataentry-column" [formGroup]="accountRequest">
    <div class="headding_account">
      <h4 class="text-md-left text-white pl-2">General Request</h4>
    </div>
    <p *ngIf="warningmessage" class="text-danger">{{warningmessage}}</p>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width p-2">
        <textarea matInput type="text" name="comments" placeholder="Comments" formControlName="comments" required></textarea>
        <mat-error *ngIf="hasError('comments', 'required')">Comment is required</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width  p-2">
        <input matInput placeholder="Email" name="email" formControlName="email" required>
        <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
        <mat-error *ngIf="hasError('email', 'email')">Valid Email is required</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width  p-2">
        <mat-select placeholder="DESOS Program" formControlName="programID" required>
          <mat-option *ngFor="let program of programList" [value]="program.program_ID">
            {{program.program_Name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('programID', 'required')">Program is required</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width  p-2">
        <input matInput type="text" name="lastname" formControlName="fullname" placeholder="Full Name">
      </mat-form-field>
    </div>
    <div class="example-button-row p-2">
      <button mat-raised-button color="primary" (click)="btnRequestClick()" [disabled]="!accountRequest.valid">Send Request</button>
      <button mat-raised-button color="primary" (click)="btnBackClick()">Back</button>
    </div>
    <div class="example-button-row p-2">
      <button mat-button color="primary" type="button"  [routerLink]="['/account/login']">Log In</button>
      <button mat-button color="primary" type="button"  [routerLink]="['/account/forgetpassword']">Forgot Password?</button>
      <button mat-button color="primary" type="button"  [routerLink]="['/account/accountrequest']">Request an account?</button>
    </div>
  </form>
</div>
