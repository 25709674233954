import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ChangePassword, DESOS_Users } from 'src/app/admin/program';
import { HttpParams, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { Observable } from 'rxjs';
import { DefaultService } from 'src/app/services/default.service';
import { DESOS_StrResult } from 'src/app/dataentry/dataentry';

@Component({
    selector: 'app-forgetpassword',
    templateUrl: './forgetpassword.component.html',
    styleUrls: ['./forgetpassword.component.css']
})
/** forgetpassword component*/
export class ForgetpasswordComponent implements OnInit{
    /** forgetpassword ctor */
  public passwordReset: FormGroup;
  private passChange: ChangePassword;
  private asyncResult: DESOS_Users;
  private desosUser: DESOS_Users;
  private strResults: DESOS_StrResult = new DESOS_StrResult();
  public warningmessage: string = "";
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  constructor(private location: Location, private route: ActivatedRoute, private router: Router,
    private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private matDialog: MatDialog, private APIservices: DefaultService) {
    let observable = new Observable(this.myObservableOne);
    this.showProgressSpinner(observable);
  }

  ngOnInit() {
    this.passwordReset = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  hasError = (controlName: string, errorName: string) => {
    return this.passwordReset.controls[controlName].hasError(errorName);
  }

  myObservableOne(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 1000);
  }

  myObservableTwo(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 2000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  public btnResetClick() {
    if (this.passwordReset.valid) {
      this.warningmessage = "Resetting the password.......";
      const strusername: string = this.passwordReset.controls["email"].value;
      let inparams = new HttpParams()
        .set('username', strusername);
      //this.asyncResult = null;
      this.showProgressSpinner(this.FindAccount(inparams));
      //if (this.asyncResult != null)
      //this.showProgressSpinner(this.ResetPassword());
      //this.getUserInfo(inparams);

     // this.router.navigate(['/account/login']);
    }
  }

  FindAccount(inparam: HttpParams) {
    return Observable.create(observer => {
      this.APIservices.getUserInfo(inparam).subscribe((result) => {
        this.asyncResult = JSON.parse(JSON.stringify(result));
        if (this.asyncResult != null) {
          this.warningmessage = "User found....";
          observer.next("Find Account completed.");
          observer.complete();
          if (this.asyncResult.account_Active_flg == 0) {
            this.warningmessage = "Your account is not active. " +
              "If you need further assistance, please contact desos@indiana.edu";
          }
          else {
            this.warningmessage = "Resetting the password.......";
            this.ResetPassword();
            //this.delay(2000).then(any => { this.resetPasswordAndSendEmail(); });
            //this.warningmessage = "Your request has been processed." +
             // "You should receive an email with your new password.";
          }
        }
        else {
          this.warningmessage = "No account is associated with that email." +
            "If you need further assistance, please contact desos@indiana.edu";
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }

  ResetPassword() {
   // return Observable.create(observer => {
      this.APIservices.resetPasswordAndSendEmail(this.asyncResult).subscribe((result) => {
        this.strResults = JSON.parse(JSON.stringify(result));
        if (this.strResults != null) {
          if (this.strResults.strOutput == "Success") {
            this.warningmessage = "Your request has been processed." +
              "You should receive an email with your new password.";
            //observer.next("Request has been processed.");
            //observer.complete();
          }
          else {
            this.warningmessage = this.strResults.strOutput;
           // observer.next("Request not processed.");
            //observer.complete();
          }
        }
        else {
          this.warningmessage = this.strResults.strOutput;
          //observer.next("No Connection, DB or API or Session.");
          //observer.complete();
        }
      });
    //});
  }

  public onBack = () => {
    this.location.back();
  }

  btnPasswordClick = function () {
    this.router.navigateByUrl('/login');
  };

  btnBackClick = function () {
    //this.router.navigateByUrl('/account/Login');
    this.router.navigate(['/account/login']);
  };

  async getUserInfo(inparam: HttpParams) {

    this.asyncResult = await this.http.get<DESOS_Users>(this.baseUrl + 'api/DefaultData/GetUserByUserName', { params: inparam }).toPromise()

    let observable = new Observable(this.myObservableOne);
    this.showProgressSpinner(observable);

    if (this.asyncResult != null) {   
      this.warningmessage = "User found....";
      this.desosUser = JSON.parse(JSON.stringify(this.asyncResult));

      if (this.desosUser.account_Active_flg == 0) {
        this.warningmessage = "Your account is not active. " +
          "If you need further assistance, please contact desos@indiana.edu";
      }
      else {
        this.warningmessage = "Resetting the password.......";
        this.delay(2000).then(any => { this.resetPasswordAndSendEmail(); });
        this.warningmessage = "Your request has been processed." +
          "You should receive an email with your new password.";
      }
    }
    else {
      this.warningmessage = "No account is associated with that email." +
        "If you need further assistance, please contact desos@indiana.edu";
    }
    //console.log('No issues, I will wait until promise is resolved..');
  };

  resetPasswordAndSendEmail() {
    this.http.post(this.baseUrl + 'api/DefaultData/PasswordReset', this.asyncResult).toPromise();
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    this.warningmessage = "If you need further assistance, please contact desos@indiana.edu";
    return Promise.reject(error.message || error);
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("Login"));
  }
}
