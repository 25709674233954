<form [formGroup]="archiveSearch">
  <div class="row">
    <div class="col-sm-6 dataentry-column">
      <mat-card class="dataentry-card">
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Client Name</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-input">
              <input matInput placeholder="First Name" name="firstname" formControlName="firstname">
            </mat-form-field>
            <mat-form-field class="mat-input" [style.width.px]="50">
              <input matInput placeholder="MI" name="middlename" maxlength="1" formControlName="middlename">
            </mat-form-field>
            <mat-form-field class="mat-input">
              <input matInput placeholder="Last name" name="lastname" formControlName="lastname">
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Client Date of Birth</mat-label>
          </div>
          <div class="col-sm-4 p-0">
            <mat-form-field class="mat-dropdown">
              <mat-select placeholder="Select a Month" formControlName="dobmonth">
                <mat-option *ngFor="let item of monthList" [value]="item.id">
                  {{item.month}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-4">
            <mat-form-field class="mat-input" [style.width.px]="80">
              <input matInput placeholder="Year(YY)" name="dobyear" maxlength="2" type="number" formControlName="dobyear">
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Program Name</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-dropdown">
              <mat-select placeholder="DESOS Program" formControlName="program">
                <mat-option *ngFor="let program of programList" [value]="program.program_ID">
                  {{program.program_Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-sm-6 dataentry-column">
      <mat-card class="dataentry-card">
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Reporting Period</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-dropdown">
              <mat-select placeholder="Select a Report Period" formControlName="reportingperiod">
                <mat-option *ngFor="let item of periodList" [value]="item.id">
                  {{item.period}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>

        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Gender</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-dropdown">
              <mat-select placeholder="Select a Gender" formControlName="gender">
                <mat-option *ngFor="let item of genderList" [value]="item.id">
                  {{item.gender}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Race</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-dropdown">
              <mat-select placeholder="Select a Race" formControlName="race">
                <mat-option *ngFor="let item of raceList" [value]="item.id">
                  {{item.race}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </div>
    <button mat-raised-button (click)="btnFindClick()" [disabled]="!archiveSearch.valid" type="button" color="primary">Find</button>
  </div>
</form>
