<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<div class="row mb-2 p-1" style="place-content: center;">
  <div class="col-md-6">
    <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
      <strong class="d-inline-block p-4 mb-2 text-primary">Reports List</strong>
      <button mat-menu-item [routerLink]="['/reports/reportparams', 11]">
        <a [routerLink]="['/reports/reportparams', 11]">Participating Programs</a>
      </button>
      <button mat-menu-item [routerLink]="['/reports/reportparams', 10]">
        <a href="#" [routerLink]="['/reports/reportparams', 10]">All Demographics Report</a>
      </button>
      <button mat-menu-item [routerLink]="['/reports/reportparams', 0]">
        <a href="#" [routerLink]="['/reports/reportparams', 0]">Facility-Based (Sheltered Employment)</a>
      </button>
      <button mat-menu-item [routerLink]="['/reports/reportparams', 1]">
        <a [routerLink]="['/reports/reportparams', 1]">Off-site group employment placement <br />(e.g. enclave, crew, small business,etc.)</a>
      </button>
      <button mat-menu-item [routerLink]="['/reports/reportparams', 2]">
        <a href="#" [routerLink]="['/reports/reportparams', 2]">Competitive Integrated Employment</a>
      </button>
      <button mat-menu-item [routerLink]="['/reports/reportparams', 3]">
        <a [routerLink]="['/reports/reportparams', 3]">Self-Employment</a>
      </button>
      <button mat-menu-item [routerLink]="['/reports/reportparams', 4]">
        <a [routerLink]="['/reports/reportparams', 4]">Non-employment day program</a>
      </button>
      <button mat-menu-item [routerLink]="['/reports/reportparams', 5]">
        <a [routerLink]="['/reports/reportparams', 5]">Alternative to employement</a>
      </button>
    </div>
  </div>
</div>
