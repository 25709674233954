export class helpText {
  public message_1 = "The name of the individual for whom you are reporting who is receiving either Day Services or Employment Services (e.g., First, Middle Initial, and Last Name).";
  public message_2 = "Click on the box to select the name of day and employment service provider/agency. (If you agency name is not listed, contact the Indiana Institute).";
  public message_3 = "Click on the box to select the name of the city where the main day and employment provider/agency is located. (If you city name is not listed, contact the Indiana Institute).";
  public message_4 = "Click on the box to select the name of the county in which the services are provided for the individual for whom you are reporting who is receiving either Day or Employment Services.";
  public message_5 = "Enter the ZIP code where services are provided for the individual for whom you are reporting who is receiving either Day or Employment Services.";
  public message_6 = "The reporting period for the Day and Employment Outcomes System is either May or November and should occur automatically.";
  public message_7 = "Enter the month and year of birth for the individual for whom you are completing this report.";
  public message_8 = "Click on the box to select gender (male or female) for the individual for whom you are completing this report.";
  public message_9 = "Click on the box to select the individual’s race.";
  public message_10 = "Select whether the individual is, is not or is partially their own legal guardian.";
  public message_11 = "Check this box if the individual you are reporting for is no longer receiving services, however you provided services within the last 6 months and Why: Choice, Moved, Death, or Other.";
  public message_12 = "Select the disability that most impacts or impedes the individual’s daily life activities.<br>" +
    "• Physical includes such areas as spina bifida, spinal cord injury, polio, etc." +
    "• Other Health Impairments includes such areas as diabetes, asthma, cystic fibrosis, etc."
  public message_13 = "If the individual has more than one identified disability (e.g., mental retardation and mental illness, mental retardation and cerebral palsy)," +
    "select the disability that was not identified in the primary but also impacts or impedes their daily life activities." +
    "If the individual does not have a secondary disability, select none."
  public message_14 = "Select the mode of mobility primarily used by the individual. “Ambulatory with assistance” relates to individuals who may be ambulatory but needs some assistance such as holding onto someone’s arm or a wall for support.";
  public message_15 = "If the individual exhibits behaviors that interfere with his or her job or daily activities or may need assistance in attaining more appropriate expressions of emotions or desires, select the level of intensity of the behaviors displayed.";
  public message_16 = "Select the level of support needed by the individual.";
  public message_17 = "Select the mode of communication primarily used by the individual. Check only one.";
  public message_18 = "Select all areas of personal assistance required by the individual. If the individual does not need any assistance, check not applicable –NA.";
  public message_19 = "Select the environment where the individual spends the majority of time of his or her day. If the individual’s time is divided between two environments (e.g., two part-time jobs), select the primary environment where the individual spends the majority of this or her day. Question # 22 will address the secondary environment.";
  public message_20 = "N/A";
  public message_21 = "N/A";
  public message_22 = "Select the source of referral for community employment. If the individual is not engaged in community employment, select non-applicable- N/A";
  public message_23 = "If the individual has taken a position with a new employer or location of a new community job in the last six month, check yes.";
  public message_24 = "Select the month and year the individual began their current or new community job. Current  refers to the community job that was initiated prior to the 6 months reporting. " +
    " New employer refers to the community job obtained within the last six months. Select month and year." +
    " If no new or current employer in the last 6 months, go to question #6";
  public message_25 = "N/A ";
  public message_26 = "If job was lost within the last 6 months, select the reason for the loss";
  public message_27 = "This question is interested in knowing what type of work the individual is engaged in (not type of business the company is in). Select the option that most closely reflects the individual’s situation. If the individual performs several different types of duties as part of their employment, select the one which the individual spends the most time doing. If other, please enter type of work performed." +
                " If the individual is does not perform work, select not applicable, N / A.";
  public message_28 = " Enter the number of hours scheduled that involves paid work in a typical week. Because this question ask for the “typical” week, you don’t have to worry about those weeks in which things are different due to sickness or other unusual event. Enter the number of hours that is most typical of the 6-month period." +
                "If the individual is typically scheduled for less than a whole hour(e.g., 12.5 hours) then use the following rounding rule:" +
                "0.1 hours – 0.5 hours = round down to the next whole number or 0 if no hours working.For example, if someone is scheduled for 10.5 hours, enter 10 hours." +
                "0.6 hours – 1 hour = round up to the next whole number.For example, 10.75 hours would be entered as 11 hours.";
  public message_29 = "Enter the number of hours in a typical week the individual is scheduled that involves non-paid time such as the hours under Alternative to Employment (ATE) or centerbased services that don’t involve performing paid work.";
  public message_30 = "This question is designed to determine the amount the individual is paid by the hour, whether or not the individual’s wage are productivity-based. For example, if the individual is on a special wage certificate allowing sub minimum wages, enter the actual hourly earnings " +
       "(e.g., $2.25) rather than the commensurate wage on which that wage is based.Because this data is “snapshot” data, you may select the hourly wage for the current or just previous time period.  Fill in the actual dollar amount for the hourly earning / wage.";
  public message_31 = "Select all the benefits, if any, that are received by the individual as a function of their employment opportunity. If the individual receives benefits that are not listed, check Other and list these benefits in the space provided. If the individual does not receive any employer benefits, check None.";
  public message_32 = "Select all the benefits, if any, that are received by the individual from the federal or state government";
  public message_33 = "Select the number of individuals without disabilities who are not paid to provide to support to the individual who are in the same environment.";
  public message_34 = "Select the number of individuals with disabilities, including this individual, who are in the same environment.";
  public message_35 = "The question is designed to identify the degree to which the individual interacts with individuals without disabilities who are not paid to provide support to the individual in their employment setting or ATE. Your response should reflect what is actually occurring in this environment.";
  public message_36 = "Select the number of hours in a typical month that follow-along support is provided to the individual who is currently employed in a community job. If the individual is not working in a community job (e.g., ATE or sheltered), select not applicable, N/A.";
  public message_37 = "This question is interested in knowing the reason why someone who is employed in a community job is not receiving follow-along services. Check all that apply.";
  public message_38 = "This question is designed to identify the type of living environment for the individual. Enter the number of people with disabilities (including this individual) in the home and the number of individuals without disabilities (excluding paid staff) in the home.";
  public message_39 = "This question is designed to identify the mode of transportation used by the individual to go to work or receive services. Check all that apply to the services received in the primary environment.";
  public message_40 = "This question refers to situations where the individual's time is divided between two environments (e.g., community employment and sheltered workshop). If the individual's situation reflects two environments, select 'YES' and respond to questions 23-25. If the individual's situation does not reflect time spent in more than one environment, select 'NO' and submit information.";
  public message_41 = "This question refers to situations where the individual's time is divided between two environments (e.g., community employment and sheltered workshop) and represents the secondary environment. Select the option that most closely reflects the individual's situation.";
  public message_42 = "This question is interested in the typical hours scheduled weekly spent in the secondary environment. Enter the number of hours typically scheduled.";
  public message_43 = "This question is designed to determine the amount the individual is paid by the hour, whether or not the individual�s wage are productivity-based. For example, if the individual is on a special wage certificate allowing sub minimum wages, enter the actual hourly earnings " +
                    " (e.g., $2.25) rather than the commensurate wage on which that wage is based.Because this data is �snapshot� data, you may select the hourly wage for the current or just previous time period. Fill in the actual dollar amount for the hourly earning / wage.";
  public message_44 = "N/A";
  public message_45 = "N/A";
  public message_46 = "N/A";
  public message_47 = "N/A";
  public message_48 = "N/A";
  public message_49 = "N/A";

  public message_36A = " If individual is not working in community group select 0 and select option from Question #17";
}

export class DataEntry_tabAvailability {
  public tab2To7Enabled: boolean = true;
  public tab8Enabled: boolean = true;
}


export class DESOS_Records_List {
  public id: string = "";//{ get; set; }
  public individual_ID: string = "";//{ get; set; } 
  public firstName: string = "";//{ get; set; }
  public q1val: string = "";//{ get; set; 
  public middleName: string = "";//{ get; set; }
  public lastName: string = "";//{ get; set; }
  public program_Name: string = "";//{ get; set; }
  public zipCode: string = "";//{ get; set; }
  public dateOfBirth: string = "";//{ get; set; }
  public city: string = "";//{ get; set; }
  public county: string = "";//{ get; set; }
  public gender: string = "";//{ get; set; }
  public Race: string = "";//{ get; set; }
  public reporting_Period: string = "";//{ get; set; }
  public Reporting_Period_ID: string = "";//{ get; set; }
  public Reporting_Period_Filter: string = "";//{ get; set; }
  public lastNameLowerCase: string = "";//{get;set;}
}

export class DESOS_Search_Fields {
  public id: string = "";//{ get; set; }
  public individual_ID: string = "";;//{ get; set; }
  public firstName: string = "";//{ get; set; }
  public middleName: string = "";//{ get; set; }
  public lastName: string = "";//{ get; set; }
  public lastNameLowerCase: string = "";//{ get; set; }
  public reporting_Period: string = "";//{ get; set; }
  public Reporting_Period_Filter: string = "";//{ get; set; }
  public Reporting_Period_Type: string = "";//{ get; set; }
  public program_ID: string = "";//{ get; set; }
  public cityID: string = "";//{ get; set; }
  public countyID: string = "";//{ get; set; }
  public dateOfBirth: string = "";//{ get; set; }
  public gender: string = "";//{ get; set; }
  public race: string = "";//{ get; set; }
  public zipCode: string = "";//{ get; set; }
  public services: string = "";//{ get; set; }
  public allreporting: string = "";//{ get; set; }
  public isAdmin: boolean;//{ get; set; }
  public pageCurrent: number = 0;
  public pageSize: number = 10;
}

export class DESOS_RosterData {
  public Individuals: DESOS_Individuals = new DESOS_Individuals();
  public Outcomes: DESOS_Outcomes = new DESOS_Outcomes();
}

export class DESOS_Individuals {
  public individual_ID: string = "";// { get; set; }
  public firstName: string = "";// { get; set; }
  public middleName: string = "";// { get; set; }
  public lastName: string = "";// { get; set; }
  public zipCode: string = "";// { get; set; }
  public dateOfBirth: string = "";// { get; set; }
  public gender: string = "";// { get; set; }
  public race: string = "";// { get; set; }
  public unique_ID: string = "";// { get; set; }
  public legalGuardian: string = "";// { get; set; }
  public otherGender: string = "";// { get; set; }
  public otherRace: string = "";// { get; set; }
}


export class DESOS_Outcomes {
  public id: string = "";// { get; set; }
  public reporting_Period: string = "";// { get; set; }
  public individual_ID: string = "";// { get; set; }
  public program_ID: string = "";// { get; set; }
  public countyID: string = "";// { get; set; }
  public cityID: string = "";// { get; set; }
  public nlrs: string = "";// { get; set; }
  public nlrSsubval: string = "";// { get; set; }
  public nlrSsubval2: string = "";// { get; set; }
  public pdLval: string = "";// { get; set; }
  public pdLsubval: string = "";// { get; set; }
  public sdLval: string = "";// { get; set; }
  public sdLsubval: string = "";// { get; set; }
  public mbYval: string = "";// { get; set; }
  public bIval: string = "";// { get; set; }
  public los: string = "";// { get; set; }
  public communication: string = "";// { get; set; }
  public lpa: string = "";// { get; set; }
  public q1val: string = "";// { get; set; }
  public q1subval: string = "";// { get; set; }
  public q1subval2: string = "";// { get; set; }
  public q1subval3: string = "";// { get; set; }
  public q1subval4: string = "";// { get; set; }
  public q2val: string = "";// { get; set; }
  public q3val: string = "";// { get; set; }
  public q3subval: string = "";// { get; set; }
  public q4val: string = "";// { get; set; }
  public q5val: string = "";// { get; set; }
  public q6val: string = "";// { get; set; }
  public q7val: string = "";// { get; set; }
  public q8val: string = "";// { get; set; }
  public q8subval: string = "";// { get; set; }
  public q9val: string = "";// { get; set; } // Float
  public q10val: string = "";// { get; set; } //Float
  public q11val: string = "";// { get; set; } //Float
  public q12val: string = "";// { get; set; }
  public q12subval: string = "";// { get; set; }
  public q13val: string = "";// { get; set; }
  public q14val1: string = "";// { get; set; } // Float
  public q14val2: string = "";// { get; set; } // Float
  public q14val3: string = "";// { get; set; } // Float
  public q14val4: string = "";// { get; set; } // Float
  public q14val5: string = "";//{ get; set; } //Float
  public q14val6: string = "";// { get; set; } //Float
  public q14subval: string = "";// { get; set; }
  public q15val: string = "";// { get; set; }
  public q16val: string = "";// { get; set; }
  public q17val: string = "";// { get; set; }
  public q18val: string = "";// { get; set; }
  public q19val: string = "";// { get; set; }
  public q20val1: string = "";// { get; set; } //Float
  public q20val2: string = "";// { get; set; } //Float
  public q21val: string = "";// { get; set; }
  public q21subval: string = "";// { get; set; }
  public q22val: string = "";// { get; set; }
  public q23val: string = "";// { get; set; }
  public q23subval: string = "";// { get; set; }
  public q23subval2: string = "";// { get; set; }
  public q23subval3: string = "";// { get; set; }
  public q23subval4: string = "";// { get; set; }
  public q24val: string = "";// { get; set; } // Float
  public q26val: string = "";// { get; set; } // Float
  public q25val: string = "";// { get; set; } //Float
  public userCreate: string = "";// { get; set; }
  public userEdit: string = "";// { get; set; }
  public q19subval: string = "";// { get; set; }
  public legalGuardian: string = "";// { get; set; }
  public q1subval5: string = "";// { get; set; }
  public q1subval6: string = "";// { get; set; }
  public q23subval5: string = "";// { get; set; }
  public q23subval6: string = "";// { get; set; }
}


export class DESOS_DataEntry {
  public individual_ID: string = "";// { get; set; }
  public firstName: string = "";// { get; set; }
  public middleName: string = "";// { get; set; }
  public lastName: string = "";// { get; set; }
  public zipCode: string = "";// { get; set; }
  public dateOfBirth: string = "";// { get; set; }
  public gender: string = "";// { get; set; }
  public race: string = "";// { get; set; }
  public unique_ID: string = "";// { get; set; }
  public legalGuardian: string = "";// { get; set; }
  public otherGender: string = "";// { get; set; }
  public otherRace: string = "";// { get; set; }

  public id: string = "";// { get; set; }
  public reporting_Period: string = "";// { get; set; }
 // public individual_ID: string = "";// { get; set; }
  public program_ID: string = "";// { get; set; }
  public countyID: string = "";// { get; set; }
  public cityID: string = "";// { get; set; }
  public nlrs: string = "";// { get; set; }
  public nlrssubval: string = "";// { get; set; }
  public nlrssubval2: string = "";// { get; set; }
  public pdlval: string = "";// { get; set; }
  public pdlsubval: string = "";// { get; set; }
  public sdlval: string = "";// { get; set; }
  public sdlsubval: string = "";// { get; set; }
  public mbyval: string = "";// { get; set; }
  public bival: string = "";// { get; set; }
  public los: string = "";// { get; set; }
  public communication: string = "";// { get; set; }
  public lpa: string = "";// { get; set; }
  public q1val: string = "";// { get; set; }
  public q1subval: string = "";// { get; set; }
  public q1subval2: string = "";// { get; set; }
  public q1subval3: string = "";// { get; set; }
  public q1subval4: string = "";// { get; set; }
  public q2val: string = "";// { get; set; }
  public q3val: string = "";// { get; set; }
  public q3subval: string = "";// { get; set; }
  public q4val: string = "";// { get; set; }
  public q5val: string = "";// { get; set; }
  public q6val: string = "";// { get; set; }
  public q7val: string = "";// { get; set; }
  public q8val: string = "";// { get; set; }
  public q8subval: string = "";// { get; set; }
  public q9val: string = "";// { get; set; } // Float
  public q10val: string = "";// { get; set; } //Float
  public q11val: string = "";// { get; set; } //Float
  public q12val: string = "";// { get; set; }
  public q12subval: string = "";// { get; set; }
  public q13val: string = "";// { get; set; }
  public q14val1: string = "";// { get; set; } // Float
  public q14val2: string = "";// { get; set; } // Float
  public q14val3: string = "";// { get; set; } // Float
  public q14val4: string = "";// { get; set; } // Float
  public q14val5: string = "";//{ get; set; } //Float
  public q14val6: string = "";// { get; set; } //Float
  public q14subval: string = "";// { get; set; }
  public q15val: string = "";// { get; set; }
  public q16val: string = "";// { get; set; }
  public q17val: string = "";// { get; set; }
  public q18val: string = "";// { get; set; }
  public q19val: string = "";// { get; set; }
  public q20val1: string = "";// { get; set; } //Float
  public q20val2: string = "";// { get; set; } //Float
  public q21val: string = "";// { get; set; }
  public q21subval: string = "";// { get; set; }
  public q22val: string = "";// { get; set; }
  public q23val: string = "";// { get; set; }
  public q23subval: string = "";// { get; set; }
  public q23subval2: string = "";// { get; set; }
  public q23subval3: string = "";// { get; set; }
  public q23subval4: string = "";// { get; set; }
  public q24val: string = "";// { get; set; } // Float
  public q25val: string = "";// { get; set; } //Float
  public userCreate: string = "";// { get; set; }
  public userEdit: string = "";// { get; set; }
  public q19subval: string = "";// { get; set; }
 // public legalGuardian: string = "";// { get; set; }
  public q1subval5: string = "";// { get; set; }
  public q1subval6: string = "";// { get; set; }
  public q23subval5: string = "";// { get; set; }
  public q23subval6: string = "";// { get; set; }
}

export class DESOS_StrResult {
  public strOutput: string = "";
}


export class DESOS_StrRosterResult {
  public strOutput: string = "";
  public strError: string = "";
  public strIndividual_ID: string = "";
  public strOutcomes_ID: string = "";
}

export interface DialogData {
  individual: string;
  outcome: string;
  action: string;
  status: string;
}
