import { Component, Inject, OnInit, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DESOS_Programs } from '../program';
import { ProgramService } from 'src/app/services/program.service';

@Component({
    selector: 'app-program-list',
    templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.css'],
  providers: [ProgramService]
})
/** program-list component*/
export class ProgramListComponent implements OnInit {
  public programlist: DESOS_Programs[];
  public displayedColumns: string[] = ['program_ID'];
  //public displayedColumns: string[] = ['Program_ID', 'Program_Name', 'Street_Address', 'City', 'State', 'ZipCode', 'County', 'Executive_Director_Name',
  //  'Telephone', 'Fax', 'Executive_Director_Email', 'DESOS_Manager_Name', 'DESOS_Manager_Email', 'Emp_flg', 'Res_flg'];
  public dataSource: MatTableDataSource<DESOS_Programs>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //public dataSource: program[];
    /** program-list ctor */
  constructor(private route: ActivatedRoute, private router: Router, http: HttpClient,
    @Inject('BASE_URL') baseUrl: string, private APIservices: ProgramService) {
    this.loadPrograms();
  }

  ngOnInit() {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  loadPrograms() {
    this.APIservices.getProgramList().subscribe(result => {
      this.programlist = result;
      this.dataSource = new MatTableDataSource(this.programlist);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      /*console.log({ result });*/
    }, error => console.error(error));    
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  btnAddProgramClick = function () {
    this.router.navigate(['/admin/programadd']);
  }
}


