import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { helpText, DESOS_Individuals, DESOS_Outcomes } from '../dataentry';
import { dataentry_tab6_quest13to17 } from '../dataentry-tab6';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TempDataService } from 'src/app/services/temp-data.service';
import { DataentryService } from '../../services/dataentry.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DESOS_UserInfo } from 'src/app/account/account';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-dataentry-tab6',
  templateUrl: './dataentry-tab6.component.html',
  styleUrls: ['./dataentry-tab6.component.css'],
  providers: [TempDataService]
})
/** dataentry-tab6 component*/
export class DataentryTab6Component implements OnInit, AfterViewInit{

  public desosuserinfo: DESOS_UserInfo;
  questionData: any;

  q13ChunkNum: number = 2;
  q14ChunkNum: number = 2;
  q15ChunkNum: number = 1;
  q16ChunkNum: number = 3;
  
  /** dataentry-tab6 ctor */
  helpMessage: helpText;
  public DEtab6Quest13to17: dataentry_tab6_quest13to17;
  public isDisable13: boolean = false;
  public isDisable14: boolean = false;
  public isDisable15: boolean = false;
  public isDisable16: boolean = false;
  public isDisable17: boolean = false;
  public primary: number;
  private curEditOutComes: DESOS_Outcomes;
  public tab6FormGroup: FormGroup;
  constructor(private snackBar: MatSnackBar, private tempdata: TempDataService, private APIservices: DataentryService, private authentication: AuthenticationService) {
    this.helpMessage = new helpText();
    this.DEtab6Quest13to17 = new dataentry_tab6_quest13to17();
  }

  ngOnInit() {

    //if (sessionStorage.editoutcomes != null) {
    //  this.curEditOutComes = JSON.parse(sessionStorage.editoutcomes);
    //}

    this.curEditOutComes = this.tempdata.getCurrentoutcome();

    this.tab6FormGroup = new FormGroup({
      q15val: new FormControl('', [Validators.required]),
      q16val: new FormControl('', [Validators.required]),
      q17val: new FormControl('', [Validators.required]),
      q18val: new FormControl('', [Validators.required]),
      q19subval: new FormControl(''),
      q19val: new FormControl(''),
    });

    this.fetchQuestionWithOptions();

  }
  async fetchQuestionWithOptions() {
    try {
      // Define the range of question identifiers
      const startQuestionIndex = 13;
      const endQuestionIndex = 17;
      const questionPrefix = '';

      // Initialize an object to hold the data for each question
      this.questionData = {};

      // Fetch questions within the range
      for (let questionIndex = startQuestionIndex; questionIndex <= endQuestionIndex; questionIndex++) {
        const questionNumber = `${questionPrefix}${questionIndex}`;
        const inparam = new HttpParams().set('questionNumber', questionNumber);
        this.questionData[questionNumber] = await this.APIservices.getQuestionWithOptions(inparam).toPromise();
      }

      // Check if data for all questions is retrieved
      const allDataReceived = Object.keys(this.questionData).length >= (endQuestionIndex - startQuestionIndex + 1) &&
        Object.values(this.questionData).every(data => !!data);

      if (allDataReceived) {
        console.log('Data retrieved:', this.questionData[`${questionPrefix}${endQuestionIndex}`]); // Example log for the first question
        this.curEditOutComes = this.tempdata.getCurrentoutcome();
        console.log('curEditOutComes:', this.curEditOutComes);

        this.initFormGroup();
        this.q1AnswerDependent();

      } else {
        console.log('No data received');
      }
    } catch (error) {
      console.error('Failed to fetch question options:', error);
    }
  };
  ngAfterViewInit() {

  }


  q1AnswerDependent() {
    if (this.tempdata.getRosterAction() != "new") {
      if (this.curEditOutComes.q1val != "2" && this.curEditOutComes.q1val != "3" && this.curEditOutComes.q1val != "5") {
        this.disableA(true);
      }
      if (this.curEditOutComes.q1val == "5") {
        this.disableA(true);
        this.disableB(true);
      }
      this.primary = parseInt(this.curEditOutComes.q1val);
    }
  }

  initFormGroup() {

    this.DEtab6Quest13to17.strquestion13Selected = this.curEditOutComes.q15val;
    this.tab6FormGroup.controls["q15val"].setValue(this.curEditOutComes.q15val);
    this.DEtab6Quest13to17.strquestion14Selected = this.curEditOutComes.q16val;
    this.tab6FormGroup.controls["q16val"].setValue(this.curEditOutComes.q16val);
    this.DEtab6Quest13to17.strquestion15Selected = this.curEditOutComes.q17val;
    this.tab6FormGroup.controls["q17val"].setValue(this.curEditOutComes.q17val);
    this.DEtab6Quest13to17.strquestion16Selected = this.curEditOutComes.q18val;
    this.tab6FormGroup.controls["q18val"].setValue(this.curEditOutComes.q18val);
    if (this.DEtab6Quest13to17.strquestion16Selected == "0") {
      this.DEtab6Quest13to17.opt1question16Selected = true;
    }
    else {
      this.DEtab6Quest13to17.opt1question16Selected = false;
    }

    this.Q17Selected(this.curEditOutComes.q19val);
    this.tab6FormGroup.controls["q19val"].setValue(this.curEditOutComes.q19val);

    //if (this.curEditOutComes.q1val == "3") {
    //  this.tab4FormGroup.controls["q4val"].disable();
    //  this.isDisable3 = true;
    //}
  }

  hasError = (controlName: string, errorName: string) => {
    return this.tab6FormGroup.controls[controlName].hasError(errorName);
  }

  getEntries(curNewOutComes: DESOS_Outcomes): DESOS_Outcomes {
    try {

      curNewOutComes.q15val = this.tab6FormGroup.controls["q15val"].value;
      curNewOutComes.q16val = this.tab6FormGroup.controls["q16val"].value;
      curNewOutComes.q17val = this.tab6FormGroup.controls["q17val"].value;
      curNewOutComes.q18val = this.tab6FormGroup.controls["q18val"].value;
      curNewOutComes.q19val = this.getq19val();
      curNewOutComes.q19subval = this.tab6FormGroup.controls["q19subval"].value;

      sessionStorage.setItem("editoutcomes", JSON.stringify(this.curEditOutComes));
    }
    catch (Error) {
      console.log("Tab6:" + Error.message);
    }

    return curNewOutComes;

  }


  Q17Selected(inValue: string) {

    if (inValue.indexOf('ckRNS_SP') >= 0) {
      this.DEtab6Quest13to17.chk1question17Selected = true;
    }
    if (inValue.indexOf('ckRNS_ADG') >= 0) {
      this.DEtab6Quest13to17.chk2question17Selected = true;
    }
    if (inValue.indexOf('ckRNS_CC') >= 0) {
      this.DEtab6Quest13to17.chk3question17Selected = true;
    }
    if (inValue.indexOf('ckRNS_M') >= 0) {
      this.DEtab6Quest13to17.chk4question17Selected = true;
    }
    if (inValue.indexOf('ckRNS_NF') >= 0) {
      this.DEtab6Quest13to17.chk5question17Selected = true;
    }
    if (inValue.indexOf('ckRNS_O') >= 0) {
      this.DEtab6Quest13to17.chk6question17Selected = true;
      this.DEtab6Quest13to17.strquestion17OtherDes = this.curEditOutComes.q19subval;
      this.tab6FormGroup.controls["q19subval"].setValue(this.curEditOutComes.q19subval);
    }
  }

  getq19val(): string {
    var strResults = "";

    if (this.DEtab6Quest13to17.chk1question17Selected) {
      strResults = "ckRNS_SP,";
    }
    if (this.DEtab6Quest13to17.chk2question17Selected) {
      strResults = strResults + "ckRNS_ADG,";
    }
    if (this.DEtab6Quest13to17.chk3question17Selected) {
      strResults = strResults + "ckRNS_CC,";
    }
    if (this.DEtab6Quest13to17.chk4question17Selected) {
      strResults = strResults + "ckRNS_M,";
    }
    if (this.DEtab6Quest13to17.chk5question17Selected) {
      strResults = strResults + "ckRNS_NF,";
    }
    if (this.DEtab6Quest13to17.chk6question17Selected) {
      strResults = strResults + "ckRNS_O,";
    }
    return strResults;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
    });
  }

  closeSnackBar() {
    //this.snackBar.dismiss();
  }


  setOtherQ17(blnValue: boolean) {
    if (blnValue) {
      this.tab6FormGroup.controls["q19subval"].setValidators(Validators.required);
      this.tab6FormGroup.controls["q19subval"].updateValueAndValidity();
    }
    else {
      this.tab6FormGroup.controls["q19subval"].setValidators(null);
      this.tab6FormGroup.controls["q19subval"].updateValueAndValidity();
    }
  }

  onChangeQuest17(event: MatCheckboxChange) {
    if (event.source.name == "chk6question17Selected") {
      if (event.checked == true) {
        this.DEtab6Quest13to17.chk6question17Selected = true;
        this.DEtab6Quest13to17.strquestion17OtherDes = "";
        this.setOtherQ17(true);
      }
      else {
        this.DEtab6Quest13to17.chk6question17Selected = false;
        this.DEtab6Quest13to17.strquestion17OtherDes = "";
        this.setOtherQ17(false);
      }
    }

    this.tab6FormGroup.controls["q19val"].setValue(this.getq19val());
  }

  OnQuest16OptSelected(event: MatRadioChange) {
    if (event.value == "0") {
      this.DEtab6Quest13to17.chk1question17Selected = false;
      this.DEtab6Quest13to17.chk2question17Selected = false;
      this.DEtab6Quest13to17.chk3question17Selected = false;
      this.DEtab6Quest13to17.chk4question17Selected = false;
      this.DEtab6Quest13to17.chk5question17Selected = false;
      this.DEtab6Quest13to17.chk6question17Selected = false;
      this.DEtab6Quest13to17.strquestion17OtherDes = "";
      this.DEtab6Quest13to17.opt1question16Selected = true
      this.tab6FormGroup.controls["q19val"].setValidators(Validators.required);
      this.tab6FormGroup.controls["q19val"].updateValueAndValidity();
    }
    else {
      this.DEtab6Quest13to17.opt1question16Selected = false;

      this.DEtab6Quest13to17.chk1question17Selected = false;
      this.DEtab6Quest13to17.chk2question17Selected = false;
      this.DEtab6Quest13to17.chk3question17Selected = false;
      this.DEtab6Quest13to17.chk4question17Selected = false;
      this.DEtab6Quest13to17.chk5question17Selected = false;
      this.DEtab6Quest13to17.chk6question17Selected = false;
      this.DEtab6Quest13to17.strquestion17OtherDes = "";
      this.tab6FormGroup.controls["q19val"].setValidators(null);
      this.tab6FormGroup.controls["q19val"].updateValueAndValidity();
    }
    this.tab6FormGroup.controls["q19val"].setValue("");
  }

  disableA(blnDisable) {
    console.log("Q16,17: ",blnDisable);
    if (blnDisable) {
      this.tab6FormGroup.controls["q18val"].disable();
      this.DEtab6Quest13to17.chk1question17Selected = false;
      this.DEtab6Quest13to17.chk2question17Selected = false;
      this.DEtab6Quest13to17.chk3question17Selected = false;
      this.DEtab6Quest13to17.chk4question17Selected = false;
      this.DEtab6Quest13to17.chk5question17Selected = false;
      this.DEtab6Quest13to17.chk6question17Selected = false;
      this.DEtab6Quest13to17.strquestion17OtherDes = "";
      this.tab6FormGroup.controls["q19val"].setValidators(null);
      this.tab6FormGroup.controls["q19val"].updateValueAndValidity();
      this.tab6FormGroup.controls["q19val"].setValue("");
      this.tab6FormGroup.controls["q19subval"].setValue("");
    }
    else {
      this.tab6FormGroup.controls["q18val"].enable();
    }
    this.tab6FormGroup.controls["q18val"].setValue("");
    this.isDisable16 = blnDisable;
    this.isDisable17 = blnDisable;
    this.DEtab6Quest13to17.opt1question16Selected = !blnDisable;
  }

  disableB(blnValue) {
    this.tab6FormGroup.controls["q15val"].setValue("");
    this.tab6FormGroup.controls["q16val"].setValue("");
    this.tab6FormGroup.controls["q15val"].disable();
    this.tab6FormGroup.controls["q16val"].disable();

    this.isDisable13 = blnValue;
    this.isDisable14 = blnValue;
  }

  refresh($value) {
    if (this.primary != $value)
    {
      this.tab6FormGroup.controls["q17val"].setValue("");
      this.tab6FormGroup.controls["q16val"].setValue("");
      this.tab6FormGroup.controls["q15val"].setValue("");
      this.tab6FormGroup.controls["q18val"].setValue("");
      this.tab6FormGroup.controls["q19val"].setValue("");
      this.tab6FormGroup.controls["q19subval"].setValue("");
    }
  }

}
