<div class="register-photo">
  <nav class="navbar navbar-expand-lg navbar-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse " id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item" role="presentation"><a class="nav-link" [routerLink]="['/admin/userhome']"><mat-icon>home</mat-icon><strong class="mb-1">Home</strong></a></li>
        <li class="nav-item" role="presentation"><a class="nav-link" [routerLink]="['/searchrecords']"><mat-icon>search</mat-icon><strong class="mb-1">Search Records</strong></a></li>
        <li class="nav-item" role="presentation"><a class="nav-link" [routerLink]="['/dataentry/rosterlist']"><mat-icon>list</mat-icon><strong class="mb-1">Roster List</strong></a></li>
        <li class="nav-item" role="presentation"><a class="nav-link" [routerLink]="['/reports']"><mat-icon>filter_tilt_shift</mat-icon><strong class="mb-1">Run Reports</strong></a></li>
        <li class="nav-item" role="presentation"><a class="nav-link active" [routerLink]="['/admin']"><mat-icon>settings</mat-icon><strong class="mb-1">Administration</strong></a></li>
        <!--<li class="nav-item" role="presentation"><a class="nav-link text-white" [routerLink]="['/admin/changepassword']" href="#"><mat-icon>security</mat-icon><strong class="d-inline-block mb-2 text-white">Change Password</strong></a></li>
  <li class="nav-item" role="presentation"><a class="nav-link text-white" [routerLink]="['/account/login','logout']"><mat-icon>person</mat-icon><strong class="d-inline-block mb-1 text-white">Log out</strong></a></li>-->
      </ul>
    </div>
    <app-userinfo-menu></app-userinfo-menu>
  </nav>
</div>
