import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DESOS_StrResult } from '../dataentry/dataentry';
import { ChangePassword, DESOS_Email_Template, DESOS_Programs } from '../admin/program';
import { DESOS_State_List, DESOS_Reporting_Periods } from '../search/default';


@Injectable()
export class ProgramService {
  private apiURL: string;
  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient, private authentication: AuthenticationService) {
    this.apiURL = baseUrl;
  }

  getUserHeader() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Authorization': this.authentication.getUserAccessToken() })
    };
    return httpOptions;
  }

  getUserHeaderOption(inparam: HttpParams) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Authorization': this.authentication.getUserAccessToken() }),
      params: inparam
    };
    return httpOptions;
  }

  getStateSelectList() {
    return this.http.get<DESOS_State_List[]>(this.apiURL + 'api/DefaultData/GetStateSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_State_List[]>('Get State List'))
    );
  }

  programAdd(inProgram: DESOS_Programs): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/Program/AddProgram', inProgram, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('program add'))
    );
  }

  programEdit(inProgram: DESOS_Programs): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/Program/EditProgram', inProgram, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('program edit'))
    );
  }

  getProgram(inparam: HttpParams): Observable<DESOS_Programs> {
    return this.http.get<DESOS_Programs>(this.apiURL + 'api/Program/GetProgram', this.getUserHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_Programs>('program get'))
    );
  }

  getProgramList() {
    return this.http.get<DESOS_Programs[]>(this.apiURL + 'api/Program/GetProgramList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Programs[]>('Get Program List'))
    );
  }

  getReportingPeriodList() {
    return this.http.get<DESOS_Reporting_Periods[]>(this.apiURL + 'api/DefaultData/GetReportingPeriodList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Reporting_Periods[]>('Get Reporting Periods List'))
    );
  }

  updateReportingPeriod(inReportingPeriods: DESOS_Reporting_Periods): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/DefaultData/UpdateReportingPeriod', inReportingPeriods, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('program edit'))
    );
  }

 private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.error(message);
    // this.messageService.add(`HeroService: ${message}`);
  }

}
