import { Component, Injectable, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dataentry';

@Component({
    selector: 'app-dataentry-modal',
    templateUrl: './dataentry-modal.component.html',
    styleUrls: ['./dataentry-modal.component.css']
})
/** dataentry-modal component*/
export class DataentryModalComponent {
    /** dataentry-modal ctor */
  public strHeading: string;
  public strContent: string;
  constructor(public dialogRef: MatDialogRef<DataentryModalComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    if (this.data.action == "new") {
      if (this.data.status == "Success") {
        this.strHeading = "New Individual and New Outcome success:";
        this.strContent = "Individual :" + this.data.individual + " Outcome :" + this.data.outcome;
      }
      else {
        this.strHeading = "New Individual and New Outcome failed:";
        this.strContent = "Failed to create record. If your session expired please log-in again and try. Please contact administration." + this.data.individual + " Outcome :" + this.data.outcome;
      }
       
     
    }
    if (this.data.action == "copy") {
      if (this.data.status == "Success") {
        this.strHeading = "Copy Individual and New Outcome success:";
        this.strContent = "Individual :" + this.data.individual + " Outcome :" + this.data.outcome;
      }
      else {
        this.strHeading = "Copy Individual and New Outcome failed:";
        this.strContent = "Failed to create record. If your session expired please log-in again and try. Please contact administration." + this.data.individual + " Outcome :" + this.data.outcome;
      }
    }
    if (this.data.action == "edit") {
      if (this.data.status == "Success") {
        this.strHeading = "Edit Individual and Edit Outcome success:";
        this.strContent = "Individual :" + this.data.individual + " Outcome :" + this.data.outcome;
      }
      else {
        this.strHeading = "Edit Individual and Edit Outcome failed:";
        this.strContent = "Failed to create record. If your session expired please log-in again and try. Please contact administration." + this.data.individual + " Outcome :" + this.data.outcome;
      }
    }
  }

  dataChange(indata: DialogData) {
    this.data = indata;
    if (this.data.action == "new") {
      this.strHeading = "New Individual and New Outcome success:";
      this.strContent = "Individual :" + this.data.individual + " Outcome :" + this.data.outcome;
    }
    if (this.data.action == "copy") {
      this.strHeading = "Copy Individual and New Outcome success:";
      this.strContent = "Individual :" + this.data.individual + " Outcome :" + this.data.outcome;
    }
    if (this.data.action == "edit") {
      this.strHeading = "Edit Individual and Edit Outcome success:";
      this.strContent = "Individual :" + this.data.individual + " Outcome :" + this.data.outcome;
    }
  }
}
