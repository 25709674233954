<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<app-dataentry-modal #datamodal *ngIf="progressSpinner"></app-dataentry-modal>
<div class="input-group-prepend p-1 example-button-row" mat-raised-button color="primary">
  <div class="input-group mb-3 col-sm-4">
    <div class="input-group-prepend">
      <span class="input-group-text">Individual ID:</span>
    </div>
    <label type="text" class="form-control">{{IndividualId.strOutput}}</label>
  </div>
  <div class="input-group mb-3 col-sm-4">
    <div class="input-group-prepend">
      <span class="input-group-text" id="inputGroupFileAddon01">Outcome ID:</span>
    </div>
    <label type="text" class="form-control">{{OutcomeId.strOutput}}</label>
  </div>
  <div class="input-group-prepend align-items-sm-baseline col-sm-4" mat-raised-button color="primary">
    <button mat-raised-button color="primary" (click)="btnBackToRoster()">Back To Roster List</button>
  </div>
</div>
<p *ngIf="warningmessage" class="text-danger">{{warningmessage}}</p>
<mat-card class="main-card">
  <mat-horizontal-stepper [linear]="true" id="toggle-linear" #stepper>
    <mat-step label="IDD" [stepControl]="firstFormGroup" name="tab1Enabled" errorMessage="Fill out required value.">
      <app-dataentry-tab1 #tab1 (tab2To8Hide)="tabs2To8Hide($event)"></app-dataentry-tab1>
      <div *ngIf="!DETabsEnabled.tab2To7Enabled" class="row p-0">
        <div class="col-sm-12  align-self-center">
          <button mat-raised-button color="primary" (click)="btnSubmitForm()" [disabled]="blnDisabledSubmit">Submit Information</button>
        </div>
      </div>
    </mat-step>
    <mat-step label="DC" *ngIf="DETabsEnabled.tab2To7Enabled" name="tab2Enabled" [stepControl]="secondFormGroup" errorMessage="Fill out required value.">
      <app-dataentry-tab2 #tab2></app-dataentry-tab2>
    </mat-step>
    <mat-step label="DC-1" *ngIf="DETabsEnabled.tab2To7Enabled" name="tab3Enabled" [stepControl]="thirdFormGroup" errorMessage="Fill out required value.">
      <app-dataentry-tab3 #tab3 (q1change)="onQ1Change($event)"></app-dataentry-tab3>
    </mat-step>
    <mat-step label="2 To 7" *ngIf="DETabsEnabled.tab2To7Enabled" name="tab4Enabled" [stepControl]="fourthFormGroup" errorMessage="Fill out required value.">
      <app-dataentry-tab4 #tab4></app-dataentry-tab4>
    </mat-step>
    <mat-step label="8 To 12" *ngIf="DETabsEnabled.tab2To7Enabled" name="tab5Enabled" [stepControl]="fifthFormGroup" errorMessage="Fill out required value.">
      <app-dataentry-tab5 #tab5></app-dataentry-tab5>
    </mat-step>
    <mat-step label="13 To 17" *ngIf="DETabsEnabled.tab2To7Enabled" name="tab6Enabled" [stepControl]="sixthFormGroup" errorMessage="Fill out required value.">
      <app-dataentry-tab6 #tab6></app-dataentry-tab6>
    </mat-step>
    <mat-step label="17 To 20" *ngIf="DETabsEnabled.tab2To7Enabled" name="tab7Enabled" [stepControl]="seventhFormGroup" errorMessage="Fill out required value.">
      <app-dataentry-tab7 #tab7 (tab8Hide)="tabs8Hide($event)"></app-dataentry-tab7>
      <div *ngIf="DETabsEnabled.tab2To7Enabled && !DETabsEnabled.tab8Enabled" class="row p-0">
        <div class="col-sm-12  align-self-center">
          <button mat-raised-button color="primary" (click)="btnSubmitForm()" [disabled]="blnDisabledSubmit">Submit Information</button>
        </div>
      </div>
    </mat-step>
    <mat-step label="21 To 23" *ngIf="this.DETabsEnabled.tab8Enabled" name="tab8Enabled" [stepControl]="eighthFormGroup" errorMessage="Fill out required value.">
      <app-dataentry-tab8 #tab8 (q21change)="onQ21Change($event)"></app-dataentry-tab8>
      <div *ngIf="DETabsEnabled.tab8Enabled" class="row p-0">
        <div class="col-sm-12  align-self-center">
          <button mat-raised-button color="primary" (click)="btnSubmitForm()" [disabled]="blnDisabledSubmit">Submit Information</button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
  
</mat-card>
