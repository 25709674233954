<div class="col-sm-12 p-3" [formGroup]="reportparam">
  <div class="row col-sm-12 p-0">
    <div class="input-group mb-2 col-sm-8">
      <mat-form-field class="col-md-12">
        <mat-select placeholder="Select a DESOS Program" formControlName="program" (selectionChange)="onChangeProgram($event)">
          <mat-option [value]="-1">
            Select All
          </mat-option>
          <mat-option *ngFor="let program of programList" [value]="program.program_ID">
            {{program.program_Name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-4">
      <mat-form-field class="col-md-12">
        <mat-select placeholder="Select a Reporting Period" formControlName="reportingperiod">
          <mat-option *ngFor="let item of periodList" [value]="item.id">
            {{item.period}}: {{item.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="example-button-row text-center">
    <button mat-raised-button color="primary" (click)="btnBackClick()">Back</button>
    <button mat-raised-button color="primary" (click)="btnContinueClick()" [disabled]="!reportparam.valid">Continue</button>
  </div>
</div>
