import { NgModule } from '@angular/core';
import { FormsModule, FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ProgramAddComponent } from './program-add/program-add.component';
import { ProgramEditComponent } from './program-edit/program-edit.component';
import { ProgramListComponent } from './program-list/program-list.component';
import { AdminmenuComponent } from '../nav-menu/admin-menu/adminmenu.component';
import { AdminComponent } from './admin.component';
import { FooterComponent } from '../nav-menu/footer/footer.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserListComponent } from './user-list/user-list.component';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { UsersmenuComponent } from '../nav-menu/users/usersmenu.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { AdminsharedModule } from '../adminshared.module';
import { RequestUserListComponent } from './request-user-list/request-user-list.component';
import { UserAddComponent } from './user-add/user-add.component';
import { ManagerVerificationComponent } from './manager-verification/manager-verification.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { UserHomeComponent } from './user-home/user-home.component';
import { ReportingPeriodComponent } from './reporting-period/reporting-period.component';
import { ProgressDialogComponent } from '../nav-menu/progress-dialog/progress-dialog.component';
import { UserService } from '../services/user.service';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ManagerVDuplicateComponent } from './manager-v-duplicate/manager-v-duplicate.component';

@NgModule({
  declarations: [
    AdminComponent,
    UserHomeComponent,
    NavMenuComponent,
    AdminDashboardComponent,
    ProgramAddComponent,
    ProgramEditComponent,
    ProgramListComponent,
    UserListComponent,
    RequestUserListComponent,
    UserAddComponent,
    ManagerVerificationComponent,
    EmailTemplateComponent,
    ReportingPeriodComponent,
    ManagerVDuplicateComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AdminsharedModule,
    AdminRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatIconModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatSortModule,
    AngularEditorModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatListModule,
    MatDividerModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    AngularEditorModule,

  ],
  providers: [UserService],
  bootstrap: []
})
export class AdminModule {
}
