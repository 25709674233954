import { Component, Output, EventEmitter, OnInit, AfterViewChecked } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { helpText, DESOS_Individuals, DESOS_Outcomes } from '../dataentry';
import { dataentry_tab7_quest18to20 } from '../dataentry-tab7';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TempDataService } from 'src/app/services/temp-data.service';
import { DataentryService } from '../../services/dataentry.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DESOS_UserInfo } from 'src/app/account/account';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-dataentry-tab7',
  templateUrl: './dataentry-tab7.component.html',
  styleUrls: ['./dataentry-tab7.component.css'],
  providers: [TempDataService]
})
/** dataentry-tab7 component*/
export class DataentryTab7Component implements OnInit, AfterViewChecked {

  public desosuserinfo: DESOS_UserInfo;
  questionData: any;

  q19ChunkNum: number = 4;

  /** dataentry-tab7 ctor */
  helpMessage: helpText;
  public DEtab7Quest18to20: dataentry_tab7_quest18to20;
  private curEditOutComes: DESOS_Outcomes;
  public tab7FormGroup: FormGroup;
  @Output() tab8Hide = new EventEmitter<boolean>()
  public primary: number; 
  constructor(private snackBar: MatSnackBar, private tempdata: TempDataService, private APIservices: DataentryService, private authentication: AuthenticationService) {
    this.helpMessage = new helpText();
    this.DEtab7Quest18to20 = new dataentry_tab7_quest18to20();

  }

  ngOnInit() {

    this.curEditOutComes = this.tempdata.getCurrentoutcome();

    this.tab7FormGroup = new FormGroup({
      q20val1: new FormControl('', [Validators.required, Validators.min(0), Validators.max(99)]),
      q20val2: new FormControl('', [Validators.required, Validators.min(0), Validators.max(99)]),
      q21val: new FormControl('', [Validators.required]),
      q21subval: new FormControl(''),
      q22val: new FormControl('', [Validators.required]),
    });

    //if (sessionStorage.editoutcomes != null) {
    //  this.curEditOutComes = JSON.parse(sessionStorage.editoutcomes);
    this.fetchQuestionWithOptions();
    //}
  }
  async fetchQuestionWithOptions() {
    try {
      // Define the range of question identifiers
      const startQuestionIndex = 19;
      const endQuestionIndex = 20;
      const questionPrefix = '';

      // Initialize an object to hold the data for each question
      this.questionData = {};

      // Fetch questions within the range
      for (let questionIndex = startQuestionIndex; questionIndex <= endQuestionIndex; questionIndex++) {
        const questionNumber = `${questionPrefix}${questionIndex}`;
        const inparam = new HttpParams().set('questionNumber', questionNumber);
        this.questionData[questionNumber] = await this.APIservices.getQuestionWithOptions(inparam).toPromise();
      }
      let additionalNumber = '18-0';
      let additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      additionalNumber = '18-1';
      additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      additionalNumber = '18-2';
      additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();
      // Check if data for all questions is retrieved
      const allDataReceived = Object.keys(this.questionData).length >= (endQuestionIndex - startQuestionIndex + 1 + 3) &&
        Object.values(this.questionData).every(data => !!data);

      if (allDataReceived) {
        console.log('Data retrieved:', this.questionData[`${questionPrefix}${endQuestionIndex}`]); // Example log for the first question
        this.curEditOutComes = this.tempdata.getCurrentoutcome();
        console.log('curEditOutComes:', this.curEditOutComes);

        this.initFormGroup();

      } else {
        console.log('No data received');
      }
    } catch (error) {
      console.error('Failed to fetch question options:', error);
    }
  };
  ngAfterViewChecked() {
    if (this.DEtab7Quest18to20.strquestion20Selected == "0") {
      //this.tab8Hide.emit(false);
    }
    else {
      //this.tab8Hide.emit(true);
    }
  }

  initFormGroup() {

    this.DEtab7Quest18to20.strquestion18DisPpl = this.curEditOutComes.q20val1;
    this.tab7FormGroup.controls["q20val1"].setValue(this.curEditOutComes.q20val1);
    this.DEtab7Quest18to20.strquestion18NonDisPpl = this.curEditOutComes.q20val2;
    this.tab7FormGroup.controls["q20val2"].setValue(this.curEditOutComes.q20val2);
    this.Q21Selected(this.curEditOutComes.q21val);
    this.tab7FormGroup.controls["q21val"].setValue(this.curEditOutComes.q21val);
    this.DEtab7Quest18to20.strquestion20Selected = this.curEditOutComes.q22val;
    this.tab7FormGroup.controls["q22val"].setValue(this.curEditOutComes.q22val);
  }

  

  hasError = (controlName: string, errorName: string) => {
    return this.tab7FormGroup.controls[controlName].hasError(errorName);
  }

  getEntries(curNewOutComes: DESOS_Outcomes): DESOS_Outcomes {
    try {
      curNewOutComes.q20val1 = "" + (this.tab7FormGroup.controls["q20val1"].value);
      curNewOutComes.q20val2 = "" + (this.tab7FormGroup.controls["q20val2"].value);
      curNewOutComes.q21val = this.getq21val();
      curNewOutComes.q21subval = this.tab7FormGroup.controls["q21subval"].value;
      curNewOutComes.q22val = this.tab7FormGroup.controls["q22val"].value;

      //sessionStorage.setItem("editoutcomes", JSON.stringify(this.curEditOutComes));
    }
    catch (Error) {
      console.log("Tab7:" + Error.message);
    }

    return curNewOutComes;
  }

  onChangePrimary($value) {
    this.primary = $value;
    console.log("Primary7: ",this.primary);
    // if(value == 5) {
    //   this.tab8Hide.emit(false);
    // }
    // if(value != 5) {
    //   this.tab8Hide.emit(true);
    // }
  }

  Q21Selected(inValue: string) {

    if (inValue.indexOf('Tran_WB') >= 0) {
      this.DEtab7Quest18to20.chk1question19Selected = true;
    }
    if (inValue.indexOf('Tran_D') >= 0) {
      this.DEtab7Quest18to20.chk2question19Selected = true;
    }
    if (inValue.indexOf('Tran_PT') >= 0) {
      this.DEtab7Quest18to20.chk3question19Selected = true;
    }
    if (inValue.indexOf('Tran_VPP') >= 0) {
      this.DEtab7Quest18to20.chk4question19Selected = true;
    }
    if (inValue.indexOf('Tran_RPP') >= 0) {
      this.DEtab7Quest18to20.chk5question19Selected = true;
    }
    if (inValue.indexOf('Tran_FCF') >= 0) {
      this.DEtab7Quest18to20.chk6question19Selected = true;
    }
    if (inValue.indexOf('Tran_T') >= 0) {
      this.DEtab7Quest18to20.chk7question19Selected = true;
    }
    if (inValue.indexOf('Tran_Other') >= 0) {
      this.DEtab7Quest18to20.chk8question19Selected = true;
      this.DEtab7Quest18to20.strquestion19OtherDes = this.curEditOutComes.q21subval;
      this.tab7FormGroup.controls["q21subval"].setValue(this.curEditOutComes.q21subval);
    }
  }

  getq21val() : string {

    var strResults = "";

    if (this.DEtab7Quest18to20.chk1question19Selected) {
      strResults= "Tran_WB,";
    }
    if (this.DEtab7Quest18to20.chk2question19Selected) {
      strResults= strResults+ "Tran_D,";
    }
    if (this.DEtab7Quest18to20.chk3question19Selected) {
      strResults= strResults+ "Tran_PT,";
    }
    if (this.DEtab7Quest18to20.chk4question19Selected) {
      strResults= strResults+ "Tran_VPP,";
    }
    if (this.DEtab7Quest18to20.chk5question19Selected) {
      strResults= strResults+ "Tran_RPP,";
    }
    if (this.DEtab7Quest18to20.chk6question19Selected) {
      strResults= strResults+ "Tran_FCF,";
    }
    if (this.DEtab7Quest18to20.chk7question19Selected) {
      strResults= strResults+ "Tran_T,";
    }
    if (this.DEtab7Quest18to20.chk8question19Selected) {
      strResults= strResults+ "Tran_Other,";
    }

    return strResults;
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
    });
  }

  closeSnackBar() {
    //this.snackBar.dismiss();
  }

  setOtherQ19(blnValue: boolean) {
    if (blnValue) {
      this.tab7FormGroup.controls["q21subval"].setValidators(Validators.required);
      this.tab7FormGroup.controls["q21subval"].updateValueAndValidity();
    }
    else {
      this.tab7FormGroup.controls["q21subval"].setValidators(null);
      this.tab7FormGroup.controls["q21subval"].updateValueAndValidity();
    }
  }

  onChangeQuest19(event: MatCheckboxChange) {
    if (event.source.name == "chk8question19Selected") {
      if (event.checked == true) {
        this.DEtab7Quest18to20.chk8question19Selected = true;
        this.DEtab7Quest18to20.strquestion19OtherDes = "";
        this.setOtherQ19(true);
      }
      else {
        this.DEtab7Quest18to20.chk8question19Selected = false;
        this.DEtab7Quest18to20.strquestion19OtherDes = "";
        this.setOtherQ19(false);
      }
    }

    this.tab7FormGroup.controls["q21val"].setValue(this.getq21val());
  }

  refresh($value) {
    if (this.primary != $value) {
      this.tab7FormGroup.controls["q22val"].setValue("");
      this.tab7FormGroup.controls["q21val"].setValue("");
      this.tab7FormGroup.controls["q21subval"].setValue("");
      this.tab7FormGroup.controls["q20val1"].setValue("");
      this.tab7FormGroup.controls["q20val2"].setValue("");
      this.DEtab7Quest18to20.chk1question19Selected = false;
      this.DEtab7Quest18to20.chk2question19Selected = false;
      this.DEtab7Quest18to20.chk3question19Selected = false;
      this.DEtab7Quest18to20.chk4question19Selected = false;
      this.DEtab7Quest18to20.chk5question19Selected = false;
      this.DEtab7Quest18to20.chk6question19Selected = false;
      this.DEtab7Quest18to20.chk7question19Selected = false;
      this.DEtab7Quest18to20.chk8question19Selected = false;
      this.DEtab7Quest18to20.strquestion19OtherDes = "";
    }
  }

  onChangeQuest20(event: MatRadioChange) {
    if (event.value == "0") {
      this.tab8Hide.emit(false);
    }
    else {
      this.tab8Hide.emit(true);
    }

    if(this.primary == 5) {
      this.tab8Hide.emit(false);
    }
  }

  setChangeQuest20(event) {
    this.tab7FormGroup.controls["q22val"].setValue(event);
    //console.log("q22 | 20: ", event);
  }

  ontab8Hide(): string {
    return this.DEtab7Quest18to20.strquestion20Selected;
  }

}
