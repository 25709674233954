import { Component, Inject, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { User, DESOS_Users, DESOS_Programs_List, ChangePassword } from '../program';
// import { stringify } from '@angular/core/';
import { empty, Observable } from 'rxjs';
import { DESOS_UserInfo } from 'src/app/account/account';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { DESOS_StrResult } from 'src/app/dataentry/dataentry';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
  providers: [AuthenticationService, UserService]
})
/** user-list component*/
export class UserListComponent implements OnInit, AfterViewInit {
  /** user-list ctor */
  public userlist: DESOS_Users[];
  public userEdit: DESOS_Users;
  public programList: DESOS_Programs_List[];
  public displayedColumns: string[] = ['username'];
  public dataSource: MatTableDataSource<DESOS_Users>;
  private apiURL: string;
  private passChange: ChangePassword;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() programid: string = "";
  private desosuserinfo: DESOS_UserInfo;
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  private asyncResult: DESOS_StrResult;
  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private matDialog: MatDialog, private authentication: AuthenticationService, private APIServices : UserService) {
    this.apiURL = baseUrl;
    //http.get<DESOS_Programs_List[]>(baseUrl + 'api/Program/GetProgramSelectList').subscribe(result => {
    this.APIServices.getProgramList().subscribe(result => {
      this.programList = result;
    }, error => console.error(error));

    //let observable = new Observable(this.myObservableOne);
    //this.showProgressSpinner(observable);
  }

  ngOnInit() {
    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);
    this.desosuserinfo = this.authentication.getAccessToken();
      if (this.desosuserinfo.strProgram == "FullAccess") {
        this.desosuserinfo.strProgram = "";
      }
      //this.userlistChild.programChanged(this.desosuserinfo.strProgram);
   //}

    
    if (this.desosuserinfo.strProgram == "") {
      //this.http.get<DESOS_Users[]>(this.apiURL + 'api/User/GetUserList').subscribe(result => {
        this.APIServices.getUserList().subscribe(result => {
        this.userlist = result;
        this.dataSource = new MatTableDataSource(this.userlist);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }

      }, error => console.error(error));
    }
    else {
      this.programChanged(this.desosuserinfo.strProgram);
    }

  }

  ngAfterViewInit() {
   // this.showProgressSpinner(this.loadUserList());
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  myObservableOne(observer) {
    setTimeout(() => {
      observer.next("done waiting for 1 sec");
      observer.complete();
    }, 1000);
  }

  loadUserList() {
    return Observable.create(observer => {
      if (this.desosuserinfo.strProgram != "") {
        let inparams = new HttpParams()
          .set('programid', this.desosuserinfo.strProgram );
        //this.http.get<DESOS_Users[]>(this.apiURL + 'api/User/GetUserListByProgram', { params: inparams }).subscribe(result => {
          this.APIServices.getUserListByProgram(inparams).subscribe(result => {
          this.userlist = result;
          this.dataSource = new MatTableDataSource(this.userlist);
          this.dataSource.paginator = this.paginator;
          if (this.dataSource.data.length > 0) {
            this.dataSource.sort = this.sort;
            observer.next("User List loaded!");
            observer.complete();
          }
          else {
            observer.next("No data found!");
            observer.complete();
          }
        });
      }
      else {
        //this.http.get<DESOS_Users[]>(this.apiURL + 'api/User/GetUserList').subscribe(result => {
          this.APIServices.getUserList().subscribe(result => {
          this.userlist = result;
          this.dataSource = new MatTableDataSource(this.userlist);
          this.dataSource.paginator = this.paginator;
          if (this.dataSource.data.length > 0) {
            this.dataSource.sort = this.sort;
            observer.next("User List loaded!");
            observer.complete();
          }
          else {
            observer.next("No data found!");
            observer.complete();
          }
        });
      }
    });
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  //btnSaveClick(userIn: DESOS_Users) {
  //  //this.http.post(this.baseUrl + 'api/Program/AddProgram', programadd).subscribe
  //  //  (data => { console.log('sent ' + data) })
  //  //<program>(baseUrl + 'api/Program/GetProgramList')
  //  this.userEdit = userIn;
  //}

  btnSavePassChangeClick(userIn: DESOS_Users) {
    this.userEdit = userIn;
    this.passChange = new ChangePassword();
    this.passChange.username = this.userEdit.username;
    this.passChange.newpassword = this.userEdit.hashPassword;
    this.passChange.resetpassflag = this.getCheckBoxValue(this.userEdit.reset_psswd_flg.toString()).toString();
    //params = params.append("loginUser", this.loginFormControl.value);
    this.showProgressSpinner(this.changeUserPassword(this.passChange));
    //this.http.post<DESOS_StrResult>(this.apiURL + 'api/User/ChangeUserPassword', this.passChange).subscribe(result => {
    //  var result = result;
    //}, error => console.error(error));
  }

  changeUserPassword(passChange: ChangePassword) {
    return Observable.create(observer => {
      //this.http.post<DESOS_StrResult>(this.apiURL + 'api/User/ChangeUserPassword', passChange)
      this.APIServices.changeUserPassword(passChange)
        .subscribe((result) => {
          this.asyncResult = JSON.parse(JSON.stringify(result));
          if (this.asyncResult != null) {
            if (this.asyncResult.strOutput == "Success") {
              alert("Password changed successfully!");
              observer.next("User added successfully!");
              observer.complete();
            }
            else {
              alert("Error while changing user password. Please contact DESOS administrator.");
              observer.next(this.asyncResult.strOutput);
              observer.complete();
            }
          }
        });
    });
  }

  updateUserInformation(userIn: DESOS_Users) {
    return Observable.create(observer => {
      //this.http.post(this.apiURL + 'api/User/ChangeUserInfo', userIn)
      this.APIServices.changeUserInfo(userIn)
        .subscribe((result) => {
          this.asyncResult = JSON.parse(JSON.stringify(result));
          if (this.asyncResult != null) {
            if (this.asyncResult.strOutput == "Success") {
              alert("User information changed successfully!");
              observer.next("User information changed successfully!");
              observer.complete();
            }
            else {
              alert("Error while changing user information. Please contact DESOS administrator.");
              observer.next(this.asyncResult.strOutput);
              observer.complete();
            }
          }
        });
    });
  }

  btnSaveClick(userIn: DESOS_Users) {
    this.userEdit = userIn;
    this.userEdit.account_Active_flg = this.getCheckBoxValue(userIn.account_Active_flg.toString());
    this.userEdit.reset_psswd_flg = this.getCheckBoxValue(userIn.reset_psswd_flg.toString());
    this.userEdit.adminLevel1 = this.getCheckBoxValue(userIn.adminLevel1.toString());
    this.userEdit.adminLevel2 = this.getCheckBoxValue(userIn.adminLevel2.toString());
    this.userEdit.adminDataEditDESOS = this.getCheckBoxValue(userIn.adminDataEditDESOS.toString());
    this.userEdit.adminDataEntryDESOS = this.getCheckBoxValue(userIn.adminDataEntryDESOS.toString());
    this.userEdit.adminReportsDESOS = this.getCheckBoxValue(userIn.adminReportsDESOS.toString());
    this.userEdit.adminProgDESOS = userIn.adminProgDESOS.toString();
    //this.passChange = new ChangePassword();
    //this.passChange.username = this.userEdit.username;
    //this.passChange.newpassword = this.userEdit.hashPassword;
    //this.passChange.resetpassflag = (this.userEdit.reset_psswd_flg.toString() == "true") ? '1' : '0';
    //params = params.append("loginUser", this.loginFormControl.value);
    this.showProgressSpinner(this.updateUserInformation(this.userEdit));
    this.showProgressSpinner(this.loadUserList());
    //this.http.post(this.apiURL + 'api/User/ChangeUserInfo', this.userEdit).subscribe(result => {
    //  var result = result;
    //}, error => console.error(error));
  }

  getCheckBoxValue(strValue: string): number {
    var blnResults: number = 0;
    if (strValue == "true" || strValue == "1") {
      blnResults = 1;
    }
    else if (strValue == "false" || strValue == "0") {
      blnResults = 0;
    }
    return blnResults
  }

  deleteUserAccount(userIn: DESOS_Users) {
    return Observable.create(observer => {
      //this.http.post(this.apiURL + 'api/User/DeleteUser', userIn)
      this.APIServices.deleteUser(userIn)
        .subscribe((result) => {
          this.asyncResult = JSON.parse(JSON.stringify(result));
          if (this.asyncResult != null) {
            if (this.asyncResult.strOutput == "Success") {
              alert("User deleted successfully!");
              observer.next("User deleted successfully!");
              observer.complete();
            }
            else {
              alert("Error while changing user password. Please contact DESOS administrator.");
              observer.next(this.asyncResult.strOutput);
              observer.complete();
            }
          }
        });
    });
  }

  btnDeleteClick(userIn: DESOS_Users) {
    this.userEdit = userIn;
    //this.passChange = new ChangePassword();
    //this.passChange.username = this.userEdit.username;
    //this.passChange.newpassword = this.userEdit.hashPassword;
    //this.passChange.resetpassflag = (this.userEdit.reset_psswd_flg.toString() == "true") ? '1' : '0';
    //params = params.append("loginUser", this.loginFormControl.value);

    //this.http.post(this.apiURL + 'api/User/DeleteUser', this.userEdit).subscribe(result => {
    //  var result = result;
    //}, error => console.error(error));

    this.showProgressSpinner(this.deleteUserAccount(this.userEdit));
    this.showProgressSpinner(this.loadUserList());
  }

  btnAddUserClick = function () {
    this.router.navigate(['/admin/useradd']);
  }

  btnBackClick() {
    this.router.navigate(['/admin/programlist']);
  }

  programChanged($event) {
    //alert($event);
    if ($event != "-1") {
      let inparams = new HttpParams()
        .set('programid', $event);
      this.desosuserinfo.strProgram = $event;
      //this.http.get<DESOS_Users[]>(this.apiURL + 'api/User/GetUserListByProgram', { params: inparams }).subscribe(result => {
      this.APIServices.getUserListByProgram(inparams).subscribe(result => {
        this.userlist = result;
        this.dataSource = new MatTableDataSource(this.userlist);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }, error => console.error(error));
    }
    else {
      //this.http.get<DESOS_Users[]>(this.apiURL + 'api/User/GetUserList').subscribe(result => {
      this.APIServices.getUserList().subscribe(result => {
        this.userlist = result;
        this.dataSource = new MatTableDataSource(this.userlist);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }, error => console.error(error));
    }
  }
}
