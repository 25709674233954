<form [formGroup]="tab8FormGroup">
  <ng-template matStepLabel>DC</ng-template>
  <div class="row">
    <div class="col-sm dataentry-column">
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable21) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['21-0']; else loading" class="col-sm-6 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['21-0']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_41}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['21-0']; else loading" class="col-sm-8 p-0">
            <div class="col-sm-8 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="q23val">
                  <mat-radio-button color="primary"
                                    class="data-radio-button"
                                    *ngIf="primary != 0"
                                    (change)="OnQuest21OptSelected($event)"
                                    value="0">
                  <span [innerHTML]="questionData['21-0'].options[0].content"></span>
                  </mat-radio-button>
                  <mat-radio-button color="primary"
                                    class="data-radio-button"
                                    (change)="OnQuest21OptSelected($event)"
                                    value="1">
                  <span [innerHTML]="questionData['21-0'].options[1].content"></span>
                  </mat-radio-button>
                  <mat-radio-button color="primary"
                                    class="data-radio-button pt-3"
                                    (change)="OnQuest21OptSelected($event)"
                                    value="2">
                  <span [innerHTML]="questionData['21-0'].options[2].content"></span>
                  </mat-radio-button>
                  <mat-radio-button color="primary"
                                    class="data-radio-button pt-3" *ngIf="primary != 3"
                                    (change)="OnQuest21OptSelected($event)"
                                    value="3">
                  <span [innerHTML]="questionData['21-0'].options[3].content"></span>
                  </mat-radio-button>
                  <mat-radio-button color="primary"
                                    class="data-radio-button pt-3" *ngIf="primary != 4"
                                    (change)="OnQuest21OptSelected($event)"
                                    value="4">
                  <span [innerHTML]="questionData['21-0'].options[4].content"></span>
                  </mat-radio-button>
                  <mat-radio-button color="primary"
                                    class="data-radio-button pt-3"
                                    (change)="OnQuest21OptSelected($event)"
                                    value="5">
                  <span [innerHTML]="questionData['21-0'].options[5].content"></span>
                  </mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button pt-3"
                                    (change)="OnQuest21OptSelected($event)"
                                    value="6">
                  <span [innerHTML]="questionData['21-0'].options[6].content"></span>
                  </mat-radio-button>
                </mat-radio-group>
              </section>
              <mat-error class="error_warning pt-3" *ngIf="hasError('q23val', 'required')">Please select any one</mat-error>
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card *ngIf="!isNone" class="dataentry-card" [hidden]="!DEtab8Quest.opt5question21Selected" name="opt5question21Selected">
        <div class="row p-0">
          <div *ngIf="questionData['21-1']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary  question_text"><span [innerHTML]="questionData['21-1']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_20}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['21-1']; else loading" class="col-sm-8 p-0">
            <div class="col-sm-12 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="q23subval3">
                  <mat-radio-button *ngFor="let option of questionData['21-1'].options"
                                    [value]="''+option.optionId"
                                    color="primary"
                                    class="data-radio-button"
                                    (change)="onChangeQuest21A($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                  <!--<mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest21A($event)" value="0">within the facility of the sheltered workshop</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest21A($event)" value="1">within an office/shopping area (e.g., strip mall)</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest21A($event)" value="2">stand-alone building</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest21A($event)" value="3">Other</mat-radio-button>-->
                </mat-radio-group>
              </section>
              <mat-error class="error_warning" *ngIf="hasError('q23subval3', 'required')">Please check one</mat-error>
              <section class="col-sm-12" [hidden]="!DEtab8Quest.opt4question21ASelected" name="opt4question21ASelected">
                <mat-form-field class="mat-input col-sm-12">
                  <input matInput placeholder="Other Descriptions" formControlName="q23subval4" maxlength="128">
                </mat-form-field>
              </section>
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card *ngIf="!isNone" class="dataentry-card" [hidden]="!DEtab8Quest.opt6question21Selected" name="opt6question21Selected">
        <div class="row p-0">
          <div *ngIf="questionData['21-2']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary  question_text"><span [innerHTML]="questionData['21-2']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_21}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['21-2']; else loading" class="col-sm-8 p-0">
            <div class="col-sm-12 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="q23subval5">
                  <mat-radio-button *ngFor="let option of questionData['21-2'].options"
                                    [value]="''+option.optionId"
                                    color="primary"
                                    class="data-radio-button"
                                    (change)="onChangeQuest21B($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                  <!--<mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest21B($event)" value="0">Retired</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest21B($event)" value="1">Choice</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest21B($event)" value="2">Volunteer</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest21B($event)" value="6">Pre-Employment Transition Services (Pre-ETS)</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest21B($event)" value="3">Community access</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest21B($event)" value="4">Looking for job<br />(e.g., Discovery, work-based learning, job club)</mat-radio-button>-->
                  <!--<mat-label class="text-nowrap" style="font-size:12px;">(e.g., Discovery, work-based learning, job club)</mat-label>-->
                  <!--<mat-radio-button color="primary" class="data-radio-button pt-3" (change)="onChangeQuest21B($event)" value="5">Other</mat-radio-button>-->
                </mat-radio-group>
              </section>
              <mat-error class="error_warning pt-3" *ngIf="hasError('q23subval5', ' required')">Please check one</mat-error>
              <section class="col-sm-12" [hidden]="!DEtab8Quest.opt7question21BSelected" name="opt7question5Selected">
                <mat-form-field class="mat-input col-sm-12 pt-3">
                  <input matInput placeholder="Other Descriptions" formControlName="q23subval6" maxlength="128">
                </mat-form-field>
              </section>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-sm dataentry-column">
      <mat-card *ngIf="!isNone" class="dataentry-card" [ngStyle]="{'background': (isDisable22) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['22']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary  question_text"><span [innerHTML]="questionData['22']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_42}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['22']; else loading" class="col-sm-8 p-0">
            <section>
              <mat-label style="font-size:12px;"><span [innerHTML]="questionData['22']?.question?.extraDesc"></span></mat-label>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <input matInput placeholder="Hours" formControlName="q24valh" maxlength="7">
                <!--<mat-error *ngIf="hasError('q24valh', 'required')">Hours is required</mat-error>-->
              </mat-form-field>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <!--<input matInput placeholder="Minutes" formControlName="q24valm" maxlength="7">-->
                <mat-select placeholder="Minutes" formControlName="q24valm">
                  <mat-option value="0">00</mat-option>
                  <mat-option value="5">30</mat-option>
                </mat-select>
              </mat-form-field>
            </section>
            <mat-error class="error_warning" *ngIf="hasError('q24valh', 'max')">Maximum 40 hours</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q24valh', 'min')">Minimum 0 hours</mat-error>
          </div>
        </div>
      </mat-card>
      <mat-card *ngIf="!isNone" class="dataentry-card" [ngStyle]="{'background': (isDisable23) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['23']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['23']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_28}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['23']; else loading" class="col-sm-8 p-0">
            <section>
              <mat-label style="font-size:12px;"><span [innerHTML]="questionData['23']?.question?.extraDesc"></span></mat-label>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <input matInput type="number" placeholder="Hours" formControlName="q26valh" maxlength="7">
                <!--<mat-error *ngIf="hasError('q26valh', 'required')">Hours is required</mat-error>-->
              </mat-form-field>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <mat-select placeholder="Minutes" formControlName="q26valm">
                  <mat-option value="0">00</mat-option>
                  <mat-option value="5">30</mat-option>
                </mat-select>
                <!--<mat-error *ngIf="hasError('q26valm', 'required')">Minutes is required</mat-error>-->
                <!--<input matInput placeholder="Minutes" formControlName="q26valm" [attr.disabled]="isDisable8" maxlength="7">-->
              </mat-form-field>
            </section>
            <mat-error class="error_warning" *ngIf="hasError('q26valh', 'max')">Maximum 40 hours</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q26valh', 'min')">Minimum 0 hours</mat-error>
          </div>
        </div>
      </mat-card>
      <mat-card *ngIf="!isNone" class="dataentry-card" [ngStyle]="{'background': (isDisable24) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['23']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary  question_text"><span [innerHTML]="questionData['24']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_43}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-8 p-0">
            <section>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <input matInput placeholder="Paid $" formControlName="q25val" type="number" [disabled]="DEtab8Quest.opt5or6question21BSelected" maxlength="5" required>
              </mat-form-field>
            </section>
            <mat-error class="error_warning" *ngIf="hasError('q25val', 'required')">Paid $ is required</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q25val', 'max')">Maximum Paid ${{paidMax}}</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q25val', 'min')">Minimum Paid ${{paidMin}}</mat-error>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="example-button-row text-center">
    <button mat-raised-button matStepperPrevious color="primary">Back</button>
  </div>
</form>
<ng-template #loading>
  <p>Loading question and options...</p>
</ng-template>
