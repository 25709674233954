<form [formGroup]="tab2FormGroup">
  <ng-template matStepLabel>DC</ng-template>
  <div class="row">
    <div class="col-sm-7 dataentry-column">
      <mat-card class="dataentry-card">
        <div class="row p-0">
          <div *ngIf="questionData['d12-0']; else loading" class="col-sm-3 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d12-0']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_12}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['d12-1'] && questionData['d12-2']; else loading" class="col-sm-9 p-0">
            <div class="row p-0">
              <div class="col-sm-3 p-0">
                <section>
                  <!--<mat-radio-group name="idOrdd">-->
                  <mat-checkbox *ngFor="let option of questionData['d12-0'].options" class="data-radio-button" color="primary" formControlName="chkpdLval" (change)="hideShowDDType($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-checkbox>
                  <!--<mat-checkbox class="data-radio-button" color="primary" formControlName="chkpdLval" (change)="hideShowDDType($event)">ID/DD</mat-checkbox>-->
                  <!--</mat-radio-group>-->
                  <section [hidden]="!DEtab2Quest1.isIDorDDSelected" name="isIDorDDSelected">
                    <mat-radio-group class="data-radio-group" formControlName="pdLsubval">
                      <mat-radio-button *ngFor="let option of questionData['d12-1'].options"
                                        name="'optType'+option.content"
                                        [value]="''+option.optionId"
                                        color="primary"
                                        class="data-radio-button"
                                        (click)="unSelectReason()">
                        <span [innerHTML]="option.content"></span>
                      </mat-radio-button>
                      <!--<mat-radio-button color="primary" class="data-radio-button" name="optTypeMidile" (click)="unSelectReason()" value="0">Mild</mat-radio-button>
                      <mat-radio-button color="primary" class="data-radio-button" name="optTypeModerate" (click)="unSelectReason()" value="1">Moderate</mat-radio-button>
                      <mat-radio-button color="primary" class="data-radio-button" name="optTypeSevere" (click)="unSelectReason()" value="2">Severe</mat-radio-button>
                      <mat-radio-button color="primary" class="data-radio-button" name="optTypeProfound" (click)="unSelectReason()" value="3">Profound</mat-radio-button>-->
                    </mat-radio-group>
                  </section>
                </section>
              </div>
              <div class="col-sm-9 p-0">
                <!--<section>-->
                <mat-radio-group class="row p-0" formControlName="pdLval">
                  <mat-radio-button *ngFor="let option of questionData['d12-2'].options"
                                    name="'optDDescription'+option.content"
                                    [value]="''+option.optionId"
                                    color="primary"
                                    class="data-radio-button col-sm-6 col-md-5 p-0"
                                    (click)="unSelectIDorDD()">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                  <!--<mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionAutism" (click)="unSelectIDorDD()" value="1">Autism</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionMentalIllness" (click)="unSelectIDorDD()" value="2">Mental Illness</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionBrainInjury" (click)="unSelectIDorDD()" value="3">Brain Injury</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionPhysical" (click)="unSelectIDorDD()" value="4">Physical</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionVisualImpairment" (click)="unSelectIDorDD()" value="5">Blind or Visual Impairment</mat-radio-button>-->
                  <!--</mat-radio-group>-->
                  <!--</section>-->
                  <!--</div>
                  <div class="col-sm-4 p-0">-->
                  <!--<section>-->
                  <!--<mat-radio-group class="data-radio-group" name="optDDDSelected" formControlName="pdLval">-->
                  <!--<mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionCerebralPalsy" (click)="unSelectIDorDD()" value="6">Cerebral Palsy</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionLearningDisability" (click)="unSelectIDorDD()" value="7">Learning Disability</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionHearingImpaired" (click)="unSelectIDorDD()" value="8">Deaf or Hard of Hearing</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionSeizureDisorder" (click)="unSelectIDorDD()" value="9">Seizure Disorder</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionOtherHealthImp" (click)="unSelectIDorDD()" value="10">Other Health Imp</mat-radio-button>-->
                </mat-radio-group>
                <!--</section>-->
              </div>
              <mat-error class="error_warning" *ngIf="hasError('pdLsubval', 'required')">Please check one</mat-error>
              <mat-error class="error_warning" *ngIf="hasError('pdLval', 'required')">Please check one</mat-error>
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card class="dataentry-card">
        <div class="row p-0">
          <div *ngIf="questionData['d13-0']; else loading" class="col-sm-3 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d13-0']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_13}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['d13-1'] && questionData['d13-2']; else loading" class="col-9 p-0">
            <div class="row p-0">
              <div class="col-sm-3 p-0">
                <mat-radio-group class="data-radio-group">
                  <mat-checkbox *ngFor="let option of questionData['d13-0'].options"
                                class="data-radio-button"
                                color="primary"
                                formControlName="chksdLval"
                                (change)="hideShowDDType1($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-checkbox>
                  <!--<mat-checkbox class="data-radio-button" color="primary" formControlName="chksdLval" (change)="hideShowDDType1($event)">ID/DD</mat-checkbox>-->

                  <section [hidden]="!DEtab2Quest2.isIDorDDSelected" name="isIDorDDSelected">
                    <mat-radio-group class="data-radio-group" formControlName="sdLsubval">
                      <mat-radio-button *ngFor="let option of questionData['d13-1'].options"
                                        name="'optType'+option.content+'1'"
                                        [value]="''+option.optionId"
                                        color="primary"
                                        class="data-radio-button"
                                        (click)="unSelectReason1()">
                        <span [innerHTML]="option.content"></span>
                      </mat-radio-button>
                      <!--<mat-radio-button color="primary" class="data-radio-button" name="optTypeMidile1" (click)="unSelectReason1()" value="0">Mild</mat-radio-button>
                      <mat-radio-button color="primary" class="data-radio-button" name="optTypeModerate1" (click)="unSelectReason1()" value="1">Moderate</mat-radio-button>
                      <mat-radio-button color="primary" class="data-radio-button" name="optTypeSevere1" (click)="unSelectReason1()" value="2">Severe</mat-radio-button>
                      <mat-radio-button color="primary" class="data-radio-button" name="optTypeProfound1" (click)="unSelectReason1()" value="3">Profound</mat-radio-button>-->
                    </mat-radio-group>
                  </section>
                  <mat-radio-group class="data-radio-group" formControlName="sdLvalnone">
                    <mat-radio-button color="primary" class="data-radio-button" (click)="unSelectoptNone()" name="optNoneSelected" value="11">None</mat-radio-button>
                  </mat-radio-group>
                </mat-radio-group>
              </div>
              <div class="col-sm-9 p-0">
                <!--<div class="row col-sm-12 p-0">-->
                <mat-radio-group class="row p-0" formControlName="sdLval">
                  <!--<div class="col-sm-6 p-0">-->
                  <mat-radio-button *ngFor="let option of questionData['d13-2'].options"
                                    name="'optDDescription'+option.content+'1'"
                                    [value]="''+option.optionId"
                                    color="primary"
                                    class="data-radio-button col-sm-6 col-md-5 p-0"
                                    (click)="unSelectIDorDD1()">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                  <!--<mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionAutism1" (click)="unSelectIDorDD1()" value="1">Autism</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionMentalIllness1" (click)="unSelectIDorDD1()" value="2">Mental Illness</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionBrainInjury1" (click)="unSelectIDorDD1()" value="3">Brain Injury</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionPhysical1" (click)="unSelectIDorDD1()" value="4">Physical</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionVisualImpairment1" (click)="unSelectIDorDD1()" value="5">Blind or Visual Impairment</mat-radio-button>-->
                  <!--</div>
                  <div class="col-sm-6 p-0">-->
                  <!--<mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionCerebralPalsy1" (click)="unSelectIDorDD1()" value="6">Cerebral Palsy</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionLearningDisability1" (click)="unSelectIDorDD1()" value="7">Learning Disability</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionHearingImpaired1" (click)="unSelectIDorDD1()" value="8">Deaf or Hard of Hearing</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionSeizureDisorder1" (click)="unSelectIDorDD1()" value="9">Seizure Disorder</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button col-sm-6 col-md-5 p-0" name="optDDescriptionOtherHealthImp1" (click)="unSelectIDorDD1()" value="10">Other Health Imp</mat-radio-button>-->
                  <!--</div>-->
                </mat-radio-group>
                <!--</div>-->
              </div>
              <mat-error class="error_warning" *ngIf="hasError('sdLsubval', 'required')">Please check one</mat-error>
              <mat-error class="error_warning" *ngIf="hasError('sdLval', 'required')">Please check one</mat-error>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-sm-5 dataentry-column">
      <mat-card class="dataentry-card">
        <div class="row p-0">
          <div *ngIf="questionData['d14']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d14']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_14}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['d14']; else loading" class="col-sm-8 p-0">
            <div class="col-sm-8 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="mbYval" required>
                  <mat-radio-button *ngFor="let option of questionData['d14'].options"
                                    [value]="''+option.optionId"
                                    color="primary"
                                    class="data-radio-button">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                  <!--<mat-radio-button color="primary" class="data-radio-button" value="0">Ambulatory</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" value="1">Wheel Chair</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" value="2">Ambulatory with Assistance</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" value="3">Use of Walker or Other Aid</mat-radio-button>-->
                </mat-radio-group>
                <mat-error class="error_warning" *ngIf="hasError('mbYval', 'required')">Please select any one</mat-error>
              </section>
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card class="dataentry-card">
        <div class="row p-0">
          <div *ngIf="questionData['d15']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d15']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_15}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['d15']; else loading" class="col-sm-8 p-0">
            <div class="col-sm-8 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="bIval" required>
                  <mat-radio-button *ngFor="let option of questionData['d15'].options"
                                    [value]="''+option.optionId"
                                    color="primary"
                                    class="data-radio-button"
                                    (change)="onChangeQuest4($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                  <!--<mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest4($event)" value="1">Mild</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest4($event)" value="2">Moderate</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest4($event)" value="3">Severe</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest4($event)" value="0">None (doesn't interfere with job)</mat-radio-button>-->
                </mat-radio-group>
                <mat-error class="error_warning" *ngIf="hasError('bIval', 'required')">Please select any one</mat-error>
              </section>
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card class="dataentry-card">
        <div class="row p-0">
          <div *ngIf="questionData['d16']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d16']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_16}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['d16']; else loading" class="col-sm-8 p-0">
            <div class="col-sm-8 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="los" required>
                  <mat-radio-button *ngFor="let option of questionData['d16'].options"
                                    [value]="''+option.optionId"
                                    color="primary"
                                    class="data-radio-button"
                                    (change)="onChangeQuest5($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                  <!--<mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest5($event)" value="0">Continuous</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest5($event)" value="1">Intermittent</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest5($event)" value="2">Occasional</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest5($event)" value="3">None</mat-radio-button>-->
                </mat-radio-group>
                <mat-error class="error_warning" *ngIf="hasError('los', 'required')">Please select any one</mat-error>
              </section>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="example-button-row text-center">
    <button mat-raised-button matStepperPrevious color="primary">Back</button>
    <button mat-raised-button matStepperNext [disabled]="!tab2FormGroup.valid" color="primary">Next</button>
  </div>
</form>
<ng-template #loading>
  <p>Loading question and options...</p>
</ng-template>
