export class dataentry_tab3_quest1_2 {
  public question1Text: string = "";
  public question2Text: string = "";

  public optquestion1Selected: string = "0";

  public strquestion2Selected: string = "";

  public chk1question2Selected: boolean = false;
  public chk2question2Selected: boolean = false;
  public chk3question2Selected: boolean = false;
  public chk4question2Selected: boolean = false;
  public chk5question2Selected: boolean = false;
  public chk6question2Selected: boolean = false;
  public chk7question2Selected: boolean = false;
  public chk8question2Selected: boolean = false;
}

export class dataentry_tab3_quest3 {
  public question1Text: string = "";
  public optquestion3Selected: string = "";

  public opt5question3Selected: boolean = false;
  public opt6question3Selected: boolean = false;

  public opt4question4Selected: boolean = false;
  public optquestion4Selected: string = "";
  public optquestion4OtherDes: string = "";

  public opt7question5Selected: boolean = false;
  public optquestion5Selected: string = "";
  public optquestion5OtherDes: string = "";
}
