import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DESOS_StrResult } from '../dataentry/dataentry';
import { DefaultService } from '../services/default.service';
import { AuthenticationService } from '../services/authentication.service';
import { DESOS_Homepage_Text } from '../models/desos-homepage-text';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
  providers: [AuthenticationService, DefaultService]
})
export class AccountComponent implements OnInit {
  private apiURL: string;
  public homepageText: DESOS_Homepage_Text;
  public DESOSresults: DESOS_StrResult;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authentication: AuthenticationService,
    private APIservices: DefaultService
  ) { }

  ngOnInit() {
    this.GetAccessToken();
  }

  GetAccessToken() {
    this.APIservices.getToken().subscribe((result) => {
      this.DESOSresults = JSON.parse(JSON.stringify(result));
      if (this.DESOSresults && this.DESOSresults.strOutput) {
        this.authentication.setGuestToken(this.DESOSresults.strOutput);
        this.loadHomepageText();
      }
    });
  }

  loadHomepageText() {
    this.APIservices.getHomepageText().subscribe((data) => {
      this.homepageText = data;
    });
  }

  btnAccountClick = () => {
    this.router.navigate(['./forgetpassword']);
  };
}
