import { Component, EventEmitter, Input, Output, OnInit, Inject } from '@angular/core';
import { dataentry_tab1_quest11 } from 'src/app/dataentry/dataentry-tab1';
import { helpText, DataEntry_tabAvailability, DESOS_Search_Fields } from 'src/app/dataentry/dataentry';
import { DESOS_Programs_List } from 'src/app/admin/program';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DESOS_Cities_List, DESOS_Counties_List, DESOS_Genders_List, DESOS_Races_List, DESOS_Month_List, DESOS_Period_List } from '../default';
import { HttpClient } from '@angular/common/http';
import { DESOS_UserInfo } from 'src/app/account/account';
// import { DISABLED } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataentryService } from 'src/app/services/dataentry.service';


@Component({
  selector: 'app-archive-search',
  templateUrl: './archive-search.component.html',
  styleUrls: ['./archive-search.component.css'],
  providers: [AuthenticationService]
})
/** archive-search component*/
export class ArchiveSearchComponent implements OnInit {

  helpMessage: helpText;
  isReasonHide: boolean;
  isOtherHide: boolean;
  private DETabsEnabled: DataEntry_tabAvailability;
  public dataentrydtQuest11: dataentry_tab1_quest11;
  public programList: DESOS_Programs_List[];
  public cityList: DESOS_Cities_List[];
  public countyList: DESOS_Counties_List[];
  public genderList: DESOS_Genders_List[];
  public raceList: DESOS_Races_List[];
  public monthList: DESOS_Month_List[];
  public periodList: DESOS_Period_List[];
  // @Input() DETabsVisible: DataEntry_tabAvailability;
  private apiURL: string = "";
  @Output() nextStep = new EventEmitter<DESOS_Search_Fields>();
  public archiveSearch: FormGroup;
  private tmpValue: tmpSearch;
  public desosuserinfo: DESOS_UserInfo;
  public isProgramEnable: boolean = false;
  /** archive-search ctor */
  constructor(private snackBar: MatSnackBar, private location: Location, private route: ActivatedRoute, private router: Router, private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string, private authentication: AuthenticationService, private APIservices: DataentryService) {
    this.apiURL = baseUrl;
    this.helpMessage = new helpText();
    this.dataentrydtQuest11 = new dataentry_tab1_quest11();
    this.isReasonHide = true;
    this.isOtherHide = true;
    this.loafDefaults();
  }

  ngOnInit() {
    this.archiveSearch = new FormGroup({
      program: new FormControl('', [Validators.required]),
      id: new FormControl(''),
      indId: new FormControl(''),
      firstname: new FormControl(''),
      middlename: new FormControl(''),
      lastname: new FormControl(''),
      city: new FormControl(''),
      county: new FormControl(''),
      zipcode: new FormControl(''),
      reportingperiod: new FormControl(''),
      dobmonth: new FormControl(''),
      dobyear: new FormControl(''),
      gender: new FormControl(''),
      race: new FormControl(''),
      services: new FormControl('')
    });

    this.desosuserinfo = this.authentication.getAccessToken();

    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);

      if (!this.desosuserinfo.blnIsAdmin) {
        this.archiveSearch.controls["program"].setValue(this.desosuserinfo.strProgram);
        this.isProgramEnable = true;
      }
    //}
  }

  loafDefaults() {

    //this.http.get<DESOS_Programs_List[]>(this.apiURL + 'api/DefaultData/GetProgramSelectList').subscribe(result => {
    this.APIservices.getProgramList().subscribe(result => {
      this.programList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Cities_List[]>(this.apiURL + 'api/DefaultData/GetCitiesSelectList').subscribe(result => {
    this.APIservices.getCitiesList().subscribe(result => {
      this.cityList = result;
    }, error => console.error(error));


    //this.http.get<DESOS_Counties_List[]>(this.apiURL + 'api/DefaultData/GetCountiesSelectList').subscribe(result => {
    this.APIservices.getCountiesList().subscribe(result => {
      this.countyList = result;
    }, error => console.error(error));

    // this.http.get<DESOS_Genders_List[]>(this.apiURL + 'api/DefaultData/GetGendersSelectList').subscribe(result => {
    this.APIservices.getGendersList().subscribe(result => {
      this.genderList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Races_List[]>(this.apiURL + 'api/DefaultData/GetRacesSelectList').subscribe(result => {
    this.APIservices.getRacesList().subscribe(result => {
      this.raceList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Month_List[]>(this.apiURL + 'api/DefaultData/GetMonthSelectList').subscribe(result => {
    this.APIservices.getMonthList().subscribe(result => {
      this.monthList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Period_List[]>(this.apiURL + 'api/DefaultData/GetCompletedReportingPeriodList').subscribe(result => {
    this.APIservices.getCompletedReportingPeriodList().subscribe(result => {
    this.periodList = result;
    }, error => console.error(error));
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
    });
  }

  closeSnackBar() {
    //this.snackBar.dismiss();
  }

  hideOptions() {
    this.isReasonHide = true;
    this.isOtherHide = true;
    //this.tab2To8Hide.emit(true);
  }

  showOptions() {
    this.isReasonHide = false;
    this.isOtherHide = true;
    this.dataentrydtQuest11.optResonSelected = 0;
  }

  showOtherText() {
    this.isOtherHide = false;
  }

  hideOtherText() {
    this.isOtherHide = true;
  }

  btnFindClick() {
    this.tmpValue = this.archiveSearch.value;
    const toSearch: DESOS_Search_Fields = new DESOS_Search_Fields();

    toSearch.program_ID = this.archiveSearch.controls["program"].value;
    toSearch.id = this.archiveSearch.controls["id"].value;
    toSearch.individual_ID = this.archiveSearch.controls["indId"].value;
    toSearch.firstName = this.archiveSearch.controls["firstname"].value;
    toSearch.middleName = this.archiveSearch.controls["middlename"].value;
    toSearch.lastName = this.archiveSearch.controls["lastname"].value;
    if (this.archiveSearch.controls["city"].value == "-1") {
      toSearch.cityID = "";
    }
    else {
      toSearch.cityID = this.archiveSearch.controls["city"].value;
    }


    if (this.archiveSearch.controls["county"].value == "-1") {
      toSearch.countyID = "";
    }
    else {
      toSearch.countyID = this.archiveSearch.controls["county"].value;
    }

    toSearch.zipCode = this.archiveSearch.controls["zipcode"].value;

    if (this.archiveSearch.controls["reportingperiod"].value == "-1") {
      toSearch.reporting_Period = "";
    }
    else {
      toSearch.reporting_Period = this.archiveSearch.controls["reportingperiod"].value;
    }

    if (this.archiveSearch.controls["dobmonth"].value == "-1") {
      toSearch.dateOfBirth = "";
    }
    else {
      toSearch.dateOfBirth = this.archiveSearch.controls["dobmonth"].value + this.archiveSearch.controls["dobyear"].value;
    }

    if (this.archiveSearch.controls["gender"].value == "-1") {
      toSearch.gender = "";
    }
    else {
      toSearch.gender = this.archiveSearch.controls["gender"].value;
    }


    if (this.archiveSearch.controls["race"].value == "-1") {
      toSearch.race = "";
    }
    else {
      toSearch.race = this.archiveSearch.controls["race"].value;
    }
    toSearch.services = this.archiveSearch.controls["services"].value;
    
    this.nextStep.emit(toSearch);
  }

  currentRecordSearches(inputSearch: DESOS_Search_Fields) {
    if (inputSearch.program_ID != "") {
      this.archiveSearch.controls["program"].setValue(inputSearch.program_ID);
      this.archiveSearch.controls["program"].disable();
      //this.isProgramEnable = true;
    }
  }
}

export class tmpSearch {
  program: string = "";
  id: string = "";
  indId: string = "";
  firstname: string = "";
  middlename: string = "";
  lastname: string = "";
  city: string = "";
  county: string = "";
  zipcode: string = "";
  reportingperiod: string = "";
  dobmonth: string = "";
  dobyear: string = "";
  gender: string = "";
  race: string = "";
  services: string = "";
}
