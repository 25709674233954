<form [formGroup]="tab5FormGroup">
  <ng-template matStepLabel>8 To 12</ng-template>
  <div class="row">
    <div class="col-sm dataentry-column">
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable8) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['8']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['8']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_29}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['8']; else loading" class="col-sm-8 p-0">
            <section>
              <mat-label style="font-size:12px;"><span [innerHTML]="questionData['8']?.question?.extraDesc"></span></mat-label>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <input matInput type="number" placeholder="Hours" formControlName="q10valh" [disabled]="isDisable9">
                <!--<mat-error *ngIf="hasError('q10valh', 'required')">Hours is required</mat-error>-->
              </mat-form-field>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <mat-select placeholder="Minutes" formControlName="q10valm" [disabled]="isDisable9">
                  <mat-option value="0">00</mat-option>
                  <mat-option value="5">30</mat-option>
                </mat-select>
                <!--<mat-error *ngIf="hasError('q10valm', 'required')">Minutes is required</mat-error>-->
                <!--<input matInput placeholder="Minutes" formControlName="q10valm" [attr.disabled]="isDisable9" maxlength="7">-->
              </mat-form-field>
              <mat-error class="error_warning" *ngIf="hasError('q10valh', 'max')">Maximum 40 hours</mat-error>
              <mat-error class="error_warning" *ngIf="hasError('q10valh', 'min')">Minimum 0 hours</mat-error>
            </section>
          </div>
          <!--<div class="col-sm-4 p-0">
            <mat-label class="text-primary  question_text">8. Non-paid hours*</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_29}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-8 p-0">
            <section>
              <mat-label style="font-size:12px;">Typical hours scheduled weekly for non-paid (volunteer & community) activity<br /></mat-label>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <input matInput type="number" placeholder="Hours" formControlName="q10valh" [disabled]="isDisable9">-->
          <!--<mat-error *ngIf="hasError('q10valh', 'required')">Hours is required</mat-error>-->
          <!--</mat-form-field>
          <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
            <mat-select placeholder="Minutes" formControlName="q10valm" [disabled]="isDisable9">
              <mat-option value="0">00</mat-option>
              <mat-option value="5">30</mat-option>
            </mat-select>-->
          <!--<mat-error *ngIf="hasError('q10valm', 'required')">Minutes is required</mat-error>-->
          <!--<input matInput placeholder="Minutes" formControlName="q10valm" [attr.disabled]="isDisable9" maxlength="7">-->
          <!--</mat-form-field>
            </section>
            <mat-error class="error_warning" *ngIf="hasError('q10valh', 'max')">Maximum 40 hours</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q10valh', 'min')">Minimum 0 hours</mat-error>
          </div>-->
        </div>
      </mat-card>
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable9) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['9']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['9']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_28}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['9']; else loading" class="col-sm-8 p-0">
            <section>
              <mat-label style="font-size:12px;"><span [innerHTML]="questionData['9']?.question?.extra_description"></span></mat-label>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <input matInput type="number" placeholder="Hours" formControlName="q9valh" maxlength="7">
                <!--<mat-error *ngIf="hasError('q9valh', 'required')">Hours is required</mat-error>-->
              </mat-form-field>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <mat-select placeholder="Minutes" formControlName="q9valm">
                  <mat-option value="0">00</mat-option>
                  <mat-option value="5">30</mat-option>
                </mat-select>
                <!--<mat-error *ngIf="hasError('q9valm', 'required')">Minutes is required</mat-error>-->
                <!--<input matInput placeholder="Minutes" formControlName="q9valm" [attr.disabled]="isDisable8" maxlength="7">-->
              </mat-form-field>
              <mat-error class="error_warning" *ngIf="hasError('q9valh', 'max')">Maximum 40 hours</mat-error>
              <mat-error class="error_warning" *ngIf="hasError('q9valh', 'min')">Minimum 0 hours</mat-error>
            </section>
          </div>

          <!--<div class="col-sm-4 p-0">
            <mat-label class="text-primary question_text">9. Paid hours*</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_28}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-8 p-0">
            <section>
              <mat-label style="font-size:12px;">Typical hours scheduled weekly for paid work<br /></mat-label>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <input matInput type="number" placeholder="Hours" formControlName="q9valh" maxlength="7">-->
          <!--<mat-error *ngIf="hasError('q9valh', 'required')">Hours is required</mat-error>-->
          <!--</mat-form-field>
          <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
            <mat-select placeholder="Minutes" formControlName="q9valm">
              <mat-option value="0">00</mat-option>
              <mat-option value="5">30</mat-option>
            </mat-select>-->
          <!--<mat-error *ngIf="hasError('q9valm', 'required')">Minutes is required</mat-error>-->
          <!--<input matInput placeholder="Minutes" formControlName="q9valm" [attr.disabled]="isDisable8" maxlength="7">-->
          <!--</mat-form-field>
            </section>
            <mat-error class="error_warning" *ngIf="hasError('q9valh', 'max')">Maximum 40 hours</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q9valh', 'min')">Minimum 0 hours</mat-error>
          </div>-->
        </div>
      </mat-card>
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable10) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['10']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['10']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_30}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['10']; else loading" class="col-sm-8 p-0">
            <section>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <input matInput type="number" placeholder="Paid $" formControlName="q11val" [disabled]="isDisable10">
              </mat-form-field>
            </section>
            <mat-error class="error_warning" *ngIf="hasError('q11val', 'max')">Maximum Paid ${{paidMax}}</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q11val', 'min')">Minimum Paid ${{paidMin}}</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q11val', 'required')">Paid $ is required</mat-error>
          </div>
          <!--<div class="col-sm-4 p-0">
            <mat-label class="text-primary   question_text">10. Hourly earnings*</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_30}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-8 p-0">
            <section>
              <mat-form-field class="mat-input placeholder_text" [style.width.px]="80">
                <input matInput type="number" placeholder="Paid $" formControlName="q11val" [disabled]="isDisable10">
              </mat-form-field>
            </section>
            <mat-error class="error_warning" *ngIf="hasError('q11val', 'max')">Maximum Paid ${{paidMax}}</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q11val', 'min')">Minimum Paid ${{paidMin}}</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q11val', 'required')">Paid $ is required</mat-error>
          </div>-->
        </div>
      </mat-card>
    </div>
    <div class="col-sm dataentry-column">
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable11) ? 'darkgray' : '' }">
        <div class="row p-0 pl-1">
          <div *ngIf="questionData['11']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['11']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_31}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['11']; else loading" class="col-sm-8 p-0">
            <div class="row p-0 pl-1">
              <div *ngFor="let chunk of questionData['11'].options | chunkArray:q11ChunkNum" class="col-sm-6 p-0">
                <section class="data-radio-group">
                  <mat-checkbox *ngFor="let option of chunk"
                                color="primary"
                                class="data-radio-button"
                                [ngModelOptions]="{standalone: true}"
                                [checked]="DEtab5Quest8to12['chk' + option.optionId + 'question11Selected']"
                                [disabled]="isDisable11"
                                [name]="'chk' + option.optionId + 'question11Selected'"
                                [(ngModel)]="DEtab5Quest8to12['chk' + option.optionId + 'question11Selected']"
                                (change)="onChangeQuest11($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-checkbox>
                </section>
              </div>
              <mat-error class="error_warning" *ngIf="hasError('q12val', 'required')">Please check all that apply</mat-error>
              <section class="col-sm-12" [hidden]="!DEtab5Quest8to12.chk6question11Selected" name="chk6question11Selected">
                <mat-form-field class="mat-input col-sm-12">
                  <input matInput placeholder="Other Descriptions" formControlName="q12subval" maxlength="128">
                </mat-form-field>
              </section>
            </div>
          </div>
          <!--<div class="col-sm-4 p-0">
            <mat-label class="text-primary  question_text">11. Employer benefits received*<br />(check all that apply)</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_31}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-8 p-0">
            <div class="row p-0 pl-1">
              <div class="col-sm-6 p-0">
                <section class="data-radio-group">
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab5Quest8to12.chk1question11Selected" [disabled]="isDisable11" name="chk1question11Selected" [(ngModel)]="DEtab5Quest8to12.chk1question11Selected" (change)="onChangeQuest11($event)">Medical</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab5Quest8to12.chk2question11Selected" [disabled]="isDisable11" name="chk2question11Selected" [(ngModel)]="DEtab5Quest8to12.chk2question11Selected" (change)="onChangeQuest11($event)">Paid sick leave</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab5Quest8to12.chk3question11Selected" [disabled]="isDisable11" name="chk3question11Selected" [(ngModel)]="DEtab5Quest8to12.chk3question11Selected" (change)="onChangeQuest11($event)">Paid vacation</mat-checkbox>
                </section>
              </div>
              <div class="col-sm-6 p-0">
                <section class="data-radio-group">
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab5Quest8to12.chk4question11Selected" [disabled]="isDisable11" name="chk4question11Selected" [(ngModel)]="DEtab5Quest8to12.chk4question11Selected" (change)="onChangeQuest11($event)">Retirement</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab5Quest8to12.chk5question11Selected" [disabled]="isDisable11" name="chk5question11Selected" [(ngModel)]="DEtab5Quest8to12.chk5question11Selected" (change)="onChangeQuest11($event)">None</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab5Quest8to12.chk6question11Selected" [disabled]="isDisable11" name="chk6question11Selected" [(ngModel)]="DEtab5Quest8to12.chk6question11Selected" (change)="onChangeQuest11($event)">Other</mat-checkbox>
                </section>
              </div>
            </div>
            <mat-error class="error_warning" *ngIf="hasError('q12val', 'required')">Please check all that apply</mat-error>
            <section class="col-sm-12" [hidden]="!DEtab5Quest8to12.chk6question11Selected" name="chk6question11Selected">
              <mat-form-field class="mat-input col-sm-12">
                <input matInput placeholder="Other Descriptions" formControlName="q12subval" maxlength="128">
              </mat-form-field>
            </section>
          </div>-->
        </div>
      </mat-card>
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable12) ? 'darkgray' : '' }">
        <div class="row p-0 pl-1">
          <div *ngIf="questionData['12']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['12']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_32}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['12']; else loading" class="col-sm-8 p-0">
            <div class="row p-0 pl-1">
              <div *ngFor="let chunk of questionData['12'].options | chunkArray:q12ChunkNum" class="col-sm-6 p-0">
                <section class="data-radio-group">
                  <mat-checkbox *ngFor="let option of chunk"
                                color="primary"
                                class="data-radio-button"
                                [ngModelOptions]="{standalone: true}"
                                [checked]="DEtab5Quest8to12['chk' + option.optionId + 'question12Selected']"
                                [disabled]="isDisable12"
                                [name]="'chk' + option.optionId + 'question12Selected'"
                                [(ngModel)]="DEtab5Quest8to12['chk' + option.optionId + 'question12Selected']"
                                (change)="onChangeQuest12($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-checkbox>
                </section>
                <mat-error class="error_warning" *ngIf="hasError('q13val', 'required')">Please check all that apply</mat-error>
              </div>
            </div>
          </div>

          <!--<div class="col-sm-4 p-0">
            <mat-label class="text-primary  question_text">12. Government benefits*<br />(check all that apply)</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_32}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-8 p-0">
            <div class="row p-0 pl-1">
              <div class="col-sm-6 p-0">
                <section class="data-radio-group">
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab5Quest8to12.chk1question12Selected" name="chk1question12Selected" [disabled]="isDisable12" [(ngModel)]="DEtab5Quest8to12.chk1question12Selected" (change)="onChangeQuest12($event)">SSI</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab5Quest8to12.chk2question12Selected" name="chk2question12Selected" [disabled]="isDisable12" [(ngModel)]="DEtab5Quest8to12.chk2question12Selected" (change)="onChangeQuest12($event)">SSDI</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab5Quest8to12.chk3question12Selected" name="chk3question12Selected" [disabled]="isDisable12" [(ngModel)]="DEtab5Quest8to12.chk3question12Selected" (change)="onChangeQuest12($event)">Medicaid</mat-checkbox>
                </section>
              </div>
              <div class="col-sm-6 p-0">
                <section class="data-radio-group">
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab5Quest8to12.chk4question12Selected" name="chk4question12Selected" [disabled]="isDisable12" [(ngModel)]="DEtab5Quest8to12.chk4question12Selected" (change)="onChangeQuest12($event)">Medicare</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab5Quest8to12.chk5question12Selected" name="chk5question12Selected" [disabled]="isDisable12" [(ngModel)]="DEtab5Quest8to12.chk5question12Selected" (change)="onChangeQuest12($event)">TANF</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab5Quest8to12.chk6question12Selected" name="chk6question12Selected" [disabled]="isDisable12" [(ngModel)]="DEtab5Quest8to12.chk6question12Selected" (change)="onChangeQuest12($event)">Other</mat-checkbox>
                </section>
              </div>
              <mat-error class="error_warning" *ngIf="hasError('q13val', 'required')">Please check all that apply</mat-error>
            </div>
          </div>-->
        </div>
      </mat-card>
    </div>
  </div>
  <div class="example-button-row text-center">
    <button mat-raised-button matStepperPrevious color="primary">Back</button>
    <button mat-raised-button matStepperNext color="primary" [disabled]="!tab5FormGroup.valid">Next</button>
  </div>
</form>

<ng-template #loading>
  <p>Loading question and options...</p>
</ng-template>
