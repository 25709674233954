<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<div class="row">
  <div class="col-3"></div>
  <div class="col-6">
    <mat-card class="program-mat-card">
      <mat-card-title color="Primary">
        <label>Program Edit</label>
      </mat-card-title>
      <form class="example-form" [formGroup]="programRequest">
        <mat-form-field class="example-full-width">
          <input formControlName="programname" matInput placeholder="Program Name" name="program_Name" value="" required>
          <!--<mat-error *ngIf="hasError('programname', 'required')">Program Name is required</mat-error>-->
        </mat-form-field>
        <div class="pt-4">
          <mat-checkbox class="example-margin" formControlName="emp_flg">Employment</mat-checkbox>
          <mat-checkbox class="example-margin pl-3" formControlName="res_flg">Day Services</mat-checkbox>
        </div>
        <mat-form-field class="example-full-width">
          <input formControlName="street_Address" matInput placeholder="Adress" name="street_Address" value="">
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input formControlName="city" matInput placeholder="City" name="city" value="">
        </mat-form-field>
        <mat-form-field class="example-full-width ">
          <!--<input [(ngModel)]="programadd.state" matInput placeholder="State" name="state" value="">-->
          <mat-select placeholder="Select a State" formControlName="state" required>
            <mat-option *ngFor="let item of stateList" [value]="item.id">
              {{item.state}}
            </mat-option>
          </mat-select>
          <!--<mat-error *ngIf="hasError('state', 'required')">State is required</mat-error>-->
        </mat-form-field>
        <mat-form-field class="example-full-width pt-2">
          <input formControlName="zipCode" matInput placeholder="Zip" name="zipCode" value="">
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input formControlName="county" matInput placeholder="County" name="county" value="">
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input formControlName="excdirname" matInput placeholder="Executive Director Name" name="executive_Director_Name" value="" required>
          <!--<mat-error *ngIf="hasError('excdirname', 'required')">Exec Director Name is required</mat-error>-->
        </mat-form-field>
        <mat-form-field class="example-full-width  pt-2">
          <input formControlName="excdiremail" matInput placeholder="Executive Director Email" name="executive_Director_Email" value="" required>
          <!--<mat-error *ngIf="hasError('excdiremail', 'required')">Email is required</mat-error>-->
        </mat-form-field>
        <mat-error class="error_warning"  *ngIf="hasError('excdiremail', 'email')">Valid Email is required</mat-error>
        <mat-form-field class="example-full-width  pt-2">
          <input formControlName="telephone" matInput placeholder="Telephone" name="telephone" value="">
          <mat-error class="error_warning"  *ngIf="hasError('telephone', 'pattern')">Telephone 10 digit is required</mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width pt-2">
          <input formControlName="fax" matInput placeholder="Fax" name="fax" value="">
        </mat-form-field>
        <mat-error class="error_warning"  *ngIf="hasError('fax', 'pattern')">Telephone 10 digit is required</mat-error>
        <mat-form-field class="example-full-width pt-2">
          <input formControlName="desosmanagname" matInput placeholder="DESOS Manager" name="desoS_Manager_Name" value="" required>
        </mat-form-field>
        <mat-error  class="error_warning"  *ngIf="hasError('desosmanagname', 'required')">DESOS Manager Name is required</mat-error>
        <mat-form-field class="example-full-width  pt-2">
          <input formControlName="desosmanagemail" matInput placeholder="DESOS Manager Email" name="desoS_Manager_Email" value="" required>
          <!--<mat-error *ngIf="hasError('desosmanagemail', 'required')">Email is required</mat-error>-->
        </mat-form-field>
        <mat-error class="error_warning"  *ngIf="hasError('desosmanagemail', 'email')">Valid Email is required</mat-error>
        <!--<mat-form-field class="example-full-width">-->
        <mat-card-actions class="example-button-row pt-2">
          <button mat-raised-button (click)="btnSaveClick()" [disabled]="!programRequest.valid" color="primary">Save</button>
          <button mat-raised-button (click)="btnBackClick()" color="primary">Back</button>
        </mat-card-actions>
        <!--</mat-form-field>-->
      </form>
    </mat-card>
  </div>
  <div class="col-3"></div>
</div>
