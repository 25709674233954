<form [formGroup]="tab6FormGroup">
  <ng-template matStepLabel>8 To 12</ng-template>
  <div class="row">
    <div class="col-sm dataentry-column">
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable13) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['13']; else loading" class="col-sm-5 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['13']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_33}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['13']; else loading" class="col-sm-7 p-0">
            <mat-radio-group class="data-radio-group" formControlName="q15val">
              <div class="row p-0">
                <mat-label style="font-size:12px;padding-left:10px;"><span [innerHTML]="questionData['13']?.question?.extraDesc"></span></mat-label>
                <div *ngFor="let chunk of questionData['13'].options | chunkArray:q13ChunkNum" class="col-sm-6 p-0">
                  <section class="data-radio-group">
                    <mat-radio-button *ngFor="let option of chunk"
                                      color="primary" class="data-radio-button" [value]="''+option.optionId">
                      <span [innerHTML]="option.content"></span>
                    </mat-radio-button>
                  </section>
                </div>
              </div>
            </mat-radio-group>
            <mat-error class="error_warning" *ngIf="hasError('q15val', 'required')">Please select any one</mat-error>
          </div>
          <!--<div class="col-sm-5 p-0">
            <mat-label class="text-primary question_text">13. Number of individuals without disabilities* (check one)</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_33}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-7 p-0">
            <mat-radio-group class="data-radio-group" formControlName="q15val">
              <div class="row p-0">
                <mat-label style="font-size:12px;padding-left:10px;">(excluding paid staff) in immediate environment<br /></mat-label>
                <div class="col-sm-6 p-0">
                  <section class="data-radio-group">
                    <mat-radio-button color="primary" class="data-radio-button" value="0">0</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" value="1">1-5</mat-radio-button>
                  </section>
                </div>
                <div class="col-sm-6 p-0">
                  <section class="data-radio-group">
                    <mat-radio-button color="primary" class="data-radio-button" value="2">6-15</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" value="3">16 or more</mat-radio-button>
                  </section>
                </div>
              </div>
            </mat-radio-group>
            <mat-error class="error_warning" *ngIf="hasError('q15val', 'required')">Please select any one</mat-error>
          </div>-->
        </div>
      </mat-card>
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable14) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['14']; else loading" class="col-sm-5 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['14']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_34}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['14']; else loading" class="col-sm-7 p-0">
            <mat-radio-group class="data-radio-group" formControlName="q16val">
              <div class="row p-0">
                <mat-label style="font-size:12px;padding-left:10px;"><span [innerHTML]="questionData['14']?.question?.extraDesc"></span></mat-label>
                <div *ngFor="let chunk of questionData['14'].options | chunkArray:q14ChunkNum" class="col-sm-6 p-0">
                  <section class="data-radio-group">
                    <mat-radio-button *ngFor="let option of chunk"
                                      color="primary" class="data-radio-button" [value]="''+option.optionId">
                      <span [innerHTML]="option.content"></span>
                    </mat-radio-button>
                  </section>
                </div>
              </div>
            </mat-radio-group>
            <mat-error class="error_warning" *ngIf="hasError('q16val', 'required')">Please select any one</mat-error>
          </div>
          <!--<div class="col-sm-5 p-0">
            <mat-label class="text-primary  question_text">14. Number of individuals with disabilities*<br />(check one):</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_34}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-7 p-0">
            <mat-radio-group class="data-radio-group" formControlName="q16val">
              <div class="row p-0">
                <mat-label style="font-size:12px;">(including this person) in immediate environment<br /></mat-label>
                <div class="col-sm-6 p-0">
                  <section class="data-radio-group">
                    <mat-radio-button color="primary" class="data-radio-button" value="0">1</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" value="1">2-4</mat-radio-button>
                  </section>
                </div>
                <div class="col-sm-6 p-0">
                  <section class="data-radio-group">
                    <mat-radio-button color="primary" class="data-radio-button" value="2">5-8</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" value="3">9 or more</mat-radio-button>
                  </section>
                </div>
              </div>
            </mat-radio-group>
            <mat-error class="error_warning" *ngIf="hasError('q16val', 'required')">Please select any one</mat-error>
          </div>-->
        </div>
      </mat-card>
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable15) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['15']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['15']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_35}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['15']; else loading" class="col-sm-8 p-0">
            <div class="col-sm-12 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="q17val">
                  <mat-radio-button *ngFor="let option of questionData['15'].options" [value]="''+option.optionId" color="primary" class="data-radio-button">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                </mat-radio-group>
              </section>
              <mat-error class="error_warning pt-3" *ngIf="hasError('q17val', 'required')">Please select any one</mat-error>
            </div>
          </div>
          <!--<div class="col-sm-4 p-0">
            <mat-label class="text-primary  question_text">15. Social interactions with individuals without disabilities excluding paid staff*<br />(check one):</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_35}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-8 p-0">
            <div class="col-sm-12 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="q17val">
                  <mat-radio-button color="primary" class="data-radio-button" value="0">Rarely, if ever</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" value="1">Exchanges greetings/brief interactions</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" value="2">Interactions primarily work related</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" value="3">Engages frequently and ongoing with <br />variety of people/coworkers</mat-radio-button>
                </mat-radio-group>
              </section>
              <mat-error class="error_warning pt-3" *ngIf="hasError('q17val', 'required')">Please select any one</mat-error>
            </div>
          </div>-->
        </div>
      </mat-card>
    </div>
    <div class="col-sm dataentry-column">
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable17) ? 'darkgray' : '' }">
        <div class="row p-0 pl-1">
          <div *ngIf="questionData['16']; else loading" class="col-sm-6 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['16']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_36A}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['16']; else loading" class="col-sm-6 p-0">
            <mat-radio-group formControlName="q18val">
              <div class="row p-0 pl-1">
                <div *ngFor="let chunk of questionData['16'].options | chunkArray:q16ChunkNum" class="col-sm-6 p-0">
                  <section class="data-radio-group">
                    <mat-radio-button *ngFor="let option of chunk"
                                      color="primary"
                                      class="data-radio-button"
                                      [value]="''+option.optionId"
                                      (change)="OnQuest16OptSelected($event)">
                      <span [innerHTML]="option.content"></span>
                    </mat-radio-button>
                  </section>
                </div>
              </div>
            </mat-radio-group>
            <mat-error class="error_warning" *ngIf="hasError('q18val', 'required')">Please select any one</mat-error>
          </div>
        </div>
      </mat-card>
      <mat-card class="dataentry-card" name="opt1question16Selected" [ngStyle]="{'background': (isDisable17) ? 'darkgray' : '' }">
        <div class="row p-0 pl-1">
          <div *ngIf="questionData['17']; else loading" class="col-sm-5 p-0">
            <mat-label class="text-primary  question_text"><span [innerHTML]="questionData['17']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_37}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['17']; else loading" class="col-sm-7 p-0">
            <section class="data-radio-group">
              <mat-checkbox *ngFor="let option of questionData['17'].options"
                            color="primary"
                            class="data-radio-button"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="!DEtab6Quest13to17.opt1question16Selected"
                            [checked]="DEtab6Quest13to17['chk' + option.optionId + 'question17Selected']"
                            name="'chk' + option.optionId + 'question17Selected'"
                            [(ngModel)]="DEtab6Quest13to17['chk' + option.optionId + 'question17Selected']"
                            (change)="onChangeQuest17($event)">
                <span [innerHTML]="option.content"></span>
              </mat-checkbox>
            </section>
            <section class="col-sm-12" [hidden]="!DEtab6Quest13to17.chk6question17Selected" name="chk6question17Selected">
              <mat-form-field class="mat-input col-sm-12">
                <input matInput placeholder="Other Descriptions" formControlName="q19subval" maxlength="128">
              </mat-form-field>
            </section>
          </div>
          <!--<div class="col-sm-7 p-0">
            <section class="data-radio-group">
              <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [disabled]="!DEtab6Quest13to17.opt1question16Selected" [checked]="DEtab6Quest13to17.chk1question17Selected" name="chk1question17Selected" [(ngModel)]="DEtab6Quest13to17.chk1question17Selected" (change)="onChangeQuest17($event)">Successfully placed</mat-checkbox>
              <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [disabled]="!DEtab6Quest13to17.opt1question16Selected" [checked]="DEtab6Quest13to17.chk2question17Selected" name="chk2question17Selected" [(ngModel)]="DEtab6Quest13to17.chk2question17Selected" (change)="onChangeQuest17($event)">Agency decision - &quot;Graduated&quot; </mat-checkbox>
              <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [disabled]="!DEtab6Quest13to17.opt1question16Selected" [checked]="DEtab6Quest13to17.chk3question17Selected" name="chk3question17Selected" [(ngModel)]="DEtab6Quest13to17.chk3question17Selected" (change)="onChangeQuest17($event)">Customer choice</mat-checkbox>
              <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [disabled]="!DEtab6Quest13to17.opt1question16Selected" [checked]="DEtab6Quest13to17.chk4question17Selected" name="chk4question17Selected" [(ngModel)]="DEtab6Quest13to17.chk4question17Selected" (change)="onChangeQuest17($event)">Moved</mat-checkbox>
              <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [disabled]="!DEtab6Quest13to17.opt1question16Selected" [checked]="DEtab6Quest13to17.chk5question17Selected" name="chk5question17Selected" [(ngModel)]="DEtab6Quest13to17.chk5question17Selected" (change)="onChangeQuest17($event)">No Funding</mat-checkbox>
              <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [disabled]="!DEtab6Quest13to17.opt1question16Selected" [checked]="DEtab6Quest13to17.chk6question17Selected" name="chk6question17Selected" [(ngModel)]="DEtab6Quest13to17.chk6question17Selected" (change)="onChangeQuest17($event)">Other</mat-checkbox>
            </section>
            <section class="col-sm-12" [hidden]="!DEtab6Quest13to17.chk6question17Selected" name="chk6question17Selected">
              <mat-form-field class="mat-input col-sm-12">
                <input matInput placeholder="Other Descriptions" formControlName="q19subval" maxlength="128">
              </mat-form-field>
            </section>
            <mat-error class="error_warning" *ngIf="hasError('q19val', 'required')">Please check all that apply</mat-error>
          </div>-->
        </div>
      </mat-card>
    </div>
  </div>
  <div class="example-button-row text-center">
    <button mat-raised-button matStepperPrevious color="primary">Back</button>
    <button mat-raised-button matStepperNext color="primary" [disabled]="!tab6FormGroup.valid">Next</button>
  </div>
</form>

<ng-template #loading>
  <p>Loading question and options...</p>
</ng-template>
