import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { DESOS_Manager_Verify } from '../program';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { VerificationService } from 'src/app/services/verification.service';
import { DESOS_StrResult, DESOS_Records_List } from 'src/app/dataentry/dataentry';
import { DESOS_Reporting_Periods, DESOS_Period_List } from 'src/app/search/default';
import { DESOS_Individuals, DESOS_Outcomes, DESOS_Search_Fields } from 'src/app/dataentry/dataentry';
import { TempDataService } from 'src/app/services/temp-data.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DESOS_Reports_Param } from 'src/app/Reports/reports';
import { DESOS_Programs_List } from 'src/app/admin/program';

@Component({
  selector: 'manager-v-duplicate',
  templateUrl: './manager-v-duplicate.component.html',
  styleUrls: ['./manager-v-duplicate.component.css'],
  providers: [VerificationService]
})
export class ManagerVDuplicateComponent implements OnInit, OnDestroy {
  public verificationlist: DESOS_Manager_Verify[];
  private apiURL: string;
  private strResults: string = "";
  private apiListURL = "api/Program/GetVerificationList";
  public desosreportinfo: DESOS_Reporting_Periods;
  public dataSource: MatTableDataSource<DESOS_Records_List>;
  public displayedColumns: string[] = ['id', 'lastNameLowerCase', 'q1val', 'reporting_Period_Filter', 'city', 'county', 'dateOfBirth'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public warningmessage: string = "";
  public rowCount: number = 1;
  private programid: string;
  private selectedPeriod: string[] = [];
  public reportparam: FormGroup;
  public programList: DESOS_Programs_List[];
  public program: DESOS_Programs_List;
  public recordsList: DESOS_Records_List[];
  public periodList: DESOS_Period_List[];
  public selectedPeriodList: DESOS_Reporting_Periods[];
  public selectedCheckboxes: { [key: string]: boolean } = {};
  public newRP: string = "";
  public reportingPeriod: DESOS_Reporting_Periods;
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  constructor(private location: Location, private route: ActivatedRoute, private router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private matDialog: MatDialog, private APIservices: VerificationService, private tempdata: TempDataService) {
    this.apiURL = baseUrl;
    let observable = new Observable(this.myObservable);
    this.showProgressSpinner(observable);

    if (this.route.snapshot.params.id != null)
    this.programid = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
    const searchfields: DESOS_Reports_Param = new DESOS_Reports_Param();

    this.reportparam = new FormGroup({
      reportingperiod: new FormControl('', [Validators.required])
    }, { updateOn: 'blur' });

    this.APIservices.getReportingPeriodList().subscribe(result => {
      const tempr = result.slice(1,4);
      this.periodList = tempr;

      this.periodList.forEach(s => {
        this.selectedCheckboxes[s.mm + s.dd] = false;        
      })

    }, error => console.error(error));

    this.APIservices.getCurrentReportingPeriod().subscribe(result => {
      this.reportingPeriod = result;

      //searchfields.reporting_Period = this.reportingPeriod.period;
      this.newRP = this.reportingPeriod.mm + this.reportingPeriod.dd; 
    }, error => console.error(error));

    this.APIservices.getProgramList().subscribe(result => {
      this.programList = result;
      this.programList.forEach(s => {
        if (parseInt(s.program_ID) == parseInt(this.programid)) {
          this.program = s;
          //this.getRosterList();
        }
      })
    }, error => console.error(error));

    /*searchfields.reporting_Period = this.reportingPeriod.period;*/
    searchfields.id = this.programid;
    //this.getRosterList(searchfields);
  }

  myObservable(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 2000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  ngOnDestroy() {
    //setTimeout(() => {
    //  this.dialog.close();
    //});
  }

  getRosterList(sfield: DESOS_Reports_Param) {
    //return Observable.create(observer => {
    console.log("refresh roster list");
    const searchfields: DESOS_Search_Fields = new DESOS_Search_Fields();
    searchfields.program_ID = this.programid;
    searchfields.reporting_Period = sfield.reporting_Period;
    //'0624', '1223'
    this.APIservices.getRosterListByReportingPeriod(searchfields).subscribe((result) => {
      this.recordsList = result;
      if (this.recordsList == null) {
        this.dataSource = new MatTableDataSource(this.recordsList);
        return 0;
      }
      this.recordsList = this.recordsList.map(function (el) {
        var o = Object.assign({}, el);
        o.lastNameLowerCase = el.lastName.toLocaleLowerCase();
        return o;
      });
      console.log(this.recordsList);
      this.dataSource = new MatTableDataSource(this.recordsList);
      this.paginator.pageIndex = searchfields.pageCurrent;
      this.paginator.pageSize = searchfields.pageSize;
      this.dataSource.paginator = this.paginator;
      if (this.dataSource.data.length > 0) {
        this.dataSource.sort = this.sort;
        //observer.next("Roster list loaded.");
        //observer.complete();
      }
      //else {
      //  observer.next("No Connection, DB or API or Session.");
      //  observer.complete();
      //}
      //});
    });
  }

  onChangePeriod(checkboxId) {
    const searchfields: DESOS_Search_Fields = new DESOS_Search_Fields();
    console.log("Clicked", checkboxId, this.selectedCheckboxes);

    if (this.selectedCheckboxes[checkboxId]) {
      this.selectedPeriod.push(checkboxId);
    } else {
      const index = this.selectedPeriod.indexOf(checkboxId);
      if (index !== -1) {
        this.selectedPeriod.splice(index, 1);
      }
    }

    let RP = "";
    this.selectedPeriod.forEach(s => { RP = RP + "'" + s + "'," });
    
      searchfields.reporting_Period = RP.slice(0, -1);
      searchfields.id = this.programid;


      this.getRosterList(searchfields);
  }

  pageChange(event: PageEvent) {
    //const searchfields: DESOS_Search_Fields = JSON.parse(sessionStorage.searchfields);
    const searchfields: DESOS_Search_Fields = this.tempdata.getSearchFields();
    searchfields.pageCurrent = event.pageIndex;
    searchfields.pageSize = event.pageSize;
    this.tempdata.setSearchFields(searchfields);
    //sessionStorage.setItem("searchfields", JSON.stringify(searchfields));
  }

  btnBackClick() {
    //this.router.navigate(['/admin/programlist']);
    this.location.back();
  }

  btnContinueClick() {

    //console.log("Clicked", this.selectedCheckboxes);
    //Overwrite reporting period
    this.recordsList.forEach(r => r.reporting_Period = this.newRP);

    this.APIservices.copyEntries(this.recordsList).subscribe(result => {
      if (result.strOutput === "Success") {
        this.router.navigate(['/dataentry/rosterlist']);
      }
      else {
        alert("Error copying entries!");
      }
    }, error => console.error(error));

  }

  getPrimaryEnv(i) {
    //console.log({ i });
    switch (i) {
      case '0': return "Facility - Based(Sheltered Employment)";
      case '1': return "Off-site group employment placement";
      case '2': return "Competitive Integrated Employment";
      case '3': return "Self-employed";
      case '4': return "Non-employment day program";
      case '5': return "Alternative to employment";
      default: return "N/A";
    }
  }

}
