import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewentryComponent } from './newentry/newentry.component';
import { DataentryComponent } from './dataentry.component';
import { RosterListComponent } from './roster-list/roster-list.component';
import { UserrosterlistComponent } from './userrosterlist/userrosterlist.component';
import { AuthGuardService } from '../services/auth-guard.service';




const dataentryRoutes: Routes = [
  {
    path: 'dataentry',
    canActivate: [AuthGuardService],
    component: DataentryComponent,
    children: [
      {
        path: 'newentry',
        component: NewentryComponent
      },
      {
        path: '',
        component: NewentryComponent
      },
      {
        path: 'rosterlist',
        component: UserrosterlistComponent
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(dataentryRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DataentryRoutingModule {
}
