import { Component } from '@angular/core';

@Component({
    selector: 'app-progress-dialog',
    templateUrl: './progress-dialog.component.html',
    styleUrls: ['./progress-dialog.component.css']
})
/** progress-dialog component*/
export class ProgressDialogComponent {
    /** progress-dialog ctor */
    constructor() {

    }
}