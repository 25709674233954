export class dataentry_tab7_quest18to20 {
  public question18Text: string = "";
  public strquestion18DisPpl: string = "";
  public strquestion18NonDisPpl: string = "";

  public question219Text: string = "";
  public chk1question19Selected: boolean = false;
  public chk2question19Selected: boolean = false;
  public chk3question19Selected: boolean = false;
  public chk4question19Selected: boolean = false;
  public chk5question19Selected: boolean = false;
  public chk6question19Selected: boolean = false;
  public chk7question19Selected: boolean = false;
  public chk8question19Selected: boolean = false;
  public strquestion19OtherDes: string = "";

  public question20Text: string = "";
  public strquestion20Selected: string = "";
  public optquestion20Selected: boolean = false;

}
