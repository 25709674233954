import { Component, ViewChild, OnInit } from '@angular/core';
import { CurrentListComponent } from './current-list/current-list.component';
import { ArchiveListComponent } from './archive-list/archive-list.component';
import { ArchiveSearchComponent } from './archive-search/archive-search.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DESOS_UserInfo } from '../account/account';
import { DESOS_Individuals, DESOS_Outcomes } from '../dataentry/dataentry';
import { DESOS_Reporting_Periods } from './default';
import { AuthenticationService } from '../services/authentication.service';
import { TempDataService } from '../services/temp-data.service';

@Component({
    selector: 'app-search-records',
    templateUrl: './search-records.component.html',
  styleUrls: ['./search-records.component.css'],
  providers: [AuthenticationService, TempDataService]
})
/** search-records component*/
export class SearchRecordsComponent implements OnInit{
    /** search-records ctor */
  public desosuserinfo: DESOS_UserInfo;
  @ViewChild(CurrentListComponent, { static: true }) currentList: CurrentListComponent;
  @ViewChild(ArchiveListComponent, { static: true }) archiveList: ArchiveListComponent;
  @ViewChild(ArchiveSearchComponent, { static: true }) archiveSearch: ArchiveSearchComponent;
  public step: number = 0;
  public hideCurrSearch: boolean = true;
  public desosreportinfo: DESOS_Reporting_Periods;
  constructor(private route: ActivatedRoute, private router: Router, private authentication: AuthenticationService, private tempdata: TempDataService) {

  }

  ngOnInit() {

    this.desosreportinfo = this.authentication.getReport();
    this.desosuserinfo = this.authentication.getAccessToken();

    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);

      if (!this.desosuserinfo.blnIsAdmin) {
        this.hideCurrSearch = false;
      }
    //}
    //else {
    //  this.router.navigate(['/account/login']);
    //}
    //if (sessionStorage.desosreportinfo != null) {
    //  this.desosreportinfo = JSON.parse(sessionStorage.desosreportinfo);
    //}
  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  searchCurrentList($event) {
    this.currentList.inputAdminSearch($event);
    this.archiveSearch.currentRecordSearches($event);
    this.step++;
  }

  searchArchiveList($event) {
    this.archiveList.inputArchiveSearch($event);
    this.step++;
  }

  btnNewOutcome() {
    const curEditIndividual: DESOS_Individuals = new DESOS_Individuals();
    const curEditOutComes: DESOS_Outcomes = new DESOS_Outcomes();
    //sessionStorage.setItem("editoutcomes", JSON.stringify(curEditOutComes));
    //sessionStorage.setItem("editindividual", JSON.stringify(curEditIndividual));
    //sessionStorage.setItem("entrytype", "new");
    this.tempdata.setCurrentindivitual(curEditIndividual);
    this.tempdata.setCurrentoutcome(curEditOutComes);
    this.tempdata.setRosterAction("new");
    this.delay(3000).then(any => {
      this.userNavigation();
    });
  }

  userNavigation() {
    this.router.navigate(['/dataentry']);
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("Outcome"));
  }

  prevStep() {
    this.step--;
  }
}
