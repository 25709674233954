<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<div class="row p-2">
  <div class="col-sm-3"></div>
  <div class="col-sm-6">
    <form class="rounded  dataentry-column" [formGroup]="accountRequest">
      <mat-card class="program-mat-card">
        <mat-card-title color="Primary">
          <label>User Add</label>
        </mat-card-title>
        <div class="form-group pl-3 pr-3 col-sm-12">
          <mat-form-field class="example-full-width">
            <mat-select placeholder="DESOS Program" formControlName="program">
              <mat-option *ngFor="let program of programList" [value]="program.program_ID">
                {{program.program_Name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="hasError('program', 'required')">Program is required</mat-error>
        </div>
        <div class="form-group pl-3 pr-3 pt-2 col-sm-12">
          <mat-form-field class="example-full-width">
            <input matInput type="text" name="username" placeholder="Email" formControlName="username" required>
            <!--<mat-error *ngIf="hasError('username', 'required')">Email is required</mat-error>-->
          </mat-form-field>
          <mat-error *ngIf="hasError('username', 'email')">Enter valid Email</mat-error>
        </div>
        <div class="form-group pl-3 pr-3 pt-1  col-sm-12">
          <mat-form-field class="example-full-width">
            <input matInput type="text" name="firstname" placeholder="First Name" formControlName="firstname" required>
            <!--<mat-error *ngIf="hasError('firstname', 'required')">First Name is required</mat-error>-->
          </mat-form-field>
        </div>
        <div class="form-group pl-3 pr-3 pt-1  col-sm-12">
          <mat-form-field class="example-full-width">
            <input matInput type="text" name="lastname" placeholder="Last Name" formControlName="lastname" required>
            <!--<mat-error *ngIf="hasError('lastname', 'required')">Last Name is required</mat-error>-->
          </mat-form-field>
        </div>
        <mat-card-actions class="example-button-row pt-2 pl-3">
          <button mat-raised-button (click)="btnSaveClick()" [disabled]="!accountRequest.valid" color="primary">Save</button>
          <button mat-raised-button (click)="btnBackClick()" color="primary">Back</button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
  <div class="col-sm-3"></div>
</div>
