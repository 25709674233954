import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DESOS_Manager_Verify } from 'src/app/admin/program';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { DESOS_StrResult } from 'src/app/dataentry/dataentry';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { Observable } from 'rxjs';
import { DefaultService } from 'src/app/services/default.service';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-verifymanager',
  templateUrl: './verifymanager.component.html',
  styleUrls: ['./verifymanager.component.css']
})
/** verifymanager component*/
export class VerifymanagerComponent implements OnInit {
  /** verifymanager ctor */
  public accountVerification: FormGroup;
  public accountInfo: FormGroup;
  public verificationEdit: DESOS_Manager_Verify;
  private asyncResult: DESOS_Manager_Verify;
  private strResults: DESOS_StrResult;
  public warningmessage: string = "";
  public hideEdit: boolean = true;
  public disableForm: boolean = false;
  private verificationKey: string = "";
  private apiURL: string;
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;

  isPopupOpen = false;
  popupMessage = '';
  popupRedirectUrl = '/account/login';

  constructor(private location: Location, private route: ActivatedRoute, private router: Router,
    private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private matDialog: MatDialog,  private APIservices: DefaultService) {
    this.apiURL = baseUrl;
    this.verificationKey = this.route.snapshot.params.key;

    //let observable = new Observable(this.myObservableOne);
   // this.showProgressSpinner(observable);

    if (this.verificationKey != "" && !(this.verificationKey === undefined || this.verificationKey === null)) {
      let inparams = new HttpParams()
        .set('urlkey', this.verificationKey);
      //this.getAsyncData(inparams);
      this.showProgressSpinner(this.getVerificationInfo(inparams));
    }
    else {
      this.router.navigate(['/account']);
    }

  }

  ngOnInit() {
    this.accountVerification = new FormGroup({
      newmanagername: new FormControl('', [Validators.required]),
      newmanageremail: new FormControl('', [Validators.required, Validators.email]),
      managername: new FormControl(''),
      manageremail: new FormControl(''),
      managerprogram: new FormControl(''),
      programid: new FormControl(''),
    });

    this.accountInfo = new FormGroup({
      option: new FormControl('', [Validators.required]),
    });
  }

  myObservableOne(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 10000);
  }

  myObservableTwo(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 10000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();

      }
    );
  }


  getVerificationInfo(inparam: HttpParams) {
    return Observable.create(observer => {
      this.APIservices.getVerificationByKey(inparam).pipe(
        retry(3)
      ).subscribe((result) => {
        //this.verificationEdit = JSON.parse(JSON.stringify(result));
        try {
          this.verificationEdit = JSON.parse(JSON.stringify(result));
        } catch (e) {
          window.location.reload(); // Refresh if its wrong
        }
        if (this.verificationEdit != null) {
          //this.verificationEdit = this.asyncResult;
          this.accountVerification.controls["managerprogram"].setValue(this.verificationEdit.program_Name);
          this.accountVerification.controls["managername"].setValue(this.verificationEdit.desoS_Manager_Name);
          this.accountVerification.controls["manageremail"].setValue(this.verificationEdit.desoS_Manager_Email);
          this.accountVerification.controls["programid"].setValue(this.verificationEdit.program_ID);
          if (this.verificationEdit.processed_flg) {
            this.warningmessage = "Your verification has already been processed. " +
              "If you need further assistance, please contact desos@indiana.edu";
            this.hideEdit = true;
            this.disableForm = true;
            observer.next("Your verification completed.");
            observer.complete();
          }
          else {
            this.disableForm = false;
            observer.next("Your verification completed.");
            observer.complete();
          }
        }
        else {
          this.warningmessage = "No verification information found." +
            "If you need further assistance, please contact desos@indiana.edu";
          observer.next("No verification information found.");
          observer.complete();
        }
      });
    });
  }

  async getAsyncData(inparam: HttpParams) {

    this.asyncResult = await this.http.get<DESOS_Manager_Verify>(this.apiURL + 'api/DefaultData/GetVerificationByKey', { params: inparam }).toPromise();
    if (this.asyncResult != null) {
      this.verificationEdit = JSON.parse(JSON.stringify(this.asyncResult));
      this.accountVerification.controls["managerprogram"].setValue(this.verificationEdit.program_Name);
      this.accountVerification.controls["managername"].setValue(this.verificationEdit.desoS_Manager_Name);
      this.accountVerification.controls["manageremail"].setValue(this.verificationEdit.desoS_Manager_Email);
      this.accountVerification.controls["programid"].setValue(this.verificationEdit.program_ID);
      if (this.asyncResult.processed_flg) {
        this.warningmessage = "Your verification has already been processed. " +
          "If you need further assistance, please contact desos@indiana.edu";
        this.hideEdit = true;
        this.disableForm = true;
      }
      else {
        this.disableForm = false;
      }
    }
    else {
      this.warningmessage = "No verification information found." +
        "If you need further assistance, please contact desos@indiana.edu";
    }
    //console.log('No issues, I will wait until promise is resolved..');
  };

  hasError = (controlName: string, errorName: string) => {
    return this.accountVerification.controls[controlName].hasError(errorName);
  }

  submitVerification(inparam: DESOS_Manager_Verify) {
    return Observable.create(observer => {
      this.APIservices.desosManagerVerification(inparam).subscribe((result) => {
        this.strResults = JSON.parse(JSON.stringify(result));
        if (this.strResults != null) {
          if (this.strResults.strOutput == "Sucess") {
            this.warningmessage = "Verification completed, Thank you";
            observer.next("Your verification completed.");
            observer.complete();
            //this.navigateTologin();
            this.popupMessage = this.warningmessage;
            this.openPopup();
          }
          else if (this.strResults.strOutput == "Exist") {
            this.warningmessage = "Verification completed, The manager you are confirming already has a DESOS login. No further action is needed. If you need further assistance, Please contact desos@indiana.edu";
            observer.next("Your verification completed.");
            observer.complete();
            this.popupMessage = this.warningmessage;
            this.openPopup();           
            //this.navigateTologin();
          }
          else {
            this.warningmessage = "Error while submitting Verification.(" + this.strResults.strOutput + ") If you need further assistance, Please contact desos@indiana.edu";
            observer.next("Your verification failed.");
            observer.complete();
            this.popupMessage = this.warningmessage;
            this.openPopup();
          }
        }
      });
    });
  }
  openPopup(): void {
    this.isPopupOpen = true;
    console.log('Popup opened:', this.isPopupOpen);
  }

  closePopup(): void {
    this.isPopupOpen = false;
  }

  redirect(): void {
    console.log('Redirecting to:', this.popupRedirectUrl);
    this.router.navigate([this.popupRedirectUrl]);
  }

  btnSubmitVerification() {

    //let observable = new Observable(this.myObservableTwo);
    this.showProgressSpinner(this.submitVerification(this.verificationEdit));

    //this.http.post(this.apiURL + 'api/DefaultData/DESOSManagerVerification', this.verificationEdit).subscribe(result => {
    //  var result = result;
    //}, error => console.error(error));
    //this.warningmessage = "The manager you are confirming already has a DESOS login. No further action is needed. If you need further assistance, Please contact desos@indiana.edu";

    //this.http.post<DESOS_StrResult>(this.apiURL + 'api/DefaultData/DESOSManagerVerification', this.verificationEdit).subscribe(result => {
    //  if (result != null) {
    //    this.strResults = JSON.parse(JSON.stringify(result));

    //    if (this.strResults.strOutput == "Sucess") {
    //      this.warningmessage = "Verification completed, Thank you";
    //      //this.accountVerification.controls["newmanagername"].setValue("");
    //      //this.accountVerification.controls["newmanageremail"].setValue("");
    //      this.navigateTologin();
    //    }
    //    else if (this.strResults.strOutput == "Exist") {
    //      this.warningmessage = "Verification completed, The manager you are confirming already has a DESOS login. No further action is needed. If you need further assistance, Please contact desos@indiana.edu";
    //      //this.accountVerification.controls["newmanagername"].setValue("");
    //      //this.accountVerification.controls["newmanageremail"].setValue("");
    //      this.navigateTologin();
    //    }
    //    else {
    //      this.warningmessage = "Error while submitting Verification.(" + this.strResults.strOutput + ") If you need further assistance, Please contact desos@indiana.edu";
    //    }
    //  }
    //}, error => console.error(error));
  }

  btnChangeSubmitVerification() {

    this.verificationEdit.new_DESOS_Manager_Name = this.accountVerification.controls["newmanagername"].value;
    this.verificationEdit.new_DESOS_Manager_Email = this.accountVerification.controls["newmanageremail"].value;
    this.verificationEdit.program_ID = this.accountVerification.controls["programid"].value;
    this.warningmessage = "Updating....";
    this.showProgressSpinner(this.submitNewManagerAccount(this.verificationEdit));
    //this.delay(2000).then(any => { this.getNewManagerAccount(); });
  }


  submitNewManagerAccount(inparam: DESOS_Manager_Verify) {
    return Observable.create(observer => {
      this.APIservices.newDESOSManagerVerification(inparam).subscribe((result) => {
        this.strResults = JSON.parse(JSON.stringify(result));
        if (this.strResults != null) {
          if (this.strResults.strOutput == "Sucess") {
            this.warningmessage = "Verification completed, Thank you";
            this.popupMessage = this.warningmessage;
            this.accountVerification.controls["newmanagername"].setValue("");
            this.accountVerification.controls["newmanageremail"].setValue("");
            observer.next("Your verification completed.");
            observer.complete();            
            //this.navigateTologin();
          }
          else if (this.strResults.strOutput == "Exist") {
            this.warningmessage = "The manager you are confirming already has a DESOS login. No further action is needed. If you need further assistance, Please contact desos@indiana.edu";
            this.popupMessage = this.warningmessage;
            this.accountVerification.controls["newmanagername"].setValue("");
            this.accountVerification.controls["newmanageremail"].setValue("");
            observer.next("Your verification completed.");
            observer.complete();
            //this.navigateTologin();
          }
          else {
            
            this.warningmessage = "Error while submitting Verification.(" + this.strResults.strOutput + ") If you need further assistance, Please contact desos@indiana.edu";
            this.popupMessage = this.warningmessage;
            observer.next("Your verification completed.");
            observer.complete();
          }
        }
        this.openPopup();
      });
    });
  }

  getNewManagerAccount() {

    let observable = new Observable(this.myObservableTwo);
    this.showProgressSpinner(observable);

    this.http.post<DESOS_StrResult>(this.apiURL + 'api/DefaultData/NewDESOSManagerVerification', this.verificationEdit).subscribe(result => {
      if (result != null) {
        this.strResults = JSON.parse(JSON.stringify(result));

        if (this.strResults.strOutput == "Sucess") {
          this.warningmessage = "Verification completed, Thank you";
          this.popupMessage = this.warningmessage;
          this.accountVerification.controls["newmanagername"].setValue("");
          this.accountVerification.controls["newmanageremail"].setValue("");
          this.navigateTologin();
        }
        else if (this.strResults.strOutput == "Exist") {
          this.warningmessage = "The manager you are confirming already has a DESOS login. No further action is needed. If you need further assistance, Please contact desos@indiana.edu";
          this.popupMessage = this.warningmessage;
          this.accountVerification.controls["newmanagername"].setValue("");
          this.accountVerification.controls["newmanageremail"].setValue("");
          this.navigateTologin();
        }
        else {
          this.warningmessage = "Error while submitting Verification.(" + this.strResults.strOutput + ") If you need further assistance, Please contact desos@indiana.edu";
          this.popupMessage = this.warningmessage;
        }
      }
      this.openPopup();

    }, error => console.error(error));

  }

  onChange(event: MatRadioChange) {
    if (event.value == "1") {
      this.hideEdit = true;
    }
    else {
      this.hideEdit = false;
    }
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("ManagerVerification"));
  }

  navigateTologin() {
    this.delay(15000).then(any => {
      this.router.navigate(['/account/login']);
    }); 
  }
}
