<div class="register-photo">
  <div class="row h-25">
    <div class="col-sm-8">
      <p class="text-white"><a href="https://www.iu.edu/copyright/index.html" class="text-white" target="_blank"><u>Copyright</u></a> ©  {{ currentYear }} The Trustees of <a href="https://www.iu.edu/" target="_blank" class="text-white"><u>Indiana University</u></a> | <a routerLink="/privacy-notice" class="text-white"><u>Privacy Notice</u></a> | <a href="https://accessibility.iu.edu/" target="_blank" class="text-white"><u>Accessibility</u></a></p>
    </div>
    <div class="col-sm-4">
      <img src="./assets/images/home_page_logo.gif" style="height: 30px;" />
    </div>
  </div>
</div>
