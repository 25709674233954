import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chunkArray'
})
export class ChunkArrayPipe implements PipeTransform {
  transform(array: any[], size: number): any[][] {
    let result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }
}
