import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DESOS_Programs_List } from 'src/app/admin/program';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { Observable } from 'rxjs';
import { DefaultService } from 'src/app/services/default.service';
import { DESOS_StrResult } from 'src/app/dataentry/dataentry';

@Component({
    selector: 'app-general-request',
    templateUrl: './general-request.component.html',
    styleUrls: ['./general-request.component.css']
})
/** general-request component*/
export class GeneralRequestComponent{
    /** general-request ctor */
  public programList: DESOS_Programs_List[];
  public accountRequest: FormGroup;
  private generalRequest: General_Request;
  public strResults: DESOS_StrResult = new DESOS_StrResult();
  private apiURL: string;
  public warningmessage: string = "";
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  constructor(private location: Location, private route: ActivatedRoute, private router: Router,
    private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private matDialog: MatDialog,  private APIservices: DefaultService) {

    this.apiURL = baseUrl;
    //http.get<DESOS_Programs_List[]>(baseUrl + 'api/DefaultData/GetProgramSelectList').subscribe(result => {
    //  this.programList = result;
    //}, error => console.error(error));

    //let observable = new Observable(this.myObservableOne);
    this.showProgressSpinner(this.LoadProgramList());
  }

  ngOnInit() {
    this.accountRequest = new FormGroup({
      comments: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      programID: new FormControl('', [Validators.required]),
      fullname: new FormControl('')
    });
  }

  LoadProgramList() {
    return Observable.create(observer => {
      this.APIservices.getProgramList().subscribe((result) => {
        this.programList = JSON.parse(JSON.stringify(result));
        if (this.programList != null) {
          observer.next("Program list loaded.");
          observer.complete();
        }
        else {
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }

  hasError = (controlName: string, errorName: string) => {
    return this.accountRequest.controls[controlName].hasError(errorName);
  }

  myObservableOne(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 1000);
  }

  myObservableTwo(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 2000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  btnRequestClick () {
    if (this.accountRequest.valid) {
      let observable = new Observable(this.myObservableTwo);
      this.showProgressSpinner(observable);
      this.generalRequest = new General_Request();
      this.generalRequest.programID = this.accountRequest.controls["programID"].value;
      this.generalRequest.fullname = this.accountRequest.controls["fullname"].value;
      this.generalRequest.email = this.accountRequest.controls["email"].value;
      this.generalRequest.comments = this.accountRequest.controls["comments"].value;
      this.showProgressSpinner(this.NewGeneralRequest(this.generalRequest));
      //this.http.post(this.apiURL + 'api/DefaultData/GeneralRequest', this.generalRequest).subscribe
      //  (data => { console.log('sent ' + data) })
      //this.warningmessage = "General Request sent successfully.";
      //this.router.navigate(['/account']);
    }
  }

  NewGeneralRequest(newGeneralRequest: General_Request) {
    return Observable.create(observer => {
      this.APIservices.newGeneralRequest(newGeneralRequest).subscribe((result) => {
        this.strResults = JSON.parse(JSON.stringify(result));
        if (this.strResults != null) {
          if (this.strResults.strOutput == "Success") {
            this.warningmessage = "New General Request sent successfully..";
            observer.next("General Request completed.");
            observer.complete();
          }
          else {
            if (this.strResults.strOutput != "") {
              this.warningmessage = this.strResults.strOutput;
              observer.next("New General Request Error.");
              observer.complete();
            }
          }
        }
        else {
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }

  public onBack = () => {
    this.location.back();
  }

  btnBackClick = function () {
    this.router.navigate(['/account/login']);
  };
}

export class General_Request {
  public programID: string = "";
  public fullname: string = "";
  public email: string = "";
  public comments: string = "";
}
