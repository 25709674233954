export class Program {
  programid: string;
  programname: string;
  address: string;
  city: string;
  state: string;
  zip: string
  country: string;
  execdirector: string;
  execemail: string;
  telephone: string;
  fax: string;
  desosmanager: string;
  desosmanageremail: string;
}

export class User {
  userid: string = "";
  username: string = "";
  firstname: string = "";
  lastname: string = "";
  program: string = "";
  password: string = "";
  active: string = "";
}

export class Login {
  username: string = "";
  password: string = "";
}

export class DESOD_Login {
  username: string = "";
  password: string = "";
}

export class ChangePassword {
  username: string = "";
  oldpassword: string = "";
  newpassword: string = "";
  repeatpassword: string = "";
  resetpassflag: string = "";
}

export class DESOS_Users {
  username: string;
  adminLevel1: number;
  adminLevel2: number;
  adminDataEntryDESOS: number;
  adminDataEntryNIOS: number;
  adminReportsDESOS: number;
  adminReportsNIOS: number;
  adminDataEditDESOS: number;
  adminDataEditNIOS: number;
  adminProgDESOS: string;
  adminProgNIOS: string;
  hashPassword: string;
  reset_psswd_flg: number;
  firstName: string;
  lastName: string;
  account_Active_flg: number;
  token: string;
}

export interface iDESOS_Users {
  username: string;
  adminLevel1: number;
  adminLevel2: number;
  adminDataEntryDESOS: number;
  adminDataEntryNIOS: number;
  adminReportsDESOS: number;
  adminReportsNIOS: number;
  adminDataEditDESOS: number;
  adminDataEditNIOS: number;
  adminProgDESOS: string;
  adminProgNIOS: string;
  hashPassword: string;
  reset_psswd_flg: number;
  firstName: string;
  lastName: string;
  account_Active_flg: number;
}


export class DESOS_Programs {
  program_ID: string = "";
  program_Name: string = "";
  street_Address: string = "";
  city: string = "";
  state: string = "";
  zipCode: string = "";
  county: string = "";
  executive_Director_Name: string = "";
  telephone: string = "";
  fax: string = "";
  executive_Director_Email: string = "";
  desoS_Manager_Name: string = "";
  desoS_Manager_Email: string = "";
  emp_flg: boolean = false;
  res_flg: boolean = false;
}

export class DESOS_Manager_Verify_URL {
  public url_Current: string = ""; //  { get; set; }
}

export class DESOS_Manager_Verify {
  public verifyID: string = "";// { get; set; }
  public program_ID: string = "";//  { get; set; }
  public program_Name: string = "";//  { get; set; }
  public executive_Director_Name: string = "";//  { get; set; }
  public executive_Director_Email: string = "";//  { get; set; }
  public processed_flg: boolean = false; //  { get; set; }
  public desoS_Manager_Name: string = "";//  { get; set; }
  public desoS_Manager_Email: string = "";//  { get; set; }
  public new_DESOS_Manager_Name: string = ""; //  { get; set; }
  public new_DESOS_Manager_Email: string = "";//  { get; set; }
  public accepted_flg: boolean = false; //  { get; set; }
  public denied_flg: boolean = false; //  { get; set; }
  public notes: string = "";//  { get; set; }
  public url: string = ""; //  { get; set; }
  public url_Key: string = ""; //  { get; set; }
  public url_Current: string = ""; //  { get; set; }
  public gen_dt: string = ""; //  { get; set; }
  public email_Sent_flg: boolean = false; //  { get; set; }
  public selected: boolean = false; //  { get; set; }
}

export class DESOS_Programs_List {
  public program_ID: string;
  public program_Name: string;
}

export class UserLoginModel {
  public username: string = "";
  public password: string = "";
}

export class DESOS_Email_Template {
  public id: string = "";// { get; set; }
  public template_Name: string = "";// { get; set; }
  public subject: string = "";// { get; set; }
  public active_flag: string = "";// { get; set; }
  public email_Content: string = "";// { get; set; }
}
