import { Component, Inject, OnInit, ViewChild, Input, Output, OnChanges, ElementRef } from '@angular/core';
import { DESOS_Reports_Param, DESOS_Report_Results, DESOS_Report_Results_All, DESOS_Reports, Report_Results } from '../reports';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { EventEmitter } from 'events';
import { TempDataService } from 'src/app/services/temp-data.service';
import { ReportService } from 'src/app/services/report.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.css']
})
/** report-view component*/
export class ReportViewComponent implements OnInit, OnChanges {
  /** report-view ctor */
  panelOpenState = false;
  private reportparams: DESOS_Reports_Param;
  private reportId: number;
  public isCustom: boolean;
  public isEmptyOrError: boolean; 
  private Report1Results: Report_Results[];
  public dataSource: MatTableDataSource<Report_Results>[];
  public dataSourceItem: MatTableDataSource<Report_Results>;
  public table1Headers: HeaderName = new HeaderName();
  public table2Headers: HeaderName;
  private apiURL: string;
  public titleList: string[];
  public printDataSource: string[];
  private reportresults: DESOS_Report_Results_All;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public displayedColumns: string[] = ['Item', 'Count', 'Percentage'];
  public titleHeaders: object = {
    "program": "Program", "county": "County", "city": "City",
    "gender": "Gender", "race": "Race", "age": "Age", "legalGuardian": "Own legal Guardian",
    "pdl": "Primary Disability",
    "pdlSubval": "Primary Disability: ID/DD",
    "sdl": "Secondary Disability",
    "sdlSubval": "Secondary Disability: ID/DD",
    "mobility": "Mobility", "bi": "Behavioral Issues",
    "los": "Level of Support", "communication": "Communication", "lpa": "Level of Personal Assistance",
    "customReport": "Data"
  };
  public showPColumn: boolean[]
  // Printer Section
  //@Input('printRPT1') section: string;
  //@Output() sectionChange = new EventEmitter<any>(); 
  constructor(private location: Location, private route: ActivatedRoute, private router: Router,
    private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private matDialog: MatDialog, private ele: ElementRef,
  private tempdata: TempDataService, private APIServices: ReportService) {
    this.apiURL = baseUrl;
    //if (this.section === undefined)
    //  this.section = '';
    //this.getRecordsListById("110");
    let observable = new Observable(this.myObservableThree);
    this.showProgressSpinner(observable);
  }

  ngOnInit() {

    this.reportparams = this.tempdata.getReportparams();
    //if (sessionStorage.reportparams != null) {
    //  this.reportparams = JSON.parse(sessionStorage.reportparams);
    //}

    this.LoadReports();

    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);

    //  if (!this.desosuserinfo.blnIsAdmin) {
    //    this.reportparam.controls["program"].disable();
    //    this.reportparam.controls["reportingperiod"].disable();
    //  }
    //}

  }

  ngOnChanges(changes) {
    if (changes.section && changes.section.currentValue !== undefined  && changes.section.currentValue !== '') {

    }
  }

  afterPrint() {
    console.log("after print");
    this.ele.nativeElement.children[0].innerHTML = '';
   // this.sectionChange.emit('');
    //this.section = '';

  }

  myObservableThree(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 2000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        //console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  LoadReports() {
    //this.http.post<DESOS_Report_Results>(this.apiURL + 'api/Reports/GetReport', this.reportparams).subscribe(result => {
    this.APIServices.getGetReportAll(this.reportparams).subscribe(result => {
      this.reportresults = result;
      console.log("results: ", this.reportresults, parseInt(this.reportparams.id));
      if (this.reportresults != null) {
        this.reportId = parseInt(this.reportparams.id);
        // DEMOGRAPHIC
        if (parseInt(this.reportparams.id) >= 10) {
          this.dataSource = [];
          this.titleList = [];
          this.printDataSource = [];
          this.showPColumn = [];
          let itemIndex = 0;

          if (this.reportId > 10) {
            this.isCustom = true;
            try { this.Report1Results = JSON.parse(this.reportresults['customReport']); }
            catch (error) {
              this.isEmptyOrError = true;
              console.log("error in reports", error);
            }
            this.dataSourceItem = new MatTableDataSource(this.Report1Results);
            this.dataSource[0] = this.dataSourceItem;
            this.displayedColumns = JSON.parse(this.reportresults['customColumns']);
            this.titleList['customReport'] = this.reportresults['customTitle'];
          }
          else {
            for (let i in this.reportresults) {
              try { this.Report1Results = JSON.parse(this.reportresults[i]); }
              catch (error) {
                this.isEmptyOrError = true;
                console.log("error in reports[", i, "]", error);
              }
              if (this.reportresults[i] == null) {
                continue;
              }
              //console.log("report", this.titleHeaders[i], i);
              this.dataSourceItem = new MatTableDataSource(this.Report1Results);
              this.dataSource[itemIndex] = this.dataSourceItem;
              this.printDataSource[itemIndex] = JSON.parse(this.reportresults[i]);
              this.showPColumn[itemIndex] = (Object.keys(JSON.parse(this.reportresults[i])[0]).length > 2);
              this.titleList[itemIndex] = i;
              itemIndex = itemIndex + 1;
            }
          }
        }
        else {
          // QUESTIONS
          this.dataSource = [];
          this.titleList = [];
          this.printDataSource = [];
          this.showPColumn = [];
          let itemIndex = 0;

          JSON.parse(this.reportresults.questions).forEach(obj => {
            const key = obj.Question;
            const value = obj.Content;
            this.titleHeaders[key] = value;
          });

          //console.log("Questions", this.titleHeaders);

          for (let i in this.reportresults) {
            let item = i;
            if (i == "program" ||
              i == "questions" || this.reportresults[i] == "" || this.reportresults[i] == null
            ) {
              continue;
            }
            try { this.Report1Results = JSON.parse(this.reportresults[i]); }
            catch (error) {
              this.isEmptyOrError = true;
              console.log("error in reports", error);
            }
            this.showPColumn[itemIndex] = (Object.keys(JSON.parse(this.reportresults[i])[0]).length > 2);
            if (i.startsWith("q") && i !== "questions") {
              item = i.toUpperCase();
            }

            this.dataSourceItem = new MatTableDataSource(this.Report1Results);
            this.dataSource[itemIndex] = this.dataSourceItem;
            this.printDataSource[itemIndex] = JSON.parse(this.reportresults[i]);
            this.titleList[itemIndex] = item;
            itemIndex = itemIndex + 1;
          }
        }

        if (this.dataSource[0] != undefined) {
          if (this.dataSource[0].data.length > 0) {
            this.dataSource[0].sort = this.sort;
          }
        }

        //this.dataSource.paginator = this.paginator;
        //if (this.dataSource.data.length > 0) {
        //  this.dataSource.sort = this.sort;
        //}
      }
      else {
        this.isEmptyOrError = true;
      }
    }, error => console.error(error));
  }

  GetTable1Headers(header1: string) {
    var splitHeader1 = header1.split(',');
    this.table1Headers = new HeaderName();
    if (splitHeader1.length >= 1) {
      this.table1Headers.Header1 = splitHeader1[0];
    }
    if (splitHeader1.length >= 2) {
      this.table1Headers.Header2 = splitHeader1[1];
    }
    if (splitHeader1.length >= 3) {
      this.table1Headers.Header3 = splitHeader1[2];
    }
    if (splitHeader1.length >= 4) {
      this.table1Headers.Header4 = splitHeader1[3];
    }
    if (splitHeader1.length >= 5) {
      this.table1Headers.Header5 = splitHeader1[4];
    }

    //this.displayedColumns = [this.table1Headers.Header1, this.table1Headers.Header2, this.table1Headers.Header3, this.table1Headers.Header4];
  }

  GetTable2Headers(header2: string) {
    var splitHeader1 = header2.split(',');
    this.table2Headers = new HeaderName();
    if (splitHeader1.length >= 1) {
      this.table2Headers.Header1 = splitHeader1[0];
    }
    if (splitHeader1.length >= 2) {
      this.table2Headers.Header2 = splitHeader1[1];
    }
    if (splitHeader1.length >= 3) {
      this.table2Headers.Header3 = splitHeader1[2];
    }
    if (splitHeader1.length >= 4) {
      this.table2Headers.Header4 = splitHeader1[3];
    }
    if (splitHeader1.length >= 5) {
      this.table2Headers.Header5 = splitHeader1[4];
    }
  }

  btnBackClick() {
    //this.router.navigate(['/admin/programlist']);
    this.location.back();
  }

  btnPrintClick() {

    let doc = new jsPDF();
    let startHeight = 20;
    let rowHeight = 5;
    let pageHeight = doc.internal.pageSize.height; //297
    let pageWidth = doc.internal.pageSize.width; //297

    doc.text('Report', pageWidth / 2 - 10, pageHeight/ 2 - 20);
    //doc.text('Primary Environment: ', pageWidth / 2 - 30, pageHeight / 2 - 20);

    doc.addPage();

    if (this.reportId <= 10) {

      doc.text('IDD', pageWidth / 2 - 10, startHeight);

      doc.text(this.titleHeaders['gender'], 14, startHeight + rowHeight * 2);
      autoTable(doc, { html: '#table-gender', startY: startHeight + rowHeight * 3 });

      doc.text(this.titleHeaders['race'], 14, startHeight + rowHeight * 11);
      autoTable(doc, { html: '#table-race', startY: startHeight + rowHeight * 12 });

      doc.text(this.titleHeaders['age'], 14, startHeight + rowHeight * 26);
      autoTable(doc, { html: '#table-age', startY: startHeight + rowHeight * 27 });

      doc.text(this.titleHeaders['legalGuardian'], 14, startHeight + rowHeight * 40);
      autoTable(doc, { html: '#table-legalGuardian', startY: startHeight + rowHeight * 41 });

      doc.addPage();

      doc.text('DC', pageWidth / 2 - 10, startHeight);

      doc.text(this.titleHeaders['pdl'], 14, startHeight + rowHeight * 2);
      autoTable(doc, { html: '#table-pdl', startY: startHeight + rowHeight * 3 });

      doc.text(this.titleHeaders['pdlSubval'], 14, startHeight + rowHeight * 26);
      autoTable(doc, { html: '#table-pdlSubval', startY: startHeight + rowHeight * 27 });

      doc.addPage();

      doc.text(this.titleHeaders['sdl'], 14, startHeight + rowHeight * 2);
      autoTable(doc, { html: '#table-sdl', startY: startHeight + rowHeight * 3 });

      doc.text(this.titleHeaders['sdlSubval'], 14, startHeight + rowHeight * 26);
      autoTable(doc, { html: '#table-sdlSubval', startY: startHeight + rowHeight * 27 });

      doc.addPage();

      doc.text(this.titleHeaders['mobility'], 14, startHeight + rowHeight * 2);
      autoTable(doc, { html: '#table-mobility', startY: startHeight + rowHeight * 3 });

      doc.text(this.titleHeaders['bi'], 14, startHeight + rowHeight * 14);
      autoTable(doc, { html: '#table-bi', startY: startHeight + rowHeight * 15 });

      doc.text(this.titleHeaders['los'], 14, startHeight + rowHeight * 26);
      autoTable(doc, { html: '#table-los', startY: startHeight + rowHeight * 27 });

      doc.addPage();

      doc.text('DC-1', pageWidth / 2 - 10, startHeight);

      doc.text(this.titleHeaders['communication'], 14, startHeight + rowHeight * 2);
      autoTable(doc, { html: '#table-communication', startY: startHeight + rowHeight * 3 });

      doc.text(this.titleHeaders['lpa'], 14, startHeight + rowHeight * 14);
      autoTable(doc, { html: '#table-lpa', startY: startHeight + rowHeight * 15 });

      if (this.reportparams.id != "10") {
        doc.addPage();

        doc.text('Based on Primary Environment ', pageWidth / 2 - 40, startHeight);

        doc.text(this.titleHeaders['Q1'], 14, startHeight + rowHeight * 2);
        autoTable(doc, { html: '#table-Q1', startY: startHeight + rowHeight * 3 });

        if (this.reportparams.id != '5') {
          doc.text(this.titleHeaders['Q2'], 14, startHeight + rowHeight * 8);
          autoTable(doc, { html: '#table-Q2', startY: startHeight + rowHeight * 9 });
        }

        if (this.reportparams.id != '4' && this.reportparams.id != '5') {

          if (this.reportparams.id != '3') {
            doc.text(this.titleHeaders['Q3'], 14, startHeight + rowHeight * 21);
            autoTable(doc, { html: '#table-Q3', startY: startHeight + rowHeight * 22 });
          }

          doc.text(this.titleHeaders['Q5'], 14, startHeight + rowHeight * 28);
          autoTable(doc, { html: '#table-Q5', startY: startHeight + rowHeight * 29 });

          doc.text(this.titleHeaders['Q6'], 14, startHeight + rowHeight * 37);
          autoTable(doc, { html: '#table-Q6', startY: startHeight + rowHeight * 38 });

          // RESET Page
          //doc.text('page ' + 1,150, 285);
          doc.addPage();

          doc.text(this.titleHeaders['Q7'], 14, startHeight + rowHeight * 2);
          autoTable(doc, { html: '#table-Q7', startY: startHeight + rowHeight * 3 });

          doc.text(this.titleHeaders['Q8'], 14, startHeight + rowHeight * 22);
          autoTable(doc, { html: '#table-Q8', startY: startHeight + rowHeight * 23 });

          doc.text(this.titleHeaders['Q9'], 14, startHeight + rowHeight * 31);
          autoTable(doc, { html: '#table-Q9', startY: startHeight + rowHeight * 32 });

          doc.text(this.titleHeaders['Q10'], 14, startHeight + rowHeight * 40);
          autoTable(doc, { html: '#table-Q10', startY: startHeight + rowHeight * 41 });

          // RESET Page
          doc.addPage();

          doc.text(this.titleHeaders['Q11'], 14, startHeight + rowHeight * 2);
          autoTable(doc, { html: '#table-Q11', startY: startHeight + rowHeight * 3 });
        }

        if (this.reportparams.id != '4') { doc.addPage(); }

        doc.text(this.titleHeaders['Q12'], 14, startHeight + rowHeight * 15);
        autoTable(doc, { html: '#table-Q12', startY: startHeight + rowHeight * 16 });

        if (this.reportparams.id != '5') {

          doc.text(this.titleHeaders['Q13'], 14, startHeight + rowHeight * 28);
          autoTable(doc, { html: '#table-Q13', startY: startHeight + rowHeight * 29 });

          doc.text(this.titleHeaders['Q14'], 14, startHeight + rowHeight * 39);
          autoTable(doc, { html: '#table-Q14', startY: startHeight + rowHeight * 40 });
        }

        // RESET Page
        doc.addPage();

        doc.text(this.titleHeaders['Q15'], 14, startHeight + rowHeight * 2);
        autoTable(doc, { html: '#table-Q15', startY: startHeight + rowHeight * 3 });

        if (this.reportparams.id == '2') {
          doc.text(this.titleHeaders['Q16'], 14, startHeight + rowHeight * 13);
          autoTable(doc, { html: '#table-Q16', startY: startHeight + rowHeight * 14 });

          doc.text(this.titleHeaders['Q17'], 14, startHeight + rowHeight * 26);
          autoTable(doc, { html: '#table-Q17', startY: startHeight + rowHeight * 27 });
        }

        doc.text(this.titleHeaders['Q18A'], 14, startHeight + rowHeight * 40);
        autoTable(doc, { html: '#table-Q18A', startY: startHeight + rowHeight * 41 });

        // RESET Page
        doc.addPage();

        doc.text(this.titleHeaders['Q18B'], 14, startHeight + rowHeight * 2);
        autoTable(doc, { html: '#table-Q18B', startY: startHeight + rowHeight * 3 });

        doc.text(this.titleHeaders['Q19'], 14, startHeight + rowHeight * 11);
        autoTable(doc, { html: '#table-Q19', startY: startHeight + rowHeight * 12 });

        doc.text(this.titleHeaders['Q20'], 14, startHeight + rowHeight * 28);
        autoTable(doc, { html: '#table-Q20', startY: startHeight + rowHeight * 29 });

        if (this.reportparams.id != '5') {

          doc.text(this.titleHeaders['Q21'], 14, startHeight + rowHeight * 37);
          autoTable(doc, { html: '#table-Q21', startY: startHeight + rowHeight * 38 });

          // RESET Page
          doc.addPage();

          doc.text(this.titleHeaders['Q22'], 14, startHeight + rowHeight * 2);
          autoTable(doc, { html: '#table-Q22', startY: startHeight + rowHeight * 3 });

          doc.text(this.titleHeaders['Q23'], 14, startHeight + rowHeight * 12);
          autoTable(doc, { html: '#table-Q23', startY: startHeight + rowHeight * 13 });

          doc.text(this.titleHeaders['Q24'], 14, startHeight + rowHeight * 24);
          autoTable(doc, { html: '#table-Q24', startY: startHeight + rowHeight * 25 });
        }
      }
    } else {
      doc.text(this.titleList['customReport'], 14, startHeight + rowHeight * 2);

      autoTable(doc, { html: '#table-customReport', startY: startHeight + rowHeight * 3 });
    }

    doc.save('Report.pdf')
  }

  btPrintRPT1() {
   // alert(document.getElementById("printRPT1").innerHTML);
   // if (this.section && this.section != undefined && this.section != '') {
      var printContents = document.getElementById("printRPT1").outerHTML;
      var originalContents = document.body.innerHTML;
      //alert(printContents);
      if (window) {
        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
          var popup = window.open('', '_blank',
            'width=800px,height=600px,scrollbars=no,menubar=no,toolbar=no,'
            + 'location=no,status=no,titlebar=no');

          popup.window.focus();
          popup.document.write('<!DOCTYPE html><html><head>  '
            //+ '<link rel="stylesheet" href="node_modules/bootstrap/dist/css/bootstrap.css" '
           // + this.fetchStylesheets()

            //+ 'media="screen,print">'
            ///+ '<link rel="stylesheet" href="style.css" media="screen,print">'
            + '</head><body onload="window.print()">'  + '<div>'
            + printContents + '</div></html>');
          popup.onbeforeunload = function (event) {
            popup.close();
            return '.\n';
          };
          popup.onabort = function (event) {
            popup.document.close();
            popup.close();
          }
        } else {
          var popup = window.open('', '_blank', 'width=800px,height=600px');
          popup.document.open();
          popup.document.write('<html><head>' +
          //  + this.fetchStylesheets()

            //+'<link rel="stylesheet" href="node_modules/bootstrap/dist/css/bootstrap.css"'
            //+ ' media="all">'
            //+ '<link rel="stylesheet" href="style.css" media="all">'
            + '</head><body onload="window.print()">' + printContents + '</html>');
          popup.document.close();
        }

        popup.document.close();
     }
      return true;
    }
  //}


  fetchStylesheets() {
    var output: string = '';
    for (var i = 0; i < document.styleSheets.length; i++) {
      output = output + ' <link rel="stylesheet" type="text/css" href="' +
        window.document.styleSheets[0].href + '" /> ';
    }
    return output;
  }

  fetchscriptSheets() {
    var output: string = '';
    for (var i = 0; i < document.scripts.length; i++) {
      output = output + ' <script type="text/javascript" src="' +
        window.document.scripts[0].src + '" /> ';
    }
    return output;
  }
}



export class HeaderName {
  public Header1: string = "";
  public Header2: string = "";
  public Header3: string = "";
  public Header4: string = "";
  public Header5: string = "";
}
