import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { dataentry_tab2_quest1, dataentry_tab2_quest2, dataentry_tab2_quest3_4_5 } from '../dataentry-tab2';
import { helpText, DESOS_Individuals, DESOS_Outcomes } from '../dataentry';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TempDataService } from 'src/app/services/temp-data.service';
import { DataentryService } from '../../services/dataentry.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DESOS_UserInfo } from 'src/app/account/account';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-dataentry-tab2',
  templateUrl: './dataentry-tab2.component.html',
  styleUrls: ['./dataentry-tab2.component.css'],
  providers:[TempDataService],
  encapsulation: ViewEncapsulation.None,
})
/** dataentry-tab2 component*/
export class DataentryTab2Component implements OnInit {
  public desosuserinfo: DESOS_UserInfo;
  questionData: any;

  /** dataentry-tab2 ctor */
  isIDorDDSelected: boolean;
  isIDorDDTypeReset: boolean;
  isIDorDDTypeSelected: false;
  isDisabilitySelected: false;
  helpMessage: helpText;
  public DEtab2Quest1: dataentry_tab2_quest1;
  public DEtab2Quest2: dataentry_tab2_quest2;
  public DEtab2Quest345: dataentry_tab2_quest3_4_5;
  private curEditOutComes: DESOS_Outcomes;
  public tab2FormGroup: FormGroup;
  constructor(private snackBar: MatSnackBar, private tempdata: TempDataService, private APIservices: DataentryService, private authentication: AuthenticationService) {
    this.isIDorDDSelected = false;
    this.isIDorDDTypeReset = true;
    this.helpMessage = new helpText();
    this.DEtab2Quest1 = new dataentry_tab2_quest1();
    this.DEtab2Quest2 = new dataentry_tab2_quest2();
    this.DEtab2Quest345 = new dataentry_tab2_quest3_4_5();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
    });
  }

  ngOnInit() {

    //if (sessionStorage.editoutcomes != null) {
    //  this.curEditOutComes = JSON.parse(sessionStorage.editoutcomes);
    //}

    this.curEditOutComes = this.tempdata.getCurrentoutcome();

    this.tab2FormGroup = new FormGroup({
      chkpdLval: new FormControl(),
      pdLval: new FormControl('', [Validators.required]),
      pdLsubval: new FormControl(''),
      chksdLval: new FormControl(),
      sdLval: new FormControl('', [Validators.required]),
      sdLsubval: new FormControl(''),
      sdLvalnone: new FormControl(''),
      mbYval: new FormControl('', [Validators.required]),
      bIval: new FormControl('', [Validators.required]),
      los: new FormControl('', [Validators.required]),
    });

    this.fetchQuestionWithOptions();
  }

  hasError = (controlName: string, errorName: string) => {
    return this.tab2FormGroup.controls[controlName].hasError(errorName);
  }
  async fetchQuestionWithOptions() {
    try {
      // Define the range of question identifiers
      const startQuestionIndex = 14;
      const endQuestionIndex = 16;
      const questionPrefix = 'd';

      // Initialize an object to hold the data for each question
      this.questionData = {};

      // Fetch questions within the range
      for (let questionIndex = startQuestionIndex; questionIndex <= endQuestionIndex; questionIndex++) {
        const questionNumber = `${questionPrefix}${questionIndex}`;
        const inparam = new HttpParams().set('questionNumber', questionNumber);
        this.questionData[questionNumber] = await this.APIservices.getQuestionWithOptions(inparam).toPromise();
      }

      let additionalNumber = 'd12-0';
      let additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      additionalNumber = 'd12-1';
      additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      additionalNumber = 'd12-2';
      additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      additionalNumber = 'd13-0';
      additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      additionalNumber = 'd13-1';
      additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      additionalNumber = 'd13-2';
      additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      // Check if data for all questions is retrieved
      const allDataReceived = Object.keys(this.questionData).length >= (endQuestionIndex - startQuestionIndex + 1 + 6) &&
        Object.values(this.questionData).every(data => !!data);

      if (allDataReceived) {
        console.log('Data retrieved:', this.questionData[`${questionPrefix}${startQuestionIndex}`]); // Example log for the first question
        this.curEditOutComes = this.tempdata.getCurrentoutcome();
        console.log('curEditOutComes:', this.curEditOutComes);
        this.initFormGroup();
      } else {
        console.log('No data received');
      }
    } catch (error) {
      console.error('Failed to fetch question options:', error);
    }
  };
  initFormGroup() {

    if (this.curEditOutComes.pdLsubval != "")
      this.curEditOutComes.pdLval == "0"
    if (this.curEditOutComes.sdLsubval != "")
      this.curEditOutComes.sdLval == "0"

    this.DEtab2Quest1.chkIDorDDS = (this.curEditOutComes.pdLsubval != "" ? true : false);
    this.tab2FormGroup.controls["chkpdLval"].setValue(this.DEtab2Quest1.chkIDorDDS);
    if (this.DEtab2Quest1.chkIDorDDS) {
      this.DEtab2Quest1.isIDorDDSelected = true;
      //this.tab2FormGroup.controls["pdLsubval"].enable();
      this.DEtab2Quest1.optTypeSelected = this.curEditOutComes.pdLsubval;
      this.tab2FormGroup.controls["pdLsubval"].setValue(this.curEditOutComes.pdLsubval);
      this.tab2FormGroup.controls["pdLval"].setValue("0");
      this.tab2FormGroup.controls["pdLval"].setValidators(null);
      this.tab2FormGroup.controls["pdLval"].updateValueAndValidity();
    }
    else {
      this.DEtab2Quest1.isIDorDDSelected = false;
     // this.tab2FormGroup.controls["pdLsubval"].disable();
      this.DEtab2Quest1.optDDDSelected = this.curEditOutComes.pdLval;
      this.tab2FormGroup.controls["pdLval"].setValue(this.curEditOutComes.pdLval);
    }

    this.DEtab2Quest2.chkIDorDDS = (this.curEditOutComes.sdLsubval != "" ? true : false);
    this.tab2FormGroup.controls["chksdLval"].setValue(this.DEtab2Quest2.chkIDorDDS);
    if (this.DEtab2Quest2.chkIDorDDS) {
      this.DEtab2Quest2.isIDorDDSelected = true;
      this.DEtab2Quest2.optTypeSelected = this.curEditOutComes.sdLsubval;
      this.tab2FormGroup.controls["sdLsubval"].setValue(this.curEditOutComes.sdLsubval);
      this.tab2FormGroup.controls["sdLval"].setValue("0");
      this.tab2FormGroup.controls["sdLval"].setValidators(null);
      this.tab2FormGroup.controls["sdLval"].updateValueAndValidity();
    }
    else {
      this.DEtab2Quest2.isIDorDDSelected = false;
      this.DEtab2Quest2.optDDDSelected1 = this.curEditOutComes.sdLval;
      this.DEtab2Quest2.numNoneSelected = this.curEditOutComes.sdLval;
      this.tab2FormGroup.controls["sdLval"].setValue(this.curEditOutComes.sdLval);
      this.tab2FormGroup.controls["sdLvalnone"].setValue(this.curEditOutComes.sdLval);
    }
    this.tab2FormGroup.controls["mbYval"].setValue(this.curEditOutComes.mbYval);
    this.tab2FormGroup.controls["bIval"].setValue(this.curEditOutComes.bIval);
    this.tab2FormGroup.controls["los"].setValue(this.curEditOutComes.los);
    // this.DEtab2Quest345.optquestion3Selected = this.curEditOutComes.mbYval;
    // this.DEtab2Quest345.optquestion4Selected = this.curEditOutComes.bIval;
    // this.DEtab2Quest345.optquestion5Selected = this.curEditOutComes.los;
  }

  getEntries(curNewOutComes: DESOS_Outcomes) : DESOS_Outcomes {
    //this.curEditOutComes.chkpdLval = (this.tab2FormGroup.controls["chkpdLval"].value == true ? "1" : "0");
    try {

      curNewOutComes.pdLval = this.tab2FormGroup.controls["pdLval"].value;
      curNewOutComes.pdLsubval = this.tab2FormGroup.controls["pdLsubval"].value;
      curNewOutComes.sdLval = this.tab2FormGroup.controls["sdLval"].value;
      curNewOutComes.sdLsubval = this.tab2FormGroup.controls["sdLsubval"].value;
      curNewOutComes.mbYval = this.tab2FormGroup.controls["mbYval"].value;
      curNewOutComes.bIval = this.tab2FormGroup.controls["bIval"].value;
      curNewOutComes.los = this.tab2FormGroup.controls["los"].value;

      //sessionStorage.setItem("editoutcomes", JSON.stringify(this.curEditOutComes));
    }
    catch (Error) {
      console.log("Tab2:" + Error.message);
    }

    return curNewOutComes;
  }

  closeSnackBar() {
    //this.snackBar.dismiss();
  }

  hideShowDDType(event: MatCheckboxChange) {
    if (event.checked) {
      this.DEtab2Quest1.isIDorDDSelected = true;
     // this.tab2FormGroup.controls["pdLsubval"].enable();
      //this.DEtab2Quest1.optDDDSelected = "";
      //this.DEtab2Quest1.optTypeSelected = "";
      this.tab2FormGroup.controls["pdLval"].setValue("0");
      this.tab2FormGroup.controls["pdLsubval"].setValue("");
      this.tab2FormGroup.controls["pdLsubval"].setValidators(Validators.required);
      this.tab2FormGroup.controls["pdLval"].setValidators(null);

    }
    else {
      this.DEtab2Quest1.isIDorDDSelected = false;
      //this.DEtab2Quest1.optDDDSelected = "";
      //this.tab2FormGroup.controls["pdLsubval"].disable();
      this.tab2FormGroup.controls["pdLval"].setValue("");
      this.tab2FormGroup.controls["pdLsubval"].setValidators(null);
      this.tab2FormGroup.controls["pdLval"].setValidators(Validators.required);
    }

    this.tab2FormGroup.controls["pdLsubval"].updateValueAndValidity();
    this.tab2FormGroup.controls["pdLval"].updateValueAndValidity();
  }

  unSelectReason() {
    this.DEtab2Quest1.optDDDSelected = "";
    this.tab2FormGroup.controls["pdLval"].setValue("0");
  }


  unSelectIDorDD() {
    this.DEtab2Quest1.optTypeSelected = "";
    this.tab2FormGroup.controls["pdLsubval"].setValue("");
    this.tab2FormGroup.controls["chkpdLval"].setValue(false);
    this.DEtab2Quest1.chkIDorDDS = false;
    this.DEtab2Quest1.isIDorDDSelected = false;

    this.tab2FormGroup.controls["pdLsubval"].setValidators(null);
    this.tab2FormGroup.controls["pdLsubval"].updateValueAndValidity();

  }

  hideShowDDType1(event: MatCheckboxChange) {
    if (event.checked) {
      this.DEtab2Quest2.isIDorDDSelected = true;
      this.DEtab2Quest2.optDDDSelected1 = "";
      this.DEtab2Quest2.optTypeSelected = "";
      this.DEtab2Quest2.numNoneSelected = "";
      this.tab2FormGroup.controls["sdLsubval"].setValue("");
      this.tab2FormGroup.controls["sdLvalnone"].setValue("");
      this.tab2FormGroup.controls["sdLval"].setValue("");
      this.tab2FormGroup.controls["sdLsubval"].setValidators(Validators.required);
      this.tab2FormGroup.controls["sdLval"].setValidators(null);
    }
    else {
      this.DEtab2Quest2.isIDorDDSelected = false;
      this.DEtab2Quest2.optDDDSelected1 = "";
      this.DEtab2Quest2.numNoneSelected = "";
      this.DEtab2Quest2.optTypeSelected = "";
      this.tab2FormGroup.controls["sdLsubval"].setValue("");
      this.tab2FormGroup.controls["sdLvalnone"].setValue("");
      this.tab2FormGroup.controls["sdLval"].setValue("");
      this.tab2FormGroup.controls["sdLsubval"].setValidators(null);
      this.tab2FormGroup.controls["sdLval"].setValidators(Validators.required);
    }

    this.tab2FormGroup.controls["sdLsubval"].updateValueAndValidity();
    this.tab2FormGroup.controls["sdLval"].updateValueAndValidity();
  }

  unSelectReason1() {
    this.DEtab2Quest2.optDDDSelected1 = "";
    this.DEtab2Quest2.numNoneSelected = "";
    this.tab2FormGroup.controls["sdLvalnone"].setValue("");
    this.tab2FormGroup.controls["sdLval"].setValue("0");
  }

  unSelectIDorDD1() {
    this.DEtab2Quest2.optTypeSelected = "";
    this.DEtab2Quest2.numNoneSelected = "";
    this.DEtab2Quest2.chkIDorDDS = false;
    this.DEtab2Quest2.isIDorDDSelected = false;
    this.tab2FormGroup.controls["chksdLval"].setValue(false);
    this.tab2FormGroup.controls["sdLsubval"].setValue("");
    this.tab2FormGroup.controls["sdLvalnone"].setValue("");
    this.tab2FormGroup.controls["sdLsubval"].setValidators(null);
    this.tab2FormGroup.controls["sdLsubval"].updateValueAndValidity();
  }


  unSelectoptNone() {

    this.DEtab2Quest2.optDDDSelected1 = "";
    this.DEtab2Quest2.optTypeSelected = "";
    this.DEtab2Quest2.chkIDorDDS = false;
    this.DEtab2Quest2.isIDorDDSelected = false;
    this.tab2FormGroup.controls["chksdLval"].setValue(false);
    this.tab2FormGroup.controls["sdLval"].setValue("11");
    this.tab2FormGroup.controls["sdLsubval"].setValue("");

    //this.tab2FormGroup.controls["sdLval"].setValidators(null);
    this.tab2FormGroup.controls["sdLsubval"].setValidators(null);

    this.tab2FormGroup.controls["sdLsubval"].updateValueAndValidity();
    //this.tab2FormGroup.controls["sdLval"].updateValueAndValidity();
  }

  onChangeQuest4(event: MatRadioChange) {
    if (event.value == "4") {
      this.DEtab2Quest345.optquestion5Selected = "4";
    }
    else {
      if (this.DEtab2Quest345.optquestion5Selected = "4") {
        this.DEtab2Quest345.optquestion5Selected = "0"
      }

    }
  }

  onChangeQuest5(event: MatRadioChange) {
    if (event.value != "4") {
      this.DEtab2Quest345.optquestion4Selected = "0";
    }
    else {
      if (this.DEtab2Quest345.optquestion4Selected = "4") {
        this.DEtab2Quest345.optquestion4Selected = "0"
      }
    }
  }
}
