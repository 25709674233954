import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { DESOS_Programs_List } from '../admin/program';
import { DESOS_Period_List } from '../search/default';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DESOS_Reports, DESOS_Report_Results, DESOS_Report_Results_All, DESOS_Reports_Param } from '../Reports/reports';


@Injectable({ providedIn: 'root' })
export class ReportService {
  private apiURL: string;
  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient, private authentication: AuthenticationService) {
    this.apiURL = baseUrl;
  }

  getUserHeader() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Authorization': this.authentication.getUserAccessToken() })
    };
    return httpOptions;
  }

  getUserHeaderOption(inparam: HttpParams) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Authorization': this.authentication.getUserAccessToken() }),
      params: inparam
    };
    return httpOptions;
  }

  getProgramList(): Observable<DESOS_Programs_List[]> {
    return this.http.get<DESOS_Programs_List[]>(this.apiURL + 'api/Program/GetProgramSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Programs_List[]>('get program list'))
    );
  }

  getActiveReportingPeriodList(): Observable<DESOS_Period_List[]> {
    return this.http.get<DESOS_Period_List[]>(this.apiURL + 'api/DefaultData/GetActivePeriodSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Period_List[]>('get active reporting period list'))
    );
  }

  getReportingPeriodList(): Observable<DESOS_Period_List[]> {
    return this.http.get<DESOS_Period_List[]>(this.apiURL + 'api/DefaultData/GetReportingPeriodSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Period_List[]>('get reporting period list'))
    );
  }

  getAdminReports(): Observable<DESOS_Reports[]> {
    return this.http.get<DESOS_Reports[]>(this.apiURL + 'api/Reports/GetAdminReports', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Reports[]>('get admin reports list'))
    );
  }

  getUserReports(): Observable<DESOS_Reports[]> {
    return this.http.get<DESOS_Reports[]>(this.apiURL + 'api/Reports/GetUserReports', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Reports[]>('get user reports list'))
    );
  }

  getGetReport(inReportParam: DESOS_Reports_Param): Observable<DESOS_Report_Results> {
    return this.http.post<DESOS_Report_Results>(this.apiURL + 'api/Reports/GetReport', inReportParam, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Report_Results>('get report'))
    );
  }

  getGetReportAll(inReportParam: DESOS_Reports_Param): Observable<DESOS_Report_Results_All> {
    return this.http.post<DESOS_Report_Results_All>(this.apiURL + 'api/Reports/GetReportAll', inReportParam, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Report_Results_All>('get report'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.error(message);
    // this.messageService.add(`HeroService: ${message}`);
  }
}
