import { Component, OnInit } from '@angular/core';
import { DESOS_UserInfo } from 'src/app/account/account';
import { DESOS_Reporting_Periods } from 'src/app/search/default';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-userinfo-menu',
    templateUrl: './userinfo-menu.component.html',
  styleUrls: ['./userinfo-menu.component.css'],
  providers: [AuthenticationService]
})
/** userinfo-menu component*/
 
export class UserinfoMenuComponent implements OnInit {
    /** userinfo-menu ctor */
  public desosuserinfo: DESOS_UserInfo;
  public desosreportinfo: DESOS_Reporting_Periods;
  public strDESOSRoles: string = "";
  constructor(private authentication: AuthenticationService) {

  }

  ngOnInit() {
    this.desosuserinfo = this.authentication.getAccessToken();
    this.desosreportinfo = this.authentication.getReport();
    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);
    //  if (this.desosuserinfo.blnDESOSEntry) {
    //    this.strDESOSRoles += " Entry ";
    //  }
    //  if (this.desosuserinfo.blnDESOSEdit) {
    //    this.strDESOSRoles += " Edit ";
    //  }
    //  if (this.desosuserinfo.blnDESOSReport) {
    //    this.strDESOSRoles += " Reports ";
    //  }
    //}

    //if (sessionStorage.desosreportinfo) {
    //  this.desosreportinfo = JSON.parse(sessionStorage.desosreportinfo)
    //}
  }
}
