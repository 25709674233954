import { NgModule } from '@angular/core';
import { AdminmenuComponent } from './nav-menu/admin-menu/adminmenu.component';
import { FooterComponent } from './nav-menu/footer/footer.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule, MatSelectChange } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangepasswordComponent } from './admin/changepassword/changepassword.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { DataentryRoutingModule } from './dataentry/dataentry-routing.module';
import { DataentryModule } from './dataentry/dataentry.module';
import { UsersmenuComponent } from './nav-menu/users/usersmenu.component';
import { UserinfoMenuComponent } from './nav-menu/userinfo-menu/userinfo-menu.component';
import { SearchRecordsComponent } from './search/search-records.component';
import { AdminSearchComponent } from './search/admin-search/admin-search.component';
import { CurrentListComponent } from './search/current-list/current-list.component';
import { ArchiveSearchComponent } from './search/archive-search/archive-search.component';
import { SearchRoutingModule } from './search/search-routing.module';
import { ArchiveListComponent } from './search/archive-list/archive-list.component';
import { NewentryComponent } from './dataentry/newentry/newentry.component';
import { RosterListComponent } from './dataentry/roster-list/roster-list.component';
import { ReportsRoutingModule } from './Reports/reports-routing.module';
import { ProgressDialogComponent } from './nav-menu/progress-dialog/progress-dialog.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { ChunkArrayPipe } from './services/chunk-array.pipe';

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        LayoutModule,
        ReactiveFormsModule,
        AdminRoutingModule,
        DataentryRoutingModule,
        SearchRoutingModule,
        ReportsRoutingModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatTableModule,
        MatInputModule,
        MatPaginatorModule,
        MatExpansionModule,
        MatStepperModule,
        MatBadgeModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatMenuModule,
        MatTooltipModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatProgressSpinnerModule,
    ],
    declarations: [
        AdminmenuComponent,
        UsersmenuComponent,
        UserinfoMenuComponent,
        FooterComponent,
        ChangepasswordComponent,
        ProgressDialogComponent,
        PrivacyNoticeComponent,
        ChunkArrayPipe, 
    ],
    exports: [
        AdminmenuComponent,
        UsersmenuComponent,
        UserinfoMenuComponent,
        FooterComponent,
        ChangepasswordComponent,
        ProgressDialogComponent,
      PrivacyNoticeComponent,
      ChunkArrayPipe,
    ]
})
export class AdminsharedModule {
}
