export class dataentry_tab4_quest2to7 {
  public question2Text: string = "";
  public optquestion2Selected: string = "-1";
  public optquestion2Other: boolean = false;
  public strquestion2Other: string = "";

  public question3Text: string = "";
  public optquestion3Selected: string = "-1";

  public question4Text: string = "";
  public strquestion4Month: string = "";
  public strquestion4Year: string = "";
  public chkquestion4Selected: boolean = false;

  public question5Text: string = "";
  public optquestion5Selected: string = "-1";

  public question6Text: string = "";
  public optquestion6Selected: string = "-1";

  public question7Text: string = "";
  public optquestion7Selected: string = "-1";
  public optquestion7Other: boolean = false;
  public strquestion7Other: string = "";
}
