<div class="row mb-2 p-1">
  <div class="col-md-12">
    <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
      <div class="col p-4 d-flex flex-column position-static">
        <strong class="d-inline-block mb-2 text-primary">Forms</strong>
        <div class="row pl-4">
          <p class="description">For a printable DESOS Form: a blank copy of the DESOS Survey.</p>
          <a href="./assets/files/2024 Day and Employment Services Outcomes System Survey.pdf" class="learn-more">Click Here »</a>
        </div>
        <div class="row pl-4">
          <p class="description">For PDF instructions for DESOS.</p>
          <a href="./assets/files/DESOS  Definitions and Instructions 2024.pdf" class="learn-more">Click Here »</a>
        </div>
      </div>
      <div class="col-auto d-none d-lg-block">
        <img src="./assets/images/photo_instructions.jpg" />
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
      <div class="col p-4 d-flex flex-column position-static">
        <strong class="d-inline-block mb-2 text-primary">Contact</strong>
        <div class="row pl-4">
          <p class="card-text mb-auto">Any problems or questions please contact the administrator.</p>&nbsp;
          <a href="mailto:desos@indiana.edu" class="learn-more">desos@indiana.edu</a>
        </div>
      </div>
      <div class="col-auto d-none d-lg-block">
        <img src="./assets/images/photo_help.jpg" />
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
      <!--<strong class="d-inline-block p-4 mb-2 text-primary">Menu</strong>-->
      <button *ngIf="mnu1Visible" mat-menu-item routerLink="/searchrecords">
        <mat-icon color="primary">search</mat-icon>
        <span>Search Records</span>
      </button>
      <button *ngIf="mnu2Visible" mat-menu-item [routerLink]="['/dataentry/rosterlist']">
        <mat-icon color="primary">list</mat-icon>
        <span>Roster List</span>
      </button>
      <button *ngIf="mnu3Visible" mat-menu-item  [routerLink]="['/reports']">
        <mat-icon color="primary">filter_tilt_shift</mat-icon>
        <span>Run Reports</span>
      </button>
      <button *ngIf="mnu4Visible" mat-menu-item [routerLink]="['/admin']">
        <mat-icon color="primary">settings</mat-icon>
        <span>Administration</span>
      </button>
    </div>
  </div>
</div>
