<div class="row mb-2 p-1">
  <div class="col-md-6" *ngIf="mnu1Visible">
    <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
      <div class="col p-4 d-flex flex-column position-static">
        <strong class="d-inline-block mb-2 text-primary">Manage Individual Records</strong>
        <h3 class="mb-0">Individual Records</h3>
        <!--<div class="mb-1 text-muted">{{ todaydate | date }}</div>-->
        <p class="card-text mb-auto">Add/Edit/Search Existing Individual Records.</p>
        <a routerLink="/searchrecords" routerLinkActive="active" class="stretched-link"></a>
      </div>
      <div class="col-auto d-none d-lg-block">
        <img src="./assets/images/photo_search.jpg" />
      </div>
    </div>
  </div>
  <div class="col-md-6" *ngIf="mnu2Visible">
    <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
      <div class="col p-4 d-flex flex-column position-static">
        <strong class="d-inline-block mb-2 text-primary">Manager Verification</strong>
        <h3 class="mb-0">Verification</h3>
        <!--<div class="mb-1 text-muted">{{ todaydate | date }}</div>-->
        <p class="mb-auto">Verify/Edit/Search Programs Executive, manager info.</p>
        <a href="#" routerLink="./verificationlist" class="stretched-link"></a>
      </div>
      <div class="col-auto d-none d-lg-block">
        <img src="./assets/images/photo_manage_programs.jpg" />
      </div>
    </div>
  </div>
  <div class="col-md-6" *ngIf="mnu3Visible">
    <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
      <div class="col p-4 d-flex flex-column position-static">
        <strong class="d-inline-block mb-2 text-primary">Manage DESOS Account</strong>
        <h3 class="mb-0">DESOS Account</h3>
        <!--<div class="mb-1 text-muted">{{ todaydate | date }}</div>-->
        <p class="card-text mb-auto">Process/Delete/Search Account Request, Add/Edit User accounts.</p>
        <a href="#" routerLink="./requestuserlist" class="stretched-link"></a>
      </div>
      <div class="col-auto d-none d-lg-block">
        <img src="./assets/images/photo_accounts.jpg" />
      </div>
    </div>
  </div>
  <div class="col-md-6" *ngIf="mnu4Visible">
    <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
      <strong class="d-inline-block p-4 mb-2 text-primary">DESOS Settings</strong>
      <button mat-menu-item routerLink="./userlist">
        <mat-icon color="primary">group</mat-icon>
        <span>User Accounts</span>
      </button>
      <button mat-menu-item routerLink="./programlist">
        <mat-icon color="primary">business</mat-icon>
        <span>Programs</span>
      </button>
      <button mat-menu-item routerLink="./reportingperiod">
        <mat-icon color="primary">access_time</mat-icon>
        <span>Reporting Period</span>
      </button>
      <button mat-menu-item routerLink="./emailtemplate">
        <mat-icon color="primary">email</mat-icon>
        <span>Email Templates</span>
      </button>
    </div>
  </div>
</div>
