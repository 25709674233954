<div class="row p-2">
  <div class="col-sm-3"></div>
  <div class="col-sm-6">
    <form class="rounded  dataentry-column" [formGroup]="accountRequest">
      <mat-card class="program-mat-card">
        <mat-card-title color="Primary">
          <label>Password Change</label>
        </mat-card-title>
        <div class="form-group pl-3 pr-3 pt-3 col-sm-12">
          <mat-form-field class="example-full-width p-1">
            <input matInput type="password" name="oldpassword" placeholder="Old Password" formControlName="oldpassword" required>          
          </mat-form-field>
        </div>
        <div class="form-group pl-3 pr-3 pt-1  col-sm-12">
          <mat-form-field class="example-full-width p-1">
            <input matInput type="password" name="newpassword" placeholder="New Password" formControlName="newpassword" required>
          </mat-form-field>
          <mat-error  class="error_warning" *ngIf="hasError('newpassword', 'minlength')">Minimum 8 Charactor is required</mat-error>
          <mat-error  class="error_warning" *ngIf="hasError('newpassword', 'pattern')">Password Should Have One Upper Case, One Lower Case, One Special Char and One Number combination</mat-error>
        </div>
        <div class="form-group pl-3 pr-3 pt-1  col-sm-12">
          <mat-form-field class="example-full-width p-1">
            <input matInput type="password" name="repeatpassword" placeholder="Repeat New Password" formControlName="repeatpassword" required>
          </mat-form-field>
          <mat-error  class="error_warning" *ngIf="hasError('repeatpassword', 'minlength')">Minimum 8 Charactor is required</mat-error>
          <mat-error  class="error_warning" *ngIf="hasError('newpassword', 'pattern')">Password Should Have One Upper Case, One Lower Case, One Special Char and One Number combination</mat-error>
        </div>
        <mat-card-actions class="example-button-row p-2 pt-1">
          <button mat-raised-button (click)="btnSaveClick()" [disabled]="!accountRequest.valid" color="primary">Save</button>
          <button mat-raised-button (click)="btnBackClick()" type="button" color="primary">Back</button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
  <div class="col-sm-3"></div>
</div>
