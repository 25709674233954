<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<div class="row p-2">
  <div class="col-sm-12">
    <form class="rounded  dataentry-column" [formGroup]="emailTemplate">
      <mat-card class="program-mat-card">
        <mat-card-title color="Primary">
          <label>Email Template</label>
        </mat-card-title>
        <p *ngIf="warningmessage" class="text-danger">{{warningmessage}}</p>
        <div class="form-group pl-3 pr-3 pt-1 col-sm-12">
          <mat-form-field class="example-full-width col-sm-6">
            <mat-select placeholder="Select a Template" formControlName="templatename" (selectionChange)="onChangeProgram($event)">
              <mat-option value="AC_REQ">User Account Request</mat-option>
              <mat-option value="AC_ADD">DESOS User Account Creation</mat-option>
              <mat-option value="VR_MNG">Manager Verify Process – New Manager Account creation</mat-option>
              <mat-option value="PS_CNG">Password Reset</mat-option>
              <mat-option value="VR_PRS">Manager Verification Process</mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('templatename', 'required')">Template Name is required</mat-error>
          </mat-form-field>
          <mat-icon color="primary" matTooltip="{{messageText}}" matTooltipClass="example-tooltip-red">info</mat-icon>
        </div>
        <div class="form-group pl-3 pr-3 pb-1 col-sm-12">
          <mat-form-field class="example-full-width col-sm-10">
            <input matInput type="text" name="subject" placeholder="Email Subject" formControlName="subject" parse-int ng-true-value="1" ng-false-value="0" maxlength="150">
            <mat-error *ngIf="hasError('subject', 'required')">Email Subject is required</mat-error>
          </mat-form-field>
        </div>
        <div class="form-group pl-3 pr-3 pt-1 col-sm-12">
          <angular-editor id="emailcontent" formControlName="emailcontent" [(ngModel)]="htmlContent" [config]="config"></angular-editor>
          <mat-error *ngIf="hasError('emailcontent', 'required')">Template Content is required</mat-error>
          <mat-error *ngIf="hasError('emailcontent', 'maxLength')">Maximum 8000 char allowed!</mat-error>
        </div>

        <div class="form-group pl-3 pr-3 pt-1  col-sm-12">
        </div>
        <mat-card-actions>
          <button mat-raised-button (click)="btnChangeSubmit()" [disabled]="!emailTemplate.valid" color="primary">Save</button>
          <button mat-raised-button (click)="btnBackClick()" type="button" color="primary">Back</button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</div>
