<p *ngIf="!dataSource"><em>Loading...</em></p>

<div class="row">
  <div class="align-self-center col-sm-6 pt-1">
    <label class="sr-only" for="inlineFormInputGroup">Search Here</label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <button mat-raised-button color="primary">Search</button>
      </div>
      <input type="text" (keyup)="applyFilter($event.target.value)" class="form-control" id="idSearch" placeholder="Search Here">
    </div>
  </div>
  <div class="align-self-center col-sm-6 pt-1">
    <label class="sr-only" for="inlineFormInputGroup">Program Name</label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <button mat-raised-button (click)="btnAddProgramClick()" color="primary">Add Program</button>
      </div>
    </div>
  </div>
</div>

<div class="mat-elevation-z8 pb-1">
  <table class="table" mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="program_ID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div class="row col-sm-12 text-primary text-nowrap">
          <div class="col-sm-12">
            <h6>Program Name</h6>
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="w-100">
        <mat-accordion class="program-headers-align">
          <mat-expansion-panel class="p-0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <button mat-mini-fab color="primary">{{ element.program_Name.slice(0,2) }}</button>
                <div class="col-sm-9">
                  <p>{{element.program_Name}}</p>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field class="example-full-width">
              <input class="text-nowrap" [(ngModel)]="element.program_Name" matInput placeholder="Program Name" [disabled]="true" name="program_Name" value="">
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <input [(ngModel)]="element.street_Address" matInput placeholder="Address" [disabled]="true" name="street_Address" value="">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input [(ngModel)]="element.city" matInput placeholder="City" [disabled]="true" name="city" value="">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input [(ngModel)]="element.state" matInput placeholder="State" [disabled]="true" name="state" value="">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input [(ngModel)]="element.zipCode" matInput placeholder="Zip" [disabled]="true" name="zipCode" value="">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input [(ngModel)]="element.county" matInput placeholder="County" [disabled]="true" name="county" value="">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input [(ngModel)]="element.executive_Director_Name" matInput placeholder="Exec Director" [disabled]="true" name="executive_Director_Name" value="">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input [(ngModel)]="element.executive_Director_Email" matInput placeholder="Exec Email" [disabled]="true" name="executive_Director_Email" value="">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input [(ngModel)]="element.telephone" matInput placeholder="Telephone" name="telephone" [disabled]="true" value="">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input [(ngModel)]="element.fax" matInput placeholder="Fax" name="fax" [disabled]="true" value="">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input [(ngModel)]="element.desoS_Manager_Name" matInput placeholder="DESOS Manager" [disabled]="true" name="desoS_Manager_Name" value="">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input [(ngModel)]="element.desoS_Manager_Email" matInput placeholder="DESOS Manager Email" [disabled]="true" name="desoS_Manager_Email" value="">
            </mat-form-field>
            <mat-checkbox class="example-margin pl-2" [(ngModel)]="element.emp_flg" name="emp_flg" [disabled]="true" [labelPosition]="before">Employment</mat-checkbox>
            <mat-checkbox class="example-margin pl-2" [(ngModel)]="element.res_flg" name="res_flg" [disabled]="true" [labelPosition]="before">Day Services</mat-checkbox>
            <section>
              <mat-card-actions>
                <a [routerLink]="['/admin/programedit', element.program_ID]" mat-raised-button routerLinkActive="active" color="primary">Edit</a>
              </mat-card-actions>
            </section>
          </mat-expansion-panel>
        </mat-accordion>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[100]" showFirstLastButtons></mat-paginator>
</div>

