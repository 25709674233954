<form [formGroup]="tab3FormGroup">
  <ng-template matStepLabel>DC</ng-template>
  <div class="row">
    <div class="col-sm dataentry-column">
      <mat-card class="dataentry-card">
        <div class="row p-0 pl-1">
          <div *ngIf="questionData['d17']; else loading" class="col-sm-3 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d17']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_17}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['d17']; else loading" class="col-9 p-0">
            <mat-radio-group class="data-radio-group" formControlName="communication">
              <mat-radio-button *ngFor="let option of questionData['d17'].options"
                                [value]="''+option.optionId"
                                color="primary"
                                class="data-radio-button">
                <span [innerHTML]="option.content"></span>
              </mat-radio-button>
              <!--<mat-radio-button color="primary" class="data-radio-button" value="0">Verbal</mat-radio-button>
              <mat-radio-button color="primary" class="data-radio-button" value="2">Limited Verbal</mat-radio-button>
              <mat-radio-button color="primary" class="data-radio-button" value="4">Uses Sign Language</mat-radio-button>
              <mat-radio-button color="primary" class="data-radio-button" value="1">Non-Verbal</mat-radio-button>
              <mat-radio-button color="primary" class="data-radio-button" value="3">Augmentative Device </mat-radio-button>-->
            </mat-radio-group>
            <mat-error class="error_warning" *ngIf="hasError('communication', 'required')">Please select any one</mat-error>
          </div>
        </div>
      </mat-card>
      <mat-card class="dataentry-card">
        <div class="row p-0 pl-1">
          <div *ngIf="questionData['d18']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d18']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_18}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['d18']; else loading" class="col-8 p-0">
            <div class="row p-0 pl-1">
              <div *ngFor="let chunk of questionData['d18'].options | chunkArray:d18ChunkNum" class="col-sm-6 p-0">
                <section class="data-radio-group">
                  <mat-checkbox *ngFor="let option of chunk; let i = index; let last = last"
                                color="primary"
                                class="data-radio-button"
                                [ngModelOptions]="{standalone: true}"
                                [checked]="DEtab3Quest1['chk' + option.optionId + 'question2Selected']"
                                name="'chk' + option.optionId + 'question2Selected'"
                                [(ngModel)]="DEtab3Quest1['chk' + option.optionId + 'question2Selected']"
                                (change)="last ? onChangeQuest2None($event) : onChangeQuest2($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-checkbox>
                </section>
              </div>

              <!--<div class="col-sm-5 p-0">
                <section class="data-radio-group">
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab3Quest1.chk1question2Selected" name="chk1question2Selected" [(ngModel)]="DEtab3Quest1.chk1question2Selected" (change)="onChangeQuest2($event)">Restroom</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab3Quest1.chk2question2Selected" name="chk2question2Selected" [(ngModel)]="DEtab3Quest1.chk2question2Selected" (change)="onChangeQuest2($event)">Transferring</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab3Quest1.chk3question2Selected" name="chk3question2Selected" [(ngModel)]="DEtab3Quest1.chk3question2Selected" (change)="onChangeQuest2($event)">1-Person Assistance</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab3Quest1.chk4question2Selected" name="chk4question2Selected" [(ngModel)]="DEtab3Quest1.chk4question2Selected" (change)="onChangeQuest2($event)">3 or more</mat-checkbox>
                </section>
              </div>
              <div class="col-sm-7 p-0">
                <section class="data-radio-group">
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab3Quest1.chk5question2Selected" name="chk5question2Selected" [(ngModel)]="DEtab3Quest1.chk5question2Selected" (change)="onChangeQuest2($event)">Eating/Drinking</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab3Quest1.chk6question2Selected" name="chk6question2Selected" [(ngModel)]="DEtab3Quest1.chk6question2Selected" (change)="onChangeQuest2($event)">Medical/Nursing Needs</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab3Quest1.chk7question2Selected" name="chk7question2Selected" [(ngModel)]="DEtab3Quest1.chk7question2Selected" (change)="onChangeQuest2($event)">2-Person Assistance</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab3Quest1.chk8question2Selected" name="chk8question2Selected" [(ngModel)]="DEtab3Quest1.chk8question2Selected" (change)="onChangeQuest2None($event)">None</mat-checkbox>
                </section>
              </div>-->
            </div>
            <mat-error class="error_warning" *ngIf="hasError('lpa', 'required')">Please check all that apply</mat-error>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-sm dataentry-column">
      <mat-card class="dataentry-card">
        <div class="row p-0">
          <div *ngIf="questionData['1-0']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['1-0']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_19}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['1-0']; else loading" class="col-sm-8 p-0">
            <div class="col-sm-8 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="q1val">
                  <mat-radio-button *ngFor="let option of questionData['1-0'].options" [value]="''+option.optionId" color="primary" class="data-radio-button" (change)="OnQuest3OptSelected($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                </mat-radio-group>
              </section>
              <mat-error class="error_warning pt-3" *ngIf="hasError('q1val', 'required')">Please select any one</mat-error>
            </div>
          </div>



          <!--
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary question_text">1. Primary environment*<br />(check one)</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_19}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-8 p-0">
            <div class="col-sm-8 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="q1val">
                  <mat-radio-button color="primary" class="data-radio-button" (change)="OnQuest3OptSelected($event)" value="0">Facility-Based (Sheltered Employment)</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="OnQuest3OptSelected($event)" value="1">Off-site group employment placement <br />(e.g. enclave, crew, small business,etc.)</mat-radio-button>-->
          <!--<mat-label class="text-nowrap" style="font-size: 12px;">(e.g. enclave, crew, small business,etc.)</mat-label>-->
          <!--<mat-radio-button color="primary" class="data-radio-button pt-3" (change)="OnQuest3OptSelected($event)" value="2">Competitive Integrated Employment</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button pt-3" (change)="OnQuest3OptSelected($event)" value="3">Self-employed</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button pt-3" (change)="OnQuest3OptSelected($event)" value="4">Non-employment day program</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button pt-3" (change)="OnQuest3OptSelected($event)" value="5">Alternative to employment</mat-radio-button>
                </mat-radio-group>
              </section>
              <mat-error class="error_warning pt-3" *ngIf="hasError('q1val', 'required')">Please select any one</mat-error>
            </div>
          </div>-->
        </div>
      </mat-card>
      <mat-card class="dataentry-card" [hidden]="!DEtab3Quest2.opt5question3Selected" name="opt5question3Selected">
        <div class="row p-0">
          <div *ngIf="questionData['1-1']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['1-1']?.question?.content"></span></mat-label>
            <!--<mat-icon color="primary" matTooltip="{{helpMessage.message_20}}" matTooltipClass="example-tooltip-red">info</mat-icon>-->
          </div>
          <div *ngIf="questionData['1-1']; else loading" class="col-sm-8 p-0">
            <div class="col-sm-12 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="q1subval3">
                  <mat-radio-button *ngFor="let option of questionData['1-1'].options"
                                    [value]="''+option.optionId"
                                    color="primary"
                                    class="data-radio-button"
                                    (change)="onChangeQuest4($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                  <!--<mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest4($event)" value="0">within the facility of the sheltered workshop</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest4($event)" value="1">within an office/shopping area (e.g., strip mall)</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest4($event)" value="2">stand-alone building</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest4($event)" value="3">Other</mat-radio-button>-->
                </mat-radio-group>
              </section>
              <section class="col-sm-12" [hidden]="!DEtab3Quest2.opt4question4Selected" name="opt4question4Selected">
                <mat-form-field class="mat-input col-sm-12">
                  <input matInput placeholder="Other Descriptions" formControlName="q1subval4" maxlength="128">
                </mat-form-field>
              </section>
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card class="dataentry-card" [hidden]="!DEtab3Quest2.opt6question3Selected" name="opt6question3Selected">
        <div class="row p-0">
          <div *ngIf="questionData['1-2']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['1-2']?.question?.content"></span></mat-label>
          </div>
          <div *ngIf="questionData['1-2']; else loading" class="col-sm-8 p-0">
            <div class="col-sm-12 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="q1subval5">
                  <mat-radio-button *ngFor="let option of questionData['1-2'].options"
                                    [value]="''+option.optionId"
                                    color="primary"
                                    class="data-radio-button"
                                    (change)="onChangeQuest5($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                  <!--<mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest5($event)" value="0">Retired</mat-radio-button>
  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest5($event)" value="1">Choice</mat-radio-button>
  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest5($event)" value="2">Volunteer</mat-radio-button>-->
                  <!--<mat-radio-button  color="primary" class="data-radio-button" (change)="onChangeQuest5($event)" value="6">Pre-Employment Transition Services (Pre-ETS)</mat-radio-button>-->
                  <!--<mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest5($event)" value="3">Community access</mat-radio-button>
  <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest5($event)" value="4">Looking for job <br />(e.g., Discovery, work-based learning, job club)</mat-radio-button>-->
                  <!--<mat-label class="text-nowrap" style="font-size: 12px;">(e.g., Discovery, work-based learning, job club)</mat-label>-->
                  <!--<mat-radio-button color="primary" class="data-radio-button pt-3" (change)="onChangeQuest5($event)" value="5">Other</mat-radio-button>-->
                </mat-radio-group>
              </section>
              <section class="col-sm-12" [hidden]="!DEtab3Quest2.opt7question5Selected" name="opt7question5Selected">
                <mat-form-field class="mat-input col-sm-12 pt-3">
                  <input matInput placeholder="Other Descriptions" formControlName="q1subval6" maxlength="128">
                </mat-form-field>
              </section>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="example-button-row  text-center">
    <button mat-raised-button matStepperPrevious color="primary">Back</button>
    <button mat-raised-button matStepperNext color="primary" [disabled]="!tab3FormGroup.valid">Next</button>
  </div>
</form>
<ng-template #loading>
  <p>Loading question and options...</p>
</ng-template>
