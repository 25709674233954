import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.css']
})
export class PrivacyNoticeComponent {
  privacyNotice: boolean;  // Define the property here

  constructor() {
  }

  ngOnInit(): void {
    this.privacyNotice = true;
    //loadPrivacyNotice();
  } 
}
