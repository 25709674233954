<p *ngIf="!dataSource"></p>
<app-progress-dialog  *ngIf="progressSpinner"></app-progress-dialog>
<div class="input-group-prepend p-2 example-button-row" mat-raised-button color="primary">
  <!--<button mat-raised-button color="primary" (click)="sidenav.open()">Back To Data Entry</button>-->
  <button mat-raised-button color="primary" *ngIf="(desosreportinfo.mm === 'NO' ? false:true)" (click)="btnNewOutcome()">New Record and Outcome</button>
</div>
<div class="row col-sm-12 p-0">
  <div class="col-sm-4 pt-2">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <button mat-raised-button color="primary">Search</button>
        <!--<div class="input-group-text text-primary">Search</div>-->
      </div>
      <input type="text" (keyup)="applyFilter($event.target.value)" class="form-control" id="idSearch" placeholder="Search Here">
    </div>
  </div>
  <div class="col-sm-6 pl-3" [formGroup]="rosterlist">
    <div class="row col-sm-12 p-0">
      <div class="input-group mb-2 col-sm-8">
        <!--<mat-label>Select Program</mat-label>-->
        <mat-form-field class="example-full-width">
          <mat-select placeholder="Select DESOS Program" formControlName="program"  (selectionChange)="onChangeProgram($event)">
            <mat-option (selectionChange)="onChangeProgram($event)" [value]="-1">
              None
            </mat-option>
            <mat-option *ngFor="let program of programList" (selectionChange)="onChangeProgram($event)" [value]="program.program_ID">
              {{program.program_Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-4">
        <mat-checkbox class="pt-5" color="primary"  (change)="onChangeServices($event)" formControlName="services">Include Those No Longer Receiving Services</mat-checkbox>
        <mat-checkbox class="pt-5" color="primary"  (change)="onChangeAllPeriods($event)" formControlName="allperiod">Include Previous Reporting Periods</mat-checkbox>
      </div>
    </div>
  </div>
</div>
<div class="mat-elevation-z8 pb-1 example-table-container">
  <table mat-table [dataSource]="dataSource" style="width:100%" matSort>
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <h6 class="text-primary">Action</h6> </th>
      <td mat-cell *matCellDef="let element" class="flex-nowrap p-0" style="width:200px;padding:10px;">
        <div class="row col-sm-12">
          <div class="col-sm-6 p-2" *ngIf="element.reporting_Period === desosreportinfo.mm + desosreportinfo.dd">
            <button (click)="editOutComes(element.id, element.individual_ID)" color="primary" mat-stroked-button routerLinkActive="active">Edit</button>
          </div>
          <div class="col-sm-6 p-2" *ngIf="element.reporting_Period !== desosreportinfo.mm + desosreportinfo.dd">
            <button (click)="newOutComes(element.id, element.individual_ID)" color="primary" mat-stroked-button routerLinkActive="active">New Outcome for {{ desosreportinfo.mm + '/' + desosreportinfo.dd }} </button>
          </div>
        </div>
      </td>
    </ng-container>
    <!-- Previous Entry Column -->
    <ng-container matColumnDef="reporting_Period_Filter">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> Last RP </h6> </th>
      <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.reporting_Period_Filter}} </td>
    </ng-container>
    <ng-container matColumnDef="q1val">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> Primary Environment </h6> </th>
      <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{getPrimaryEnv(element.q1val)}} </td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="lastNameLowerCase">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> Name </h6> </th>
      <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.lastName + ' ' + element.firstName}} </td>
    </ng-container>
    <!-- Symbol Column -->
    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> City </h6> </th>
      <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.city}} </td>
    </ng-container>
    <ng-container matColumnDef="county">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"> <h6 class="text-primary">County </h6> </th>
      <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.county}} </td>
    </ng-container>
    <ng-container matColumnDef="dateOfBirth">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> DOB </h6> </th>
      <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.dateOfBirth}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator (page)="pageChange($event)" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

