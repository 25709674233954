import { Component, EventEmitter, Input, Output, Inject, OnInit } from '@angular/core';
import { DESOS_Programs_List } from 'src/app/admin/program';
import { helpText, DataEntry_tabAvailability, DESOS_Search_Fields } from 'src/app/dataentry/dataentry';
import { dataentry_tab1_quest11 } from 'src/app/dataentry/dataentry-tab1';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DESOS_Cities_List, DESOS_Counties_List, DESOS_Genders_List, DESOS_Races_List, DESOS_Month_List, DESOS_Period_List } from '../default';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataentryService } from 'src/app/services/dataentry.service';

@Component({
    selector: 'app-admin-search',
    templateUrl: './admin-search.component.html',
  styleUrls: ['./admin-search.component.css'],
  providers:[DataentryService]
})
/** admin-search component*/
export class AdminSearchComponent implements OnInit{

  helpMessage: helpText;
  isReasonHide: boolean;
  isOtherHide: boolean;
  private DETabsEnabled: DataEntry_tabAvailability;
  public dataentrydtQuest11: dataentry_tab1_quest11;
  public programList: DESOS_Programs_List[];
  public cityList: DESOS_Cities_List[];
  public countyList: DESOS_Counties_List[];
  public genderList: DESOS_Genders_List[];
  public raceList: DESOS_Races_List[];
  public monthList: DESOS_Month_List[];
  public periodList: DESOS_Period_List[];
  private apiURL: string = "";
  @Output() nextStep = new EventEmitter<DESOS_Search_Fields>();
  public adminSearch: FormGroup;
  public adminSearchA: FormGroup;
  public adminSearchB: FormGroup;
  private tmpValue: tmpSearch;
 // @Input() DETabsVisible: DataEntry_tabAvailability;
  //@Output() tab2To8Hide = new EventEmitter<boolean>()
    /** admin-search ctor */
  constructor(private snackBar: MatSnackBar, private location: Location, private route: ActivatedRoute, private router: Router,
    private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private APIservices: DataentryService) {
    this.helpMessage = new helpText();
    this.apiURL = baseUrl;
    this.dataentrydtQuest11 = new dataentry_tab1_quest11();
    this.isReasonHide = true;
    this.isOtherHide = true;
    this.loafDefaults();
  }


  ngOnInit() {
    this.adminSearch = new FormGroup({
      program: new FormControl('', [Validators.required]),
      outcomeid: new FormControl(''),
      individualid: new FormControl(''),
      firstname: new FormControl(''),
      middlename: new FormControl(''),
      lastname: new FormControl(''),
      city: new FormControl(''),
      county: new FormControl(''),
      zipcode: new FormControl(''),
      reportingperiod: new FormControl(''),
      dobmonth: new FormControl(''),
      dobyear: new FormControl(''),
      gender: new FormControl(''),
      race: new FormControl(''),
      services: new FormControl('')
    }, { updateOn: 'blur' });
  }

  hasError = (controlName: string, errorName: string) => {
    return this.adminSearch.controls[controlName].hasError(errorName);
  }

  loafDefaults() {

    //this.http.get<DESOS_Programs_List[]>(this.apiURL + 'api/DefaultData/GetProgramSelectList').subscribe(result => {
    this.APIservices.getProgramList().subscribe(result => {
      this.programList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Cities_List[]>(this.apiURL + 'api/DefaultData/GetCitiesSelectList').subscribe(result => {
    this.APIservices.getCitiesList().subscribe(result => {
    this.cityList = result;
    }, error => console.error(error));


    //this.http.get<DESOS_Counties_List[]>(this.apiURL + 'api/DefaultData/GetCountiesSelectList').subscribe(result => {
    this.APIservices.getCountiesList().subscribe(result => {
    this.countyList = result;
    }, error => console.error(error));

   // this.http.get<DESOS_Genders_List[]>(this.apiURL + 'api/DefaultData/GetGendersSelectList').subscribe(result => {
    this.APIservices.getGendersList().subscribe(result => {
    this.genderList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Races_List[]>(this.apiURL + 'api/DefaultData/GetRacesSelectList').subscribe(result => {
    this.APIservices.getRacesList().subscribe(result => {
    this.raceList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Month_List[]>(this.apiURL + 'api/DefaultData/GetMonthSelectList').subscribe(result => {
    this.APIservices.getMonthList().subscribe(result => {
    this.monthList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Period_List[]>(this.apiURL + 'api/DefaultData/GetReportingPeriodSelectList').subscribe(result => {
    this.APIservices.getReportingPeriodList().subscribe(result => {
    this.periodList = result;
    }, error => console.error(error));
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
    });
  }

  closeSnackBar() {
    //this.snackBar.dismiss();
  }

  hideOptions() {
    this.isReasonHide = true;
    this.isOtherHide = true;
    //this.tab2To8Hide.emit(true);
  }

  showOptions() {
    this.isReasonHide = false;
    this.isOtherHide = true;
    this.dataentrydtQuest11.optResonSelected = 0;
    //this.tab2To8Hide.emit(false);
    //this.DETabsVisible.tab2To7Enabled = false;
    //this.DETabsVisible.tab8Enabled = false;
  }

  showOtherText() {
    this.isOtherHide = false;
  }

  hideOtherText() {
    this.isOtherHide = true;
  }

  btnFindClick() {
    this.tmpValue = this.adminSearch.value;

    const toSearch: DESOS_Search_Fields = new DESOS_Search_Fields();

    toSearch.program_ID = this.adminSearch.controls["program"].value;
    toSearch.id = this.adminSearch.controls["outcomeid"].value;
    toSearch.individual_ID = this.adminSearch.controls["individualid"].value;
    toSearch.firstName = this.adminSearch.controls["firstname"].value;
    toSearch.middleName = this.adminSearch.controls["middlename"].value;
    toSearch.lastName = this.adminSearch.controls["lastname"].value;
    if (this.adminSearch.controls["city"].value == "-1") {
      toSearch.cityID = "";
    }
    else {
      toSearch.cityID = this.adminSearch.controls["city"].value;
    }
    if (this.adminSearch.controls["county"].value == "-1") {
      toSearch.countyID = "";
    }
    else {
      toSearch.countyID = this.adminSearch.controls["county"].value;
    }
    toSearch.zipCode = this.adminSearch.controls["zipcode"].value;

    if (this.adminSearch.controls["reportingperiod"].value == "-1") {
      toSearch.reporting_Period = "";
    }
    else {
      toSearch.reporting_Period = this.adminSearch.controls["reportingperiod"].value;
    }

    if (this.adminSearch.controls["dobmonth"].value == "-1") {
      toSearch.dateOfBirth = "";
    }
    else {
      toSearch.dateOfBirth = this.adminSearch.controls["dobmonth"].value + this.adminSearch.controls["dobyear"].value;
    }

    if (this.adminSearch.controls["gender"].value == "-1") {
      toSearch.gender = "";
    }
    else {
      toSearch.gender = this.adminSearch.controls["gender"].value;
    }
    if (this.adminSearch.controls["race"].value == "-1") {
      toSearch.race = "";
    }
    else {
      toSearch.race = this.adminSearch.controls["race"].value;
    }
    toSearch.services = this.adminSearch.controls["services"].value;
    this.nextStep.emit(toSearch);
  }
}

export class tmpSearch {
  program: string = "";
  id: string = "";
  indId: string = "";
  firstname: string = "";
  middlename: string = "";
  lastname: string = "";
  city: string = "";
  county: string = "";
  zipcode: string = "";
  reportingperiod: string = "";
  dobmonth: string = "";
  dobyear: string = "";
  gender: string = "";
  race: string = "";
  services: string = "";
}
