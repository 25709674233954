<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<div class="row p-3">
  <form class="col-lg-12 p-0 shadow-lg dataentry-card rounded  dataentry-column" [formGroup]="accountRequest">
    <div class="pl-2 pr-1">
      <h4 class="text-md-left text-primary pl-2"><strong>Password Reset</strong> </h4>
      <p *ngIf="warningmessage" class="text-danger">{{warningmessage}}</p>
    </div>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width p-1">
        <input matInput type="password" name="oldpassword" placeholder="Temporary Password" formControlName="oldpassword" required>
      </mat-form-field>
      <!--<mat-error class="error_warning" *ngIf="hasError('oldpassword', 'required')">Old Password is required</mat-error>-->
    </div>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width p-1">
        <input matInput type="password" name="newpassword" placeholder="New Password"  formControlName="newpassword" required>
      </mat-form-field>
      <!--<mat-error class="error_warning" *ngIf="hasError('newpassword', 'required')">New Password is required</mat-error>-->
      <mat-error  class="error_warning" *ngIf="hasError('newpassword', 'minlength')">Minimum 8 Charactor is required</mat-error>
      <mat-error class="error_warning" *ngIf="hasError('newpassword', 'pattern')">Password Should Have One Upper Case, One Lower Case, One Special Char($@$!%*?&) and One Number combination</mat-error>
    </div>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width p-1">
        <input matInput type="password" name="repeatpassword" placeholder="Repeat New Password"  formControlName="repeatpassword" required>
      </mat-form-field>
      <!--<mat-error class="error_warning" *ngIf="hasError('repeatpassword', 'required')">Repeat New Password is required</mat-error>-->
      <mat-error  class="error_warning" *ngIf="hasError('repeatpassword', 'minlength')">Minimum 8 Charactor is required</mat-error>
      <mat-error class="error_warning" *ngIf="hasError('repeatpassword', 'pattern')">Password Should Have One Upper Case, One Lower Case, One Special Char($@$!%*?&) and One Number combination</mat-error>
    </div>
    <div class="example-button-row p-2 pt-1">
      <button mat-raised-button (click)="btnSaveClick()" [disabled]="!accountRequest.valid" color="primary">Save</button>
      <!--<button mat-raised-button [routerLink]="['/account/login']" color="primary">Login</button>-->
    </div>
  </form>
</div>
