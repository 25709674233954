<h3 class="text-primary" mat-dialog-title>DESOS Entry</h3>
<div mat-dialog-content>
  <span class="text-info">{{strHeading}}</span>
  <br />
  <br />
  <span>{{strContent}}</span>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="data.individual" cdkFocusInitial>Ok</button>
</div>
