<div class="col-sm-12 p-3">
  <div class="row col-sm-12 p-0">
    <div class="input-group mb-2 col-sm-12" *ngIf="program">
      <div class="input-group mb-2 col-sm-4">
        <strong class="d-inline-block mb-2 text-primary">PROGRAM:</strong>
        <p class="col-md-12">{{program.program_Name}}</p>
      </div>
      <div class="col-sm-3" *ngIf="reportingPeriod">
        <strong class="d-inline-block mb-2 text-primary">CURRENT REPORTING PERIOD:</strong>
        <p>20{{reportingPeriod.dd}}-{{reportingPeriod.mm}} ({{reportingPeriod.description}})</p>
      </div>
      <div class="col-sm-5" *ngIf="periodList">
        <strong class="d-inline-block mb-2 text-primary">SELECT REPORTING PERIOD:</strong>
        <ul style="list-style: none;">
          <li *ngFor="let p of periodList">
            <mat-checkbox (change)="onChangePeriod(p.mm + p.dd)" [(ngModel)]="selectedCheckboxes[p.mm+p.dd]">
              20{{p.dd}} - {{p.mm}} ({{p.description}})
            </mat-checkbox>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-sm-12">
      <strong class="d-inline-block mb-2 text-primary">DATA TO DUPLICATE:</strong>
    </div>
    </div>
    <div class="mat-elevation-z8 pb-1 example-table-container">
      <table mat-table [dataSource]="dataSource" style="width:100%" matSort>
        <!-- Position Column -->
        <!--<ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <h6 class="text-primary">Action</h6> </th>
        <td mat-cell *matCellDef="let element" class="flex-nowrap p-0" style="width:200px;padding:10px;">
          <div class="row col-sm-12">
            <div class="col-sm-6 p-2" *ngIf="element.reporting_Period === desosreportinfo.mm + desosreportinfo.dd">
              <button (click)="editOutComes(element.id, element.individual_ID)" color="primary" mat-stroked-button routerLinkActive="active">Edit</button>
            </div>
            <div class="col-sm-6 p-2" *ngIf="element.reporting_Period !== desosreportinfo.mm + desosreportinfo.dd">
              <button (click)="newOutComes(element.id, element.individual_ID)" color="primary" mat-stroked-button routerLinkActive="active">New Outcome for {{ desosreportinfo.mm + '/' + desosreportinfo.dd }} </button>
            </div>
          </div>
        </td>
      </ng-container>-->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> ID </h6> </th>
          <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.id}} </td>
        </ng-container>
        <!-- Previous Entry Column -->
        <ng-container matColumnDef="reporting_Period_Filter">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> Reporting Period </h6> </th>
          <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.reporting_Period_Filter}} </td>
        </ng-container>
        <ng-container matColumnDef="q1val">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> Primary Environment </h6> </th>
          <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{getPrimaryEnv(element.q1val)}} </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="lastNameLowerCase">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> Name </h6> </th>
          <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.lastName + ' ' + element.firstName}} </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> City </h6> </th>
          <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.city}} </td>
        </ng-container>
        <ng-container matColumnDef="county">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"> <h6 class="text-primary">County </h6> </th>
          <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.county}} </td>
        </ng-container>
        <ng-container matColumnDef="dateOfBirth">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> DOB </h6> </th>
          <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.dateOfBirth}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator (page)="pageChange($event)" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
    <div class="example-button-row text-center mt-3">
      <button mat-raised-button color="primary" class="mr-2" (click)="btnBackClick()">Back</button>
      <button mat-raised-button color="primary" class="ml-2"
              (click)="btnContinueClick()"
              [disabled]="(!recordsList) || (recordsList.length < 1)"
              >Continue</button>
    </div>
  </div>
