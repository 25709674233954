<p *ngIf="!dataSource"><em>Loading...</em></p>
<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<div class="form-row">
  <div class="align-self-center col-sm-4 pt-1">
    <label class="sr-only" for="inlineFormInputGroup">Search Here</label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <button mat-raised-button color="primary">Search</button>
      </div>
      <input type="text" (keyup)="applyFilter($event.target.value)" class="form-control" id="idSearch" placeholder="Search Here">
    </div>
  </div>
  <div class="align-self-center col-sm-4 pt-1">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <button mat-raised-button (click)="btnAddUserClick()" color="primary">Add User</button>
      </div>
    </div>
  </div>
</div>
<div class="mat-elevation-z8 pb-1">
  <table class="table" mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div class="row col-sm-12 text-primary text-lg-left">
          <div class="col-sm-4 text-nowrap">
            <h6>User Name</h6>
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="w-100">
        <mat-accordion class="user-headers-align">
          <mat-expansion-panel class="p-0">
            <mat-expansion-panel-header>
              <mat-panel-title class="col-sm-8">
                <button mat-mini-fab color="primary">{{element.firstName.slice(0,1) + element.lastName.slice(0,1) }}</button>
                <div class="col-sm-6">
                  <p>{{element.username}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                <div class="col-sm-3">
                  <p>{{element.firstName}}</p>
                </div>
                <div class="col-sm-3">
                  <p>{{element.lastName}}</p>
                </div>
                <mat-icon>account_circle</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <section>
              <mat-card class="program-mat-card">
                <mat-card-title color="Primary">
                  <label>User Info</label>
                </mat-card-title>
                <mat-form-field class="mat-input">
                  <input matInput placeholder="User name / Email" [(ngModel)]="element.username" [disabled]="true" name="username">
                </mat-form-field>
                <mat-form-field class="mat-input">
                  <input matInput placeholder="First name" [(ngModel)]="element.firstName" name="firstName">
                </mat-form-field>
                <mat-form-field class="mat-input">
                  <input matInput placeholder="Last name" [(ngModel)]="element.lastName" name="lastname">
                </mat-form-field>
                <mat-form-field class="col-sm-4">
                  <mat-select placeholder="DESOS Program" [disabled]="desosuserinfo.blnIsDESOSManager" [(ngModel)]="element.adminProgDESOS">
                    <mat-option *ngFor="let program of programList" [value]="program.program_ID">
                        {{program.program_Name}}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-card-actions>
                  <div class="user-button-row box">
                    <mat-checkbox class="example-margin" [(ngModel)]="element.reset_psswd_flg" parse-int ng-true-value="1" ng-false-value="0" [labelPosition]="before">Prompt Password Change</mat-checkbox>
                    <mat-form-field class="mat-input">
                      <input matInput placeholder="New Password" type="password" [(ngModel)]="element.hashPassword" name="hashPassword">
                    </mat-form-field>
                    <button mat-raised-button (click)="btnSavePassChangeClick(element)" color="primary">Change Password</button>
                  </div>
                </mat-card-actions>
              </mat-card>
            </section>
            <section>
              <mat-card class="program-mat-card">
                <mat-card-title color="primary">
                  <label>User Access</label>
                </mat-card-title>
                <mat-checkbox class="example-margin" [(ngModel)]="element.account_Active_flg" parse-int ng-true-value="1" ng-false-value="0" [labelPosition]="before">Active</mat-checkbox>
                <mat-checkbox class="example-margin" [(ngModel)]="element.adminLevel1" [disabled]="desosuserinfo.blnIsDESOSManager" parse-int ng-true-value="1" ng-false-value="0" [labelPosition]="before">Admin Access Level 1</mat-checkbox>
                <mat-checkbox class="example-margin" [(ngModel)]="element.adminLevel2" [disabled]="desosuserinfo.blnIsDESOSManager" parse-int ng-true-value="1" ng-false-value="0" [labelPosition]="before">Admin Access Level 2</mat-checkbox>
                <br />
                <mat-checkbox class="example-margin" [(ngModel)]="element.adminDataEntryDESOS" parse-int ng-true-value="1" ng-false-value="0" [labelPosition]="before">Admin Data Entry DESOS</mat-checkbox>
                <mat-checkbox class="example-margin" [(ngModel)]="element.adminDataEditDESOS" parse-int ng-true-value="1" ng-false-value="0" [labelPosition]="before">Admin Data Edit DESOS</mat-checkbox>
                <mat-checkbox class="example-margin" [(ngModel)]="element.adminReportsDESOS" parse-int ng-true-value="1" ng-false-value="0" [labelPosition]="before">Admin Data Reports DESOS</mat-checkbox>
                <mat-card-actions>
                  <div class="example-button-row">
                      <button mat-raised-button (click)="btnSaveClick(element)" color="primary">Save</button>
                      <button mat-raised-button (click)="btnDeleteClick(element)" color="primary">Delete</button>
                  </div>
                </mat-card-actions>
              </mat-card>
            </section>
          </mat-expansion-panel>
        </mat-accordion>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>


