import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Location, formatDate, DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DESOS_Reporting_Periods } from 'src/app/search/default';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProgramService } from 'src/app/services/program.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-reporting-period',
  templateUrl: './reporting-period.component.html',
  styleUrls: ['./reporting-period.component.css'],
  providers: [ProgramService]
})



/** reporting-period component*/
export class ReportingPeriodComponent implements OnInit {
  /** reporting-period ctor */
  public rtpdEntry: FormGroup;
  public reportingPeriod: DESOS_Reporting_Periods[];
  private asyncResultPeriods: DESOS_Reporting_Periods[];
  public dataSource: MatTableDataSource<DESOS_Reporting_Periods>;
  public displayedColumns: string[] = ['id', 'mm', 'description', 'completedate', 'active'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private apiURL: string;
  public isAdd: boolean;
  public isEdit: boolean;
  private latest: number;
  constructor(private location: Location, private route: ActivatedRoute, private router: Router, private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string, private APIservices: ProgramService) {
    this.apiURL = baseUrl;

  }

  ngOnInit() {
    this.rtpdEntry = new FormGroup({
      id: new FormControl(''),
      mm: new FormControl([Validators.required]),
      dd: new FormControl([Validators.required]),
      description: new FormControl('', [Validators.required]),
      completedate: new FormControl([Validators.required]),
      active: new FormControl(),
      isannual: new FormControl(),
    });

    this.dataSource = new MatTableDataSource<DESOS_Reporting_Periods>();
    this.isAdd = false;
    this.isEdit = true;

    this.loadReportingPeriod();
    //this.rtpdEntry.controls["save"].disable();
  }

  hasError = (controlName: string, errorName: string) => {
    return this.rtpdEntry.controls[controlName].hasError(errorName);
  }


  async getAsyncData() {

    //this.asyncResultPeriods = await this.http.get<DESOS_Reporting_Periods[]>(this.apiURL + 'api/DefaultData/GetReportingPeriodList').toPromise();
    this.asyncResultPeriods = await this.APIservices.getReportingPeriodList().toPromise();
    if (this.asyncResultPeriods != null) {
      this.reportingPeriod = JSON.parse(JSON.stringify(this.asyncResultPeriods));
      this.dataSource = new MatTableDataSource(this.reportingPeriod);
      this.latest = this.reportingPeriod[0]['id'];
      this.dataSource.paginator = this.paginator;
      if (this.dataSource.data.length > 0) {
        this.dataSource.sort = this.sort;
      }
    }
  };

  loadReportingPeriod() {
    this.getAsyncData();
  }

  onEdit(reportPeriod: DESOS_Reporting_Periods) {
    var datePipe = new DatePipe('en-US');
    // reportPeriod.completedate = datePipe.transform(reportPeriod.completedate, 'MM/dd/yyyy');
    this.rtpdEntry.controls["id"].setValue(reportPeriod.id);
    this.rtpdEntry.controls["mm"].setValue(reportPeriod.mm);
    this.rtpdEntry.controls["dd"].setValue(reportPeriod.dd);
    //this.rtpdEntry.controls["mmdd"].setValue(new Date(reportPeriod.mm + "/" + reportPeriod.dd + "/" + datePipe.transform(reportPeriod.completedate, 'yyyy')));
    this.rtpdEntry.controls["description"].setValue(reportPeriod.description);
    this.rtpdEntry.controls["completedate"].setValue(new Date(datePipe.transform(reportPeriod.completedate, 'MM/dd/yyyy')));
    this.rtpdEntry.controls["active"].setValue((reportPeriod.active == "1" ? true : false));
    this.rtpdEntry.controls["isannual"].setValue((reportPeriod.isannual == "True" ? true : false));
    this.isAdd = true;
    this.isEdit = false;
    //this.latest = -1;
    //this.rtpdEntry.controls["add"].disable();
    //this.rtpdEntry.controls["save"].enable();
    //this.rtpdEntry.controls["mmdd"].setValue( new Date("01/02/2019"));
  }

  btnAddClick() {
    var datePipe = new DatePipe('en-US');
    const reportperiod: DESOS_Reporting_Periods = new DESOS_Reporting_Periods();
    reportperiod.id = 0;
    reportperiod.mm = (this.rtpdEntry.controls["mm"].value.toString().length >= 2) ? this.rtpdEntry.controls["mm"].value : ('0' + this.rtpdEntry.controls["mm"].value);
    reportperiod.dd = (this.rtpdEntry.controls["dd"].value.toString().length >= 2) ? this.rtpdEntry.controls["dd"].value : ('0' + this.rtpdEntry.controls["dd"].value);
    reportperiod.completedate = datePipe.transform(this.rtpdEntry.controls["completedate"].value, 'MM/dd/yyyy');
    reportperiod.description = this.rtpdEntry.controls["description"].value;
    reportperiod.active = (this.rtpdEntry.controls["active"].value == true ? "1" : "0");
    reportperiod.isannual = (this.rtpdEntry.controls["isannual"].value == true ? "True" : "False");

    // this.http.post(this.apiURL + 'api/DefaultData/UpdateReportingPeriod', reportperiod).subscribe(result => {
    this.APIservices.updateReportingPeriod(reportperiod).subscribe(result => {
      var result = result;
    }, error => console.error(error));

    this.delay(1000).then(any => { this.ngOnInit(); });
  }

  btnEditClick() {
    var datePipe = new DatePipe('en-US');
    const reportperiod: DESOS_Reporting_Periods = new DESOS_Reporting_Periods();
    reportperiod.id = this.rtpdEntry.controls["id"].value;
    reportperiod.mm = (this.rtpdEntry.controls["mm"].value.toString().length >= 2) ? this.rtpdEntry.controls["mm"].value : ('0' + this.rtpdEntry.controls["mm"].value);
    reportperiod.dd = (this.rtpdEntry.controls["dd"].value.toString().length >= 2) ? this.rtpdEntry.controls["dd"].value : ('0' + this.rtpdEntry.controls["dd"].value);
    reportperiod.completedate = datePipe.transform(this.rtpdEntry.controls["completedate"].value, 'MM/dd/yyyy');
    reportperiod.description = this.rtpdEntry.controls["description"].value;
    reportperiod.active = (this.rtpdEntry.controls["active"].value == true ? "1" : "0");
    reportperiod.isannual = (this.rtpdEntry.controls["isannual"].value == true ? "True" : "False");

    //console.log("rp", reportperiod);

    //this.http.post(this.apiURL + 'api/DefaultData/UpdateReportingPeriod', reportperiod).subscribe(result => {
    this.APIservices.updateReportingPeriod(reportperiod).subscribe(result => {
      var result = result;
    }, error => console.error(error));

    this.delay(1000).then(any => {
      this.ngOnInit();
      if (this.latest == reportperiod.id) {
        //console.log(this.latest, reportperiod.id);
        this.router.navigate(['/account/login', 'logout']);
      }
    });
  }

  btnCancelClick() {
    this.delay(1000).then(any => { this.ngOnInit(); });
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("Login"));
  }

  btnBackClick() {
    this.location.back();
  }

}
