import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { DESOS_Manager_Verify } from '../program';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { Observable } from 'rxjs';
import { VerificationService } from 'src/app/services/verification.service';
import { DESOS_StrResult } from 'src/app/dataentry/dataentry';


@Component({
    selector: 'app-manager-verification',
    templateUrl: './manager-verification.component.html',
  styleUrls: ['./manager-verification.component.css'],
  providers: [VerificationService]
})
/** manager-verification component*/
export class ManagerVerificationComponent implements OnInit , OnDestroy {
    /** manager-verification ctor */
  public verificationlist: DESOS_Manager_Verify[];
  public resultsArray: DESOS_Manager_Verify[];
  public selectedList: DESOS_Manager_Verify[] = [];
  public verificationEdit: DESOS_Manager_Verify;
  public verificationEmailAll: DESOS_Manager_Verify;
  public displayedColumns: string[] = ['program_ID'];
  private apiURL: string;
  public dataSource: MatTableDataSource<DESOS_Manager_Verify>;
  public resultsStr: DESOS_StrResult = new DESOS_StrResult();
  public hideEmailed: boolean = false;
  public hideProcessed: boolean = false;
  public isSendEmail: boolean = false;
  private strResults: string = "";
  private apiListURL = "api/Program/GetVerificationList";
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public warningmessage: string = "";
  public rowCount: number = 1;
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private matDialog: MatDialog, private APIservices: VerificationService) {
    this.apiURL = baseUrl;
    let observable = new Observable(this.myObservable);
    this.showProgressSpinner(observable);
  }

  ngOnInit() {
    this.loadVerificationList();
    
    //setTimeout(() => {
    //  this.dialog = this.matDialog.open(ProgressDialogComponent, { id: 'ManagerVerificationComponent', disableClose: true });
    //});
  }

  myObservable(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 2000);
  }

  myObservableOne(observer) {
    setTimeout(() => {
      observer.next("done waiting for 1 sec");
      observer.complete();
    }, 2000);
  }

  myObservableTwo(observer) {
    setTimeout(() => {
      observer.next("done waiting for 1 sec");
      observer.complete();
    }, this.rowCount * 1000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  ngOnDestroy() {
    //setTimeout(() => {
    //  this.dialog.close();
    //});
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onChangeHideEmail(event: MatCheckboxChange) {
    let observable = new Observable(this.myObservableOne);
    this.showProgressSpinner(observable);
    if (event.checked == true) {
        this.hideEmailed = true;
      }
      else {
        this.hideEmailed = false;
    }
    this.loadVerificationList();
  }

  onChangeHideProcessed(event: MatCheckboxChange) {  

    let observable = new Observable(this.myObservableOne);
    this.showProgressSpinner(observable);

    if (event.checked == true) {
        this.hideProcessed = true;
      }
      else {
        this.hideProcessed = false;
    }
    this.loadVerificationList();
  }

  async loadVerificationList() {
    const strAPIHideEmailSent: string = "api/Program/GetNotEmailedVerificationList";
    const strAPIHideProcessed: string = "api/Program/GetNotProcessedVerificationList";
    const strAPIHideEmailSentandProcessed: string = "api/Program/GetNotEmailedandProcessedVerificationList";
    const strAPILoadAll: string = "api/Program/GetVerificationList";
        if (this.hideProcessed && this.hideEmailed) {
          this.apiListURL = strAPIHideEmailSentandProcessed;
        }
        else {
          if (this.hideProcessed) {
            this.apiListURL = strAPIHideProcessed;
          }
          else if (this.hideEmailed) {
            this.apiListURL = strAPIHideEmailSent;
          }
          else {
            this.apiListURL = strAPILoadAll;
          }
        }

   // this.resultsArray = await this.http.get<DESOS_Manager_Verify[]>(this.apiURL + this.apiListURL).toPromise();
    this.resultsArray = await this.APIservices.loadVerificationList(this.apiURL + this.apiListURL).toPromise();
    if (this.resultsArray != null) {
      this.verificationlist = JSON.parse(JSON.stringify(this.resultsArray));
      this.dataSource = new MatTableDataSource(this.verificationlist);
      this.dataSource.paginator = this.paginator;
      if (this.dataSource.data.length > 0) {
        this.dataSource.sort = this.sort;
        this.rowCount = this.dataSource.data.length;
      }
      console.log("Verification List", this.verificationlist); 
    }
    //  this.http.get<DESOS_Manager_Verify[]>(this.apiURL + this.apiListURL).subscribe(result => {
    //  this.verificationlist = result;
    //  this.dataSource = new MatTableDataSource(this.verificationlist);
    //    this.dataSource.paginator = this.paginator;
    //    if (this.dataSource.data.length > 0) {
    //      this.dataSource.sort = this.sort;
    //    }

    //}, error => console.error(error));

    this.warningmessage = "";
  }

  btnSendAllEmailClick() {

   // let observable = new Observable(this.myObservableTwo);
   // this.showProgressSpinner(observable);

    this.warningmessage = "Processing....";
    let newURL = document.getElementsByTagName('base')[0].href.replace("https://", "");
    this.showProgressSpinner(this.ProcessAllandSendEmail(newURL));
    //this.http.get(this.apiURL + "api/Program/ProcessAndEmail").subscribe(result => {
      //this.verificationlist = result;
      //this.dataSource = new MatTableDataSource(this.verificationlist);
      //this.dataSource.paginator = this.paginator;
      //if (this.dataSource.data.length > 0) {
      //  this.dataSource.sort = this.sort;
      //}
   // }, error => console.error(error));
    
    this.delay(5000).then(any => {
      this.loadVerificationList();
    });

  }

  ProcessAllandSendEmail(urlString) {

    this.verificationEmailAll = new DESOS_Manager_Verify;
    this.verificationEmailAll.url_Current = urlString;
    console.log("URL", urlString, this.verificationEmailAll.url_Current);

    return Observable.create(observer => {
      this.APIservices.processAll(this.verificationEmailAll).subscribe((result) => {
        this.resultsStr = JSON.parse(JSON.stringify(result));
        if (this.resultsStr != null) {
          if (this.resultsStr.strOutput == "Success") {
            this.warningmessage = "Process and send email to all completed.";
            observer.next("Process completed.");
             observer.complete();
          }
          else {
            if (this.resultsStr.strOutput != "") {
              this.warningmessage = "Process and send email to all completed. Failed processed programmes are " + this.resultsStr.strOutput;
              observer.next("Process completed.");
              observer.complete();
            }
            else {
              this.warningmessage = "";
              observer.next("Process completed.");
              observer.complete();
            }
          }
        }
        else {
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }


  btnGenerateClick() {
    //this.http.get(this.apiURL + "api/Program/GenerateVerificationList").subscribe(result => {
    //  //this.verificationlist = result;
    //  //this.dataSource = new MatTableDataSource(this.verificationlist);
    //  //this.dataSource.paginator = this.paginator;
    //  //if (this.dataSource.data.length > 0) {
    //  //  this.dataSource.sort = this.sort;
    //  //}
    //}, error => console.error(error));
    this.warningmessage = "Processing....";
    //this.generateList();
    this.showProgressSpinner(this.generateVerificationList());
    this.delay(3000).then(any => {
      this.loadVerificationList();
    });
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  generateVerificationList() {
    return Observable.create(observer => {
      this.APIservices.generateVerificationList().subscribe((result) => {
        this.resultsStr = JSON.parse(JSON.stringify(result));
        if (this.resultsStr != null) {

          if (this.resultsStr.strOutput == "Success") {
            this.warningmessage = "Verification List generated successfully.";
            observer.next("Verification List generated successfully.");
            observer.complete();
          }
          else {
            this.warningmessage = "Error: " + this.resultsStr.strOutput;
            observer.next("Generate Verification List failed.");
            observer.complete();
          }

        }
        else {
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }

  async generateList() {

    this.strResults = await this.http.get<string>(this.apiURL + "api/Program/GenerateVerificationList").toPromise();
    if (this.strResults == "success") {
      ;
    }
    else {
      alert("Error while generating verification list please contact administrator!");
    }
  };


  btnClearClick() {
    this.warningmessage = "Processing....";
    //this.clearList();
    this.showProgressSpinner(this.clearVerificationList());
    this.delay(3000).then(any => {
      this.loadVerificationList();
    });

  }


  clearVerificationList() {
    return Observable.create(observer => {
      this.APIservices.clearVerificationList().subscribe((result) => {
        this.resultsStr = JSON.parse(JSON.stringify(result));
        if (this.resultsStr != null) {

          if (this.resultsStr.strOutput == "Success") {
            this.warningmessage = "Verification List cleared successfully.";
            observer.next("Verification List cleared successfully.");
            observer.complete();
          }
          else {
            this.warningmessage = "Error: " + this.resultsStr.strOutput;
            observer.next("Clear Verification List failed.");
            observer.complete();
          }

        }
        else {
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }


  async clearList() {

    this.strResults = await this.http.get<string>(this.apiURL + "api/Program/ClearVerificationList").toPromise();
    if (this.strResults == "success") {
      ;
    }
    else {
      alert("Error while generating verification list please contact administrator!");
    }
  };

  btnSaveClick(verificationIn: DESOS_Manager_Verify) {
    let observable = new Observable(this.myObservable);
    this.showProgressSpinner(observable);

    this.verificationEdit = verificationIn;
    this.verificationEdit.executive_Director_Name = verificationIn.executive_Director_Name;
    this.verificationEdit.executive_Director_Email = verificationIn.executive_Director_Email;
    this.verificationEdit.desoS_Manager_Name = verificationIn.desoS_Manager_Name;
    this.verificationEdit.desoS_Manager_Email = verificationIn.desoS_Manager_Email;
    this.verificationEdit.email_Sent_flg = verificationIn.email_Sent_flg;
    this.verificationEdit.processed_flg = verificationIn.processed_flg;
    this.verificationEdit.accepted_flg = verificationIn.accepted_flg;
    this.verificationEdit.denied_flg = verificationIn.denied_flg;

    //this.http.post(this.apiURL + 'api/Program/ChangeVerificationInfo', this.verificationEdit).subscribe(result => {
    //  var result = result;
    //}, error => console.error(error));

    this.showProgressSpinner(this.updateVerificationInfo(this.verificationEdit));

    this.delay(2000).then(any => {
      this.loadVerificationList();
    });

  }

  updateVerificationInfo(verificationIn: DESOS_Manager_Verify) {
    return Observable.create(observer => {
      this.APIservices.updateVerification(verificationIn).subscribe((result) => {
        this.resultsStr = JSON.parse(JSON.stringify(result));
        if (this.resultsStr != null) {

          if (this.resultsStr.strOutput == "Success") {
            this.warningmessage = "Verification List update successfully.";
            observer.next("Verification List update successfully.");
            observer.complete();
          }
          else {
            this.warningmessage = "Error: " + this.resultsStr.strOutput;
            observer.next("update Verification List failed.");
            observer.complete();
          }

        }
        else {
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }

  btnDuplicateDataClick(verificationIn: DESOS_Manager_Verify) {
    this.router.navigateByUrl('/admin/managerduplicate/' + verificationIn.program_ID);
  }

  btnSendEmailClick(verificationIn: DESOS_Manager_Verify) {

    let observable = new Observable(this.myObservable);
    this.showProgressSpinner(observable);

    this.verificationEdit = verificationIn;
    this.verificationEdit.executive_Director_Name = verificationIn.executive_Director_Name;
    this.verificationEdit.executive_Director_Email = verificationIn.executive_Director_Email;
    this.verificationEdit.desoS_Manager_Name = verificationIn.desoS_Manager_Name;
    this.verificationEdit.desoS_Manager_Email = verificationIn.desoS_Manager_Email;
    this.verificationEdit.email_Sent_flg = verificationIn.email_Sent_flg;
    this.verificationEdit.processed_flg = verificationIn.processed_flg;
    this.verificationEdit.accepted_flg = verificationIn.accepted_flg;
    this.verificationEdit.denied_flg = verificationIn.denied_flg;

    let newURL = document.getElementsByTagName('base')[0].href.replace("https://", "");
    this.verificationEdit.url_Current = newURL;
    console.log("URL", newURL, this.verificationEdit.url_Current);

    //this.http.post(this.apiURL + 'api/Program/SendEmailSingleVerification', this.verificationEdit).subscribe(result => {
    //  var result = result;
    //}, error => console.error(error));

    this.showProgressSpinner(this.sendEmailSingleVerification(this.verificationEdit));

    this.delay(2000).then(any => {
      this.loadVerificationList();
    });
  }


  sendEmailSingleVerification(verificationIn: DESOS_Manager_Verify) {
    return Observable.create(observer => {
      this.APIservices.sendEmailToVerification(verificationIn).subscribe((result) => {
        this.resultsStr = JSON.parse(JSON.stringify(result));
        if (this.resultsStr != null) {

          if (this.resultsStr.strOutput == "Success") {
            this.warningmessage = "Sending Email Verification successfully.";
            observer.next("Sending Email Verification successfully.");
            observer.complete();
          }
          else {
            this.warningmessage = "Error: " + this.resultsStr.strOutput;
            observer.next("Sending Email Verification failed.");
            observer.complete();
          }

        }
        else {
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }


  btnDeleteClick(verificationIn: DESOS_Manager_Verify) {

    let observable = new Observable(this.myObservable);
    this.showProgressSpinner(observable);

    this.verificationEdit = verificationIn;
    this.verificationEdit.executive_Director_Name = verificationIn.executive_Director_Name;
    this.verificationEdit.executive_Director_Email = verificationIn.executive_Director_Email;
    this.verificationEdit.desoS_Manager_Name = verificationIn.desoS_Manager_Name;
    this.verificationEdit.desoS_Manager_Email = verificationIn.desoS_Manager_Email;
    this.verificationEdit.email_Sent_flg = verificationIn.email_Sent_flg;
    this.verificationEdit.processed_flg = verificationIn.processed_flg;
    this.verificationEdit.accepted_flg = verificationIn.accepted_flg;
    this.verificationEdit.denied_flg = verificationIn.denied_flg;

    //this.http.post(this.apiURL + 'api/Program/DeleteVerificationInfo', this.verificationEdit).subscribe(result => {
    //  var result = result;
    //}, error => console.error(error));

    this.showProgressSpinner(this.deleteVerificationInfo(this.verificationEdit));

    this.delay(2000).then(any => {
      this.loadVerificationList();
    });
  }

  deleteVerificationInfoMultiple(verificationIn: DESOS_Manager_Verify[]) {
    return Observable.create(observer => {
      this.APIservices.deleteVerificationMultiple(verificationIn).subscribe((result) => {
        this.resultsStr = JSON.parse(JSON.stringify(result));
        if (this.resultsStr != null) {

          if (this.resultsStr.strOutput == "Success") {
            this.warningmessage = "Verification List delete successfully.";
            observer.next("Verification List delete successfully.");
            observer.complete();
          }
          else {
            this.warningmessage = "Error: " + this.resultsStr.strOutput;
            observer.next("delete Verification List failed.");
            observer.complete();
          }

        }
        else {
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }

  deleteVerificationInfo(verificationIn: DESOS_Manager_Verify) {
    return Observable.create(observer => {
      this.APIservices.deleteVerification(verificationIn).subscribe((result) => {
        this.resultsStr = JSON.parse(JSON.stringify(result));
        if (this.resultsStr != null) {

          if (this.resultsStr.strOutput == "Success") {
            this.warningmessage = "Verification List delete successfully.";
            observer.next("Verification List delete successfully.");
            observer.complete();
          }
          else {
            this.warningmessage = "Error: " + this.resultsStr.strOutput;
            observer.next("delete Verification List failed.");
            observer.complete();
          }

        }
        else {
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }

  getCheckBoxValue(strValue: string): number {
    var blnResults: number = 0;
    if (strValue == "true" || strValue == "1") {
      blnResults = 1;
    }
    else if (strValue == "false" || strValue == "0") {
      blnResults = 0;
    }
    return blnResults
  }

  btnEditTemplateClick() {
    this.router.navigate(['/admin/emailtemplate']);
  }

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.verificationlist != null && this.verificationlist.every(t => t.selected);
  }

  someComplete(): boolean {
    if (this.verificationlist == null) {
      return false;
    }
    return this.verificationlist.filter(t => t.selected).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.verificationlist == null) {
      return;
    }
    this.verificationlist.forEach(t => (t.selected = completed));
  }

  canRemove() {
    return this.allComplete || this.someComplete();
  }

  btnRemoveEntriesClick() {

    //DESOS_Manager_Verify selectedListItem = new DESOS_Manager_Verify;

    //DESOS_Manager_Verify selectedProgram[] = new DESOS_Manager_Verify[];

    let observable = new Observable(this.myObservable);
    this.showProgressSpinner(observable);

    this.selectedList = [];

    this.verificationlist.forEach(s => {
      if (s.selected == true) { this.selectedList.push(s); }
    });
    console.log(this.selectedList);

    this.showProgressSpinner(this.deleteVerificationInfoMultiple(this.selectedList));

    this.delay(5000).then(any => {
      this.loadVerificationList();
    });
  }
}
