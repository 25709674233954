  <form [formGroup]="tab1FormGroup1">
    <ng-template matStepLabel>IDD</ng-template>
    <div class="row">
      <div class="col-sm dataentry-column">
        <mat-card class="dataentry-card">
          <div *ngIf="questionData['d1']; else loading" class="row">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d1']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_1}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <!--<div class="row">
            <mat-label class="text-primary question_text">Client Name</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_1}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>-->
          <div *ngIf="questionData['d1']; else loading" class="row col-sm-12">
            <mat-form-field class="mat-input col-sm-5 answer_text">
              <input matInput placeholder="First Name" name="firstName" formControlName="firstname" required>
            </mat-form-field>
            <mat-form-field class="mat-input col-sm-2 answer_text" [style.width.px]="50">
              <input matInput placeholder="MI" name="middleName" maxlength="1" formControlName="middlename">
            </mat-form-field>
            <mat-form-field class="mat-input col-sm-5 answer_text">
              <input matInput placeholder="Last name" name="lastname" formControlName="lastname" required>
            </mat-form-field>
          </div>
        </mat-card>
        <mat-card class="dataentry-card">
          <div class="row p-0">
            <div *ngIf="questionData['d2']; else loading" class="col-sm-4 p-0">
              <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d2']?.question?.content"></span></mat-label>
              <mat-icon color="primary" matTooltip="{{helpMessage.message_2}}" matTooltipClass="example-tooltip-red">info</mat-icon>
            </div>
            <div *ngIf="questionData['d2']; else loading" class="col-sm-8 p-0">
              <mat-form-field class="mat-dropdown">
                <mat-select placeholder="DESOS Program" formControlName="program" required>
                  <mat-option *ngFor="let program of programList" [value]="program.program_ID">
                    {{program.program_Name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
        <mat-card class="dataentry-card">
          <div class="row p-0">
            <div *ngIf="questionData['d3']; else loading" class="col-sm-4 p-0">
              <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d3']?.question?.content"></span></mat-label>
              <mat-icon color="primary" matTooltip="{{helpMessage.message_3}}" matTooltipClass="example-tooltip-red">info</mat-icon>
            </div>
            <div *ngIf="questionData['d3']; else loading" class="col-sm-8">
              <mat-form-field class="mat-dropdown">
                <mat-select placeholder="Select a City" formControlName="city" required>
                  <mat-option *ngFor="let item of cityList" [value]="item.id">
                    {{item.city}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
        <mat-card class="dataentry-card">
          <div class="row p-0">
            <div *ngIf="questionData['d4']; else loading" class="col-sm-4 p-0">
              <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d4']?.question?.content"></span></mat-label>
              <mat-icon color="primary" matTooltip="{{helpMessage.message_4}}" matTooltipClass="example-tooltip-red">info</mat-icon>
            </div>
            <div *ngIf="questionData['d4']; else loading" class="col-sm-8">
              <mat-form-field class="mat-dropdown">
                <mat-select placeholder="Select a County" formControlName="county" required>
                  <mat-option *ngFor="let item of countyList" [value]="item.id">
                    {{item.county}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card>

        <mat-card class="dataentry-card">
          <div class="row p-0">
            <div *ngIf="questionData['d5']; else loading" class="col-sm-4 p-0">
              <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d5']?.question?.content"></span></mat-label>
              <mat-icon color="primary" matTooltip="{{helpMessage.message_5}}" matTooltipClass="example-tooltip-red">info</mat-icon>
            </div>
            <div *ngIf="questionData['d5']; else loading" class="col-sm-8">
              <mat-form-field class="mat-input">
                <input matInput placeholder="ZIP Code" formControlName="zipcode" required>
              </mat-form-field>
              <mat-error class="error_warning" *ngIf="hasError('zipcode', 'pattern')">Please enter 5 digit zipcode.</mat-error>
            </div>
          </div>
        </mat-card>
        <mat-card class="dataentry-card">
          <div class="row p-0">
            <div *ngIf="questionData['d6']; else loading" class="col-sm-4 p-0">
              <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d6']?.question?.content"></span></mat-label>
              <mat-icon color="primary" matTooltip="{{helpMessage.message_6}}" matTooltipClass="example-tooltip-red">info</mat-icon>
            </div>
            <div *ngIf="questionData['d6']; else loading" class="col-sm-8">
              <mat-form-field class="mat-dropdown">
                <mat-select placeholder="Select a Reporting Period" formControlName="reportingperiod" required>
                  <mat-option *ngFor="let item of periodList" [value]="item.id">
                    {{item.period}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="col-sm dataentry-column">    
        <mat-card class="dataentry-card">
          <div class="row p-0">
            <div *ngIf="questionData['d7']; else loading" class="col-sm-5 p-0">
              <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d7']?.question?.content"></span></mat-label>
              <mat-icon color="primary" matTooltip="{{helpMessage.message_7}}" matTooltipClass="example-tooltip-red">info</mat-icon>
            </div>
            <div *ngIf="questionData['d7']; else loading" class="col-sm-4">
              <mat-form-field class="mat-dropdown">
                <mat-select placeholder="Select a Month" formControlName="dobmonth" required>
                  <mat-option *ngFor="let item of monthList" [value]="item.id">
                    {{item.month}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="mat-input" [style.width.px]="80">
                <input matInput placeholder="Year(YY)" formControlName="dobyear" required>
              </mat-form-field>
            </div>
            <mat-error class="error_warning" *ngIf="hasError('dobyear', 'pattern')">Please enter 2 digit year(00 to 99)</mat-error>
          </div>
        </mat-card>
        <mat-card class="dataentry-card">
          <div class="row p-0">
            <div *ngIf="questionData['d8']; else loading" class="col-sm-4 p-0">
              <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d8']?.question?.content"></span></mat-label>
              <mat-icon color="primary" matTooltip="{{helpMessage.message_8}}" matTooltipClass="example-tooltip-red">info</mat-icon>
            </div>
            <div *ngIf="questionData['d8']; else loading" class="col-sm-8">
              <mat-form-field class="mat-dropdown">
                <mat-select placeholder="Select a Gender" formControlName="gender"  (selectionChange)="onChangeGender($event)" required>
                  <mat-option *ngFor="let item of genderList" [value]="item.id">
                    {{item.gender}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <section class="col-sm-12" [hidden]="isOtherGenderHide" name="isOtherGenderHide">
                <mat-form-field class="mat-input col-sm-12">
                  <input matInput placeholder="Other Gender" formControlName="othergender" maxlength="128">
                </mat-form-field>
              </section>
            </div>
          </div>
        </mat-card>

        <mat-card class="dataentry-card">
          <div class="row p-0">
            <div *ngIf="questionData['d9']; else loading" class="col-sm-4 p-0">
              <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d9']?.question?.content"></span></mat-label>
              <mat-icon color="primary" matTooltip="{{helpMessage.message_9}}" matTooltipClass="example-tooltip-red">info</mat-icon>
            </div>
            <div *ngIf="questionData['d9']; else loading" class="col-sm-8">
              <mat-form-field class="mat-dropdown">
                <mat-select placeholder="Select a Race" formControlName="race"  (selectionChange)="onChangeRace($event)" required>
                  <mat-option *ngFor="let item of raceList" [value]="item.id">
                    {{item.race}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <section class="col-sm-12" [hidden]="isOtherRaceHide" name="isOtherRaceHide">
                <mat-form-field class="mat-input col-sm-12">
                  <input matInput placeholder="Other Race" formControlName="otherrace" maxlength="128">
                </mat-form-field>
              </section>
            </div>
          </div>
        </mat-card>
        <mat-card class="dataentry-card">
          <div class="row p-0">
            <div *ngIf="questionData['d10']; else loading" class="col-sm-5 p-0">
              <mat-label class="text-primary question_text"><span [innerHTML]="questionData['d10']?.question?.content"></span></mat-label>
              <mat-icon color="primary" matTooltip="{{helpMessage.message_10}}" matTooltipClass="example-tooltip-red">info</mat-icon>
            </div>
            <div *ngIf="questionData['d10']; else loading" class="col-sm-7">
              <mat-radio-group formControlName="legalguardian">
                <mat-radio-button *ngFor="let option of questionData['d10'].options" [value]="''+option.optionId" color="primary" class="example-margin">
                  <span [innerHTML]="option.content"></span>
                </mat-radio-button>
                <!--<mat-radio-button color="primary" class="example-margin" value="0">Yes</mat-radio-button>
                <mat-radio-button color="primary" class="example-margin pl-4" value="1">No</mat-radio-button>
                <mat-radio-button color="primary" class="example-margin pl-4" value="2">Partial</mat-radio-button>-->
              </mat-radio-group>
              <mat-error class="error_warning" *ngIf="hasError('legalguardian', 'required')">Please select one</mat-error>
            </div>
          </div>
        </mat-card>
        <mat-card class="dataentry-card">
          <div class="row p-0">
            <div *ngIf="questionData['d11-0']; else loading" class="col-sm-5 p-0">
              <mat-label name="lblnolonger" class="text-primary  question_text"><span [innerHTML]="questionData['d11-0']?.question?.content"></span></mat-label>
              <mat-icon color="primary" matTooltip="{{helpMessage.message_11}}" matTooltipClass="example-tooltip-red">info</mat-icon>
            </div>
            <div *ngIf="questionData['d11-1']; else loading" class="col-sm-7 p-0">
              <div class="row p-0">
                <mat-radio-group formControlName="nlrs">
                  <mat-radio-button *ngFor="let option of questionData['d11-0'].options"
                                    [value]="''+option.optionId"
                                    color="primary"
                                    class="example-margin"
                                    [name]="'rbutton' + (option.optionId + 1)"
                                    (click)="option.optionId == '0' ? hideOptions() : showOptions()">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                  <!--<mat-radio-button color="primary" class="example-margin" name="rbutton1" (click)="hideOptions()" value="0">N/A - individual is still receiving services</mat-radio-button>
  <mat-radio-button color="primary" class="example-margin" name="rbutton2" (click)="showOptions()" value="1">individual is no longer receiving services</mat-radio-button>-->
                </mat-radio-group>
                <mat-error  class="error_warning" *ngIf="hasError('nlrs', 'required')">Please select any one</mat-error>
              </div>
              <div class="row p-0">
                <section [hidden]="isReasonHide" name="isReasonHide">
                  <mat-radio-group formControlName="nlrSsubval">
                    <mat-radio-button *ngFor="let option of questionData['d11-1'].options"
                                      [value]="''+option.optionId"
                                      color="primary"
                                      class="example-margin"
                                      [name]="'rbutton' + (option.optionId + 1)"
                                      (click)="option.optionId == '3' ? showOtherText() : hideOtherText()">
                      <span [innerHTML]="option.content"></span>
                    </mat-radio-button>
                    <!--<mat-radio-button color="primary" class="example-margin" name="rbutton1" (click)="hideOtherText()" value="0">Choice</mat-radio-button>
                    <mat-radio-button color="primary" class="example-margin" name="rbutton2" (click)="hideOtherText()" value="1">Moved</mat-radio-button>
                    <mat-radio-button color="primary" class="example-margin" name="rbutton3" (click)="hideOtherText()" value="2">Death</mat-radio-button>
                    <mat-radio-button color="primary" class="example-margin" name="rbutton4" (click)="showOtherText()" value="3">Other</mat-radio-button>-->
                  </mat-radio-group>
                </section>
              </div>
              <div class="row p-0 col-sm-12">
                <section class="col-sm-12" [hidden]="isOtherHide" name="isOtherHide">
                  <mat-form-field class="mat-input col-sm-12">
                    <input matInput placeholder="Other Descriptions" formControlName="nlrSsubval2" maxlength="128">
                  </mat-form-field>
                </section>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <div  class="text-center">
      <button mat-raised-button matStepperNext *ngIf="(tab1FormGroup1.get('nlrs').value === '0' ? true:false)" [disabled]="!tab1FormGroup1.valid"  color="primary">Next</button>
    </div>
  </form>
  <ng-template #loading>
    <p>Loading question and options...</p>
  </ng-template>
