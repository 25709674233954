export class DESOS_Reports {
  public id: string = "";// { get; set; }
  public description: string = "";//  { get; set; }
  public url_pdf: string = "";//  { get; set; }
  public url_word: string = "";//  { get; set; }
  public url_html: string = "";//  { get; set; }
  public program: string = "";//  { get; set; }
  public active: string = "";//  { get; set; }
  public list_order_num: string = "";//  { get; set; }
}

export class DESOS_Reports_Param {
  public id: string = "";//{ get; set; }
  public individual_ID: string = "";;//{ get; set; }
  public firstName: string = "";//{ get; set; }
  public middleName: string = "";//{ get; set; }
  public lastName: string = "";//{ get; set; }
  public reporting_Period: string = "";//{ get; set; }
  public program_ID: string = "";//{ get; set; }
  public cityID: string = "";//{ get; set; }
  public countyID: string = "";//{ get; set; }
  public dateOfBirth: string = "";//{ get; set; }
  public gender: string = "";//{ get; set; }
  public race: string = "";//{ get; set; }
  public zipCode: string = "";//{ get; set; }
  public services: string = "";//{ get; set; }
  public isAdmin: boolean;//{ get; set; }
  public pageCurrent: number = 0;
  public pageSize: number = 10;
}

export class DESOS_Report_Results {
  public resultsCount: string = "";;
  public reportTotalsDescLB: string = "";
  public report1Header: string = "";
  public report2Header: string = "";
  public report1: Report_Results[] = null;
  public report2: Report_Results[] = null;
}

export class DESOS_Report_Results_All {
  public questions: string = "";
  public program: string = "";
  public county: string = "";
  public city: string = "";
  public age: string = "";
  public gender: string = "";
  public race: string = "";
  public legalguardian: string = "";
  public pdl: string = "";
  public pdlSubval: string = "";
  public sdl: string = "";
  public sdlSubval: string = "";
  public mobility: string = "";
  public bi: string = "";
  public los: string = "";
  public lpa: string = "";
  public communication: string = "";
  public q1: string = "";
  public q2: string = "";
  public q3: string = "";
  public q4: string = "";
  public q5: string = "";
  public q6: string = "";
  public q7: string = "";
  public q8: string = "";
  public q9: string = "";
  public q10: string = "";
  public q11: string = "";
  public q12: string = "";
  public q13: string = "";
  public q14: string = "";
  public q15: string = "";
  public q16: string = "";
  public q17: string = "";
  public q18a: string = "";
  public q18b: string = "";
  public q19: string = "";
  public q20: string = "";
  public q21: string = "";
  public q22: string = "";
  public q23: string = "";
  public q24: string = "";
  public customReport: string = "";
  public customColumns: string = "";
  public customTitle: string = "";
}

export class Report_Results {
  public column1: string = "";
  public column2: string = "";
  public column3: string = "";
  public column4: string = "";
  public column5: string = "";
}
