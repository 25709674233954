import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DESOS_UserInfo } from '../account/account';
import { AuthenticationService } from '../services/authentication.service';

@Component({
    selector: 'app-dataentry',
    templateUrl: './dataentry.component.html',
  styleUrls: ['./dataentry.component.css'],
  providers: [AuthenticationService]
})
/** dataentry component*/
export class DataentryComponent implements OnInit {
    /** dataentry ctor */
  public desosuserinfo: DESOS_UserInfo;
  constructor(private route: ActivatedRoute, private router: Router, private authentication: AuthenticationService) {

  }

  ngOnInit() {
    //this.getUserAccess();
    this.desosuserinfo = this.authentication.getAccessToken();
    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);

    //  //alert(sessionStorage.getItem("desosuserinfo"));
    //}
    //else {
    //  this.router.navigate(['/account/login']);
    //}
  }
}
