import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountModule } from './account/account.module';
import { AdminModule } from './admin/admin.module';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataentryModule } from './dataentry/dataentry.module';
import { AdminmenuComponent } from './nav-menu/admin-menu/adminmenu.component';
import { FooterComponent } from './nav-menu/footer/footer.component';
import { AdminsharedModule } from './adminshared.module';
import { SearchModule } from './search/search.module';
import { ReportsModule } from './Reports/reports.module';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthGuardGuestService } from './services/auth-guard-guest.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    AccountModule,
    AdminModule,
    DataentryModule,
    SearchModule,
    ReportsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularEditorModule,
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) and `ping` is 120 (2 minutes).
   // UserIdleModule.forRoot({ idle: 60, timeout: 30, ping: 10 }),
    ],
  providers: [BnNgIdleService, AuthenticationService, AuthGuardGuestService, AuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
