import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AdminmenuComponent } from '../nav-menu/admin-menu/adminmenu.component';
import { FooterComponent } from '../nav-menu/footer/footer.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataentryRoutingModule } from './dataentry-routing.module';
import { DataentryComponent } from './dataentry.component';
import { NewentryComponent } from './newentry/newentry.component';
import { AdminsharedModule } from '../adminshared.module';
import { DataentryTab1Component } from './dataentry-tab1/dataentry-tab1.component';
import { DataentryTab2Component } from './dataentry-tab2/dataentry-tab2.component';
import { DataentryTab3Component } from './dataentry-tab3/dataentry-tab3.component';
import { DataentryTab4Component } from './dataentry-tab4/dataentry-tab4.component';
import { DataentryTab5Component } from './dataentry-tab5/dataentry-tab5.component';
import { DataentryTab6Component } from './dataentry-tab6/dataentry-tab6.component';
import { DataentryTab7Component } from './dataentry-tab7/dataentry-tab7.component';
import { DataentryTab8Component } from './dataentry-tab8/dataentry-tab8.component';
import { RosterListComponent } from './roster-list/roster-list.component';
import { UserrosterlistComponent } from './userrosterlist/userrosterlist.component';
import { DataentryModalComponent } from './dataentry-modal/dataentry-modal.component';
import { TempDataService } from '../services/temp-data.service';

@NgModule({
    declarations: [
        NewentryComponent,
        DataentryComponent,
        DataentryTab1Component,
        DataentryTab2Component,
        DataentryTab3Component,
        DataentryTab4Component,
        DataentryTab5Component,
        DataentryTab6Component,
        DataentryTab7Component,
        DataentryTab8Component,
        RosterListComponent,
        UserrosterlistComponent,
        DataentryModalComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        AdminsharedModule,
        DataentryRoutingModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatIconModule,
        MatOptionModule,
        MatSelectModule,
        MatStepperModule,
        MatBadgeModule,
        MatDividerModule,
        MatRadioModule,
        MatSelectModule,
        MatButtonModule,
        MatTooltipModule,
        MatSidenavModule,
        MatSortModule,
        MatDialogModule,
    ],
    providers: [TempDataService],
    bootstrap: []
})
export class DataentryModule {
}
