import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DESOS_StrResult } from '../dataentry/dataentry';
import { ChangePassword, DESOS_Email_Template, DESOS_Programs_List, User, DESOS_Users } from '../admin/program';
import { DESOS_Account_RequestList } from '../account/account';


@Injectable()
export class UserService {
  private apiURL: string;
  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient, private authentication: AuthenticationService) {
    this.apiURL = baseUrl;
  }

  getUserHeader() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Authorization': this.authentication.getUserAccessToken() })
    };
    return httpOptions;
  }

  getUserHeaderOption(inparam: HttpParams) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Authorization': this.authentication.getUserAccessToken() }),
      params: inparam
    };
    return httpOptions;
  }

  getProgramList(): Observable<DESOS_Programs_List[]> {
    return this.http.get<DESOS_Programs_List[]>(this.apiURL + 'api/Program/GetProgramSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Programs_List[]>('get program list'))
    );
  }

  checkUserLoggedIn(inparam: HttpParams): Observable<DESOS_StrResult> {
    return this.http.get<DESOS_StrResult>(this.apiURL + 'api/User/CheckLogInUser', this.getUserHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_StrResult>('get Checked User Logged-In'))
    );
  }

  changeUserPassword(inChangePassword: ChangePassword): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/User/ChangeUserPassword', inChangePassword, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('change user password'))
    );
  }

  changeUserInfo(inUser: DESOS_Users): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/User/ChangeUserInfo', inUser, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('change user info'))
    );
  }

  deleteUser(inUser: DESOS_Users): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/User/DeleteUser', inUser, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('change user info'))
    );
  }


  getEmailTemplate(inparam: HttpParams): Observable<DESOS_Email_Template> {
    return this.http.get<DESOS_Email_Template>(this.apiURL + 'api/Program/GetEmailTemplate', this.getUserHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_Email_Template>('get email template'))
    );
  }

  updateEmailTemplate(inEmailTemplate: DESOS_Email_Template): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/Program/UpdateEmailTemplate', inEmailTemplate, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('update email template'))
    );
  }

  getRequestedUserListByProgram(inparam: HttpParams): Observable<DESOS_Account_RequestList[]> {
    return this.http.get<DESOS_Account_RequestList[]>(this.apiURL + 'api/User/GetRequestedUserListByProgram', this.getUserHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_Account_RequestList[]>('get user requests by program'))
    );
  }

  getRequestedUserList(): Observable<DESOS_Account_RequestList[]> {
    return this.http.get<DESOS_Account_RequestList[]>(this.apiURL + 'api/User/GetRequestedUserList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Account_RequestList[]>('get user requests'))
    );
  }

  processUserRequest(inparam: HttpParams): Observable<DESOS_Account_RequestList[]> {
    return this.http.get<DESOS_Account_RequestList[]>(this.apiURL + 'api/User/ProcessUserRequest', this.getUserHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_Account_RequestList[]>('process user request'))
    );
  }

  deleteUserRequest(inparam: HttpParams): Observable<DESOS_Account_RequestList[]> {
    return this.http.get<DESOS_Account_RequestList[]>(this.apiURL + 'api/User/DeleteUserRequest', this.getUserHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_Account_RequestList[]>('delete user request'))
    );
  }

  addUser(inUser: User): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/User/NewUserAccount', inUser, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('add new user'))
    );
  }

  getUserListByProgram(inparam: HttpParams): Observable<DESOS_Users[]> {
    return this.http.get<DESOS_Users[]>(this.apiURL + 'api/User/GetUserListByProgram', this.getUserHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_Users[]>('get user list by program'))
    );
  }

  getUserList(): Observable<DESOS_Users[]> {
    return this.http.get<DESOS_Users[]>(this.apiURL + 'api/User/GetUserList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Users[]>('get user list'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.error(message);
    // this.messageService.add(`HeroService: ${message}`);
  }

}
