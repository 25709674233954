import { Component, Inject, ViewChild, Output, Input, EventEmitter, OnInit } from '@angular/core';
import { DESOS_Account_RequestList, DESOS_UserInfo } from 'src/app/account/account';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DESOS_Programs_List } from '../program';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { UserListComponent } from '../user-list/user-list.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-request-user-list',
  templateUrl: './request-user-list.component.html',
  styleUrls: ['./request-user-list.component.css'],
  providers: [AuthenticationService]
})
/** request-user-list component*/
export class RequestUserListComponent implements OnInit{
  /** request-user-list ctor */
  private apiURL: string;
  public programList: DESOS_Programs_List[];
  private userRequestList: DESOS_Account_RequestList[];
  public dataSource: MatTableDataSource<DESOS_Account_RequestList>;
  public desosuserinfo: DESOS_UserInfo;
  public selectProgramControl: FormControl;
  public programid: string ="-1";
  public displayedColumns: string[] = ['accRequestID', 'request_dt', 'processed_dt', 'firstName', 'lastName', 'email', 'comments'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() onProgramChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(UserListComponent, { static: true }) userlistChild: UserListComponent;
  public selectedprogramid: string = "";
  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private authentication: AuthenticationService, private APIServices: UserService) {
    this.apiURL = baseUrl;
    this.selectProgramControl = new FormControl('');
    //http.get<DESOS_Account_RequestList[]>(baseUrl + 'api/User/GetRequestedUserList').subscribe(result => {
    //  this.userRequestList = result;
    //  this.dataSource = new MatTableDataSource(this.userRequestList);
    //  this.dataSource.paginator = this.paginator;
    //  if (this.dataSource.data.length > 0) {
    //    this.dataSource.sort = this.sort;
    //  }
    //}, error => console.error(error));

    //http.get<DESOS_Programs_List[]>(baseUrl + 'api/Program/GetProgramSelectList').subscribe(result => {
    this.APIServices.getProgramList().subscribe(result => {
      this.programList = result;
    }, error => console.error(error));
  }

  ngOnInit() {
    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);
    this.desosuserinfo = this.authentication.getAccessToken();

    if (this.desosuserinfo.strProgram == "FullAccess" || this.desosuserinfo.strProgram == "") {
        this.desosuserinfo.strProgram = "-1";
        this.programid = "-1";
      }
      else {
        this.programid = this.desosuserinfo.strProgram;
      }
      this.loadRequestedList();
      this.userlistChild.programChanged(this.desosuserinfo.strProgram);
   // }
  }

  btnAddUserClick = function () {
    this.router.navigate(['/admin/useradd']);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadRequestedList() {
    if (this.programid != "-1") {
      let inparams = new HttpParams()
        .set('programid', this.programid);
      //this.http.get<DESOS_Account_RequestList[]>(this.apiURL + 'api/User/GetRequestedUserListByProgram', { params: inparams }).subscribe(result => {
      this.APIServices.getRequestedUserListByProgram(inparams).subscribe(result => {
        this.userRequestList = result;
        this.dataSource = new MatTableDataSource(this.userRequestList);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }, error => console.error(error));
    }
    else {
      //this.http.get<DESOS_Account_RequestList[]>(this.apiURL + 'api/User/GetRequestedUserList').subscribe(result => {
      this.APIServices.getRequestedUserList().subscribe(result => {
        this.userRequestList = result;
        this.dataSource = new MatTableDataSource(this.userRequestList);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }, error => console.error(error));
    }
  }

  onChangeProgram(event: MatSelectChange) {
    //alert(event.value);
    this.programid = event.value;
    this.userlistChild.programChanged(event.value);
    if (event.value != "-1") {
      let inparams = new HttpParams()
        .set('programid', event.value);
      //this.http.get<DESOS_Account_RequestList[]>(this.apiURL + 'api/User/GetRequestedUserListByProgram', { params: inparams }).subscribe(result => {
      this.APIServices.getRequestedUserListByProgram(inparams).subscribe(result => {
        this.userRequestList = result;
        this.dataSource = new MatTableDataSource(this.userRequestList);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }, error => console.error(error));
    }
    else {
      //this.http.get<DESOS_Account_RequestList[]>(this.apiURL + 'api/User/GetRequestedUserList').subscribe(result => {
      this.APIServices.getRequestedUserList().subscribe(result => {
        this.userRequestList = result;
        this.dataSource = new MatTableDataSource(this.userRequestList);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }, error => console.error(error));
    }
    //this.selectedprogramid = event.value; 
  }

  processUserRequest(accrequestid) {
    if (this.programid != "-1") {
      let inparams = new HttpParams()
        .set('programid', this.programid)
        .set('accrequestid', accrequestid);
      //this.http.get<DESOS_Account_RequestList[]>(this.apiURL + 'api/User/ProcessUserRequest', { params: inparams }).subscribe(result => {
      this.APIServices.processUserRequest(inparams).subscribe(result => {
        this.userRequestList = result;
        this.dataSource = new MatTableDataSource(this.userRequestList);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }, error => console.error(error));
    }
    else {
      let inparams = new HttpParams()
        .set('programid', '0')
        .set('accrequestid', accrequestid);
      //this.http.get<DESOS_Account_RequestList[]>(this.apiURL + 'api/User/ProcessUserRequest', { params: inparams }).subscribe(result => {
        this.APIServices.processUserRequest(inparams).subscribe(result => {
        this.userRequestList = result;
        this.dataSource = new MatTableDataSource(this.userRequestList);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }, error => console.error(error));
    }
  }

  deleteUserRequest(accrequestid) {
    if (this.programid != "-1") {
      let inparams = new HttpParams()
        .set('programid', this.programid)
        .set('accrequestid', accrequestid);
      //this.http.get<DESOS_Account_RequestList[]>(this.apiURL + 'api/User/DeleteUserRequest', { params: inparams }).subscribe(result => {
      this.APIServices.deleteUserRequest(inparams).subscribe(result => {
        this.userRequestList = result;
        this.dataSource = new MatTableDataSource(this.userRequestList);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }, error => console.error(error));
    }
    else {
      let inparams = new HttpParams()
        .set('programid', '0')
        .set('accrequestid', accrequestid);
      //this.http.get<DESOS_Account_RequestList[]>(this.apiURL + 'api/User/DeleteUserRequest', { params: inparams }).subscribe(result => {
      this.APIServices.deleteUserRequest(inparams).subscribe(result => {
        this.userRequestList = result;
        this.dataSource = new MatTableDataSource(this.userRequestList);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }, error => console.error(error));
    }
  }

}
