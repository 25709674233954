import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import { DESOS_Records_List, DESOS_Search_Fields, DESOS_Outcomes, DESOS_Individuals } from 'src/app/dataentry/dataentry';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DESOS_UserInfo } from 'src/app/account/account';
import { DESOS_Reporting_Periods } from '../default';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TempDataService } from 'src/app/services/temp-data.service';
import { DataentryService } from 'src/app/services/dataentry.service';

@Component({
  selector: 'app-current-list',
  templateUrl: './current-list.component.html',
  styleUrls: ['./current-list.component.css'],
  providers: [AuthenticationService, TempDataService, DataentryService]
})
/** current-list component*/
export class CurrentListComponent implements OnInit {
  /** current-list ctor */
  private apiURL: string;
  private recordsList: DESOS_Records_List[];
  public dataSource: MatTableDataSource<DESOS_Records_List>;
  public desosreportinfo: DESOS_Reporting_Periods;
  public displayedColumns: string[] = ['id', 'firstName', 'city', 'county', 'dateOfBirth', 'gender', 'race'];
  private asyncOutcomesResult: DESOS_Outcomes;
  private asyncIndividualResult: DESOS_Individuals;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private individualid: string = "";
  public desosuserinfo: DESOS_UserInfo;
  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private authentication: AuthenticationService, private tempdata: TempDataService, private APIservices: DataentryService) {
    this.apiURL = baseUrl;
    //this.getRecordsListById("110");
  }

  ngOnInit() {

    this.desosreportinfo = this.authentication.getReport();
    this.desosuserinfo = this.authentication.getAccessToken();

    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);

      if (!this.desosuserinfo.blnIsAdmin) {
        const search: DESOS_Search_Fields = new DESOS_Search_Fields();
        search.program_ID = this.desosuserinfo.strProgram;
        this.getRecordsList(search);
      }
   // }

    //if (sessionStorage.desosreportinfo != null) {
    //  this.desosreportinfo = JSON.parse(sessionStorage.desosreportinfo);
    //}
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getRecordsListById(strId: string) {
    let inparams = new HttpParams()
      .set('id', strId);

   // this.http.get<DESOS_Records_List[]>(this.apiURL + 'api/DataEntry/GetRecordListById', { params: inparams }).subscribe(result => {
    this.APIservices.getRecordListById(inparams).subscribe(result => {
    this.recordsList = result;
      this.dataSource = new MatTableDataSource(this.recordsList);
      this.dataSource.paginator = this.paginator;
      if (this.dataSource.data.length > 0) {
        this.dataSource.sort = this.sort;
      }
    }, error => console.error(error));
  }

  getRecordsListByIndividualId(strId: string) {
    let inparams = new HttpParams()
      .set('indId', strId);

    //this.http.get<DESOS_Records_List[]>(this.apiURL + 'api/DataEntry/GetRecordListByIndId', { params: inparams }).subscribe(result => {
      this.APIservices.getRecordListByIndividual(inparams).subscribe(result => {
      this.recordsList = result;
      if (this.recordsList != null) {
        this.dataSource = new MatTableDataSource(this.recordsList);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }
    }, error => console.error(error));
  }

  getRecordsList(inSearchFields: DESOS_Search_Fields) {
    //let inparams = new HttpParams()
    //  .set('indId', strId);

    //this.http.post<DESOS_Records_List[]>(this.apiURL + 'api/DataEntry/GetCurrentRecordList', inSearchFields).subscribe(result => {
    this.APIservices.getCurrentRecordList(inSearchFields).subscribe(result => {
      this.recordsList = result;
      if (this.recordsList != null) {
        this.dataSource = new MatTableDataSource(this.recordsList);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }
    }, error => console.error(error));
  }

  inputAdminSearch(inputSearch: DESOS_Search_Fields) {
    if (inputSearch.id != "") {
      this.getRecordsListById(inputSearch.id);
    }
    else if (inputSearch.individual_ID != "") {
      this.getRecordsListByIndividualId(inputSearch.individual_ID);
    }
    else {
      this.getRecordsList(inputSearch);
    }
  }

  editOutComes(recordId, individualId) {
    let inparams = new HttpParams()
      .set('strRecordId', recordId);
    this.getAsyncOutcomesData(inparams);

    let inparams1 = new HttpParams()
      .set('strIndividualId', individualId);
    this.getAsyncIndividualData(inparams1);
    this.tempdata.setRosterAction("edit");
    this.delay(3000).then(any => {
      this.userNavigation();
    });
  }

  userNavigation() {
    this.router.navigate(['/dataentry']);
  }

  newOutComes(recordId, individualId) {
    let inparams = new HttpParams()
      .set('strRecordId', recordId);
    this.getAsyncOutcomesData(inparams);

    let inparams1 = new HttpParams()
      .set('strIndividualId', individualId);
    this.getAsyncIndividualData(inparams1);
    this.tempdata.setRosterAction("new");
    this.delay(3000).then(any => {
      this.userNavigation();
    });
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("Login"));
  }

  async getAsyncOutcomesData(inparam: HttpParams) {

    this.asyncOutcomesResult = await this.APIservices.getOutcomeById(inparam).toPromise();
    //this.http.get<DESOS_Outcomes>(this.apiURL + 'api/DataEntry/GetDESOSOutcome', { params: inparam }).toPromise();

    if (this.asyncOutcomesResult != null) {
      //alert(JSON.stringify(this.asyncOutcomesResult));
      //this.individualid = this.asyncOutcomesResult.individual_ID;
      //alert(this.individualid);
      this.tempdata.setCurrentoutcome(this.asyncOutcomesResult);
      //sessionStorage.setItem("editoutcomes", JSON.stringify(this.asyncOutcomesResult));
    }
    else {
      console.log('Invalid OutComes ID.');
      alert("Invalid OutComes ID.");
    }
    //console.log('No issues, I will wait until promise is resolved..');
  };


  async getAsyncIndividualData(inparam: HttpParams) {

    this.asyncIndividualResult = await this.APIservices.getIndivitualById(inparam).toPromise();
      //this.http.get<DESOS_Individuals>(this.apiURL + 'api/DataEntry/GetIndividual', { params: inparam }).toPromise();

    if (this.asyncIndividualResult != null) {
      //alert(JSON.stringify(this.asyncIndividualResult));
      this.tempdata.setCurrentindivitual(this.asyncIndividualResult);
      //sessionStorage.setItem("editindividual", JSON.stringify(this.asyncIndividualResult));
    }
    else {
      console.log('Invalid OutComes ID.');
      alert("Invalid OutComes ID.");
    }
  };
}
