<p *ngIf="!dataSource"><em>Loading...</em></p>
<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<div class="row">
  <div class="align-self-center col-sm-6 pt-1">
    <label class="sr-only" for="inlineFormInputGroup">Search Here</label>
    <div class="input-group mb-2">
      <div class="input-group-prepend example-button-row" mat-raised-button color="primary">
        <button mat-raised-button color="primary" (click)="btnGenerateClick()">Clear and Generate New Verification List</button>
        <!--<button mat-raised-button color="primary" (click)="btnClearClick()">Clear Verification List</button>-->
      </div>
    </div>
  </div>
  <div class="align-self-center col-sm-6 pt-1">
    <label class="sr-only" for="inlineFormInputGroup">Program Name</label>
    <div class="input-group mb-2">
      <div class="input-group-prepend example-button-row">
        <button mat-raised-button (click)="btnSendAllEmailClick()" color="primary" [disabled]="isSendEmail">Process and Send Email To All Listed</button>
        <button mat-raised-button (click)="btnEditTemplateClick()" class="pl-3" color="primary">Email Template</button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="align-self-center col-sm-6 pt-1">
    <label class="sr-only" for="inlineFormInputGroup">Search Here</label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <button mat-raised-button color="primary">Search</button>
        <!--<div class="input-group-text text-primary">Search</div>-->
      </div>
      <input type="text" (keyup)="applyFilter($event.target.value)" class="form-control" id="idSearch" placeholder="Search Here">
     
    </div>
  </div>
  <div class="align-self-center col-sm-6 pt-1">
    <div class="input-group mb-2">
      <mat-checkbox class="example-margin pl-3" parse-int ng-true-value="1" ng-false-value="0" (change)="onChangeHideEmail($event)">Hide Email Sent List</mat-checkbox>
      <mat-checkbox class="example-margin pl-3" parse-int ng-true-value="1" ng-false-value="0" (change)="onChangeHideProcessed($event)">Hide Processed List</mat-checkbox>
    </div>
    <div class="input-group mb-2">
      <mat-checkbox class="example-margin pl-3"
                    [checked]="allComplete"
                    [color]="'primary'"
                    [indeterminate]="someComplete()"
                    (change)="setAll($event.checked)">{{allComplete ? "Clear all" : "Select all"}}</mat-checkbox>
      <button mat-raised-button (click)="btnRemoveEntriesClick()" class="ml-3 pl-3" color="primary" *ngIf="canRemove()">Remove Programs</button>
    </div>
  </div>
</div>
<p *ngIf="warningmessage" class="text-danger">{{warningmessage}}</p>
<div class="mat-elevation-z8 pb-1">
  <table class="table" mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="program_ID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div class="row col-sm-12 text-primary text-nowrap">
          <div class="col-sm-12">
            <h6>Program Name</h6>
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="w-100 h-100" style="display: inline-flex;">
        <mat-checkbox class="example-margin p-3" parse-int ng-true-value="1" ng-false-value="0"
                      [(ngModel)]="element.selected"
                      [color]="'accent'"
                      (ngModelChange)="updateAllComplete()"
                      ></mat-checkbox>
        <mat-accordion class="program-headers-align w-100">
          <mat-expansion-panel class="p-0">
            <mat-expansion-panel-header [collapsedHeight]="'60px'" [expandedHeight]="'60px'">
              <mat-panel-title>
                <button mat-mini-fab color="primary">{{ element.program_Name.slice(0,2) }}</button>
                <div class="col-sm-9">
                  <p>{{element.program_Name}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                <div class="row col-sm-6">
                  <mat-form-field class="col-sm-12">
                    <input class="col-sm-12" [(ngModel)]="element.executive_Director_Name" matInput placeholder="Executive Director Name/Email" [disabled]="true" name="Executive_Director_Name" value="">
                    <input class="col-sm-12" [(ngModel)]="element.executive_Director_Email" matInput [disabled]="true" name="Executive_Director_Email" value="">
                  </mat-form-field>
                </div>
                <div class="row col-sm-6">
                  <mat-form-field class="col-sm-12">
                    <input [(ngModel)]="element.desoS_Manager_Name" matInput placeholder="DESOS Manager Name/Email" [disabled]="true" name="DESOS_Manager_Name" value="">
                    <input [(ngModel)]="element.desoS_Manager_Email" matInput [disabled]="true" name="DESOS_Manager_Email" value="">
                  </mat-form-field>
                </div>
                <!--<div class="row col-sm-4">
                  <mat-form-field class="col-sm-12">
                    <input [(ngModel)]="element.new_DESOS_Manager_Name" matInput placeholder="New DESOS Manager Name/Email" [disabled]="true" name="New DESOS_Manager_Name" value="">
                    <input [(ngModel)]="element.new_DESOS_Manager_Email" matInput [disabled]="true" name="New DESOS_Manager_Email" value="">
                  </mat-form-field>
                </div>-->
              </mat-panel-description>
            </mat-expansion-panel-header>
            <section>
              <mat-card class="program-mat-card">
                <mat-card-title color="primary">
                  <label>Verification Details</label>
                </mat-card-title>
                <mat-form-field class="example-full-width">
                  <input [(ngModel)]="element.executive_Director_Name" class="w-100 text-nowrap" matInput placeholder="Executive Director Name" name="Executive_Director_Name" value="">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <input [(ngModel)]="element.executive_Director_Email" class="w-100 text-nowrap" matInput placeholder="Executive Director Email" name="Executive_Director_Email" value="">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <input [(ngModel)]="element.desoS_Manager_Name" class="w-100 text-nowrap" matInput placeholder="DESOS Manager Name" name="DESOS_Manager_Name" value="">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <input [(ngModel)]="element.desoS_Manager_Email" class="w-100 text-nowrap" matInput placeholder="DESOS Manager Email" name="DESOS_Manager_Email" value="">
                </mat-form-field>
                <!--<mat-form-field class="example-full-width">
                  <input [(ngModel)]="element.new_DESOS_Manager_Name" [disabled]="true" class="w-100 text-nowrap" matInput placeholder="New DESOS Manager Name" name="New_DESOS_Manager_Name" value="">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <input [(ngModel)]="element.new_DESOS_Manager_Email" [disabled]="true" class="w-100 text-nowrap" matInput placeholder="New DESOS Manager Email" name="New_DESOS_Manager_Email" value="">
                </mat-form-field>-->
                <br />
                <section class="pt-2">
                  <mat-checkbox class="example-margin pl-2" [(ngModel)]="element.email_Sent_flg" parse-int ng-true-value="1" ng-false-value="0" [labelPosition]="before">Email Sent</mat-checkbox>
                  <mat-checkbox class="example-margin pl-2" [(ngModel)]="element.processed_flg" parse-int ng-true-value="1" ng-false-value="0" [labelPosition]="before">Processed</mat-checkbox>
                  <mat-checkbox class="example-margin pl-2" [(ngModel)]="element.accepted_flg" parse-int ng-true-value="1" ng-false-value="0" [labelPosition]="before">Accepted</mat-checkbox>
                  <mat-checkbox class="example-margin pl-2" [(ngModel)]="element.denied_flg" parse-int ng-true-value="1" ng-false-value="0" [labelPosition]="before">Denied</mat-checkbox>
                </section>
                <mat-card-actions>
                  <div class="row col-sm-12">
                    <div class="user-button-row col-sm-3">
                      <button mat-raised-button (click)="btnSaveClick(element)" color="primary">Save</button>
                    </div>
                    <div class="user-button-row col-sm-3">
                      <button mat-raised-button (click)="btnDeleteClick(element)" color="primary">Delete</button>
                    </div>
                    <div class="user-button-row col-sm-3">
                      <button mat-raised-button (click)="btnSendEmailClick(element)" color="primary">Send Email To Executive</button>
                    </div>
                    <div class="user-button-row col-sm-3">
                      <button mat-raised-button (click)="btnDuplicateDataClick(element)" color="primary">Duplicate Data</button>
                    </div>
                  </div>
                </mat-card-actions>
              </mat-card>
            </section>
          </mat-expansion-panel>
        </mat-accordion>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

