import { NgModule, ChangeDetectionStrategy, ViewChild, OnChanges, Input, OnInit, AfterViewChecked, Inject, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef, } from '@angular/core';
import { Component } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarContainer } from '@angular/material/snack-bar';
import { MatHorizontalStepper, MatStep } from '@angular/material/stepper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { helpText, DataEntry_tabAvailability, DESOS_Individuals, DESOS_Outcomes, DESOS_StrResult, DESOS_RosterData, DESOS_StrRosterResult } from '../dataentry';
import { DESOS_Programs_List } from 'src/app/admin/program';
import { DataentryTab4Component } from '../dataentry-tab4/dataentry-tab4.component';
import { DataentryTab5Component } from '../dataentry-tab5/dataentry-tab5.component';
import { DataentryTab6Component } from '../dataentry-tab6/dataentry-tab6.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DataentryTab1Component } from '../dataentry-tab1/dataentry-tab1.component';
import { DataentryTab2Component } from '../dataentry-tab2/dataentry-tab2.component';
import { DataentryTab3Component } from '../dataentry-tab3/dataentry-tab3.component';
import { DataentryTab7Component } from '../dataentry-tab7/dataentry-tab7.component';
import { DataentryTab8Component } from '../dataentry-tab8/dataentry-tab8.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { DESOS_UserInfo } from 'src/app/account/account';
import { stringify } from '@angular/compiler/src/util';
// import { forEach } from '@angular/router/src/utils/collection';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { Observable } from 'rxjs';
import { DataentryModalComponent } from '../dataentry-modal/dataentry-modal.component';
import { DataentryService } from 'src/app/services/dataentry.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TempDataService } from 'src/app/services/temp-data.service';



@Component({
  selector: 'app-newentry',
  templateUrl: './newentry.component.html',
  styleUrls: ['./newentry.component.css'],
  providers: [DataentryService, AuthenticationService, TempDataService]
})
/** newentry component*/
export class NewentryComponent implements OnInit, AfterViewChecked, AfterViewInit {
  /** newentry ctor */
  private programList: DESOS_Programs_List[];
  helpMessage: helpText;
  public DETabsEnabled: DataEntry_tabAvailability;
  public blnDisabledSubmit: boolean = false;
  public isTab8Visible: boolean = false;
  public selectedIndex: number = 0;
  public primary: number;
  @Input() action: string = "";
  private apiURL: string;
  public IndividualId: DESOS_StrResult = new DESOS_StrResult();
  public OutcomeId: DESOS_StrResult = new DESOS_StrResult();
  public RosterEntryResults: DESOS_StrRosterResult = new DESOS_StrRosterResult();
  public warningmessage: string = "";
  private desosuserinfo: DESOS_UserInfo;
  @ViewChild(MatHorizontalStepper, { static: true }) stepper: MatHorizontalStepper;
  @ViewChild(DataentryTab1Component, { static: true }) tab1: DataentryTab1Component;
  @ViewChild(DataentryTab2Component, { static: false }) tab2: DataentryTab2Component;
  @ViewChild(DataentryTab3Component, { static: false }) tab3: DataentryTab3Component;
  @ViewChild(DataentryTab4Component, { static: false }) tab4: DataentryTab4Component;
  @ViewChild(DataentryTab5Component, { static: false }) tab5: DataentryTab5Component;
  @ViewChild(DataentryTab6Component, { static: false }) tab6: DataentryTab6Component;
  @ViewChild(DataentryTab7Component, { static: false }) tab7: DataentryTab7Component;
  @ViewChild(DataentryTab8Component, { static: false }) tab8: DataentryTab8Component;
  @ViewChildren(MatStep) matsteps: QueryList<MatStep>;
  @ViewChild(DataentryModalComponent, { static: false }) datamodal: DataentryModalComponent;
  private curEditIndividual: DESOS_Individuals;
  private curEditOutComes: DESOS_Outcomes;
  private curNewIndividual: DESOS_Individuals;
  private curNewOutComes: DESOS_Outcomes;
  private curRosterData: DESOS_RosterData;
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public fourthFormGroup: FormGroup;
  public fifthFormGroup: FormGroup;
  public sixthFormGroup: FormGroup;
  public seventhFormGroup: FormGroup;
  public eighthFormGroup: FormGroup;
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  constructor(private _formBuilder: FormBuilder, private snackBar: MatSnackBar, private location: Location, private route: ActivatedRoute,
    private router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private cdRef: ChangeDetectorRef,
    private matDialog: MatDialog, private APIservices: DataentryService, private authentication: AuthenticationService, private tempdata: TempDataService) {
    this.helpMessage = new helpText();
    this.DETabsEnabled = new DataEntry_tabAvailability();
    this.apiURL = baseUrl;
    let observable = new Observable(this.myObservableOne);
    this.showProgressSpinner(observable);
    //this.tabsEnabled();
  }

  ngOnInit() {

    this.desosuserinfo = this.authentication.getAccessToken();

    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);
    //}
    this.loadRecords();

    this.loadFormGroup();

  }

  ngAfterViewInit() {
    this.loadValidation()
    this.tabsEnabled();
    this.q1AnswerDependent();
  }

  ngAfterViewChecked() {

  }

  myObservableOne(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 1000);
  }

  myObservableTwo(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 2000);
  }

  myObservableSix(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 7000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  loadFormGroup() {
    this.firstFormGroup = this._formBuilder.group({
      tab1: [false, Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      tab2: [false, Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      tab3: [false, Validators.required]
    });
    this.fourthFormGroup = this._formBuilder.group({
      tab4: [false, Validators.required]
    });
    this.fifthFormGroup = this._formBuilder.group({
      tab5: [false, Validators.required]
    });
    this.sixthFormGroup = this._formBuilder.group({
      tab6: [false, Validators.required]
    });
    this.seventhFormGroup = this._formBuilder.group({
      tab7: [false, Validators.required]
    });

    this.eighthFormGroup = this._formBuilder.group({
      tab8: [false, Validators.required]
    });
  }

  loadValidation() {
    this.firstFormGroup.addControl('tab1FormGroup1', this.tab1.tab1FormGroup1);
    this.tab1.tab1FormGroup1.setParent(this.firstFormGroup);
    //  if (this.tab2 != undefined) {
    this.secondFormGroup.addControl('tab2FormGroup', this.tab2.tab2FormGroup);
    this.tab2.tab2FormGroup.setParent(this.secondFormGroup);

    this.thirdFormGroup.addControl('tab3FormGroup', this.tab3.tab3FormGroup);
    this.tab3.tab3FormGroup.setParent(this.thirdFormGroup);

    this.fourthFormGroup.addControl('tab4FormGroup', this.tab4.tab4FormGroup);
    this.tab4.tab4FormGroup.setParent(this.fourthFormGroup);

    this.fifthFormGroup.addControl('tab5FormGroup', this.tab5.tab5FormGroup);
    this.tab5.tab5FormGroup.setParent(this.fifthFormGroup);

    this.sixthFormGroup.addControl('tab6FormGroup', this.tab6.tab6FormGroup);
    this.tab6.tab6FormGroup.setParent(this.sixthFormGroup);

    this.seventhFormGroup.addControl('tab7FormGroup', this.tab7.tab7FormGroup);
    this.tab7.tab7FormGroup.setParent(this.seventhFormGroup);
    // }
    // if (this.tab8 != undefined) {
    this.eighthFormGroup.addControl('tab8FormGroup', this.tab8.tab8FormGroup);
    this.tab8.tab8FormGroup.setParent(this.eighthFormGroup);
    // }
  }

  loadRecords() {
    this.curEditIndividual = this.tempdata.getCurrentindivitual();
    this.curEditOutComes = this.tempdata.getCurrentoutcome();
    if (this.tempdata.getRosterAction() == "edit") {
      this.IndividualId.strOutput = this.curEditIndividual.individual_ID;
      this.OutcomeId.strOutput = this.curEditOutComes.id;
    }
    else if (this.tempdata.getRosterAction() == "copy") {
      this.IndividualId.strOutput = this.curEditIndividual.individual_ID;
      this.OutcomeId.strOutput = "New";
    }
    else if (this.tempdata.getRosterAction() == "new") {
      this.IndividualId.strOutput = "New";
      this.OutcomeId.strOutput = "New";
      //this.isTab8Visible = true;
    }

    //if (sessionStorage.editindividual != null) {
     // this.curEditIndividual = JSON.parse(sessionStorage.editindividual);
      if (this.curEditIndividual.individual_ID != "") {
        this.IndividualId.strOutput = this.curEditIndividual.individual_ID;
      }
      else {
        this.IndividualId.strOutput = "New";
        this.isTab8Visible = true;
    }

     // if (sessionStorage.editoutcomes != null) {
      //  this.curEditOutComes = JSON.parse(sessionStorage.editoutcomes);
        if (this.curEditOutComes.id != "") {

          if (this.tempdata.getRosterAction() == "copy") {
            this.OutcomeId.strOutput = "New";
          } else {
            this.OutcomeId.strOutput = this.curEditOutComes.id;
            //this.onQ1Change(this.curEditOutComes.q1val);
          }

        }
        else {
          this.OutcomeId.strOutput = "New";
        }
      //}
    //}
  }



  btnSubmitForm() {
    if (this.checkFormValidate()) {

      this.warningmessage = "Updating Individual...";
      this.curNewIndividual = this.tab1.getEntriesIndivitual();
      this.curNewIndividual.unique_ID = this.desosuserinfo.strUserName;
      this.curNewOutComes = new DESOS_Outcomes();
      this.curNewOutComes = this.tab1.getEntriesOutcomes(this.curNewOutComes);
      this.curNewOutComes.userCreate = this.desosuserinfo.strUserName;
      this.curNewOutComes.userEdit = this.desosuserinfo.strUserName;
      this.warningmessage = "Updating Outcomes....";
      if (this.curNewOutComes.nlrs == "0") {

        // if (this.DETabsEnabled.tab2To7Enabled) {
        this.curNewOutComes = this.tab2.getEntries(this.curNewOutComes);
        this.curNewOutComes = this.tab3.getEntries(this.curNewOutComes);
        this.curNewOutComes = this.tab4.getEntries(this.curNewOutComes);
        this.curNewOutComes = this.tab5.getEntries(this.curNewOutComes);
        this.curNewOutComes = this.tab6.getEntries(this.curNewOutComes);
        this.curNewOutComes = this.tab7.getEntries(this.curNewOutComes);
        //if (this.DETabsEnabled.tab8Enabled)
        //CHECK676
        if (this.curNewOutComes.q22val == "1" && this.curNewOutComes.q1val != "5") {
          this.curNewOutComes = this.tab8.getEntries(this.curNewOutComes);
          if (this.curNewOutComes.q23val == "6") {
            this.curNewOutComes.q22val = "0";
          }
        }
      }

      if (this.tempdata.getRosterAction() == "new") {
        //this.delay(3000).then(any => { this.insertIndivitual(this.curNewIndividual); });
       // this.delay(5000).then(any => { this.insertOutcomes(this.curNewOutComes); });
        //let observable = new Observable(this.myObservableSix);
        //this.showProgressSpinner(observable);
        this.showProgressSpinner(this.InsertRosterEntry_A(this.curNewIndividual, this.curNewOutComes));
        //this.delay(5000).then(any => { this.InsertRosterEntry(this.curNewIndividual, this.curNewOutComes); });
        //this.delay(7000).then(any => { this.openDialog(); });
      }
      else if (this.tempdata.getRosterAction() == "copy") {
       //this.delay(3000).then(any => { this.updateIndivitual(this.curNewIndividual); });
       //this.delay(5000).then(any => { this.insertOutcomes(this.curNewOutComes); });
        //let observable = new Observable(this.myObservableSix);
        //this.showProgressSpinner(observable);
        this.curNewIndividual.individual_ID = this.IndividualId.strOutput;
        this.showProgressSpinner(this.CopyRosterEntry_A(this.curNewIndividual, this.curNewOutComes));
        //this.delay(5000).then(any => { this.CopyRosterEntry(this.curNewIndividual, this.curNewOutComes); });
        //this.delay(7000).then(any => { this.openDialog(); });
      } else
        if (this.tempdata.getRosterAction() == "edit") {
         //this.delay(3000).then(any => { this.updateIndivitual(this.curNewIndividual); });
         //this.delay(5000).then(any => { this.updateOutcomes(this.curNewOutComes); });
          //let observable = new Observable(this.myObservableSix);
          //this.showProgressSpinner(observable);
          this.curNewIndividual.individual_ID = this.IndividualId.strOutput;
          this.curNewOutComes.id = this.OutcomeId.strOutput;
          this.showProgressSpinner(this.UpdateRosterEntry_A(this.curNewIndividual, this.curNewOutComes));
          console.log("edit")
          ////this.APIservices.UpdateRosterEntry(this.curNewIndividual, this.curNewOutComes).subscribe(results => this.RosterEntryResults = results);
          ////if (this.RosterEntryResults != null) {
          ////  if (this.RosterEntryResults.strOutput == "Success") {
          ////    this.openDialog();
          ////  }
          ////}
          //this.delay(5000).then(any => { this.UpdateRosterEntry(this.curNewIndividual, this.curNewOutComes); });
          //this.delay(7000).then(any => { this.openDialog(); });
        }
    }
    else {
      alert("Please fill all the required fields!");
    }
    //this.warningmessage = "";
  }

  openDialog(): void {
    const dialogRef = this.matDialog.open(DataentryModalComponent, {
      width: '400px',
      disableClose: true,
      data: { action: this.tempdata.getRosterAction(), individual: this.RosterEntryResults.strIndividual_ID, outcome: this.RosterEntryResults.strOutcomes_ID, status: this.RosterEntryResults.strOutput }
    });

    dialogRef.afterClosed().subscribe(result => {
      //sessionStorage.setItem("entrytype", "done");
      this.location.back();
    });
  }

  //updateProgress() {
  //  this.datamodal.dataChange({ action: sessionStorage.entrytype, individual: this.IndividualId.strOutput, outcome: this.OutcomeId.strOutput });
  //}

  checkFormValidate(): boolean {
    var blnResults = true;
    if (!this.tab1.tab1FormGroup1.valid) {
      this.stepper.selectedIndex = 0;
      blnResults = false;
    }

    if (this.tab2 != undefined) {
      if (this.tab2.tab2FormGroup.enabled) {
        if (!this.tab2.tab2FormGroup.valid) {
          this.stepper.selectedIndex = 1;
          blnResults = false;
        }
      }
    }
    if (this.tab3 != undefined) {
      if (this.tab3.tab3FormGroup.enabled) {
        if (!this.tab3.tab3FormGroup.valid) {
          this.stepper.selectedIndex = 2;
          blnResults = false;
        }
      }
    }

    if (this.tab4 != undefined) {
      if (this.tab4.tab4FormGroup.enabled) {
        if (!this.tab4.tab4FormGroup.valid) {
          this.stepper.selectedIndex = 3;
          blnResults = false;
        }
      }
    }
    if (this.tab5 != undefined) {
      if (this.tab5.tab5FormGroup.enabled) {
        if (!this.tab5.tab5FormGroup.valid) {
          this.stepper.selectedIndex = 4;
          blnResults = false;
        }
      }
    }
    if (this.tab6 != undefined) {
      if (this.tab6.tab6FormGroup.enabled) {
        if (!this.tab6.tab6FormGroup.valid) {
          this.stepper.selectedIndex = 5;
          blnResults = false;
        }
      }
    }
    if (this.tab7 != undefined) {
      if (this.tab7.tab7FormGroup.enabled) {
        if (!this.tab7.tab7FormGroup.valid) {
          this.stepper.selectedIndex = 6;
          blnResults = false;
        }
      }
    }

    if (this.tab8 != undefined) {
      if (this.tab8.tab8FormGroup.enabled) {
        if (!this.tab8.tab8FormGroup.valid) {
          this.stepper.selectedIndex = 7;
          blnResults = false;
        }
      }
    }


    return blnResults;
  }

  //ngOnChanges() {
  //  // create header using child_id
  //  //console.log(this.action);
  //}

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("Roster Add/Edit"));
  }


  insertIndivitual(inIndividuals: DESOS_Individuals) {

    this.http.post<DESOS_StrResult>(this.apiURL + 'api/DataEntry/InsertIndividuals', inIndividuals).subscribe(result => {
      this.IndividualId = JSON.parse(JSON.stringify(result));
    }, error => console.error(error));

  }

  updateIndivitual(inIndividuals: DESOS_Individuals) {

    this.http.post<DESOS_StrResult>(this.apiURL + 'api/DataEntry/UpdateIndividuals', inIndividuals).subscribe(result => {
      this.IndividualId = JSON.parse(JSON.stringify(result));
    }, error => console.error(error));

  }

  insertOutcomes(inOutcomes: DESOS_Outcomes) {

    inOutcomes.individual_ID = this.IndividualId.strOutput;
    this.http.post<DESOS_StrResult>(this.apiURL + 'api/DataEntry/InsertOutcomes', inOutcomes).subscribe(result => {
      this.OutcomeId = JSON.parse(JSON.stringify(result));
    }, error => console.error(error));
    this.warningmessage = "";

  }

  updateOutcomes(inOutcomes: DESOS_Outcomes) {

    inOutcomes.individual_ID = this.IndividualId.strOutput;
    this.http.post<DESOS_StrResult>(this.apiURL + 'api/DataEntry/UpdateOutcomes', inOutcomes).subscribe(result => {
      this.OutcomeId = JSON.parse(JSON.stringify(result));
    }, error => console.error(error));
    this.warningmessage = "";

  }

  InsertRosterEntry_A(inIndividuals: DESOS_Individuals, inOutcomes: DESOS_Outcomes) {
    return Observable.create(observer => {
      this.APIservices.InsertRosterEntry(this.curNewIndividual, this.curNewOutComes).subscribe((result) => {
        this.RosterEntryResults = JSON.parse(JSON.stringify(result));
        if (this.RosterEntryResults != null) {
          if (this.RosterEntryResults.strOutput == "Success") {
            this.warningmessage = "Update completed.";
            observer.next("Update completed.");
            observer.complete();
            this.openDialog();
          }
          else {
            if (this.RosterEntryResults.strOutput != "") {
              this.warningmessage = "Update failed.";
              observer.next("Update error.");
              observer.complete();
              this.openDialog();
            }
          }
        }
        else {
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }

  InsertRosterEntry(inIndividuals: DESOS_Individuals, inOutcomes: DESOS_Outcomes) {

    this.curRosterData = new DESOS_RosterData();
    this.curRosterData.Individuals = inIndividuals;
    this.curRosterData.Outcomes = inOutcomes;
    this.http.post<DESOS_StrRosterResult>(this.apiURL + 'api/DataEntry/InsertRosterEntry', this.curRosterData).subscribe(result => {
      this.RosterEntryResults = JSON.parse(JSON.stringify(result));
      if (this.RosterEntryResults != null) {
        if (this.RosterEntryResults.strOutput == "Success") {
          this.openDialog();
        }
      }
    }, error => console.error(error));
    this.warningmessage = "";
  }

  UpdateRosterEntry_A(inIndividuals: DESOS_Individuals, inOutcomes: DESOS_Outcomes) {
    return Observable.create(observer => {
      this.APIservices.UpdateRosterEntry(this.curNewIndividual, this.curNewOutComes).subscribe((result) => {
      this.RosterEntryResults = JSON.parse(JSON.stringify(result));
      if (this.RosterEntryResults != null) {
        if (this.RosterEntryResults.strOutput == "Success") {
          this.warningmessage = "Update completed.";
          observer.next("Update completed.");
          observer.complete();
          this.openDialog();
          
        }
        else {
          if (this.RosterEntryResults.strOutput != "") {
            this.warningmessage = "Update failed.";
            observer.next("Update error.");
            observer.complete();
            this.openDialog();
          }
        }
      }
      else {
        observer.next("No Connection, DB or API or Session.");
        observer.complete();
      }
      });
    });
  }

  UpdateRosterEntry(inIndividuals: DESOS_Individuals, inOutcomes: DESOS_Outcomes) {

    this.curRosterData = new DESOS_RosterData();
    this.curRosterData.Individuals = inIndividuals;
    this.curRosterData.Outcomes = inOutcomes;
    this.http.post<DESOS_StrRosterResult>(this.apiURL + 'api/DataEntry/UpdateRosterEntry', this.curRosterData).subscribe(result => {
      this.RosterEntryResults = JSON.parse(JSON.stringify(result));
      if (this.RosterEntryResults != null) {
        if (this.RosterEntryResults.strOutput == "Success") {
          this.openDialog();          
        }
      }
    }, error => console.error(error));
    this.warningmessage = "";
  }

  CopyRosterEntry_A(inIndividuals: DESOS_Individuals, inOutcomes: DESOS_Outcomes) {
    return Observable.create(observer => {
      this.APIservices.CopyRosterEntry(this.curNewIndividual, this.curNewOutComes).subscribe((result) => {
        this.RosterEntryResults = JSON.parse(JSON.stringify(result));
        if (this.RosterEntryResults != null) {
          if (this.RosterEntryResults.strOutput == "Success") {
            this.warningmessage = "Update completed.";
            observer.next("Update completed.");
            observer.complete();
            this.openDialog();

          }
          else {
            if (this.RosterEntryResults.strOutput != "") {
              this.warningmessage = "Update failed.";
              observer.next("Update error.");
              observer.complete();
              this.openDialog();
            }
          }
        }
        else {
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }

  CopyRosterEntry(inIndividuals: DESOS_Individuals, inOutcomes: DESOS_Outcomes) {

    this.curRosterData = new DESOS_RosterData();
    this.curRosterData.Individuals = inIndividuals;
    this.curRosterData.Outcomes = inOutcomes;
    this.http.post<DESOS_StrRosterResult>(this.apiURL + 'api/DataEntry/CopyRosterEntry', this.curRosterData).subscribe(result => {
      this.RosterEntryResults = JSON.parse(JSON.stringify(result));
      if (this.RosterEntryResults != null) {
        if (this.RosterEntryResults.strOutput == "Success") {
          this.openDialog();
        }
      }
    }, error => console.error(error));
    this.warningmessage = "";
  }

  reloadEntryForm() {
    this.tab1.ngOnInit();
    this.tab2.ngOnInit();
    this.tab3.ngOnInit();
    this.tab4.ngOnInit();
    this.tab5.ngOnInit();
    this.tab6.ngOnInit();
    this.tab7.ngOnInit();
    this.tab8.ngOnInit();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  closeSnackBar() {
    this.snackBar.dismiss();
  }



  tabsEnabled() {
    if (this.tempdata.getRosterAction() != "new") {
      if (this.curEditOutComes.nlrs == "0") {
        this.DETabsEnabled.tab2To7Enabled = true;
      }
      else {
        this.DETabsEnabled.tab2To7Enabled = false;
      }

      if (this.curEditOutComes.q22val == "1") {
        this.DETabsEnabled.tab8Enabled = true;
        this.isTab8Visible = true;
      }
      else {
        this.DETabsEnabled.tab8Enabled = false;
        this.isTab8Visible = false;
      }
    }
    else {
      this.DETabsEnabled.tab8Enabled = false;
      this.isTab8Visible = false;
      //this.matsteps = this.stepper._steps;
      //this.matsteps.find(matsep => matsep.label === '21 To 23').stepControl.disable();
      //this.matsteps.toArray()[0].stepControl.
    }

    this.cdRef.detectChanges();
  }

  tabs2To8Hide($event) {
    if ($event == true) {
      //this.blnDisabledSubmit = true;
      this.DETabsEnabled.tab2To7Enabled = $event;
      //this.DETabsEnabled.tab8Enabled = $event;
    }
    else {
      //this.blnDisabledSubmit = false;
      this.DETabsEnabled.tab2To7Enabled = $event;
      this.DETabsEnabled.tab8Enabled = $event;
      this.isTab8Visible = $event;
    }
    //  this.loadValidation();
    this.loadFormGroup();
    this.cdRef.detectChanges();
    //this.loadValidation();
  }

  tabs8Hide($event) {
    console.log("hide8",$event);
    this.DETabsEnabled.tab8Enabled = $event;
    this.cdRef.detectChanges();
    this.tab8.onChangePrimary(this.primary);
    this.tab8.refresh(this.primary);
    // this.loadValidation();
  }

  q1AnswerDependent() {
    console.log("q1depend");
    if (this.tempdata.getRosterAction() != "new") {
      if (this.curEditOutComes.q1val == "4" || this.curEditOutComes.q1val == "5") {
        this.tab4.disableA(true);
        this.tab5.disableA(true);
        this.tab6.disableA(true);
      }
      if (this.curEditOutComes.q1val == "3") {
        this.tab4.disable3(true);
        this.tab6.disableA(true);
      }
      if (this.curEditOutComes.q1val == "5") {
        this.tab7.onChangePrimary(5);
      }
      //this.Q1init(this.curEditOutComes.q1val);
    }
/*    this.Q1init(this.curEditOutComes.q1val);*/
  }

  Q1init($val) {
    console.log("qinit");
    this.onQ1Change($val);
  }

  onQ1Change($event) {    
    console.log("onQ1Change", $event);
    let val = parseInt($event);
    console.log("Q1 updated: ", $event);
    this.primary = val;

    switch(val) {
      case 4:
        this.tab4.disableA(true);
        this.tab4.disableB(false);
        this.tab4.disable3(true);
        this.tab5.disableA(true);
        this.tab6.disableA(true);
        break;
      case 0:
      case 1:
        console.log("in 1-0: ", val);
        this.tab4.disableA(false);
        this.tab4.disableB(false);
        this.tab4.disable3(false);
        this.tab5.disableA(false);
        this.tab6.disableA(true);
        break;
      case 5:
        console.log("Disable 5: ", val);
        this.tab4.disableA(true);
        this.tab4.disableB(true);
        this.tab4.disable3(true);
        this.tab5.disableA(true);
        this.tab6.disableA(true);
        this.tab6.disableB(true);
        break;
      case 3:
        console.log("in 3: ", val);
        this.tab4.disable3(true);
        break;
      default:
        console.log("in else: ", val);
        this.tab4.disable3(false);
        this.tab4.disableA(false);
        this.tab4.disableB(false);
        this.tab5.disableA(false);
        this.tab6.disableA(false);
        break;
    }

    if ($event == "3") {
      console.log("dsbl:3");
      this.tab4.disable3(true);
      this.tab6.disableA(true);
    }

    this.tab5.setPaidLimit($event);
    console.log("setPrimaryb4tab8:", $event);
    if (this.isTab8Visible) {
      this.tab8.onChangePrimary($event);
      this.tab8.refresh($event);
    }

    //clear old inputs
    this.tab4.refresh(val);
    this.tab5.refresh(val);
    this.tab6.refresh(val);
    this.tab7.refresh(val);
    this.tab7.onChangePrimary(val);
  }

  onQ21Change($event) {
    console.log("21: ", $event);
    this.tab7.setChangeQuest20(0);
    //this.tab8.disableAll(1);
    //this.tabs8Hide(1);
  }

  selectionChange(event: StepperSelectionEvent) {
    //this.selectedIndex = 4;
    //alert(event.selectedIndex);
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.next();

  }

  btnBackToRoster() {
    this.router.navigate(['/dataentry/rosterlist']);
  }

}
