import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchRoutingModule } from './search-routing.module';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AdminsharedModule } from '../adminshared.module';
import { SearchRecordsComponent } from './search-records.component';
import { AdminSearchComponent } from './admin-search/admin-search.component';
import { CurrentListComponent } from './current-list/current-list.component';
import { ArchiveSearchComponent } from './archive-search/archive-search.component';
import { ArchiveListComponent } from './archive-list/archive-list.component';
import { NewentryComponent } from '../dataentry/newentry/newentry.component';
import { DataentryModule } from '../dataentry/dataentry.module';

@NgModule({
  declarations: [
    SearchRecordsComponent,
    AdminSearchComponent,
    CurrentListComponent,
    ArchiveSearchComponent,
    ArchiveListComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AdminsharedModule,
    SearchRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatStepperModule,
    MatBadgeModule,
    MatDividerModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatTooltipModule,
    MatSortModule,
    MatSidenavModule,
  ],
  providers: [],
  bootstrap: []
})
export class SearchModule {
}
