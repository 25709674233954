import { Component, OnInit, Inject } from '@angular/core';
import { Program, DESOS_Programs } from '../program';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatCard } from '@angular/material/card';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { DESOS_State_List } from 'src/app/search/default';
import { Observable } from 'rxjs';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { ProgramService } from 'src/app/services/program.service';
import { DESOS_StrResult } from 'src/app/dataentry/dataentry';

@Component({
    selector: 'app-program-edit',
    templateUrl: './program-edit.component.html',
  styleUrls: ['./program-edit.component.css'],
  providers: [ProgramService]
})
/** program-edit component*/
export class ProgramEditComponent implements OnInit  {
    /** program-edit ctor */
  public programadd: DESOS_Programs;
  public asyncResult: DESOS_Programs;
  public programRequest: FormGroup;
  public stateList: DESOS_State_List[];
  private apiURL: string;
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  public resultsStr: DESOS_StrResult = new DESOS_StrResult();
  //private mobnumPattern: string = "^((\\+1-?)|0)?[0-9]{10}$";
  private mobnumPattern: string = "^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$";
  constructor(private location: Location, private route: ActivatedRoute, private router: Router,
    private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private matDialog: MatDialog, private APIServices: ProgramService) {
    this.apiURL = baseUrl;
    this.loafDefaults();
    let observable = new Observable(this.myObservableTwo);
    this.showProgressSpinner(observable);
  }

  ngOnInit() {
    let params = new HttpParams();
    params = params.append("id", this.route.snapshot.params.id);
    this.getAsyncData(params);
    this.programRequest = new FormGroup({
      programname: new FormControl('', [Validators.required]),
      excdirname: new FormControl('', [Validators.required]),
      excdiremail: new FormControl('', [Validators.required, Validators.email]),
      desosmanagname: new FormControl('', [Validators.required]),
      desosmanagemail: new FormControl('', [Validators.required, Validators.email]),
      state: new FormControl('15', [Validators.required]),
      street_Address: new FormControl(''),
      city: new FormControl(''),
      zipCode: new FormControl(''),
      county: new FormControl(''),
      telephone: new FormControl('', [Validators.pattern(this.mobnumPattern)]),
      fax: new FormControl('', [Validators.pattern(this.mobnumPattern)]),
      emp_flg: new FormControl(),
      res_flg: new FormControl(),
    });

    this.delay(1000).then(any => {
      this.loadPage();
    });
  }

  loadPage() {
    this.programRequest.controls["programname"].setValue(this.programadd.program_Name);
    this.programRequest.controls["excdirname"].setValue(this.programadd.executive_Director_Name);
    this.programRequest.controls["excdiremail"].setValue(this.programadd.executive_Director_Email);
    this.programRequest.controls["desosmanagname"].setValue(this.programadd.desoS_Manager_Name);
    this.programRequest.controls["desosmanagemail"].setValue(this.programadd.desoS_Manager_Email);
    this.programRequest.controls["state"].setValue(this.programadd.state);
    this.programRequest.controls["street_Address"].setValue(this.programadd.street_Address);
    this.programRequest.controls["city"].setValue(this.programadd.city);
    this.programRequest.controls["zipCode"].setValue(this.programadd.zipCode);
    this.programRequest.controls["county"].setValue(this.programadd.county);
    this.programRequest.controls["telephone"].setValue(this.programadd.telephone);
    this.programRequest.controls["fax"].setValue(this.programadd.fax);
    this.programRequest.controls["emp_flg"].setValue(this.programadd.emp_flg);
    this.programRequest.controls["res_flg"].setValue(this.programadd.res_flg);
  }

  hasError = (controlName: string, errorName: string) => {
    return this.programRequest.controls[controlName].hasError(errorName);
  }

  myObservableTwo(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 2000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  loafDefaults() {
    //this.http.get<DESOS_State_List[]>(this.apiURL + 'api/DefaultData/GetStateSelectList').subscribe(result => {
    //  this.stateList = result;
    //}, error => console.error(error));
    this.APIServices.getStateSelectList().subscribe(result => {
      this.stateList = result;
    }, error => console.error(error));
  }

  async getAsyncData(inparam: HttpParams) {

    //this.asyncResult = await this.http.get<DESOS_Programs>(this.baseUrl + 'api/Program/GetProgram', { params: inparam }).toPromise();
    this.asyncResult = await this.APIServices.getProgram(inparam).toPromise();
    if (this.asyncResult != null) {
      this.programadd = JSON.parse(JSON.stringify(this.asyncResult));
    }

  };

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  btnSaveClick() {
     // this.programddit = this.program.value;
    let observable = new Observable(this.myObservableTwo);
    this.showProgressSpinner(observable);
      //if (this.programRequest.valid) {
        this.programadd.program_Name = this.programRequest.controls["programname"].value;
        this.programadd.executive_Director_Name = this.programRequest.controls["excdirname"].value;
        this.programadd.executive_Director_Email = this.programRequest.controls["excdiremail"].value;
        this.programadd.desoS_Manager_Name = this.programRequest.controls["desosmanagname"].value;
        this.programadd.desoS_Manager_Email = this.programRequest.controls["desosmanagemail"].value;
        this.programadd.state = this.programRequest.controls["state"].value;
        this.programadd.street_Address = this.programRequest.controls["street_Address"].value;
        this.programadd.city = this.programRequest.controls["city"].value;
        this.programadd.zipCode = this.programRequest.controls["zipCode"].value;
        this.programadd.county = this.programRequest.controls["county"].value;
        this.programadd.telephone = this.programRequest.controls["telephone"].value;
        this.programadd.fax = this.programRequest.controls["fax"].value;
        this.programadd.emp_flg = this.programRequest.controls["emp_flg"].value;
        this.programadd.res_flg = this.programRequest.controls["res_flg"].value;
      //}
      //this.http.post(this.baseUrl + 'api/Program/AddProgram', this.programadd).subscribe
      //  (data => {
      //    console.log('sent ' + data);
      //    alert("Program updated successfully.");
      //  })
      this.APIServices.programEdit(this.programadd).subscribe(result => {
        this.resultsStr = result;
      }, error => console.error(error));

      //<program>(baseUrl + 'api/Program/GetProgramList')
    //this.router.navigate(['/admin/programlist']);
    this.location.back();
  }

  btnBackClick() {
    //this.router.navigate(['/admin/programlist']);
    this.location.back();
  }

}
