
export class dataentry_tab1_quest11 {
  public questionText: string = "No Longer Receiving Services";

  public optIndividual: number ;
  public optYes: boolean = false;
  public optNo: boolean = false;

  public optResonSelected: number;
  public optResonChoice: boolean = false;
  public optResonMoved: boolean = false;
  public optResonDeath: boolean = false;
  public optResonOther: boolean = false;

  public legalGuardian: string = "";
  public strIndividual: string = "";
  public strResonSelected: string = "";

  public strOtherDescription: string = "";

  public strFirstName: string = "";
  public strMiddleName: string = "";
  public strLastName: string = "";
  public strProgram: string = "";
  public strCity: string = "";
  public strCounty: string = "";
  public strGender: string = "";
  public strReportingPeriod: string = "";
  public strRace: string = "";
  public strDOBMonth: string = "";
  public strDOBYear: string = "";
  //public optsindividual: string[] = ['N/A - individual is still receiving services', 'individual is no longer receiving services',];
}
