import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminmenuComponent } from '../nav-menu/admin-menu/adminmenu.component' 
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { DESOS_Users } from './program';
import { DESOS_UserInfo } from '../account/account';
import { AuthenticationService } from '../services/authentication.service';
@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  providers: [AuthenticationService]
})
/** admin component*/
export class AdminComponent implements OnInit {
    /** admin ctor */
  private apiURL: string;
  private desosUser1: DESOS_Users;
  public desosuserinfo: DESOS_UserInfo = new DESOS_UserInfo();
  constructor(private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar,
    private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private authentication: AuthenticationService) {
    this.apiURL = baseUrl;
  }

  ngOnInit() {
    this.desosuserinfo =  this.authentication.getAccessToken();
    //this.getUserAccess();
    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);

    //  //alert(sessionStorage.getItem("desosuserinfo"));
    //}
    //else {
    //  this.router.navigate(['/account/login']);
    //}
   
  }

  getUserAccess() {
    this.desosUser1 = sessionStorage.loginUser;

    if (this.desosUser1 === undefined || this.desosUser1 === null) {
          this.http.get<DESOS_Users>(this.apiURL + 'api/User/LoggedInUser').subscribe(result => {
          this.desosUser1 = result;
        });
    }

    if (this.desosUser1 === undefined) {
      this.router.navigate(['/account/login']);
    }
    else {
      sessionStorage.loginUser = this.desosUser1;
    }
  }

}

