<p *ngIf="!dataSource"></p>

<div class="row col-sm-12 p-0">
  <div class="col-sm-4 pt-2">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <button mat-raised-button color="primary">Search</button>
        <!--<div class="input-group-text text-primary">Search</div>-->
      </div>
      <input type="text" (keyup)="applyFilter($event.target.value)" class="form-control" id="idSearch" placeholder="Search Here">
    </div>
  </div>
  <div class="col-sm-6 pl-3">
    <div class="input-group mb-2">
      <!--<mat-label>Select Program</mat-label>-->
      <mat-form-field class="example-full-width">
        <mat-select placeholder="Select DESOS Program" [(ngModel)]="desosuserinfo.strProgram" [disabled]="desosuserinfo.blnIsDESOSManager" (selectionChange)="onChangeProgram($event)">
          <mat-option (selectionChange)="onChangeProgram($event)" [value]="-1">
            Select All
          </mat-option>
          <mat-option *ngFor="let program of programList" (selectionChange)="onChangeProgram($event)" [value]="program.program_ID">
            {{program.program_Name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

<!--<div>
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
  </mat-form-field>
</div>-->


<div class="mat-elevation-z8 pb-1">
  <table mat-table [dataSource]="dataSource" style="width:100%" matSort>
    <!-- Position Column -->
    <ng-container matColumnDef="accRequestID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <h6 class="text-primary">Action</h6> </th>
      <td mat-cell *matCellDef="let element" class="flex-nowrap p-0" style="width:200px;padding:10px;">
        <div class="row pl-2">
          <div class="col-sm-12 p-2 example-button-row" style="width:200px">
            <button (click)="processUserRequest(element.accRequestID)" color="primary" [disabled]="element.processed_dt != ''" mat-stroked-button routerLinkActive="active">Process</button>
            <button (click)="deleteUserRequest(element.accRequestID)" color="primary" mat-stroked-button routerLinkActive="active">Delete</button>
          </div>
        </div>
      </td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="request_dt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"> <h6 class="text-primary">Requested Date </h6></th>
      <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;">{{element.request_dt}}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="processed_dt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> Processed Date </h6> </th>
      <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.processed_dt}} </td>
    </ng-container>
    <!-- Symbol Column -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"> <h6 class="text-primary">First Name </h6> </th>
      <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.firstName}} </td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> Last Name </h6> </th>
      <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.lastName}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> Email </h6> </th>
      <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.email}} </td>
    </ng-container>
    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:auto;padding:10px;"><h6 class="text-primary"> Comments </h6> </th>
      <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.comments}} </td>
    </ng-container>
    <!--<ng-container matColumnDef="ID">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
    <td mat-cell *matCellDef="let element"> <a [routerLink]="['/admin/programedit', element.accRequestID]" routerLinkActive="active">Process</a></td>
  </ng-container>-->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
<div class="pt-2">
  <app-user-list></app-user-list>
</div>
