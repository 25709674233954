<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<div class="row p-3">
  <form class="col-sm-12 p-0 shadow-lg dataentry-card dataentry-column rounded" [formGroup]="accountRequest">
    <div class="headding_account">
      <h4 class="text-md-left text-white pl-2">Account Request</h4>
    </div>
    <p *ngIf="warningmessage" class="text-danger">{{warningmessage}}</p>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width p-2">
        <mat-select placeholder="DESOS Program" formControlName="program" required>
          <mat-option *ngFor="let program of programList" [value]="program.program_ID">
            {{program.program_Name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('program', 'required')">Program is required</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width p-2">
        <input matInput placeholder="Email" name="email" formControlName="email" required>
        <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
        <mat-error *ngIf="hasError('email', 'email')">Valid Email is required</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width  p-2">
        <input matInput type="text" name="firstname" placeholder="First Name" formControlName="firstname" required>
        <mat-error *ngIf="hasError('firstname', 'required')">First Name is required</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width  p-2">
        <input matInput type="text" name="lastname" placeholder="Last Name" formControlName="lastname" required>
        <mat-error *ngIf="hasError('lastname', 'required')">Last Name is required</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width  p-2">
        <textarea matInput type="text" name="comments" placeholder="Comments" formControlName="comments"></textarea>
      </mat-form-field>
    </div>
    <div class="example-button-row  p-2">
      <button mat-raised-button color="primary" (click)="btnAccountRequestClick()" [disabled]="!accountRequest.valid">Send Account Request</button>
      <button mat-raised-button color="primary" (click)="onBack()">Back</button>
    </div>
    <div class="example-button-row  p-2">
      <button mat-button color="primary"  type="button"  [routerLink]="['/account/login']">Log In</button>
      <button mat-button color="primary" type="button"   [routerLink]="['/account/forgetpassword']">Forgot Password?</button>
      <button mat-button color="primary" type="button"   [routerLink]="['/account/generalrequest']">General request</button>
    </div>
  </form>
</div>
