import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DESOS_UserInfo } from 'src/app/account/account';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
  providers: [AuthenticationService]
})
/** admin-dashboard component*/
export class AdminDashboardComponent implements OnInit {
    /** admin-dashboard ctor */
  public todaydate: Date;
  public desosuserinfo: DESOS_UserInfo;
  public mnu1Visible: boolean = true;
  public mnu2Visible: boolean = true;
  public mnu3Visible: boolean = true;
  public mnu4Visible: boolean = true;

  constructor(private route: ActivatedRoute, private router: Router, private authentication: AuthenticationService) {
    this.todaydate = new Date();
  }

  ngOnInit() {
    //this.getUserAccess();
   // if (sessionStorage.desosuserinfo != null) {
     // this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);
      //alert(sessionStorage.getItem("desosuserinfo"));
    this.desosuserinfo = this.authentication.getAccessToken();
      if (this.desosuserinfo.blnIsDESOSManager) {
        this.mnu1Visible = false;
        this.mnu2Visible = false;
        this.mnu3Visible = true;
        this.mnu4Visible = false;
      }

      if (this.desosuserinfo.blnIsUser) {
        this.router.navigate(['/searchrecords']);
      }

    //}
    //else {
    //  this.router.navigate(['/account/login']);
    //}
  }
}
