import { Component } from '@angular/core';

@Component({
    selector: 'app-adminmenu',
    templateUrl: './adminmenu.component.html',
    styleUrls: ['./adminmenu.component.css']
})
/** adminmenu component*/
export class AdminmenuComponent {
    /** adminmenu ctor */
    constructor() {

    }
}