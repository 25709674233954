import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { DESOS_Individuals, DESOS_Outcomes, DESOS_RosterData, DESOS_StrRosterResult, DESOS_StrResult, DESOS_Search_Fields, DESOS_Records_List } from '../dataentry/dataentry';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { DefaultService } from './default.service';
import { AuthenticationService } from './authentication.service';
import { DESOS_Programs_List } from '../admin/program';
import { DESOS_Cities_List, DESOS_Counties_List, DESOS_Genders_List, DESOS_Races_List, DESOS_Month_List, DESOS_Period_List } from '../search/default';

@Injectable({ providedIn: 'root' })
export class DataentryService {
  private apiURL: string;
  private token: string;
  private strResults: DESOS_StrResult;
  private curRosterData: DESOS_RosterData;
  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient, private APIdefaults: DefaultService, private authentication: AuthenticationService) {
    this.apiURL = baseUrl;
    //this.GetAccessToken();
  }

  getHeader() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.token })
    };
    return httpOptions;
  }

  getUserHeader() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache',
        'Authorization': this.authentication.getUserAccessToken()
      })
    };
    return httpOptions;
  }

  getUserHeaderOption(inparam: HttpParams) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache',
        'Authorization': this.authentication.getUserAccessToken()
      }),
      params: inparam
    };
    return httpOptions;
  }

  InsertRosterEntry(inIndividuals: DESOS_Individuals, inOutcomes: DESOS_Outcomes) {

    this.curRosterData = new DESOS_RosterData();
    this.curRosterData.Individuals = inIndividuals;
    this.curRosterData.Outcomes = inOutcomes;
    return this.http.post<DESOS_StrRosterResult>(this.apiURL + 'api/DataEntry/InsertRosterEntry', this.curRosterData, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrRosterResult>('intsert roster entries'))
    );
  }

  UpdateRosterEntry(inIndividuals: DESOS_Individuals, inOutcomes: DESOS_Outcomes): Observable<DESOS_StrRosterResult> {

    this.curRosterData = new DESOS_RosterData();
    this.curRosterData.Individuals = inIndividuals;
    this.curRosterData.Outcomes = inOutcomes;
    return this.http.post<DESOS_StrRosterResult>(this.apiURL + 'api/DataEntry/UpdateRosterEntry', this.curRosterData, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrRosterResult>('update roster entries'))
    );
  }

  CopyRosterEntry(inIndividuals: DESOS_Individuals, inOutcomes: DESOS_Outcomes) {

    this.curRosterData = new DESOS_RosterData();
    this.curRosterData.Individuals = inIndividuals;
    this.curRosterData.Outcomes = inOutcomes;

    return this.http.post<DESOS_StrRosterResult>(this.apiURL + 'api/DataEntry/CopyRosterEntry', this.curRosterData, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrRosterResult>('copy roster entries'))
    );
  }
  
  getProgramList(): Observable<DESOS_Programs_List[]> {
    return this.http.get<DESOS_Programs_List[]>(this.apiURL + 'api/Program/GetProgramSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Programs_List[]>('get program list'))
    );
  }

  getCitiesList(): Observable<DESOS_Cities_List[]> {
    return this.http.get<DESOS_Cities_List[]>(this.apiURL + 'api/DefaultData/GetCitiesSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Cities_List[]>('get cities list'))
    );
  }

  getCountiesList(): Observable<DESOS_Counties_List[]> {
    return this.http.get<DESOS_Counties_List[]>(this.apiURL + 'api/DefaultData/GetCountiesSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Counties_List[]>('get counties list'))
    );
  }

  getGendersList(): Observable<DESOS_Genders_List[]> {
    return this.http.get<DESOS_Genders_List[]>(this.apiURL + 'api/DefaultData/GetGendersSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Genders_List[]>('get genders list'))
    );
  }

  getRacesList(): Observable<DESOS_Races_List[]> {
    return this.http.get<DESOS_Races_List[]>(this.apiURL + 'api/DefaultData/GetRacesSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Races_List[]>('get race list'))
    );
  }

  getMonthList(): Observable<DESOS_Month_List[]> {
    return this.http.get<DESOS_Month_List[]>(this.apiURL + 'api/DefaultData/GetMonthSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Month_List[]>('get month list'))
    );
  }

  getReportingPeriodList(): Observable<DESOS_Period_List[]> {
    return this.http.get<DESOS_Period_List[]>(this.apiURL + 'api/DefaultData/GetReportingPeriodSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Period_List[]>('get reporting period list'))
    );
  }

  getCompletedReportingPeriodList(): Observable<DESOS_Period_List[]> {
    return this.http.get<DESOS_Period_List[]>(this.apiURL + 'api/DefaultData/GetCompletedReportingPeriodList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Period_List[]>('get completed reporting period list'))
    );
  }

  getRosterList(inSearchFields: DESOS_Search_Fields): Observable<DESOS_Records_List[]> {
    return this.http.post<DESOS_Records_List[]>(this.apiURL + 'api/DataEntry/GetRosterList', inSearchFields, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Records_List[]>('get roster list'))
    );
  }

 getCurrentRecordList(inSearchFields: DESOS_Search_Fields): Observable<DESOS_Records_List[]> {
    return this.http.post<DESOS_Records_List[]>(this.apiURL + 'api/DataEntry/GetCurrentRecordList', inSearchFields, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Records_List[]>('get roster list'))
    );
  }

  getRecordListById(inparam: HttpParams): Observable<DESOS_Records_List[]> {
    return this.http.get<DESOS_Records_List[]>(this.apiURL + 'api/DataEntry/GetRecordListById', this.getUserHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_Records_List[]>('get roster list'))
    );
  }

  getRecordListByIndividual(inparam: HttpParams): Observable<DESOS_Records_List[]> {
    return this.http.get<DESOS_Records_List[]>(this.apiURL + 'api/DataEntry/GetRecordListByIndId', this.getUserHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_Records_List[]>('get roster list'))
    );
  }

  getIndivitualById(inparam: HttpParams): Observable<DESOS_Individuals> {
    return this.http.get<DESOS_Individuals>(this.apiURL + 'api/DataEntry/GetIndividual', this.getUserHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_Individuals>('get Indivitual'))
    );
  }

  getOutcomeById(inparam: HttpParams): Observable<DESOS_Outcomes> {
    return this.http.get<DESOS_Outcomes>(this.apiURL + 'api/DataEntry/GetDESOSOutcome', this.getUserHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_Outcomes>('get Outcome'))
    );
  }
  getQuestionWithOptions(inparam: HttpParams): Observable<any> {
    return this.http.get<any>(this.apiURL + 'api/DataEntry/GetQuestionWithOptions', this.getUserHeaderOption(inparam)).pipe(
      catchError(this.handleError<any>('get Outcome'))
    );
  }
  GetAccessToken() {    
    this.APIdefaults.getToken().subscribe((result) => {
      this.strResults = JSON.parse(JSON.stringify(result));
      if (this.strResults != null) {
        if (this.strResults.strOutput != "") {
          this.token = this.strResults.strOutput;
        }
      }
      else {
        this.token = "";
      }
      });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of (result as T);
    };
  }

  private log(message: string) {
    console.error(message);
   // this.messageService.add(`HeroService: ${message}`);
  }
}
