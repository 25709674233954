export class DESOS_Cities_List {
  public id: string;
  public city: string;
}

export class DESOS_Counties_List {
  public id: string;//{ get; set; }
  public county: string;//{ get; set; }
}

export class DESOS_Genders_List {
  public id: string;//{ get; set; }
  public gender: string;//{ get; set; }
}

export class DESOS_Races_List {
  public id: string;//{ get; set; }
  public race: string;//{ get; set; }
}

export class DESOS_Month_List {
  public id: string;//{ get; set; }
  public month: string;//{ get; set; }
}

export class DESOS_Period_List {
  public id: number;//{ get; set; }
  public mm: string;//{ get; set; }
  public dd: string;//{ get; set; }
  public description: string;//{ get; set; }
  public completedate: string;//{ get; set; }
  public active: string;//{ get; set; }
  public period: string;//{ get; set; }
}

export class DESOS_State_List {
  public id: string;//{ get; set; }
  public state: string;//{ get; set; }
}

export class DESOS_Reporting_Periods {
  public id: number;//{ get; set; }
  public mm: string;//{ get; set; }
  public dd: string;//{ get; set; }
  public description: string;//{ get; set; }
  public completedate: string;//{ get; set; }
  public active: string;//{ get; set; }
  public isannual: string;//{ get; set; }
}
