
export class dataentry_tab2_quest1
{
  public questionText: string = "";
  public chkIDorDDS: boolean = false;
  public isIDorDDSelected: boolean = false;
  public optTypeSelected: string = "";
  public optDDDSelected: string = "";
}

export class dataentry_tab2_quest2 {
  public questionText: string = "";
  public chkIDorDDS: boolean = false;
  public isIDorDDSelected: boolean = false;
  public optTypeSelected: string = "";
  public optDDDSelected1: string = "";
  public optNoneSelected: boolean = false;
  public numNoneSelected: string = "";
}

export class dataentry_tab2_quest3_4_5 {
  public question3Text: string = "";
  public question4Text: string = "";
  public question5Text: string = "";
  public optquestion3Selected: string = "";
  public optquestion4Selected: string = "";
  public optquestion5Selected: string = "";
}
