

export class userLogin {
  public username: string;
  public password: string;
}

export class DESOS_Account_Request {
  public accRequestID: string;
  public programID: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public comments: string;
 }

export class DESOS_Account_RequestList {
  public accRequestID: string;
  public programID: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public comments: string;
  public Processed_dt: string;
  public Request_dt: string;
  public process: string;
  public delete: string;
}

export class DESOS_UserInfo {
  public strUserName: string = "";
  public strFirstName: string = "";
  public strLastName: string = "";
  public strAccessRole: string = "";
  public strProgram: string = "";
  public blnDESOSEntry: boolean = false;
  public blnDESOSEdit: boolean = false;
  public blnDESOSReport: boolean = false;
  public blnIsAdmin: boolean = false;
  public blnIsDESOSManager: boolean = false;
  public blnIsUser: boolean = false;
  public passwordReset: number = 0;
  public token: string = "";
}
