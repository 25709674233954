import { Component, OnInit, Inject } from '@angular/core';
import { DESOS_UserInfo } from '../account/account';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  providers: [AuthenticationService]
})
/** reports component*/
export class ReportsComponent implements OnInit {
    /** reports ctor */
  private apiURL: string;
  public desosuserinfo: DESOS_UserInfo;
  constructor(private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar, private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string, private authentication: AuthenticationService) {

  }

  ngOnInit() {
    //this.getUserAccess();
    this.desosuserinfo = this.authentication.getAccessToken();
    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);

    //  //alert(sessionStorage.getItem("desosuserinfo"));
    //}
    //else {
    //  this.router.navigate(['/account/login']);
    //}

  }
}
