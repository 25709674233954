import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DESOS_Programs_List, User } from '../program';
import { DESOS_StrResult } from 'src/app/dataentry/dataentry';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';


@Component({
    selector: 'app-user-add',
    templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css'],
  providers: [UserService]
})
/** user-add component*/
export class UserAddComponent implements OnInit{
    /** user-add ctor */
  public programList: DESOS_Programs_List[];
  public accountRequest: FormGroup;
  private newuser: User;
  private tmpNewUser: tmpUser;
  private apiURL: string;
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  private asyncResult: DESOS_StrResult;
  constructor(private location: Location, private route: ActivatedRoute, private router: Router,
    private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private matDialog: MatDialog, private APIServices: UserService) {
      this.apiURL = baseUrl;
      //http.get<DESOS_Programs_List[]>(baseUrl + 'api/DefaultData/GetProgramSelectList').subscribe(result => {
     
    this.APIServices.getProgramList().subscribe(result => {
      this.programList = result;
    }, error => console.error(error));
  }

  ngOnInit() {
    this.accountRequest = new FormGroup({
      program: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required, Validators.email]),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required])
    });
  }

  hasError = (controlName: string, errorName: string) => {
    return this.accountRequest.controls[controlName].hasError(errorName);
  }


  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  btnSaveClick() {
      //alert(JSON.stringify(this.accountRequest.value));
      //this.tmpNewUser = this.accountRequest.value;
    this.showProgressSpinner(this.createUserAccount());
  }

  createUserAccount() {
    return Observable.create(observer => {
      this.newuser = new User();
      this.newuser.program = this.accountRequest.controls["program"].value;
      this.newuser.firstname = this.accountRequest.controls["firstname"].value;
      this.newuser.lastname = this.accountRequest.controls["lastname"].value;
      this.newuser.username = this.accountRequest.controls["username"].value;

      //this.http.post<DESOS_StrResult>(this.apiURL + 'api/User/NewUserAccount', this.newuser)
      this.APIServices.addUser(this.newuser)
        .subscribe((result) => {
          this.asyncResult = JSON.parse(JSON.stringify(result));
          if (this.asyncResult != null) {
            if (this.asyncResult.strOutput == "Success") {
              alert("User added successfully!");
              observer.next("User added successfully!");
              observer.complete();
              this.location.back();
            }
            else if (this.asyncResult.strOutput == "Exist") {
              alert("User already exist!");
              observer.next("User already exist!");
              observer.complete();
            }
            else {
              alert("Error while creating a account. Please contact DESOS administrator.");
              observer.next(this.asyncResult.strOutput);
              observer.complete();
            }
          }
        });
    });
  }

  public btnBackClick = () => {
    this.location.back();
  }


}

export class tmpUser {
  program: string = "";
  username: string ="";
  firstname: string = "";
  lastname: string = "";
}
