import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DESOS_Email_Template } from '../program';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { UserService } from 'src/app/services/user.service';
import { DESOS_StrResult } from 'src/app/dataentry/dataentry';

@Component({
    selector: 'app-email-template',
    templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css'],
  providers: [UserService],
  encapsulation: ViewEncapsulation.None,
})


/** email-template component*/
export class EmailTemplateComponent implements OnInit{
    /** email-template ctor */
  public emailTemplate: FormGroup;
  private desosResult: DESOS_StrResult;
  public templateList: DESOS_Email_Template[];
  public asyncResults: DESOS_Email_Template[];
  private myControl = new FormControl();
  public template: DESOS_Email_Template = new DESOS_Email_Template();
  public asyncResult: DESOS_Email_Template;
  private templateid: string = "";
  filteredOptions: Observable<DESOS_Email_Template[]>;
  public warningmessage: string = "";
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  public htmlContent = ''
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    translate: 'no',
    defaultParagraphSeparator: 'br'
  };
  public messageText: string = "The fields that can be used in subject or email content are given here." +
    "User_Name, User_Password, First_Name, Last_Name, Email, Comments, Program_Name, Executive_Director_Name, Executive_Director_Email, DESOS_Manager_Name, DESOS_Manager_Email, URL" +
     " example1: Email From Director Executive_Director_Name"
      " example2: Please click this link URL";
  private apiURL: string;
  constructor(private location: Location, private route: ActivatedRoute, private router: Router,
    private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private matDialog: MatDialog, private APIService: UserService) {
    this.apiURL = baseUrl;
    //this.getAsyncData();
    //let observable = new Observable(this.myObservableTwo);
    //this.showProgressSpinner(observable);
  }

  hasError = (controlName: string, errorName: string) => {
    return this.emailTemplate.controls[controlName].hasError(errorName);
  }

  displayFn(user?: DESOS_Email_Template): string | undefined {
    return user ? user.template_Name : undefined;
  }

  private _filter(name: string): DESOS_Email_Template[] {
    const filterValue = name.toLowerCase();
    return this.templateList.filter(templateList => templateList.template_Name.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit() {
    this.emailTemplate = new FormGroup({
      templatename: new FormControl('', [Validators.required]),
      subject: new FormControl('', [Validators.required]),
      emailcontent: new FormControl('', [Validators.required,Validators.maxLength(8000)]),
      active: new FormControl('')
    });
    this.warningmessage = "";

    //this.filteredOptions = this.myControl.valueChanges
    //  .pipe(
    //  startWith<string | DESOS_Email_Template>(''),
    //  map(value => typeof value === 'string' ? value : value.template_Name),
    //  map(template_Name => template_Name ? this._filter(template_Name) : this.templateList.slice())
    //  );
  }

  btnBackClick() {
    this.location.back();
  }


  async getAsyncData() {

    this.asyncResults = await this.http.get<DESOS_Email_Template[]>(this.apiURL + 'api/Program/GetEmailTemplateList').toPromise();
    if (this.asyncResults != null) {
      this.templateList = JSON.parse(JSON.stringify(this.asyncResults));
    }
    else {
      this.template = new DESOS_Email_Template();
      this.template.id = "";
    }
  };

  //async getAsyncPost() {

  //  await this.http.post(this.apiURL + 'api/Program/UpdateEmailTemplate', this.template).toPromise();
  //  //, error => console.error(error));
  //};

  onChangeProgram(event: MatSelectChange) {
    this.warningmessage = "";
    let inparams = new HttpParams()
        .set('id', event.value);
      this.getAsyncTemplate(inparams);
    let observable = new Observable(this.myObservableTwo);
    this.showProgressSpinner(observable);
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("Login"));
  }

  myObservableTwo(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 2000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  async getAsyncTemplate(inparam: HttpParams) {

    //this.asyncResult = await this.http.get<DESOS_Email_Template>(this.apiURL + 'api/Program/GetEmailTemplate', { params: inparam }).toPromise();
    this.asyncResult = await this.APIService.getEmailTemplate(inparam).toPromise();
    if (this.asyncResult != null) {
      this.template = JSON.parse(JSON.stringify(this.asyncResult));
      //this.accountVerification.controls["managerprogram"].setValue(this.verificationEdit.program_Name);
      this.emailTemplate.controls["subject"].setValue(this.template.subject);
      this.emailTemplate.controls["emailcontent"].setValue(this.template.email_Content);
     // this.emailTemplate.controls["active"].setValue(this.template.active_flag == "1"?true:false);
      this.htmlContent = this.template.email_Content;
    }
    else {
      this.template = new DESOS_Email_Template();
      this.template.id = "";
      this.emailTemplate.controls["subject"].setValue("");
      this.emailTemplate.controls["emailcontent"].setValue("");
    }
  };

  btnChangeSubmit() {

   // let observable = new Observable(this.myObservableTwo);
   // this.showProgressSpinner(observable);

    this.template.template_Name = this.emailTemplate.controls["templatename"].value;
    this.template.subject = this.emailTemplate.controls["subject"].value;
    this.template.email_Content = this.emailTemplate.controls["emailcontent"].value;
    //this.template.active_flag = this.getCheckBoxValue(this.emailTemplate.controls["active"].value).toString();

    this.showProgressSpinner(this.UpdateEmailTemplate(this.template));
    //this.http.post(this.apiURL + 'api/Program/UpdateEmailTemplate', this.template).subscribe(result => {
    //  var result = result;
    //  this.warningmessage = "Email Template updated successfully.";
    //}, error => console.error(error));

    //this.location.back();

   // this.getAsyncPost();
   // this.getAsyncData();
   // this.loadPage();
  }


  UpdateEmailTemplate(inEmailTemplate: DESOS_Email_Template) {
    return Observable.create(observer => {
      this.APIService.updateEmailTemplate(inEmailTemplate).subscribe((result) => {
        this.desosResult = JSON.parse(JSON.stringify(result));
        if (this.desosResult != null) {
         
          if (this.desosResult.strOutput == "Success") {
            this.warningmessage = "Email Template updated successfully.";
            observer.next("Email Template updated successfully.");
            observer.complete();
          }
          else {
            this.warningmessage = "Error: " + this.desosResult.strOutput;
            observer.next("Email Template updated failed.");
            observer.complete();
          }

        }
        else {
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
      });
    });
  }

  getCheckBoxValue(strValue: string): number {
    var blnResults: number = 0;
    if (strValue == "true" || strValue == "1") {
      blnResults = 1;
    }
    else if (strValue == "false" || strValue == "0") {
      blnResults = 0;
    }
    return blnResults
  }

  loadPage() {
    this.emailTemplate.controls["templatename"].setValue('');
    this.emailTemplate.controls["subject"].setValue('');
    this.emailTemplate.controls["emailcontent"].setValue('');
    this.emailTemplate.controls["active"].setValue('');
  }
}
