<div class="example-button-row text-center p-2">
  <button mat-raised-button color="primary" (click)="btnBackClick()">Back</button>
  <button mat-raised-button color="primary" (click)="btnPrintClick()">Print</button>
</div>
<div id="printRPT1-old" class="container justify-content-center" style="padding: 20px 0;">
  <p  class="container justify-content-center text-center" *ngIf="isEmptyOrError">No Data was found.</p>
  <div style="min-height:400px;">
    <mat-accordion *ngIf="!isCustom; else showCustom">
      <mat-expansion-panel *ngFor="let dS of dataSource; let d = index">
        <mat-expansion-panel-header>
          <mat-panel-title id="header-{{titleList[d]}}">
            {{titleHeaders[titleList[d]]}}
          </mat-panel-title>
          <mat-panel-description>
            Click to expand
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!--<h4 class="">{{titleHeaders[titleList[d]]}}</h4>-->
        <table mat-table [dataSource]="dS" matSort class="w-100" style="height:fit-content;" id="table-{{titleList[d]}}">

          <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
          <!-- Position Column -->
          <ng-container matColumnDef="Item">
            <th mat-header-cell *matHeaderCellDef style="width:auto;padding:10px;"> Element </th>
            <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.Item.trim()}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="Count">
            <th mat-header-cell *matHeaderCellDef style="width:auto;padding:10px;"> Count </th>
            <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.Count.toFixed(2)}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container *ngIf="showPColumn[d]" matColumnDef="Percentage">
            <th mat-header-cell *matHeaderCellDef style="width:auto;padding:10px;"> Percentage </th>
            <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.Percentage}}% </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="(showPColumn[d]) ? displayedColumns : ['Item', 'Count']" style="width:100px;border-bottom:1px;"></tr>
          <tr mat-row *matRowDef="let row; columns: (showPColumn[d]) ? displayedColumns : ['Item', 'Count'];" style="width:100px;border-bottom:1px;"></tr>
        </table>
      </mat-expansion-panel>
    </mat-accordion>

    <!--SHOW THIS-->

    <ng-template #showCustom>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title id="header-customReport">
              {{titleList['customReport']}}
            </mat-panel-title>
            <mat-panel-description>
              Click to expand
            </mat-panel-description>
          </mat-expansion-panel-header>
            <table mat-table [dataSource]="dataSource[0]" matSort class="w-100" style="height:fit-content;" id="table-customReport">
              <ng-container matColumnDef="Item">
                <th mat-header-cell *matHeaderCellDef style="width:auto;padding:10px;"> Element </th>
                <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.Item.trim()}} </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="Count">
                <th mat-header-cell *matHeaderCellDef style="width:auto;padding:10px;"> Count </th>
                <td mat-cell *matCellDef="let element" style="width:auto;padding:10px;"> {{element.Count.toFixed(2)}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="(showPColumn[d]) ? displayedColumns : ['Item', 'Count']" style="width:100px;border-bottom:1px;"></tr>
              <tr mat-row *matRowDef="let row; columns: (showPColumn[d]) ? displayedColumns : ['Item', 'Count'];" style="width:100px;border-bottom:1px;"></tr>
            </table>

        </mat-expansion-panel>
      </mat-accordion>

    </ng-template>
  </div>
</div>
