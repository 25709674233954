<form [formGroup]="tab7FormGroup">
  <ng-template matStepLabel>18 To 20</ng-template>
  <div class="row">
    <div class="col-sm-6 dataentry-column">
      <mat-card class="dataentry-card">
        <div class="row p-0">
          <div *ngIf="questionData['18-0']; else loading" class="col-sm-3 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['18-0']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_38}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['18-1'] && questionData['18-2']; else loading" class="col-sm-9 p-0">
            <section>
              <mat-form-field class="mat-input" [style.width.px]="100">
                <input matInput placeholder="Person" formControlName="q20val1" type="number">
              </mat-form-field>
              <mat-label class="text-nowrap" style="font-size:12px;"><span [innerHTML]="questionData['18-1']?.question?.content"></span></mat-label>
            </section>
            <section>
              <mat-form-field class="mat-input" [style.width.px]="100">
                <input matInput placeholder="Person" formControlName="q20val2" type="number">
              </mat-form-field>
              <mat-label class="text-nowrap" style="font-size:12px;"><span [innerHTML]="questionData['18-2']?.question?.content"></span></mat-label>
            </section>
            <mat-error class="error_warning" *ngIf="hasError('q20val1', 'max')">Please enter number 1 to 99</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q20val1', 'min')">Please enter number 1 to 99</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q20val2', 'max')">Please enter number 1 to 99</mat-error>
            <mat-error class="error_warning" *ngIf="hasError('q20val2', 'min')">Please enter number 1 to 99</mat-error>
          </div>
        </div>
      </mat-card>
      <mat-card class="dataentry-card">
        <div class="row p-0 pl-1">
          <div *ngIf="questionData['19']; else loading" class="col-sm-3 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['19']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_39}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['19']; else loading" class="col-sm-9 p-0">
            <div class="row p-0 pl-2">
              <div *ngFor="let chunk of questionData['19'].options | chunkArray:q19ChunkNum" class="col-sm-6 p-0">
                <section class="data-radio-group">
                  <mat-checkbox *ngFor="let option of chunk"
                                [ngModelOptions]="{standalone: true}"
                                color="primary"
                                class="data-radio-button"
                                [checked]="DEtab7Quest18to20['chk' + option.optionId + 'question19Selected']"
                                name="'chk' + option.optionId + 'question19Selected'"
                                [(ngModel)]="DEtab7Quest18to20['chk' + option.optionId + 'question19Selected']"
                                (change)="onChangeQuest19($event)">
                    <span [innerHTML]="option.content"></span>
                  </mat-checkbox>
                </section>
              </div>
            </div>
            <section class="col-sm-12" [hidden]="!DEtab7Quest18to20.chk8question19Selected" name="chk87question19Selected">
              <mat-form-field class="mat-input col-sm-12">
                <input matInput placeholder="Other Descriptions" formControlName="q21subval" maxlength="128">
              </mat-form-field>
            </section>
            <mat-error class="error_warning" *ngIf="hasError('q21val', 'required')">Please check all that apply</mat-error>
          </div>
          <!--<div class="col-sm-3 p-0">
            <mat-label class="text-primary question_text">19. Transportation*<br />(check all that apply)</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_39}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-9 p-0">
            <div class="row p-0 pl-2">
              <div class="col-sm-6 p-0">
                <section class="data-radio-group">
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab7Quest18to20.chk1question19Selected" name="chk1question19Selected" [(ngModel)]="DEtab7Quest18to20.chk1question19Selected" (change)="onChangeQuest19($event)">Walks/bikes</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab7Quest18to20.chk2question19Selected" name="chk2question19Selected" [(ngModel)]="DEtab7Quest18to20.chk2question19Selected" (change)="onChangeQuest19($event)">Drives</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab7Quest18to20.chk3question19Selected" name="chk3question19Selected" [(ngModel)]="DEtab7Quest18to20.chk3question19Selected" (change)="onChangeQuest19($event)">Public transportation</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab7Quest18to20.chk4question19Selected" name="chk4question19Selected" [(ngModel)]="DEtab7Quest18to20.chk4question19Selected" (change)="onChangeQuest19($event)">Vocational program provides</mat-checkbox>

                </section>
              </div>
              <div class="col-sm-6 p-0">
                <section class="data-radio-group">
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab7Quest18to20.chk5question19Selected" name="chk5question19Selected" [(ngModel)]="DEtab7Quest18to20.chk5question19Selected" (change)="onChangeQuest19($event)">Residential program provides</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab7Quest18to20.chk6question19Selected" name="chk6question19Selected" [(ngModel)]="DEtab7Quest18to20.chk6question19Selected" (change)="onChangeQuest19($event)">Friends/coworkers/family </mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab7Quest18to20.chk7question19Selected" name="chk7question19Selected" [(ngModel)]="DEtab7Quest18to20.chk7question19Selected" (change)="onChangeQuest19($event)">Taxi or Rideshare service</mat-checkbox>
                  <mat-checkbox color="primary" class="data-radio-button" [ngModelOptions]="{standalone: true}" [checked]="DEtab7Quest18to20.chk8question19Selected" name="chk8question19Selected" [(ngModel)]="DEtab7Quest18to20.chk8question19Selected" (change)="onChangeQuest19($event)">Other</mat-checkbox>
                </section>
              </div>
            </div>
            <section class="col-sm-12" [hidden]="!DEtab7Quest18to20.chk8question19Selected" name="chk87question19Selected">
              <mat-form-field class="mat-input col-sm-12">
                <input matInput placeholder="Other Descriptions" formControlName="q21subval" maxlength="128">
              </mat-form-field>
            </section>
            <mat-error class="error_warning" *ngIf="hasError('q21val', 'required')">Please check all that apply</mat-error>
          </div>-->
        </div>
      </mat-card>
    </div>
    <div class="col-sm-6 dataentry-column">
      <mat-card class="dataentry-card">
        <div class="row p-0 pl-1">
          <div *ngIf="questionData['20']; else loading" class="col-sm-6 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['20']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_40}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['20']; else loading" class="col-sm-6 p-0">
            <section>
              <mat-radio-group class="data-radio-group" formControlName="q22val">
                <mat-radio-button *ngFor="let option of questionData['20'].options" [value]="''+option.optionId" color="primary" class="data-radio-button" (change)="onChangeQuest20($event)">
                  <span [innerHTML]="option.content"></span>
                </mat-radio-button>
              </mat-radio-group>
              <mat-error class="error_warning" *ngIf="hasError('q22val', 'required')">Please select any one</mat-error>
            </section>
          </div>
          <!--<div class="col-sm-6 p-0">
            <mat-label class="text-primary question_text">20. DID the individual spend any time in a secondary environment?*</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_40}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-6 p-0">
            <section>
              <mat-radio-group class="data-radio-group" formControlName="q22val">
                <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest20($event)" value="0">No</mat-radio-button>
                <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest20($event)" value="1">Yes</mat-radio-button>
              </mat-radio-group>
            </section>
            <mat-error class="error_warning" *ngIf="hasError('q22val', 'required')">Please select any one</mat-error>
          </div>-->
        </div>
      </mat-card>
    </div>
  </div>
  <div class="example-button-row text-center">
    <button mat-raised-button matStepperPrevious color="primary">Back</button>
    <button mat-raised-button matStepperNext *ngIf="((tab7FormGroup.get('q22val').value === '1' && (primary != 5)) ? true:false)" [disabled]="!tab7FormGroup.valid" color="primary">Next</button>
  </div>
</form>

<ng-template #loading>
  <p>Loading question and options...</p>
</ng-template>
