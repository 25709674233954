import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { helpText, DESOS_Individuals, DESOS_Outcomes } from '../dataentry';
import { dataentry_tab4_quest2to7 } from '../dataentry-tab4';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TempDataService } from 'src/app/services/temp-data.service';
import { DataentryService } from '../../services/dataentry.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DESOS_UserInfo } from 'src/app/account/account';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-dataentry-tab4',
  templateUrl: './dataentry-tab4.component.html',
  styleUrls: ['./dataentry-tab4.component.css'],
  providers: [AuthenticationService, TempDataService],
})
/** dataentry-tab4 component*/
export class DataentryTab4Component implements OnInit, AfterViewInit {

  public desosuserinfo: DESOS_UserInfo;
  questionData: any;

  q2ChunkNum: number = 3;
  q7ChunkNum: number = 6;

  helpMessage: helpText;
  public DEtab4Quest2to7: dataentry_tab4_quest2to7;
  /** dataentry-tab4 ctor */
  public isDisable2: boolean = false;
  public isDisable3: boolean = false;
  public isDisable4: boolean = false;
  public isDisable5: boolean = false;
  public isDisable6: boolean = false;
  public isDisable7: boolean = false;
  public primary: number;
  private dobPattern: string = "^(\([0-9]{2}\))$";
  private curEditOutComes: DESOS_Outcomes;
  public tab4FormGroup: FormGroup;
  constructor(private snackBar: MatSnackBar, private tempdata: TempDataService, private APIservices: DataentryService, private authentication: AuthenticationService) {
    this.helpMessage = new helpText();
    this.DEtab4Quest2to7 = new dataentry_tab4_quest2to7();
  }

  ngOnInit() {

    //if (sessionStorage.editoutcomes != null) {
    //  this.curEditOutComes = JSON.parse(sessionStorage.editoutcomes);
    //}
    this.desosuserinfo = this.authentication.getAccessToken();

    this.tab4FormGroup = new FormGroup({
      communication: new FormControl(''),
      q3val: new FormControl('', [Validators.required]),
      q3subval: new FormControl(''),
      q4val: new FormControl('', [Validators.required]),
      q5valchk: new FormControl(),
      q5val: new FormControl(''),
      q5valm: new FormControl('', [Validators.required, Validators.pattern(this.dobPattern)]),
      q5valy: new FormControl('', [Validators.required, Validators.pattern(this.dobPattern)]),
      q6val: new FormControl('', [Validators.required]),
      q7val: new FormControl('', [Validators.required]),
      q8val: new FormControl('', [Validators.required]),
      q8subval: new FormControl(''),
    });

    this.fetchQuestionWithOptions();
  }

  async fetchQuestionWithOptions() {
    try {
      // Define the range of question identifiers
      const startQuestionIndex = 2;
      const endQuestionIndex = 7;
      const questionPrefix = '';

      // Initialize an object to hold the data for each question
      this.questionData = {};

      // Fetch questions within the range
      for (let questionIndex = startQuestionIndex; questionIndex <= endQuestionIndex; questionIndex++) {
        const questionNumber = `${questionPrefix}${questionIndex}`;
        const inparam = new HttpParams().set('questionNumber', questionNumber);
        this.questionData[questionNumber] = await this.APIservices.getQuestionWithOptions(inparam).toPromise();
      }      

      // Check if data for all questions is retrieved
      const allDataReceived = Object.keys(this.questionData).length >= (endQuestionIndex - startQuestionIndex + 1) &&
        Object.values(this.questionData).every(data => !!data);

      if (allDataReceived) {
        console.log('Data retrieved:', this.questionData[`${questionPrefix}${endQuestionIndex}`]); // Example log for the first question
        this.curEditOutComes = this.tempdata.getCurrentoutcome();
        console.log('curEditOutComes:', this.curEditOutComes);

        this.initFormGroup();
        this.q1AnswerDependent();

      } else {
        console.log('No data received');
      }
    } catch (error) {
      console.error('Failed to fetch question options:', error);
    }
  };

  ngAfterViewInit() {
    //console.log("Tab4: ", this.curEditOutComes);
    //this.q1AnswerDependent();
  }


  q1AnswerDependent() {
    if (this.tempdata.getRosterAction() != "new") {

      console.log("Tab4 Update: ", this.curEditOutComes);

      if (this.curEditOutComes.q1val == "5") {
        this.disableA(true);
        this.disableB(true);
      }
      if (this.curEditOutComes.q1val == "4") {
        this.disableA(true);
      }
    }
  }

  initFormGroup() {
    this.DEtab4Quest2to7.optquestion2Selected = this.curEditOutComes.q3val;
    this.tab4FormGroup.controls["q3val"].setValue(this.curEditOutComes.q3val);
    this.DEtab4Quest2to7.optquestion2Other = (this.DEtab4Quest2to7.optquestion2Selected == "6" ? true : false);
    this.DEtab4Quest2to7.optquestion2Selected = this.curEditOutComes.q3subval;
    this.tab4FormGroup.controls["q3subval"].setValue(this.curEditOutComes.q3subval);

    this.DEtab4Quest2to7.optquestion3Selected = this.curEditOutComes.q4val;
    this.tab4FormGroup.controls["q4val"].setValue(this.curEditOutComes.q4val);
    this.DEtab4Quest2to7.chkquestion4Selected = (this.curEditOutComes.q5val == "N/A" || this.curEditOutComes.q5val == "n/a" ? true : false);
    this.tab4FormGroup.controls["q5valchk"].setValue(this.DEtab4Quest2to7.chkquestion4Selected);
    if (!this.DEtab4Quest2to7.chkquestion4Selected) {
      this.tab4FormGroup.controls["q5valm"].enable();
      this.tab4FormGroup.controls["q5valy"].enable();
      this.DEtab4Quest2to7.strquestion4Month = this.curEditOutComes.q5val.substr(0, 2);
      this.tab4FormGroup.controls["q5valm"].setValue(this.curEditOutComes.q5val.substr(0, 2));
      this.DEtab4Quest2to7.strquestion4Year = this.curEditOutComes.q5val.substr(2, 2);
      this.tab4FormGroup.controls["q5valy"].setValue(this.curEditOutComes.q5val.substr(2, 2));

    }
    else {
      this.tab4FormGroup.controls["q5valm"].setValidators(null);
      this.tab4FormGroup.controls["q5valy"].setValidators(null);
      this.tab4FormGroup.controls["q5valm"].updateValueAndValidity();
      this.tab4FormGroup.controls["q5valy"].updateValueAndValidity();
      this.tab4FormGroup.controls["q5valm"].disable();
      this.tab4FormGroup.controls["q5valy"].disable();
    }

    this.DEtab4Quest2to7.optquestion5Selected = this.curEditOutComes.q6val;
    this.tab4FormGroup.controls["q6val"].setValue(this.curEditOutComes.q6val);
    this.DEtab4Quest2to7.optquestion6Selected = this.curEditOutComes.q7val;
    this.tab4FormGroup.controls["q7val"].setValue(this.curEditOutComes.q7val);

    this.DEtab4Quest2to7.optquestion7Selected = this.curEditOutComes.q8val;
    this.tab4FormGroup.controls["q8val"].setValue(this.curEditOutComes.q8val);
    this.DEtab4Quest2to7.optquestion7Other = (this.DEtab4Quest2to7.optquestion7Selected == "11" ? true : false);
    this.DEtab4Quest2to7.strquestion7Other = this.curEditOutComes.q8subval;
    this.tab4FormGroup.controls["q8subval"].setValue(this.curEditOutComes.q8subval);

    if (this.curEditOutComes.q1val == "3") {
      this.tab4FormGroup.controls["q4val"].disable();
      this.isDisable3 = true;
    }
    else {
      this.tab4FormGroup.controls["q4val"].enable();
      this.isDisable3 = false;
    }

    this.primary = parseInt(this.curEditOutComes.q1val);

    console.log("Form inited");
    console.log(this.isDisable3);
    console.log(this.isDisable4);
    console.log(this.isDisable5);
    console.log(this.isDisable6);
  }

  getEntries(curNewOutComes: DESOS_Outcomes): DESOS_Outcomes {
    try {

      curNewOutComes.q3val = this.tab4FormGroup.controls["q3val"].value;
      curNewOutComes.q3subval = this.tab4FormGroup.controls["q3subval"].value;
      curNewOutComes.q4val = this.tab4FormGroup.controls["q4val"].value;
      curNewOutComes.q5val = this.tab4FormGroup.controls["q5val"].value;
      if (this.tab4FormGroup.controls["q5valchk"].value) {
        curNewOutComes.q5val = "N/A";
      }
      else {
        curNewOutComes.q5val = this.tab4FormGroup.controls["q5valm"].value + "" + this.tab4FormGroup.controls["q5valy"].value;
      }
      curNewOutComes.q6val = this.tab4FormGroup.controls["q6val"].value;
      curNewOutComes.q7val = this.tab4FormGroup.controls["q7val"].value;
      curNewOutComes.q8val = this.tab4FormGroup.controls["q8val"].value;
      curNewOutComes.q8subval = this.tab4FormGroup.controls["q8subval"].value;

      //sessionStorage.setItem("editoutcomes", JSON.stringify(this.curEditOutComes));
    }
    catch (Error) {
      console.log("Tab4:" + Error.message);
    }

    return curNewOutComes;
  }



  hasError = (controlName: string, errorName: string) => {
    return this.tab4FormGroup.controls[controlName].hasError(errorName);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
    });
  }

  closeSnackBar() {
    //this.snackBar.dismiss();
  }

  onChangeQuest2(event: MatRadioChange) {
    if (event.value == "6") {
      this.DEtab4Quest2to7.optquestion2Other = true;
      this.DEtab4Quest2to7.strquestion2Other = "";
    }
    else {
      this.DEtab4Quest2to7.optquestion2Other = false;
      this.DEtab4Quest2to7.strquestion2Other = "";
    }
    this.setOtherQ3sub(event.value);
  }


  setOtherQ3sub(strValue: string) {
    if (strValue == "6") {
      this.tab4FormGroup.controls["q3subval"].setValidators(Validators.required);
      this.tab4FormGroup.controls["q3subval"].updateValueAndValidity();
    }
    else {
      this.tab4FormGroup.controls["q3subval"].setValidators(null);
      this.tab4FormGroup.controls["q3subval"].updateValueAndValidity();
    }
  }

  onChangeQuest4(event: MatCheckboxChange) {
    if (event.checked == true) {
      this.DEtab4Quest2to7.strquestion4Month = "";
      this.DEtab4Quest2to7.strquestion4Year = "";
      this.tab4FormGroup.controls["q5valm"].setValidators(null);
      this.tab4FormGroup.controls["q5valy"].setValidators(null);
      this.tab4FormGroup.controls["q5valm"].disable();
      this.tab4FormGroup.controls["q5valy"].disable();
    }
    else {
      this.tab4FormGroup.controls["q5valm"].enable();
      this.tab4FormGroup.controls["q5valy"].enable();
      this.tab4FormGroup.controls["q5valm"].setValidators([Validators.required, Validators.pattern(this.dobPattern)]);
      this.tab4FormGroup.controls["q5valy"].setValidators([Validators.required, Validators.pattern(this.dobPattern)]);

    }
    this.tab4FormGroup.controls["q5valm"].setValue("");
    this.tab4FormGroup.controls["q5valy"].setValue("");
    this.tab4FormGroup.controls["q5valm"].updateValueAndValidity();
    this.tab4FormGroup.controls["q5valy"].updateValueAndValidity();
  }

  onChangeMMYY(event: MatInput) {
    this.DEtab4Quest2to7.chkquestion4Selected = false;
  }

  onChangeQuest7(event: MatRadioChange) {
    if (event.value == "11") {
      this.DEtab4Quest2to7.optquestion7Other = true;
      this.DEtab4Quest2to7.strquestion7Other = "";
    }
    else {
      this.DEtab4Quest2to7.optquestion7Other = false;
      this.DEtab4Quest2to7.strquestion7Other = "";
    }
    this.setOtherQ8sub(event.value);
  }

  setOtherQ8sub(strValue: string) {
    if (strValue == "11") {
      this.tab4FormGroup.controls["q8subval"].setValidators(Validators.required);
      this.tab4FormGroup.controls["q8subval"].updateValueAndValidity();
    }
    else {
      this.tab4FormGroup.controls["q8subval"].setValidators(null);
      this.tab4FormGroup.controls["q8subval"].updateValueAndValidity();
    }
  }

  //Disable Question 3 -> q4val on Self-employed
  disable3(blnDisable) {
    console.log("dsable3: ", blnDisable);
    if(blnDisable)
    {
      this.tab4FormGroup.controls["q4val"].disable();
    }
    else {
      this.tab4FormGroup.controls["q4val"].enable();
    }
    this.isDisable3 = blnDisable;
  }

  disableA(blnDisable) {
    if (blnDisable) {
      this.tab4FormGroup.controls["q4val"].disable();
      this.tab4FormGroup.controls["q5valm"].disable();
      this.tab4FormGroup.controls["q5valy"].disable();
      this.tab4FormGroup.controls["q5valchk"].disable();
      this.tab4FormGroup.controls["q6val"].disable();
      this.tab4FormGroup.controls["q7val"].disable();
      this.tab4FormGroup.controls["q8val"].disable();
      this.tab4FormGroup.controls["q5valm"].setValidators(null);
      this.tab4FormGroup.controls["q5valy"].setValidators(null);
    }
    else {
      this.tab4FormGroup.controls["q4val"].enable();
      this.tab4FormGroup.controls["q5valm"].enable();
      this.tab4FormGroup.controls["q5valy"].enable();
      this.tab4FormGroup.controls["q5valchk"].enable();
      this.tab4FormGroup.controls["q6val"].enable();
      this.tab4FormGroup.controls["q7val"].enable();
      this.tab4FormGroup.controls["q8val"].enable();
      this.tab4FormGroup.controls["q5valm"].setValidators([Validators.required, Validators.pattern(this.dobPattern)]);
      this.tab4FormGroup.controls["q5valy"].setValidators([Validators.required, Validators.pattern(this.dobPattern)]);
    }
    this.tab4FormGroup.controls["q4val"].setValue("");
    this.tab4FormGroup.controls["q5valm"].setValue("");
    this.tab4FormGroup.controls["q5valy"].setValue("");
    this.tab4FormGroup.controls["q5valchk"].setValue(false);
    this.tab4FormGroup.controls["q6val"].setValue("");
    this.tab4FormGroup.controls["q7val"].setValue("");
    this.tab4FormGroup.controls["q8val"].setValue("");

    this.tab4FormGroup.controls["q5valm"].updateValueAndValidity();
    this.tab4FormGroup.controls["q5valy"].updateValueAndValidity();
    

    this.isDisable3 = blnDisable;
    this.isDisable4 = blnDisable;
    this.isDisable5 = blnDisable;
    this.isDisable6 = blnDisable;
    this.isDisable7 = blnDisable;
  }

  disableB(blnDisable) {
    if (blnDisable) {
      this.tab4FormGroup.controls["q3val"].disable();
      this.isDisable2 = blnDisable;
    }
    else {
      this.tab4FormGroup.controls["q3val"].enable();
      this.isDisable2 = blnDisable;
    }
    this.tab4FormGroup.controls["q3val"].setValue("");
  }

  refresh($value) {
    if (this.primary != $value) {
      this.tab4FormGroup.controls["q3val"].setValue("");
      this.tab4FormGroup.controls["q4val"].setValue("");
      this.tab4FormGroup.controls["q5valm"].setValue("");
      this.tab4FormGroup.controls["q5valy"].setValue("");
      this.tab4FormGroup.controls["q5valchk"].setValue(false);
      this.tab4FormGroup.controls["q6val"].setValue("");
      this.tab4FormGroup.controls["q7val"].setValue("");
      this.tab4FormGroup.controls["q8val"].setValue("");
    }
  }

}
