<form [formGroup]="tab4FormGroup">
  <ng-template matStepLabel>2-7</ng-template>
  <div class="row">
    <div class="col-sm-5 dataentry-column">
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable2) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['2']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['2']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_22}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['2']; else loading" class="col-sm-8 p-0">
            <section>
              <mat-radio-group formControlName="q3val">
                <div class="row">
                  <div *ngFor="let chunk of questionData['2'].options | chunkArray:q2ChunkNum" class="col-sm-5 p-0">
                    <section class="data-radio-group">
                      <mat-radio-button *ngFor="let option of chunk" [value]="''+option.optionId" color="primary" class="data-radio-button" (change)="onChangeQuest2($event)">
                        <span [innerHTML]="option.content"></span>
                      </mat-radio-button>
                    </section>
                  </div>
                </div>
              </mat-radio-group>
              <mat-error class="error_warning" *ngIf="hasError('q3val', 'required')">Please select one</mat-error>
            </section>
            <section class="col-sm-12" [hidden]="!DEtab4Quest2to7.optquestion2Other" name="optquestion2Other">
              <mat-form-field class="mat-input col-sm-12">
                <input matInput placeholder="Other Description" formControlName="q3subval" maxlength="128">
              </mat-form-field>
            </section>
          </div>
          <!--<div class="col-sm-4 p-0">
            <mat-label class="text-primary  question_text">2. Referral source*<br />(check one)</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_22}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>-->
          <!--<div class="col-sm-8 p-0">
            <section>
              <mat-radio-group formControlName="q3val">
                <div class="row">
                  <div class="col-sm-5 p-0">
                    <section class="data-radio-group">
                      <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest2($event)" value="0">VR</mat-radio-button>
                      <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest2($event)" value="2">Own agency</mat-radio-button>
                      <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest2($event)" value="1">School</mat-radio-button>
                    </section>
                  </div>
                  <div class="col-sm-5 p-0">
                    <section class="data-radio-group">
                      <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest2($event)" value="4">Group home</mat-radio-button>
                      <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest2($event)" value="3">Family</mat-radio-button>
                      <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest2($event)" value="6">Other</mat-radio-button>
                    </section>
                  </div>
                </div>
              </mat-radio-group>
              <mat-error class="error_warning" *ngIf="hasError('q3val', 'required')">Please select one</mat-error>
            </section>
            <section class="col-sm-12" [hidden]="!DEtab4Quest2to7.optquestion2Other" name="optquestion2Other">
              <mat-form-field class="mat-input col-sm-12">
                <input matInput placeholder="Other Description" formControlName="q3subval" maxlength="128">
              </mat-form-field>
            </section>
          </div>-->
        </div>
      </mat-card>
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable3) ? 'darkgray' : '' }">
        <div class="row p-0 pl-1">
          <div *ngIf="questionData['3']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['3']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_23}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['3']; else loading" class="col-sm-8 p-0">
            <section>
              <mat-radio-group class="row p-0" formControlName="q4val">
                <mat-radio-button *ngFor="let option of questionData['3'].options" [value]="''+option.optionId" color="primary" class="data-radio-button col-sm-4 col-md-3 pl-3">
                  <span [innerHTML]="option.content"></span>
                </mat-radio-button>
              </mat-radio-group>
              <mat-error class="error_warning" *ngIf="hasError('q4val', 'required')">Please select any one</mat-error>
            </section>
          </div>
          <!--<div class="col-sm-4 p-0">
            <mat-label class="text-primary  question_text">3. New employer in last 6 months*</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_23}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-8 p-0">
            <mat-radio-group class="row p-0" formControlName="q4val">
              <mat-radio-button color="primary" class="data-radio-button col-sm-4 col-md-3 pl-3" value="0">Yes</mat-radio-button>
              <mat-radio-button color="primary" class="data-radio-button col-sm-4 col-md-3 pl-3" value="1">No</mat-radio-button>
            </mat-radio-group>
            <mat-error class="error_warning" *ngIf="hasError('q4val', 'required')">Please select any one</mat-error>
          </div>-->
        </div>
      </mat-card>
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable4) ? 'darkgray' : '' }">
        <div class="row p-0 pl-1">
          <div *ngIf="questionData['4']; else loading" class="col-sm-5 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['4']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_24}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-7 p-0">
            <div class="row p-0 pl-1">
              <div class="col-sm-11 p-0">
                <section class="data-radio-group-row">
                  <mat-form-field class="mat-input placeholder_text" [style.width.px]="100">
                    <input matInput placeholder="Month(MM)" (keyup)="onChangeMMYY($event)" formControlName="q5valm">
                  </mat-form-field>
                  <mat-form-field class="mat-input placeholder_text" [style.width.px]="100">
                    <input matInput placeholder="Year(YY)" (keyup)="onChangeMMYY($event)" formControlName="q5valy">
                  </mat-form-field>
                  <mat-checkbox color="primary" class="data-radio-button" (change)="onChangeQuest4($event)" formControlName="q5valchk" [checked]="DEtab4Quest2to7.chkquestion4Selected">N/A</mat-checkbox>
                </section>
                <mat-error class="error_warning" *ngIf="hasError('q5valm', 'pattern')">Please enter 2 digit month 01 to 12</mat-error>
                <mat-error class="error_warning" *ngIf="hasError('q5valy', 'pattern')">Please enter 2 digit year 01 to  99</mat-error>
              </div>
            </div>
          </div>
          <!--<div class="col-sm-5 p-0">
                <mat-label class="text-primary  question_text">4. Start date for current or new employer*</mat-label>
                <mat-icon color="primary" matTooltip="{{helpMessage.message_24}}" matTooltipClass="example-tooltip-red">info</mat-icon>
              </div>
              <div class="col-7 p-0">
                <div class="row p-0 pl-1">
                  <div class="col-sm-11 p-0">
                    <section class="data-radio-group-row">
                      <mat-form-field class="mat-input placeholder_text" [style.width.px]="100">
                        <input matInput placeholder="Month(MM)" (keyup)="onChangeMMYY($event)" formControlName="q5valm">
                      </mat-form-field>
                      <mat-form-field class="mat-input placeholder_text" [style.width.px]="100">
                        <input matInput placeholder="Year(YY)" (keyup)="onChangeMMYY($event)" formControlName="q5valy">
                      </mat-form-field>
                      <mat-checkbox color="primary" class="data-radio-button" (change)="onChangeQuest4($event)" formControlName="q5valchk" [checked]="DEtab4Quest2to7.chkquestion4Selected">N/A</mat-checkbox>
                    </section>
                    <mat-error class="error_warning" *ngIf="hasError('q5valm', 'pattern')">Please enter 2 digit month 01 to 12</mat-error>
                    <mat-error class="error_warning" *ngIf="hasError('q5valy', 'pattern')">Please enter 2 digit year 01 to  99</mat-error>
                  </div>
                </div>
              </div>-->
        </div>
      </mat-card>
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable5) ? 'darkgray' : '' }">
        <div class="row p-0 pl-1">
          <div *ngIf="questionData['5']; else loading" class="col-sm-5 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['5']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_25}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['5']; else loading" class="col-sm-7 p-0">
            <div class="row p-0 pl-1">
              <div class="col-sm-5 p-0">
                <section>
                  <mat-radio-group class="data-radio-group" formControlName="q6val">
                    <mat-radio-button *ngFor="let option of questionData['5'].options" [value]="''+option.optionId" color="primary" class="data-radio-button">
                      <span [innerHTML]="option.content"></span>
                    </mat-radio-button>
                  </mat-radio-group>
                  <mat-error class="error_warning pt-3" *ngIf="hasError('q6val', 'required')">Please select any one</mat-error>
                </section>
              </div>
            </div>
          </div>

          <!--<div class="col-sm-5 p-0">
            <mat-label class="text-primary  question_text">5. Paycheck issued by*</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_25}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-7 p-0">
            <div class="row p-0 pl-1">
              <div class="col-sm-5 p-0">
                <section>
                  <mat-radio-group class="data-radio-group" formControlName="q6val">
                    <mat-radio-button color="primary" class="data-radio-button" value="0">No paycheck</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" value="1">Employer</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" value="2">Rehab/support organization<br />(Including program operated business)</mat-radio-button>-->
          <!--<mat-label class="text-nowrap" style="font-size:12px;">(Including program operated business)</mat-label>-->
          <!--</mat-radio-group>
                  <mat-error class="error_warning pt-3" *ngIf="hasError('q6val', 'required')">Please select any one</mat-error>
                </section>
              </div>
            </div>
          </div>-->
        </div>
      </mat-card>
    </div>
    <div class="col-sm-7 dataentry-column">
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable6) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['6']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['6']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_26}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['6']; else loading" class="col-sm-8 p-0">
            <div class="col-sm-8 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="q7val">
                  <mat-radio-button *ngFor="let option of questionData['6'].options" [value]="''+option.optionId" color="primary" class="data-radio-button">
                    <span [innerHTML]="option.content"></span>
                  </mat-radio-button>
                </mat-radio-group>
                <mat-error class="error_warning" *ngIf="hasError('q7val', 'required')">Please select any one</mat-error>
              </section>
            </div>
          </div>

          <!--<div class="col-sm-4 p-0">
            <mat-label class="text-primary question_text">6. If job loss for what reason*<br />(check one)</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_26}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-8 p-0">
            <div class="col-sm-8 p-0">
              <section>
                <mat-radio-group class="data-radio-group" formControlName="q7val">
                  <mat-radio-button color="primary" class="data-radio-button" value="0">Voluntary (resigned)</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" value="1">Involuntary (fired, laid off)</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" value="2">Job change/career advance</mat-radio-button>
                  <mat-radio-button color="primary" class="data-radio-button" value="3">None (not applicable)</mat-radio-button>
                </mat-radio-group>
                <mat-error class="error_warning" *ngIf="hasError('q7val', 'required')">Please select any one</mat-error>
              </section>
            </div>
          </div>-->
        </div>
      </mat-card>
      <mat-card class="dataentry-card" [ngStyle]="{'background': (isDisable7) ? 'darkgray' : '' }">
        <div class="row p-0">
          <div *ngIf="questionData['7']; else loading" class="col-sm-4 p-0">
            <mat-label class="text-primary question_text"><span [innerHTML]="questionData['7']?.question?.content"></span></mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_27}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div *ngIf="questionData['7']; else loading" class="col-sm-8 p-0">
            <mat-radio-group formControlName="q8val">
              <div class="row">
                <div *ngFor="let chunk of questionData['7'].options | chunkArray:q7ChunkNum" class="col-sm-6 p-0">
                  <section class="data-radio-group">
                    <mat-radio-button *ngFor="let option of chunk" [value]="''+option.optionId" color="primary" class="data-radio-button" (change)="onChangeQuest7($event)">
                      <span [innerHTML]="option.content"></span>
                    </mat-radio-button>
                  </section>
                </div>
                <mat-error class="error_warning" *ngIf="hasError('q8val', 'required')">Please select any one</mat-error>
              </div>
            </mat-radio-group>
            <section class="col-sm-12" [hidden]="!DEtab4Quest2to7.optquestion7Other" name="optquestion7Other">
              <mat-form-field class="mat-input col-sm-12">
                <input matInput placeholder="Other Description" formControlName="q8subval" maxlength="128">
              </mat-form-field>
            </section>
          </div>


          <!--<div class="col-sm-4 p-0">
            <mat-label class="text-primary question_text">7. Type of work performed*<br />(check one)</mat-label>
            <mat-icon color="primary" matTooltip="{{helpMessage.message_27}}" matTooltipClass="example-tooltip-red">info</mat-icon>
          </div>
          <div class="col-sm-8 p-0">
            <mat-radio-group formControlName="q8val">
              <div class="row">
                <section class="data-radio-group" *ngFor="let chunk of optionsQ7Chunks">
                  <mat-radio-button *ngFor="let option of chunk" [value]="option.optionId" color="primary" class="data-radio-button" (change)="onChangeQuest7($event)">
                    {{ option.content }}
                  </mat-radio-button>
                </section>
                <div class="col-sm-6 p-0">
                  <section class="data-radio-group">
                    <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest7($event)" value="0">Agriculture</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest7($event)" value="2">Clerical/office</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest7($event)" value="4">Grocery/retail</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest7($event)" value="6">Health/human service</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest7($event)" value="8">Janitorial/housekeeping/laundry</mat-radio-button>-->
          <!--<mat-label class="text-nowrap">housekeeping / laundry</mat-label>-->
          <!--<mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest7($event)" value="9">Recycling/sorting </mat-radio-button>
                  </section>
                </div>
                <div class="col-sm-6 p-0">
                  <section class="data-radio-group">
                    <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest7($event)" value="1">Assembly/manufacturing</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest7($event)" value="3">Food service</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest7($event)" value="5">Grounds keeping</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest7($event)" value="7">Stocking/warehouse</mat-radio-button>
                    <mat-radio-button color="primary" class="data-radio-button" (change)="onChangeQuest7($event)" value="11">Other</mat-radio-button>
                  </section>
                </div>
                <mat-error class="error_warning" *ngIf="hasError('q8val', 'required')">Please select any one</mat-error>
              </div>
            </mat-radio-group>
            <section class="col-sm-12" [hidden]="!DEtab4Quest2to7.optquestion7Other" name="optquestion7Other">
              <mat-form-field class="mat-input col-sm-12">
                <input matInput placeholder="Other Description" formControlName="q8subval" maxlength="128">
              </mat-form-field>
            </section>
          </div>-->
        </div>
      </mat-card>
    </div>
  </div>
  <div class="example-button-row text-center">
    <button mat-raised-button matStepperPrevious color="primary">Back</button>
    <button mat-raised-button matStepperNext color="primary" [disabled]="!tab4FormGroup.valid">Next</button>
  </div>
</form>

<ng-template #loading>
  <p>Loading question and options...</p>
</ng-template>
