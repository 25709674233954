import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DESOS_UserInfo } from '../account/account';

@Injectable()
export class TempDataService {
  
  searchfieldKey: string = "desos2019_searchfield";
  rosterActionKey: string = "desos2019_rosterAction";
  currentindivitualKey: string = "desos2019_currentindivitual";
  currentoutcomeKey: string = "desos2019_currentoutcome";
  reportparamsKey: string = "desos2019_reportparams";
  

  constructor(private router: Router) { }

  getSearchFields() {
    return JSON.parse(localStorage.getItem(this.searchfieldKey));
  }

  setSearchFields(searchfield) {
    localStorage.setItem(this.searchfieldKey, JSON.stringify(searchfield));
  }

  getRosterAction() {
    return JSON.parse(localStorage.getItem(this.rosterActionKey));
  }

  setRosterAction(rosterAction) {
    localStorage.setItem(this.rosterActionKey, JSON.stringify(rosterAction));
  }

  getCurrentindivitual() {
    return JSON.parse(localStorage.getItem(this.currentindivitualKey));
  }

  setCurrentindivitual(currentindivitual) {
    localStorage.setItem(this.currentindivitualKey, JSON.stringify(currentindivitual));
  }

  getCurrentoutcome() {
    return JSON.parse(localStorage.getItem(this.currentoutcomeKey));
  }

  setCurrentoutcome(currentoutcome) {
    localStorage.setItem(this.currentoutcomeKey, JSON.stringify(currentoutcome));
  }

  getReportparams() {
    return JSON.parse(localStorage.getItem(this.reportparamsKey));
  }

  setReportparams(reportparams) {
    localStorage.setItem(this.reportparamsKey, JSON.stringify(reportparams));
  }

  removeToken() {
    localStorage.removeItem(this.searchfieldKey);
    localStorage.removeItem(this.rosterActionKey);
    localStorage.removeItem(this.currentindivitualKey);
    localStorage.removeItem(this.currentoutcomeKey);
    localStorage.removeItem(this.reportparamsKey);
  }

  removeRosterEntryToken() {
    localStorage.removeItem(this.rosterActionKey);
    localStorage.removeItem(this.currentindivitualKey);
    localStorage.removeItem(this.currentoutcomeKey);
  }
}
