import { Component, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { NewentryComponent } from '../newentry/newentry.component';
import { DESOS_Individuals, DESOS_Outcomes } from '../dataentry';

@Component({
  selector: 'app-roster-list',
  templateUrl: './roster-list.component.html',
  styleUrls: ['./roster-list.component.css']
})
/** roster-list component*/
export class RosterListComponent {
  /** roster-list ctor */
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild(MatSidenavContainer, { static: true }) sidenavContainer: MatSidenavContainer;
  @ViewChild(NewentryComponent, { static: true }) newEntryForm: NewentryComponent;
  public action: string = "";

  public reason: string = '';
  constructor() {
    //this.close("toggle button");
  }

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
  }

  btnNewOutcome() {
    const curEditIndividual: DESOS_Individuals = new DESOS_Individuals();
    const curEditOutComes: DESOS_Outcomes = new DESOS_Outcomes();
    sessionStorage.setItem("editoutcomes", JSON.stringify(curEditOutComes));
    sessionStorage.setItem("editindividual", JSON.stringify(curEditIndividual));
    this.delay(3000).then(any => { this.newEntryForm.reloadEntryForm(); this.sidenav.open();}); 
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("Outcome"));
  }

  onDataEntry($event) {
   //alert($event);
    //this.newEntryForm.ngOnChanges();

    if ($event == "edit") {
      this.sidenav.open();
      this.action = $event;
      this.newEntryForm.reloadEntryForm();
    }
    if ($event == "new") {
      this.sidenav.open();
      this.action = $event;
      this.newEntryForm.reloadEntryForm();
    }
   
    //this.action = $event;
  }
  //shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));
}
