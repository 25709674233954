import { Component, Inject, Input, Output, EventEmitter, ViewEncapsulation, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";
import { DESOS_Programs_List } from 'src/app/admin/program';
import { helpText, DataEntry_tabAvailability, DESOS_Individuals, DESOS_Outcomes, DESOS_DataEntry } from '../dataentry';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { dataentry_tab1_quest11 } from '../dataentry-tab1';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DESOS_Cities_List, DESOS_Counties_List, DESOS_Genders_List, DESOS_Races_List, DESOS_Month_List, DESOS_Period_List, DESOS_Reporting_Periods } from 'src/app/search/default';
import { DESOS_UserInfo } from 'src/app/account/account';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TempDataService } from 'src/app/services/temp-data.service';
import { DataentryService } from 'src/app/services/dataentry.service';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

@Component({
  selector: 'app-dataentry-tab1',
  templateUrl: './dataentry-tab1.component.html',
  styleUrls: ['./dataentry-tab1.component.css'],
  providers: [AuthenticationService, TempDataService],
  encapsulation: ViewEncapsulation.None,
})
/** dataentry-tab1 component*/
export class DataentryTab1Component implements OnInit {

  questionData: any;

  
  helpMessage: helpText;
  isReasonHide: boolean;
  isOtherHide: boolean;
  isOtherGenderHide: boolean;
  isOtherRaceHide: boolean;
  public programList: DESOS_Programs_List[];
  public cityList: DESOS_Cities_List[];
  public countyList: DESOS_Counties_List[];
  public genderList: DESOS_Genders_List[];
  public raceList: DESOS_Races_List[];
  public monthList: DESOS_Month_List[];
  public periodList: DESOS_Period_List[];
  private DETabsEnabled: DataEntry_tabAvailability;
  public dataentrydtQuest11: dataentry_tab1_quest11;
  private curEditIndividual: DESOS_Individuals;
  private curEditOutComes: DESOS_Outcomes;
  private dataentry: DESOS_DataEntry;
  public desosreportinfo: DESOS_Reporting_Periods;
  @Input() DETabsVisible: DataEntry_tabAvailability;
  @Output() tab2To8Hide = new EventEmitter<boolean>();
  private apiURL: string;
  private dobPattern: string = "^(\([0-9]{2}\))$";
  private zipcodePattern: string = "^(\([0-9]{5}\))$";
  public tab1FormGroup1: FormGroup;
  public desosuserinfo: DESOS_UserInfo;
  /** dataentry-tab1 ctor */
  constructor(private _formBuilder: FormBuilder, private snackBar: MatSnackBar, private location: Location, private route: ActivatedRoute,
    private router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private authentication: AuthenticationService, private tempdata: TempDataService, private APIservices: DataentryService) {
    this.helpMessage = new helpText();
    this.dataentrydtQuest11 = new dataentry_tab1_quest11();
    this.isReasonHide = true;
    this.isOtherHide = true;
    this.isOtherGenderHide = true;
    this.isOtherRaceHide = true;
    this.apiURL = baseUrl;
    this.loadDefaults();
  }

  ngOnInit() {

    this.preInitData();

    this.tab1FormGroup1 = this._formBuilder.group({
      firstname: [this.curEditIndividual.firstName, Validators.required],
      middlename: [this.curEditIndividual.middleName],
      lastname: [this.curEditIndividual.lastName, Validators.required],
      program: [this.curEditOutComes.program_ID, Validators.required],
      city: [this.curEditOutComes.cityID, Validators.required],
      county: [this.curEditOutComes.countyID, Validators.required],
      zipcode: [this.curEditIndividual.zipCode, Validators.required],
      reportingperiod: [{ value: this.curEditOutComes.reporting_Period, disabled: true }, Validators.required],
      dobmonth: [this.curEditIndividual.dateOfBirth.substr(0, 2), Validators.required],
      dobyear: [this.curEditIndividual.dateOfBirth.substr(2, 2), Validators.required],
      gender: [this.curEditIndividual.gender, Validators.required],
      race: [this.curEditIndividual.race, Validators.required],
      legalguardian: [this.curEditIndividual.legalGuardian, Validators.required],
      nlrs: [this.curEditOutComes.nlrs, Validators.required],
      nlrSsubval: [this.curEditOutComes.nlrSsubval],
      nlrSsubval2: [this.curEditOutComes.nlrSsubval2],
      othergender: [this.curEditIndividual.otherGender],
      otherrace: [this.curEditIndividual.otherRace],
    })

    this.fetchQuestionWithOptions();
  }

  preInitData() {
    //if (sessionStorage.desosreportinfo) {
    //  this.desosreportinfo = JSON.parse(sessionStorage.desosreportinfo)
    //}
    this.desosreportinfo = this.authentication.getReport();

    this.desosuserinfo = this.authentication.getAccessToken();
    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);
    //}

    if (this.tempdata.getRosterAction() == "edit") {
      this.curEditIndividual = this.tempdata.getCurrentindivitual();
      this.curEditOutComes = this.tempdata.getCurrentoutcome();
    }
    else if (this.tempdata.getRosterAction() == "new" || this.tempdata.getRosterAction() == "copy") {
      this.curEditIndividual = this.tempdata.getCurrentindivitual();
      this.curEditOutComes = this.tempdata.getCurrentoutcome();
      this.curEditOutComes.reporting_Period = this.desosreportinfo.mm + this.desosreportinfo.dd;
    }

    //if (sessionStorage.editoutcomes != null) {
    //  this.curEditOutComes = JSON.parse(sessionStorage.editoutcomes);
    //  if (sessionStorage.entrytype != null) {
    //    if (sessionStorage.entrytype == "new" || sessionStorage.entrytype == "copy") {
    //      this.curEditOutComes.reporting_Period = this.desosreportinfo.mm + this.desosreportinfo.dd;
    //    }
    //  }
    //  if (sessionStorage.editindividual != null) {
    //    this.curEditIndividual = JSON.parse(sessionStorage.editindividual);
    //  }
    //}
  }

  hasError = (controlName: string, errorName: string) => {
    return this.tab1FormGroup1.controls[controlName].hasError(errorName);
  }

  async fetchQuestionWithOptions() {
    try {
      // Define the range of question identifiers
      const startQuestionIndex = 1;
      const endQuestionIndex = 10;
      const questionPrefix = 'd';

      // Initialize an object to hold the data for each question
      this.questionData = {};

      // Fetch questions within the range
      for (let questionIndex = startQuestionIndex; questionIndex <= endQuestionIndex; questionIndex++) {
        const questionNumber = `${questionPrefix}${questionIndex}`;
        const inparam = new HttpParams().set('questionNumber', questionNumber);
        this.questionData[questionNumber] = await this.APIservices.getQuestionWithOptions(inparam).toPromise();
      }

      let additionalNumber = 'd11-0';
      let additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      additionalNumber = 'd11-1';
      additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      // Check if data for all questions is retrieved
      const allDataReceived = Object.keys(this.questionData).length >= (endQuestionIndex - startQuestionIndex + 1 + 2) &&
        Object.values(this.questionData).every(data => !!data);

      if (allDataReceived) {
        console.log('Data retrieved:', this.questionData[`${questionPrefix}${startQuestionIndex}`]); // Example log for the first question
        this.curEditOutComes = this.tempdata.getCurrentoutcome();
        console.log('curEditOutComes:', this.curEditOutComes);
        this.initFormGroup();
      } else {
        console.log('No data received');
      }
    } catch (error) {
      console.error('Failed to fetch question options:', error);      
    }
  };



  initFormGroup() {
    

    this.dataentrydtQuest11.legalGuardian = this.curEditIndividual.legalGuardian;
    this.dataentrydtQuest11.strIndividual = this.curEditOutComes.nlrs;
    this.dataentrydtQuest11.strResonSelected = this.curEditOutComes.nlrSsubval;
    this.dataentrydtQuest11.strOtherDescription = this.curEditOutComes.nlrSsubval2;
    this.tab1FormGroup1.controls["dobyear"].setValidators([Validators.required, Validators.pattern(this.dobPattern)]);
    this.tab1FormGroup1.controls["dobyear"].updateValueAndValidity();
    this.tab1FormGroup1.controls["zipcode"].setValidators([Validators.required, Validators.pattern(this.zipcodePattern)]);
    this.tab1FormGroup1.controls["zipcode"].updateValueAndValidity();
    //if (this.dataentrydtQuest11.strIndividual == "1") {
    //  this.showOptions();
    //}
    //else {
    //  this.hideOptions();
    //}

    if (this.curEditOutComes.nlrs == "1") {
      this.isReasonHide = false;
      if (this.curEditOutComes.nlrSsubval == "3") {
        this.isOtherHide = false;
      }   
    }

    if (this.curEditIndividual.otherGender != "") {
      this.isOtherGenderHide = false;
    }
    if (this.curEditIndividual.otherRace != "") {
      this.isOtherRaceHide = false;
    }

    if (!this.desosuserinfo.blnIsAdmin) {
      this.tab1FormGroup1.controls["program"].setValue(this.desosuserinfo.strProgram);
      this.tab1FormGroup1.controls["program"].disable();
    }
  }

  getEntriesIndivitual(): DESOS_Individuals {
    try {
      this.preInitData();
      this.curEditIndividual.firstName = this.tab1FormGroup1.controls["firstname"].value;
      this.curEditIndividual.middleName = this.tab1FormGroup1.controls["middlename"].value;
      this.curEditIndividual.lastName = this.tab1FormGroup1.controls["lastname"].value;
      this.curEditIndividual.zipCode = this.tab1FormGroup1.controls["zipcode"].value;
      this.curEditIndividual.dateOfBirth = this.tab1FormGroup1.controls["dobmonth"].value + this.tab1FormGroup1.controls["dobyear"].value;
      this.curEditIndividual.race = this.tab1FormGroup1.controls["race"].value;
      this.curEditIndividual.gender = this.tab1FormGroup1.controls["gender"].value;
      this.curEditIndividual.otherGender = this.tab1FormGroup1.controls["othergender"].value;
      this.curEditIndividual.otherRace = this.tab1FormGroup1.controls["otherrace"].value;
      this.curEditIndividual.legalGuardian = this.tab1FormGroup1.controls["legalguardian"].value;

      this.curEditOutComes.program_ID = this.tab1FormGroup1.controls["program"].value;
      this.curEditOutComes.cityID = this.tab1FormGroup1.controls["city"].value;
      this.curEditOutComes.countyID = this.tab1FormGroup1.controls["county"].value;

      if (this.tempdata.getRosterAction() == "new" || this.tempdata.getRosterAction() == "copy") {
        this.curEditOutComes.reporting_Period = this.desosreportinfo.mm + this.desosreportinfo.dd;
      }
      else {
        this.curEditOutComes.reporting_Period = this.tab1FormGroup1.controls["reportingperiod"].value;
      }
     
      this.curEditOutComes.legalGuardian = this.tab1FormGroup1.controls["legalguardian"].value;
      this.curEditOutComes.nlrs = this.tab1FormGroup1.controls["nlrs"].value;
      this.curEditOutComes.nlrSsubval = this.tab1FormGroup1.controls["nlrSsubval"].value;
      this.curEditOutComes.nlrSsubval2 = this.tab1FormGroup1.controls["nlrSsubval2"].value;

      //alert(this.curEditOutComes.reporting_Period);

     // sessionStorage.setItem("newoutcomes", JSON.stringify(this.curEditOutComes));
     // sessionStorage.setItem("newindividual", JSON.stringify(this.curEditIndividual));
    }
    catch (Error) {
      console.log(Error.message);
    }

    return this.curEditIndividual;
  }

  getEntriesOutcomes(curNewOutComes: DESOS_Outcomes): DESOS_Outcomes {
    try {
      this.preInitData();
      this.curEditIndividual.firstName = this.tab1FormGroup1.controls["firstname"].value;
      this.curEditIndividual.middleName = this.tab1FormGroup1.controls["middlename"].value;
      this.curEditIndividual.lastName = this.tab1FormGroup1.controls["lastname"].value;
      this.curEditIndividual.zipCode = this.tab1FormGroup1.controls["zipcode"].value;
      this.curEditIndividual.dateOfBirth = this.tab1FormGroup1.controls["dobmonth"].value + this.tab1FormGroup1.controls["dobyear"].value;
      this.curEditIndividual.race = this.tab1FormGroup1.controls["race"].value;
      this.curEditIndividual.gender = this.tab1FormGroup1.controls["gender"].value;
      this.curEditIndividual.otherGender = this.tab1FormGroup1.controls["othergender"].value;
      this.curEditIndividual.otherRace = this.tab1FormGroup1.controls["otherrace"].value;
      this.curEditIndividual.legalGuardian = this.tab1FormGroup1.controls["legalguardian"].value;

      curNewOutComes.program_ID = this.tab1FormGroup1.controls["program"].value;
      curNewOutComes.cityID = this.tab1FormGroup1.controls["city"].value;
      curNewOutComes.countyID = this.tab1FormGroup1.controls["county"].value;
      curNewOutComes.id = this.curEditOutComes.id;
      curNewOutComes.individual_ID = this.curEditOutComes.individual_ID;
      if (this.tempdata.getRosterAction() == "new" || this.tempdata.getRosterAction() == "copy") {
        curNewOutComes.reporting_Period = this.desosreportinfo.mm + this.desosreportinfo.dd;
      }
      else {
        curNewOutComes.reporting_Period = this.tab1FormGroup1.controls["reportingperiod"].value;
      }

      curNewOutComes.legalGuardian = this.tab1FormGroup1.controls["legalguardian"].value;
      curNewOutComes.nlrs = this.tab1FormGroup1.controls["nlrs"].value;
      curNewOutComes.nlrSsubval = this.tab1FormGroup1.controls["nlrSsubval"].value;
      curNewOutComes.nlrSsubval2 = this.tab1FormGroup1.controls["nlrSsubval2"].value;
      //alert(this.curEditOutComes.reporting_Period);

      // sessionStorage.setItem("newoutcomes", JSON.stringify(this.curEditOutComes));
      // sessionStorage.setItem("newindividual", JSON.stringify(this.curEditIndividual));
    }
    catch (Error) {
      console.log("Tab1:" + Error.message);
    }

    return curNewOutComes;
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
    });
  }

  closeSnackBar() {
    //this.snackBar.dismiss();
  }

  hideOptions() {
    this.isReasonHide = true;
    this.isOtherHide = true;
    this.tab1FormGroup1.controls["nlrSsubval2"].setValue("");
    this.tab1FormGroup1.controls["nlrSsubval"].setValue("");
    this.setOtherNLRS(false);
    this.setNLRSReason(false);
    this.tab2To8Hide.emit(true);
  }

  showOptions() {
    this.isReasonHide = false;
    this.isOtherHide = true;
    this.tab1FormGroup1.controls["nlrSsubval2"].setValue("");
    this.tab1FormGroup1.controls["nlrSsubval"].setValue("");
    //this.dataentrydtQuest11.optResonSelected = 0;
    this.setNLRSReason(true);
    this.tab2To8Hide.emit(false);
    //this.DETabsVisible.tab2To7Enabled = false;
    //this.DETabsVisible.tab8Enabled = false;
  }

  showOtherText() {
    this.isOtherHide = false;
    this.tab1FormGroup1.controls["nlrSsubval2"].setValue("");
    this.setOtherNLRS(true);
  }

  hideOtherText() {
    this.isOtherHide = true;
    this.tab1FormGroup1.controls["nlrSsubval2"].setValue("");
    this.setOtherNLRS(false);
  }

  setNLRSReason(blnValue: boolean) {
    if (blnValue == true) {
      this.tab1FormGroup1.controls["nlrSsubval"].setValidators(Validators.required);
      this.tab1FormGroup1.controls["nlrSsubval"].updateValueAndValidity();
    }
    else {
      this.tab1FormGroup1.controls["nlrSsubval"].setValidators(null);
      this.tab1FormGroup1.controls["nlrSsubval"].updateValueAndValidity();
    }
  }

  setOtherNLRS(blnValue: boolean) {
    if (blnValue == true) {
      this.tab1FormGroup1.controls["nlrSsubval2"].setValidators(Validators.required);
      this.tab1FormGroup1.controls["nlrSsubval2"].updateValueAndValidity();
    }
    else {
      this.tab1FormGroup1.controls["nlrSsubval2"].setValidators(null);
      this.tab1FormGroup1.controls["nlrSsubval2"].updateValueAndValidity();
    }
  }

  setOtherRace(strValue: string) {
    if (strValue == "7") {
      this.tab1FormGroup1.controls["otherrace"].setValidators(Validators.required);
      this.tab1FormGroup1.controls["otherrace"].updateValueAndValidity();
    }
    else {
      this.tab1FormGroup1.controls["otherrace"].setValidators(null);
      this.tab1FormGroup1.controls["otherrace"].updateValueAndValidity();
    }
  }

  onChangeRace(event: MatSelectChange) {
    if (event.source.value == "7") {
      this.isOtherRaceHide = false;   
    }
    else {
      this.isOtherRaceHide = true;
    }
    this.setOtherRace(event.source.value);
  }

  setOtherGender(strValue: string) {
    if (strValue == "3") {
      this.tab1FormGroup1.controls["othergender"].setValidators(Validators.required);
      this.tab1FormGroup1.controls["othergender"].updateValueAndValidity();
    }
    else {
      this.tab1FormGroup1.controls["othergender"].setValidators(null);
      this.tab1FormGroup1.controls["othergender"].updateValueAndValidity();
    }
  }

  onChangeGender(event: MatSelectChange) {
    if (event.source.value == "3") {
      this.isOtherGenderHide = false;
    }
    else {
      this.isOtherGenderHide = true;
    }
    this.setOtherGender(event.source.value);
  }

  loadDefaults() {

    //this.http.get<DESOS_Programs_List[]>(this.apiURL + 'api/DefaultData/GetProgramSelectList').subscribe(result => {
    this.APIservices.getProgramList().subscribe(result => {
      this.programList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Cities_List[]>(this.apiURL + 'api/DefaultData/GetCitiesSelectList').subscribe(result => {
    this.APIservices.getCitiesList().subscribe(result => {
    this.cityList = result;
    }, error => console.error(error));


    //this.http.get<DESOS_Counties_List[]>(this.apiURL + 'api/DefaultData/GetCountiesSelectList').subscribe(result => {
    this.APIservices.getCountiesList().subscribe(result => {
    this.countyList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Genders_List[]>(this.apiURL + 'api/DefaultData/GetGendersSelectList').subscribe(result => {
    this.APIservices.getGendersList().subscribe(result => {
    this.genderList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Races_List[]>(this.apiURL + 'api/DefaultData/GetRacesSelectList').subscribe(result => {
    this.APIservices.getRacesList().subscribe(result => {
    this.raceList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Month_List[]>(this.apiURL + 'api/DefaultData/GetMonthSelectList').subscribe(result => {
    this.APIservices.getMonthList().subscribe(result => {
    this.monthList = result;
    }, error => console.error(error));

    //this.http.get<DESOS_Period_List[]>(this.apiURL + 'api/DefaultData/GetReportingPeriodSelectList').subscribe(result => {
    this.APIservices.getReportingPeriodList().subscribe(result => {
    this.periodList = result;
    }, error => console.error(error));
  }

  OnNext() {
    return false
  }


}
