import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { DefaultService } from './default.service';
import { DESOS_StrResult } from '../dataentry/dataentry';

@Injectable()
export class AuthGuardGuestService {
  public DESOSresults: DESOS_StrResult;
  constructor(private authentication: AuthenticationService, private router: Router, private APIdefaults : DefaultService) {
    this.GetAccessToken();
  }

  canActivate(): boolean | Promise<boolean> {
    let token = this.authentication.getGuestToken();
    //let accessToken = this.authentication.getAccessToken();

    if (!token) {
      console.error("Guest User authenticated.");
      this.redirectToLoginPage();
      return false;
    }
    else if (this.authentication.isGuestAuthenticated()) {
      return true;
    }
    else {
      //this.authentication.refreshToken();
      return true;
    }
  };

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("Login"));
  }

  async GetAccessToken() {
    this.DESOSresults = await this.APIdefaults.getToken().toPromise();
    //this.APIdefaults.getToken().subscribe((result) => {
      //this.DESOSresults = result;
      if (this.DESOSresults != null) {
        if (this.DESOSresults.strOutput != "") {
          this.authentication.setGuestToken(this.DESOSresults.strOutput);
        }
      }
    //});
  }

  redirectToLoginPage() {
    this.router.navigate(['/account/login']);
  }
}
