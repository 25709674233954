import { Component, EventEmitter, ViewChild, Inject, OnInit, Output } from '@angular/core';
import { DESOS_Records_List, DESOS_Search_Fields, DESOS_Outcomes, DESOS_Individuals } from 'src/app/dataentry/dataentry';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DESOS_UserInfo } from 'src/app/account/account';
import { DESOS_Reporting_Periods, DESOS_Period_List } from 'src/app/search/default';
import { DESOS_Programs_List } from 'src/app/admin/program';
import { FormGroup, FormControl } from '@angular/forms';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TempDataService } from 'src/app/services/temp-data.service';
import { DataentryService } from 'src/app/services/dataentry.service';

@Component({
  selector: 'app-userroster-list',
  templateUrl: './userrosterlist.component.html',
  styleUrls: ['./userrosterlist.component.css'],
  providers: [AuthenticationService, TempDataService]
})
/** current-list component*/
export class UserrosterlistComponent implements OnInit {
  /** current-list ctor */
  private apiURL: string;
  private resultsArray: DESOS_Records_List[];
  private recordsList: DESOS_Records_List[];
  public programList: DESOS_Programs_List[];
  public dataSource: MatTableDataSource<DESOS_Records_List>;
  public desosreportinfo: DESOS_Reporting_Periods;
  public periodList: DESOS_Period_List[];
  public displayedColumns: string[] = ['id', 'lastNameLowerCase', 'q1val', 'reporting_Period_Filter' ,'city', 'county', 'dateOfBirth'];
  private asyncOutcomesResult: DESOS_Outcomes;
  private asyncIndividualResult: DESOS_Individuals;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() outcomeAction = new EventEmitter<string>();
  private individualid: string = "";
  public desosuserinfo: DESOS_UserInfo;
  public rosterlist: FormGroup;
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private matDialog: MatDialog, private authentication: AuthenticationService, private tempdata: TempDataService, private dataentryServices: DataentryService) {
    this.apiURL = baseUrl;
    //this.getRecordsListById("110");
    let observable = new Observable(this.myObservableTwo);
   this.showProgressSpinner(observable);
    this.tempdata.removeRosterEntryToken();
    this.LoadProgramList();
  }

  ngOnInit() {
    this.rosterlist = new FormGroup({
      program: new FormControl(''),
      services: new FormControl(''),
      allperiod: new FormControl('')
    }, { updateOn: 'blur' });

    //this.loadDefaults();
    
    //alert(strin);
    //if (sessionStorage.desosreportinfo != null) {
    //  this.desosreportinfo = JSON.parse(sessionStorage.desosreportinfo);
    //}

    this.desosreportinfo = this.authentication.getReport();
    //console.log("RP:", this.desosreportinfo);
    this.desosuserinfo = this.authentication.getAccessToken();

    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);

    this.loadFilter();

    if (!this.desosuserinfo.blnIsAdmin) {
      this.rosterlist.controls["program"].setValue(this.desosuserinfo.strProgram);
      this.rosterlist.controls["program"].disable();
    }

  }


  SessionReset() {
    //var searchfields: DESOS_Search_Fields;
    //if (sessionStorage.searchfields != null)
    //  searchfields = JSON.parse(sessionStorage.searchfields);
    this.desosreportinfo = this.authentication.getReport();
    this.desosuserinfo = this.authentication.getAccessToken();

    //if (sessionStorage.desosreportinfo != null) 
    //  this.desosreportinfo = JSON.parse(sessionStorage.desosreportinfo);
    //if (sessionStorage.desosuserinfo != null) 
    //this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);
    //sessionStorage.clear();

    //if (this.desosreportinfo != null)
    //  sessionStorage.setItem("desosreportinfo", JSON.stringify(this.desosreportinfo));
    //if (this.desosuserinfo != null)
    //  sessionStorage.setItem("desosuserinfo", JSON.stringify(this.desosuserinfo));
    //if (searchfields != null)
    //sessionStorage.setItem("searchfields", JSON.stringify(searchfields));
  }

  loadFilter() {
    const searchfields: DESOS_Search_Fields = this.tempdata.getSearchFields();
    if (searchfields != null) {
      //this.getRecordsList(JSON.parse(sessionStorage.rosterlist));
      //const searchfields: DESOS_Search_Fields = JSON.parse(sessionStorage.searchfields);
     
      // search.program_ID = this.desosuserinfo.strProgram;
      // search.reporting_Period = this.desosreportinfo.mm + this.desosreportinfo.dd;
     // this.rosterlist.controls["period"].setValue(searchfields.reporting_Period);
      this.rosterlist.controls["program"].setValue(searchfields.program_ID);
      this.rosterlist.controls["services"].setValue((searchfields.services == "1" ? true : false));
      this.rosterlist.controls["allperiod"].setValue((searchfields.allreporting == "1" ? true : false));      
      this.getRosterList(searchfields);
      //this.delay(2000).then(any => {
      //  this.loadRosterGrid(searchfields);
      //});
    }
    else {
      if (!this.desosuserinfo.blnIsAdmin) {
        const searchfields: DESOS_Search_Fields = new DESOS_Search_Fields();
        searchfields.program_ID = this.desosuserinfo.strProgram;
        searchfields.reporting_Period = this.desosreportinfo.mm + this.desosreportinfo.dd;
        this.rosterlist.controls["program"].setValue(searchfields.program_ID);
        this.getRosterList(searchfields);
        //this.delay(2000).then(any => {
        //  this.loadRosterGrid(searchfields);
        //});
      }
    }
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  myObservable(observer) {
    setTimeout(() => {
      observer.next("done waiting for 1 sec");
      observer.complete();
    }, 1000);
  }

  myObservableTwo(observer) {
    setTimeout(() => {
      observer.next("done waiting for 3 sec");
      observer.complete();
    }, 3000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log({ response });
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  onChangeProgram(event: MatSelectChange) {
    //alert(event.value);
    //let observable = new Observable(this.myObservable);
   // this.showProgressSpinner(observable);
    const searchfields: DESOS_Search_Fields = new DESOS_Search_Fields();
    searchfields.program_ID = event.value;
    searchfields.reporting_Period = this.desosreportinfo.mm + this.desosreportinfo.dd;
    searchfields.services = (this.rosterlist.controls["services"].value == true ? "1" : "");
    searchfields.allreporting = (this.rosterlist.controls["allperiod"].value == true ? "1" : "");
    searchfields.Reporting_Period_Type = this.desosreportinfo.isannual;
    //if (this.desosreportinfo != null) {
    //  search.reporting_Period = this.rosterlist.controls["period"].value;
    //}
    this.tempdata.setSearchFields(searchfields);
    //sessionStorage.setItem("searchfields", JSON.stringify(searchfields));
    this.showProgressSpinner(this.refreshRosterList(searchfields));
    //this.getRecordsList(searchfields);
    //this.selectedprogramid = event.value; 
  }

  onChangeServices(event: MatCheckboxChange) {
    let observable = new Observable(this.myObservable);
    this.showProgressSpinner(observable);
    const searchfields: DESOS_Search_Fields = new DESOS_Search_Fields();
    if (event.checked == true) {
      searchfields.services = "1";
    }
    else {
      searchfields.services = "";
    }
    searchfields.program_ID = this.rosterlist.controls["program"].value;
    searchfields.allreporting = (this.rosterlist.controls["allperiod"].value == true ? "1" : "");
    searchfields.reporting_Period = this.desosreportinfo.mm + this.desosreportinfo.dd;
    searchfields.Reporting_Period_Type = this.desosreportinfo.isannual;
    //sessionStorage.setItem("searchfields", JSON.stringify(searchfields));
   // this.getRecordsList(searchfields);
    this.tempdata.setSearchFields(searchfields);
   this.showProgressSpinner(this.refreshRosterList(searchfields));
  }

  onChangeAllPeriods(event: MatCheckboxChange) {
    let observable = new Observable(this.myObservable);
    this.showProgressSpinner(observable);
    const searchfields: DESOS_Search_Fields = new DESOS_Search_Fields();
    if (event.checked == true) {
      searchfields.allreporting = "1";
    }
    else {
      searchfields.allreporting = "";
    }
    searchfields.program_ID = this.rosterlist.controls["program"].value;
    searchfields.services = (this.rosterlist.controls["services"].value == true ? "1" : "");
    searchfields.reporting_Period = this.desosreportinfo.mm + this.desosreportinfo.dd;
    searchfields.Reporting_Period_Type = this.desosreportinfo.isannual;
    //sessionStorage.setItem("searchfields", JSON.stringify(searchfields));
    // this.getRecordsList(searchfields);
    this.tempdata.setSearchFields(searchfields);
    this.showProgressSpinner(this.refreshRosterList(searchfields));
  }

  onChangePeriod(event: MatSelectChange) {
    const searchfields: DESOS_Search_Fields = new DESOS_Search_Fields();
    searchfields.program_ID = this.rosterlist.controls["program"].value;
    searchfields.reporting_Period = event.value;
    this.tempdata.setSearchFields(searchfields);
    //sessionStorage.setItem("searchfields", JSON.stringify(searchfields));
    //this.getRecordsList(searchfields);
  }

  pageChange(event: PageEvent) {
    //const searchfields: DESOS_Search_Fields = JSON.parse(sessionStorage.searchfields);
    const searchfields: DESOS_Search_Fields = this.tempdata.getSearchFields();
    searchfields.pageCurrent = event.pageIndex;
    searchfields.pageSize = event.pageSize;
    this.tempdata.setSearchFields(searchfields);
    //sessionStorage.setItem("searchfields", JSON.stringify(searchfields));
  }

  getRecordsListById(strId: string) {
    let inparams = new HttpParams()
      .set('id', strId);

    this.http.get<DESOS_Records_List[]>(this.apiURL + 'api/DataEntry/GetRecordListById', { params: inparams }).subscribe(result => {
      this.recordsList = result;
      this.recordsList = this.recordsList.map(function (el) {
        var o = Object.assign({}, el);
        o.lastNameLowerCase = el.lastName.toLocaleLowerCase();
        return o;
      });
      //console.table(result);
      this.dataSource = new MatTableDataSource(this.recordsList);
      this.dataSource.paginator = this.paginator;
      if (this.dataSource.data.length > 0) {
        this.dataSource.sort = this.sort;
      }

    }, error => console.error(error));
  }

  getRecordsListByIndividualId(strId: string) {
    let inparams = new HttpParams()
      .set('indId', strId);

    this.http.get<DESOS_Records_List[]>(this.apiURL + 'api/DataEntry/GetRecordListByIndId', { params: inparams }).subscribe(result => {
      this.recordsList = result;
      this.recordsList = this.recordsList.map(function (el) {
        var o = Object.assign({}, el);
        o.lastNameLowerCase = el.lastName.toLocaleLowerCase();
        return o;
      });
      if (this.recordsList != null) {
        this.dataSource = new MatTableDataSource(this.recordsList);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }
    }, error => console.error(error));
  }

  refreshRosterList(inSearchFields: DESOS_Search_Fields) {
    return Observable.create(observer => {
      this.dataentryServices.getRosterList(inSearchFields).subscribe((result) => {
      this.recordsList = result;
      this.recordsList = this.recordsList.map(function (el) {
        var o = Object.assign({}, el);
        o.lastNameLowerCase = el.lastName.toLocaleLowerCase();
        return o;
      });
      this.dataSource = new MatTableDataSource(this.recordsList);
      this.paginator.pageIndex = inSearchFields.pageCurrent;
      this.paginator.pageSize = inSearchFields.pageSize;
      this.dataSource.paginator = this.paginator;
      if (this.dataSource.data.length > 0) {
        this.dataSource.sort = this.sort;
        observer.next("Roster list loaded.");
        observer.complete();
      }
      else {
        observer.next("No Connection, DB or API or Session.");
        observer.complete();
      }
      });
    });
  }

  getRosterList(inSearchFields: DESOS_Search_Fields) {
    //return Observable.create(observer => {
      this.dataentryServices.getRosterList(inSearchFields).subscribe((result) => {
        this.recordsList = result;
        this.recordsList = this.recordsList.map(function (el) {
          var o = Object.assign({}, el);
          o.lastNameLowerCase = el.lastName.toLocaleLowerCase();
          return o;
        });
        console.log(this.recordsList);
        this.dataSource = new MatTableDataSource(this.recordsList);
        this.paginator.pageIndex = inSearchFields.pageCurrent;
        this.paginator.pageSize = inSearchFields.pageSize;
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
          //observer.next("Roster list loaded.");
          //observer.complete();
        }
        //else {
        //  observer.next("No Connection, DB or API or Session.");
        //  observer.complete();
        //}
      //});
    });
  }

  loadRosterGrid(inSearchFields: DESOS_Search_Fields) {
    if (this.recordsList != null) {
      this.dataSource = new MatTableDataSource(this.recordsList);
      this.paginator.pageIndex = inSearchFields.pageCurrent;
      this.paginator.pageSize = inSearchFields.pageSize;
      this.dataSource.paginator = this.paginator;
      if (this.dataSource.data.length > 0) {
        this.dataSource.sort = this.sort;
      }
    }
  }

  getRecordsList(inSearchFields: DESOS_Search_Fields) {

    this.http.post<DESOS_Records_List[]>(this.apiURL + 'api/DataEntry/GetRosterList', inSearchFields).subscribe(result => {
      console.log("HereO");
      this.recordsList = this.recordsList.map(function (el) {
        console.log("triggered");
        var o = Object.assign({}, el);
        o.lastNameLowerCase = el.lastName.toLocaleLowerCase();
        return o;
      });
      this.recordsList = result;
      if (this.recordsList != null) {
        this.dataSource = new MatTableDataSource(this.recordsList);
        this.paginator.pageIndex = inSearchFields.pageCurrent;
        this.paginator.pageSize = inSearchFields.pageSize;
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.dataSource.sort = this.sort;
        }
      }
    }, error => console.error(error));
  }

  inputAdminSearch(inputSearch: DESOS_Search_Fields) {
    if (inputSearch.id != "") {
      this.getRecordsListById(inputSearch.id);
    }
    else if (inputSearch.individual_ID != "") {
      this.getRecordsListByIndividualId(inputSearch.individual_ID);
    }
    else {
      this.getRecordsList(inputSearch);
    }
  }

  editOutComes(recordId, individualId) {
    let observable = new Observable(this.myObservableTwo);
    this.showProgressSpinner(observable);
   
    let inparams = new HttpParams()
      .set('strRecordId', recordId);
    this.asyncOutcomesResult = null;
    this.getAsyncOutcomesData(inparams);

    let inparams1 = new HttpParams()
      .set('strIndividualId', individualId);
    this.asyncIndividualResult = null;
    this.getAsyncIndividualData(inparams1);
    this.tempdata.setRosterAction("edit");
    //sessionStorage.setItem("entrytype", "edit");
    this.delay(3000).then(any => {
      this.userNavigation();
    });
  }

  btnNewOutcome() {
    let observable = new Observable(this.myObservableTwo);
    this.showProgressSpinner(observable);
    const curEditIndividual: DESOS_Individuals = new DESOS_Individuals();
    const curEditOutComes: DESOS_Outcomes = new DESOS_Outcomes();
    this.tempdata.setCurrentindivitual(curEditIndividual);
    this.tempdata.setCurrentoutcome(curEditOutComes);
    this.tempdata.setRosterAction("new");
    //sessionStorage.setItem("editoutcomes", JSON.stringify(curEditOutComes));
    //sessionStorage.setItem("editindividual", JSON.stringify(curEditIndividual));
    //sessionStorage.setItem("entrytype", "new");
    this.delay(3000).then(any => {
      this.userNavigation();
    });
  }

  userNavigation() {
    this.router.navigate(['/dataentry']);
  }

  newOutComes(recordId, individualId) {
    let observable = new Observable(this.myObservableTwo);
    this.showProgressSpinner(observable);
    let inparams = new HttpParams()
      .set('strRecordId', recordId);
    this.getAsyncOutcomesData(inparams);

    let inparams1 = new HttpParams()
      .set('strIndividualId', individualId);
    this.getAsyncIndividualData(inparams1);
    //sessionStorage.setItem("entrytype", "copy");
    this.tempdata.setRosterAction("copy");
    this.delay(3000).then(any => {
      this.userNavigation();
    });
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("Outcome"));
  }

  async getAsyncOutcomesData(inparam: HttpParams) {

    //this.asyncOutcomesResult = await this.http.get<DESOS_Outcomes>(this.apiURL + 'api/DataEntry/GetDESOSOutcome', { params: inparam }).toPromise();
    this.asyncOutcomesResult = await this.dataentryServices.getOutcomeById(inparam).toPromise();

    if (this.asyncOutcomesResult != null) {
      //alert(JSON.stringify(this.asyncOutcomesResult));
      //this.individualid = this.asyncOutcomesResult.individual_ID;
      //alert(this.individualid);
      this.tempdata.setCurrentoutcome(this.asyncOutcomesResult);
      //sessionStorage.setItem("editoutcomes", JSON.stringify(this.asyncOutcomesResult));
    }
    else {
      console.log('Invalid OutComes ID.');
      alert("Invalid OutComes ID.");
    }
    //console.log('No issues, I will wait until promise is resolved..');
  };


  async getAsyncIndividualData(inparam: HttpParams) {

    //this.asyncIndividualResult = await this.http.get<DESOS_Individuals>(this.apiURL + 'api/DataEntry/GetIndividual', { params: inparam }).toPromise();
    this.asyncIndividualResult = await this.dataentryServices.getIndivitualById(inparam).toPromise();
    if (this.asyncIndividualResult != null) {
      //alert(JSON.stringify(this.asyncIndividualResult));
      this.tempdata.setCurrentindivitual(this.asyncIndividualResult);
      //sessionStorage.setItem("editindividual", JSON.stringify(this.asyncIndividualResult));
    }
    else {
      console.log('Invalid Individual ID.');
      alert("Invalid Individual ID.");
    }
  };

  loadDefaults() {
    //this.http.get<DESOS_Programs_List[]>(this.apiURL + 'api/Program/GetProgramSelectList').subscribe(result => {
    //  this.programList = result;
    //}, error => console.error(error));

    //this.http.get<DESOS_Period_List[]>(this.apiURL + 'api/DefaultData/GetReportingPeriodSelectList').subscribe(result => {
    //  this.periodList = result;
    //}, error => console.error(error));
  }

  LoadProgramList() {
    this.dataentryServices.getProgramList().subscribe((result) => {
      //this.programList = [];
      //let resultArr = JSON.parse(JSON.stringify(result));
      //resultArr.forEach(element => {
      //  const newItem = new DESOS_Programs_List();
      //  newItem.program_ID = element.Program_ID;
      //  newItem.program_Name = element.Program_Name;
      //  this.programList.push(newItem);
      //});
      this.programList = JSON.parse(JSON.stringify(result));
      if (this.programList != null) {
        ;
      }
    });
  }

  getPrimaryEnv(i) {
    //console.log({ i });
    switch (i) {
      case '0': return "Facility - Based(Sheltered Employment)";
      case '1': return "Off-site group employment placement";
      case '2': return "Competitive Integrated Employment";
      case '3': return "Self-employed";
      case '4': return "Non-employment day program";
      case '5': return "Alternative to employment";
      default: return "N/A";
    }
  }
}
