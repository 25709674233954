import { Component } from '@angular/core';
import { DESOS_UserInfo } from 'src/app/account/account';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-user-home',
    templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.css'],
  providers: [AuthenticationService]
})
/** user-home component*/
export class UserHomeComponent {
    /** user-home ctor */
  public desosuserinfo: DESOS_UserInfo;
  public mnu1Visible: boolean = true;
  public mnu2Visible: boolean = true;
  public mnu3Visible: boolean = true;
  public mnu4Visible: boolean = true;
  constructor(private authentication: AuthenticationService) {
      //if (sessionStorage.desosuserinfo != null) {
      //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);
      //}
      this.desosuserinfo = this.authentication.getAccessToken();
      if (this.desosuserinfo.blnIsDESOSManager) {
        this.mnu1Visible = false;
        this.mnu2Visible = true;
        this.mnu3Visible = true;
        this.mnu4Visible = true;
      } else if (this.desosuserinfo.blnIsUser) {
        this.mnu1Visible = false;
        this.mnu2Visible = true;
        this.mnu3Visible = false;
        this.mnu4Visible = false;
      }
    }
}
