import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './account/login/login.component';

import { ForgetpasswordComponent } from './account/forgetpassword/forgetpassword.component';
import { AccountComponent } from './account/account.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';

const routes: Routes = [{ path: '', redirectTo: '/account', pathMatch: 'full' },
  { path: 'privacy-notice', component: PrivacyNoticeComponent }
  //{
  //  path: 'account',
  //  loadChildren: './account/account.module#AccountModule'
  //},
  //{ path: 'account', redirectTo: '/login' },
  //{ path: 'login', component: LoginComponent }

  //{
  //  path: 'account',
  //  component: AccountComponent,
  //  children: [
  //    {
  //      path: 'login',
  //      component: LoginComponent
  //    },
  //    {
  //      path: 'forgetpassword',
  //      component: ForgetpasswordComponent
  //    }
  //  ]
  //}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
