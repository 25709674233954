import { Component, OnInit, Inject } from '@angular/core';
import { Router, RouteConfigLoadEnd, ActivatedRoute } from '@angular/router';
import { userLogin, DESOS_UserInfo } from '../account';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { HttpParams, HttpClient } from '@angular/common/http';
import { DESOS_Users, iDESOS_Users, UserLoginModel, DESOS_Programs, Login } from 'src/app/admin/program';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountService } from '../account.service';
import { Observable } from 'rxjs';
import { DESOS_Reporting_Periods } from 'src/app/search/default';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DefaultService } from 'src/app/services/default.service';
import { TempDataService } from 'src/app/services/temp-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AccountService, AuthenticationService, TempDataService]
})
/** login component*/
export class LoginComponent implements OnInit {
  /** login ctor */
  public loginFormControl: FormGroup;
  public desosuserinfo: DESOS_UserInfo;
  public desosreportinfo: DESOS_Reporting_Periods;
  public warningmessage: string = "";
  private asyncResult: DESOS_Users;
  private asyncResultPeriods: DESOS_Reporting_Periods;
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  constructor(private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar,
    private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private accountservice: AccountService,
    private matDialog: MatDialog, private authentication: AuthenticationService, private APIservices: DefaultService, private tempdata: TempDataService,) {

    let observable = new Observable(this.myObservableOne);
    this.showProgressSpinner(observable);
    this.authentication.removeToken();
    this.tempdata.removeToken();
    if (this.route.snapshot.params.logout == "logout") {
      //this.http.get<iDESOS_Users>(this.baseUrl + 'api/User/UserLogOut')
      //  .subscribe(result => {
      //    //this.desosUser = undefined;
      //    sessionStorage.desosuserinfo = undefined;
      //  });
      //this.authentication.removeToken();
      this.router.navigate(['/account/login']);
    }
  }

  ngOnInit() {
    this.loginFormControl = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });

    //if (this.isIE()) {
    //  //alert("This weba application does not support Internet Explorer, Please user Edge, Chrome, Firfox, Chromium");
    //  this.warningmessage = "Your browser is not supported, Please use Microsoft Edge, Google Chrome.";
    // this.loginFormControl.controls["username"].setValue("");
    // this.loginFormControl.controls["password"].setValue("");

    //  this.loginFormControl.controls["username"].updateValueAndValidity();
    //  this.loginFormControl.controls["password"].updateValueAndValidity();

    //  this.loginFormControl.controls["username"].disable();
    //  this.loginFormControl.controls["password"].disable();
    //}
    //this.accountservice.getUser("", "");
  }


  private isIE() {
    const match = navigator.userAgent.search(/(?:MSIE|Trident\/.*; rv:)/);
    let isIE = false;

    if (match !== -1) {
      isIE = true;
    }

    return isIE;
  }

  myObservableOne(observer) {
    setTimeout(() => {
      observer.next("done waiting for 1 sec");
      observer.complete();
    }, 1000);
  }

  myObservableTwo(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 2000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  public verifyUserLogin() {

    if (this.loginFormControl.valid) {
      const strusername: string = this.loginFormControl.controls["username"].value;
      const strpassword: string = this.loginFormControl.controls["password"].value;
      let inparams = new HttpParams()
        .set('strusername', strusername)
        .set('strpassword', strpassword);
      this.getAsyncData(inparams);

    }
  }

  userNavigation() {
    //if (sessionStorage.desosuserinfo != null) {
    // this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);
    this.desosuserinfo = this.authentication.getAccessToken();
    if (this.desosuserinfo != null)
    {
      if (this.desosuserinfo.passwordReset == 1) {
        this.router.navigate(['/account/passwordreset']);
      } else if (this.desosuserinfo.blnIsAdmin) {
        this.router.navigate(['/admin']);
      }
      else {
        this.router.navigate(['/dataentry/rosterlist']);
      }
    }
    else {
      this.router.navigate(['/account/login']);
    }
  }

  getAsyncData(inparam: HttpParams) {
    this.warningmessage = "";

    return Observable.create(observer => {
      //this.asyncResult =
      //this.http.get<DESOS_Users>(this.baseUrl + 'api/DefaultData/GetLoggedInUser', { params: inparam })
        //.toPromise();

      const login: Login = new Login();
      login.username = inparam.get("strusername");
      login.password = inparam.get("strpassword");

      this.APIservices.postUserLoggedInfo(login).subscribe((result) => {
        //.subscribe((result) => {
        this.asyncResult = JSON.parse(JSON.stringify(result));
        //console.log("Access", this.asyncResult.username, this.asyncResult.username != "ACCESS RESTRICTED");

        if (this.asyncResult === null) {
          this.warningmessage = "";
          console.log('Check Your Email and Password.');
          alert("Invalid Email OR Password.");
          sessionStorage.setItem("desosuserinfo", "");
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
        else if (this.asyncResult.username === "ACCESS RESTRICTED")
        {
          this.warningmessage = "";
          console.log('Contact your manager');
          alert("Manager needs to be approved. Contact your Director.");
          sessionStorage.setItem("desosuserinfo", "");
          observer.next("No Connection, DB or API or Session.");
          observer.complete();
        }
        else
        {
              this.warningmessage = "Welcome to DESOS, Loading....";
              const desosuserinfo: DESOS_UserInfo = new DESOS_UserInfo();
              desosuserinfo.strUserName = this.asyncResult.username;
              desosuserinfo.strFirstName = this.asyncResult.firstName;
              desosuserinfo.strLastName = this.asyncResult.lastName;
              desosuserinfo.strProgram = this.asyncResult.adminProgDESOS;
              desosuserinfo.passwordReset = this.asyncResult.reset_psswd_flg;
              desosuserinfo.token = this.asyncResult.token;
              if (this.asyncResult.adminLevel1 == 1) {
                desosuserinfo.blnIsAdmin = true;
                desosuserinfo.strAccessRole = "Admin";
              }
              else if (this.asyncResult.adminLevel2 == 1) {
                desosuserinfo.blnIsDESOSManager = true;
                desosuserinfo.strAccessRole = "DESOS Manager";
              }
              else {
                desosuserinfo.blnIsUser = true;
                desosuserinfo.strAccessRole = "User";
              }
              //alert(JSON.stringify(desosuserinfo));
              this.authentication.login(desosuserinfo);
              //this.getAsyncDataA();
              //this.userNavigation();
              //sessionStorage.setItem("desosuserinfo", JSON.stringify(desosuserinfo));
              observer.next("Welcome to DESOS...");
              //call complete if you want to close this stream (like a promise)
              observer.complete();
          }
        });
    });
    //console.log('No issues, I will wait until promise is resolved..');
  }

  async getAsyncDataA() {

   // this.asyncResultPeriods = await this.http.get<DESOS_Reporting_Periods>(this.baseUrl + 'api/DefaultData/GetCurrentReportingPeriod').toPromise();

    this.asyncResultPeriods = await this.APIservices.getCurrentReportingPeriod().toPromise();

    if (this.asyncResultPeriods != null && this.asyncResultPeriods != undefined) {
     this.desosreportinfo = JSON.parse(JSON.stringify(this.asyncResultPeriods));
      //alert(JSON.stringify(desosuserinfo));
      this.authentication.setReport(this.desosreportinfo);
      //sessionStorage.setItem("desosreportinfo", JSON.stringify(this.desosreportinfo));
    }
    else {
      console.log('No Current Reporting Period Found.');
      const desosreportinfo: DESOS_Reporting_Periods = new DESOS_Reporting_Periods();
      desosreportinfo.mm = "NO";
      desosreportinfo.dd = "RP";
      //sessionStorage.setItem("desosreportinfo", JSON.stringify(desosreportinfo));
      this.authentication.setReport(this.desosreportinfo);
    }
    //console.log('No issues, I will wait until promise is resolved..');
  };

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("Login"));
  }

  btnClick = function () {
    this.router.navigate(['/account/forgetpassword']);
  };

  hasError = (controlName: string, errorName: string) => {
    return this.loginFormControl.controls[controlName].hasError(errorName);
  }

  OnLogin() {
    const strusername: string = this.loginFormControl.controls["username"].value;
    const strpassword: string = this.loginFormControl.controls["password"].value;
    let inparams = new HttpParams()
      .set('strusername', strusername)
      .set('strpassword', strpassword);

    // let observable = new Observable(this.getAsyncData(inparams));
    this.showProgressSpinner(this.getAsyncData(inparams));
    let observable = new Observable(this.myObservableOne);
    this.showProgressSpinner(observable);
    this.getAsyncDataA();
    this.delay(2000).then(any => {
      this.userNavigation();
    });
  }

}



