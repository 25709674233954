import { Component, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { helpText, DESOS_Outcomes } from '../dataentry';
import { dataentry_tab3_quest1_2, dataentry_tab3_quest3 } from '../dataentry-tab3';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataentryTab4Component } from '../dataentry-tab4/dataentry-tab4.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TempDataService } from 'src/app/services/temp-data.service';
import { DataentryService } from '../../services/dataentry.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DESOS_UserInfo } from 'src/app/account/account';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-dataentry-tab3',
  templateUrl: './dataentry-tab3.component.html',
  styleUrls: ['./dataentry-tab3.component.css'],
  providers: [AuthenticationService, TempDataService],
})
/** dataentry-tab3 component*/
export class DataentryTab3Component implements OnInit {
  /** dataentry-tab3 ctor */
  questionData: any;
  d18ChunkNum: number = 4;

  public desosuserinfo: DESOS_UserInfo;

  helpMessage: helpText;
  

  public DEtab3Quest1: dataentry_tab3_quest1_2;
  public DEtab3Quest2: dataentry_tab3_quest3;
 // public DEtab2Quest345: dataentry_tab2_quest3_4_5;
  @Output() q1change = new EventEmitter<string>();
  private curEditOutComes: DESOS_Outcomes;
  public tab3FormGroup: FormGroup;
  constructor(private snackBar: MatSnackBar, private tempdata: TempDataService, private zone: NgZone, private APIservices: DataentryService, private authentication: AuthenticationService) {
    this.helpMessage = new helpText();
    this.DEtab3Quest1 = new dataentry_tab3_quest1_2();
    this.DEtab3Quest2 = new dataentry_tab3_quest3();
  }

  ngOnInit() {

    //if (sessionStorage.editoutcomes != null) {
    //  this.curEditOutComes = JSON.parse(sessionStorage.editoutcomes);
    //}
    this.desosuserinfo = this.authentication.getAccessToken();
    this.tab3FormGroup = new FormGroup({
      communication: new FormControl('', [Validators.required]),
      lpa: new FormControl('', [Validators.required]),
      q1val: new FormControl('', [Validators.required]),
      q1subval3: new FormControl(''),
      q1subval4: new FormControl(''),
      q1subval5: new FormControl(''),
      q1subval6: new FormControl(''),
    });
    this.fetchQuestionWithOptions();
    
  }
  
  async fetchQuestionWithOptions() {
    try {
      // Define the range of question identifiers
      const startQuestionIndex = 17;
      const endQuestionIndex = 18;
      const questionPrefix = 'd';

      // Initialize an object to hold the data for each question
      this.questionData = {};

      // Fetch questions within the range
      for (let questionIndex = startQuestionIndex; questionIndex <= endQuestionIndex; questionIndex++) {
        const questionNumber = `${questionPrefix}${questionIndex}`;
        const inparam = new HttpParams().set('questionNumber', questionNumber);
        this.questionData[questionNumber] = await this.APIservices.getQuestionWithOptions(inparam).toPromise();
      }

      let additionalNumber = '1-0';
      let additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();
      additionalNumber = '1-1';
      additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();
      additionalNumber = '1-2';
      additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      // Check if data for all questions is retrieved
      const allDataReceived = Object.keys(this.questionData).length >= (endQuestionIndex - startQuestionIndex + 1 + 3) &&
        Object.values(this.questionData).every(data => !!data);

      if (allDataReceived) {
        console.log('Data retrieved:', this.questionData[`${questionPrefix}${endQuestionIndex}`]); // Example log for the first question
        this.curEditOutComes = this.tempdata.getCurrentoutcome();
        console.log('curEditOutComes:', this.curEditOutComes);

        this.q1change.emit(this.curEditOutComes.q1val);
        this.initFormGroup();
      } else {
        console.log('No data received');
      }
    } catch (error) {
      console.error('Failed to fetch question options:', error);
    }
  };
  
  initFormGroup() {
    
    this.DEtab3Quest1.optquestion1Selected = this.curEditOutComes.communication;
    this.tab3FormGroup.controls["communication"].setValue(this.curEditOutComes.communication);
    this.DEtab3Quest1.strquestion2Selected = this.curEditOutComes.lpa;
    this.tab3FormGroup.controls["lpa"].setValue(this.curEditOutComes.lpa);
    this.isQ2Selected(this.DEtab3Quest1.strquestion2Selected);

    this.DEtab3Quest2.optquestion3Selected = this.curEditOutComes.q1val;
    this.tab3FormGroup.controls["q1val"].setValue(this.curEditOutComes.q1val);
    this.q1SubValidationUpdate(this.curEditOutComes.q1val);
    this.DEtab3Quest2.opt5question3Selected = (this.DEtab3Quest2.optquestion3Selected == "4" ? true : false);
    this.DEtab3Quest2.opt6question3Selected = (this.DEtab3Quest2.optquestion3Selected == "5" ? true : false);

    this.DEtab3Quest2.optquestion4Selected = this.curEditOutComes.q1subval3;
    this.tab3FormGroup.controls["q1subval3"].setValue(this.curEditOutComes.q1subval3);
    this.DEtab3Quest2.opt4question4Selected = (this.DEtab3Quest2.optquestion4Selected == "3" ? true : false);
    this.DEtab3Quest2.optquestion4OtherDes = this.curEditOutComes.q1subval4;
    this.tab3FormGroup.controls["q1subval4"].setValue(this.curEditOutComes.q1subval4);

    this.DEtab3Quest2.optquestion5Selected = this.curEditOutComes.q1subval5;
    this.tab3FormGroup.controls["q1subval5"].setValue(this.curEditOutComes.q1subval5);
    this.DEtab3Quest2.opt7question5Selected = (this.DEtab3Quest2.optquestion5Selected == "5" ? true : false);
    this.DEtab3Quest2.optquestion5OtherDes = this.curEditOutComes.q1subval6;
    this.tab3FormGroup.controls["q1subval6"].setValue(this.curEditOutComes.q1subval6);

    //EMIT
    this.q1change.emit(this.curEditOutComes.q1val);
  }

  getEntries(curNewOutComes: DESOS_Outcomes) : DESOS_Outcomes  {
    try {

      curNewOutComes.communication = this.tab3FormGroup.controls["communication"].value;
      curNewOutComes.lpa = this.getLPA();
      curNewOutComes.q1val = this.tab3FormGroup.controls["q1val"].value;
      curNewOutComes.q1subval3 = this.tab3FormGroup.controls["q1subval3"].value;
      curNewOutComes.q1subval4 = this.tab3FormGroup.controls["q1subval4"].value;
      curNewOutComes.q1subval5 = this.tab3FormGroup.controls["q1subval5"].value;
      curNewOutComes.q1subval6 = this.tab3FormGroup.controls["q1subval6"].value;

     //sessionStorage.setItem("editoutcomes", JSON.stringify(this.curEditOutComes));

      console.log("Tab 3: ",{ curNewOutComes });
    }
    catch (Error) {
      console.log("Tab3:" + Error.message);
    }

    return curNewOutComes;
  }

  hasError = (controlName: string, errorName: string) => {
    return this.tab3FormGroup.controls[controlName].hasError(errorName);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
    });
  }



  isQ2Selected(inValue: string) {

    const blnResults: boolean = false;

    if (inValue.indexOf('LPA_RR') >= 0) {
      this.DEtab3Quest1.chk1question2Selected = true;
    }
    if (inValue.indexOf('TLPA_Trans') >= 0) {
      this.DEtab3Quest1.chk2question2Selected = true;
    }
    if (inValue.indexOf('LPA_OPA') >= 0) {
      this.DEtab3Quest1.chk3question2Selected = true;
    }
    if (inValue.indexOf('LPA_TMPA') >= 0) {
      this.DEtab3Quest1.chk4question2Selected = true;
    }
    if (inValue.indexOf('LPA_ED') >= 0) {
      this.DEtab3Quest1.chk5question2Selected = true;
    }
    if (inValue.indexOf('LPA_MNN') >= 0) {
      this.DEtab3Quest1.chk6question2Selected = true;
    }
    if (inValue.indexOf('LPA_TPA') >= 0) {
      this.DEtab3Quest1.chk7question2Selected = true;
    }
    if (inValue.indexOf('LPA_NA') >= 0) {
      this.DEtab3Quest1.chk8question2Selected = true;
    }
  }

  getLPA(): string {

     var strResults = "";

    if (this.DEtab3Quest1.chk1question2Selected) {
      strResults = "LPA_RR,"
    }
    if (this.DEtab3Quest1.chk2question2Selected) {
      strResults = strResults + "TLPA_Trans,"
    }
    if (this.DEtab3Quest1.chk3question2Selected) {
      strResults = strResults + "LPA_OPA,"
    }
    if (this.DEtab3Quest1.chk4question2Selected) {
      strResults = strResults + "LPA_TMPA,"
    }
    if (this.DEtab3Quest1.chk5question2Selected) {
      strResults = strResults + "LPA_ED,"
    }
    if (this.DEtab3Quest1.chk6question2Selected) {
      strResults = strResults + "LPA_MNN,"
    }
    if (this.DEtab3Quest1.chk7question2Selected) {
      strResults = strResults + "LPA_TPA,"
    }
    if (this.DEtab3Quest1.chk8question2Selected) {
      strResults = strResults + "LPA_NA"
    }
    return strResults;
  }

  closeSnackBar() {
    //this.snackBar.dismiss();
  }

  onChangeQuest2(event: MatCheckboxChange) {
    if (event.checked == true) {
      this.DEtab3Quest1.chk8question2Selected = false;
    }
    this.tab3FormGroup.controls["lpa"].setValue(this.getLPA());
  }

  onChangeQuest2None(event: MatCheckboxChange) {
    if (event.source.name == "chk8question2Selected" && event.checked == true) {
      this.DEtab3Quest1.chk1question2Selected = false;
      this.DEtab3Quest1.chk2question2Selected = false;
      this.DEtab3Quest1.chk3question2Selected = false;
      this.DEtab3Quest1.chk4question2Selected = false;
      this.DEtab3Quest1.chk5question2Selected = false;
      this.DEtab3Quest1.chk6question2Selected = false;
      this.DEtab3Quest1.chk7question2Selected = false;
    }
    this.tab3FormGroup.controls["lpa"].setValue(this.getLPA());
  }

  q1SubValidationUpdate(q1val: string) {
    if (q1val == "4") {
      this.tab3FormGroup.controls["q1subval3"].setValidators(Validators.required);
      this.tab3FormGroup.controls["q1subval3"].updateValueAndValidity();
      this.tab3FormGroup.controls["q1subval5"].setValidators(null);
      this.tab3FormGroup.controls["q1subval5"].updateValueAndValidity();
    } else if (q1val == "5") {
      this.tab3FormGroup.controls["q1subval5"].setValidators(Validators.required);
      this.tab3FormGroup.controls["q1subval5"].updateValueAndValidity();
      this.tab3FormGroup.controls["q1subval3"].setValidators(null);
      this.tab3FormGroup.controls["q1subval3"].updateValueAndValidity();
    }
    else {
      this.tab3FormGroup.controls["q1subval5"].setValidators(null);
      this.tab3FormGroup.controls["q1subval3"].setValidators(null);
      this.tab3FormGroup.controls["q1subval3"].updateValueAndValidity();
      this.tab3FormGroup.controls["q1subval5"].updateValueAndValidity();
    }
  }

  OnQuest3OptSelected(event: MatRadioChange) {
    this.setOtherSub4("0");
    this.setOtherSub6("0");
    if (event.value == "4") {
      this.DEtab3Quest2.opt5question3Selected = true;
      this.DEtab3Quest2.opt6question3Selected = false;
      this.DEtab3Quest2.opt4question4Selected = false;
      this.DEtab3Quest2.opt7question5Selected = false;
      this.tab3FormGroup.controls["q1subval3"].setValue("");
      this.tab3FormGroup.controls["q1subval4"].setValue("");
      this.tab3FormGroup.controls["q1subval5"].setValue("");
      this.tab3FormGroup.controls["q1subval6"].setValue("");
      this.q1SubValidationUpdate("4");
      this.q1change.emit("4");
    }
    else if (event.value == "5"){
      this.DEtab3Quest2.opt5question3Selected = false;
      this.DEtab3Quest2.opt6question3Selected = true;
      this.DEtab3Quest2.opt4question4Selected = false;
      this.DEtab3Quest2.opt7question5Selected = false;
      this.tab3FormGroup.controls["q1subval3"].setValue("");
      this.tab3FormGroup.controls["q1subval4"].setValue("");
      this.tab3FormGroup.controls["q1subval5"].setValue("");
      this.tab3FormGroup.controls["q1subval6"].setValue("");
      this.q1SubValidationUpdate("5");
      this.q1change.emit("5");
    }
    else {
      this.DEtab3Quest2.opt5question3Selected = false;
      this.DEtab3Quest2.opt6question3Selected = false;
      this.DEtab3Quest2.opt4question4Selected = false;
      this.DEtab3Quest2.opt7question5Selected = false;
      this.tab3FormGroup.controls["q1subval3"].setValue("");
      this.tab3FormGroup.controls["q1subval4"].setValue("");
      this.tab3FormGroup.controls["q1subval5"].setValue("");
      this.tab3FormGroup.controls["q1subval6"].setValue("");
      this.q1SubValidationUpdate(event.value);
      this.q1change.emit(event.value);
    }

  }

  setOtherSub4(strValue: string) {
    if (strValue == "3") {
      this.tab3FormGroup.controls["q1subval4"].setValidators(Validators.required);
      this.tab3FormGroup.controls["q1subval4"].updateValueAndValidity();
    }
    else {
      this.tab3FormGroup.controls["q1subval4"].setValidators(null);
      this.tab3FormGroup.controls["q1subval4"].updateValueAndValidity();
    }
  }

  setOtherSub6(strValue: string) {
    if (strValue == "5") {
      this.tab3FormGroup.controls["q1subval6"].setValidators(Validators.required);
      this.tab3FormGroup.controls["q1subval6"].updateValueAndValidity();
    }
    else {
      this.tab3FormGroup.controls["q1subval6"].setValidators(null);
      this.tab3FormGroup.controls["q1subval6"].updateValueAndValidity();
    }
  }

  onChangeQuest4(event: MatRadioChange) {
    if (event.value == "3") {
      this.DEtab3Quest2.opt4question4Selected = true;
    }
    else {
      this.DEtab3Quest2.opt4question4Selected = false;
    }
    this.setOtherSub4(event.value);
  }

  onChangeQuest5(event: MatRadioChange) {
    if (event.value == "5") {
      this.DEtab3Quest2.opt7question5Selected = true;
    }
    else {
      this.DEtab3Quest2.opt7question5Selected = false;
    }
    this.setOtherSub6(event.value);
  }
}
