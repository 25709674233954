import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DESOS_UserInfo } from '../account/account';
import { BnNgIdleService } from 'bn-ng-idle';


@Injectable()
export class AuthenticationService {
  token = {
    refresh_token: 'refreshtokencode',
    exp: new Date((new Date().getDate() + 1)),
    access_token : new DESOS_UserInfo()
    //access_token: {
    //  username: 'user',
    //  roles: ['Admin', 'RegisteredUser', 'Super User']
    //}
  };

  guesttokenKey: string = "desos2019_gtoken";
  tokenKey: string = "desos2019_utoken";
  reportKey: string = "desos2019_ureport";
  private desosuserinfo: DESOS_UserInfo;
  constructor(private router: Router, private bnIdle: BnNgIdleService) { }

  login(loggedinuser: DESOS_UserInfo) {
    this.token.access_token = loggedinuser;
    this.setToken(this.token);

    //this.router.navigate(['admin', 'dashboard']);
    this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {

      console.log("session started");

      if (isTimedOut) {
        console.log('session expired');
        this.removeToken();
        this.router.navigate(['/account/login']);
        alert("Your session has expired. Please log in.");
        this.bnIdle.stopTimer();
      }
    });
  }

  logout() {
    this.removeToken();
    this.router.navigate(['/account/login']);
  }

  getGuestToken() {
    return JSON.parse(localStorage.getItem(this.guesttokenKey));
  }

  setGuestToken(guesttoken) {
    localStorage.setItem(this.guesttokenKey, JSON.stringify(guesttoken));
  }

  getToken() {
    return JSON.parse(localStorage.getItem(this.tokenKey));
  }

  setToken(token) {
    localStorage.setItem(this.tokenKey, JSON.stringify(token));
  }

  getReport() {
    return JSON.parse(localStorage.getItem(this.reportKey));
  }

  setReport(report) {
    localStorage.setItem(this.reportKey, JSON.stringify(report));
  }

  getAccessToken() {
    return JSON.parse(localStorage.getItem(this.tokenKey))['access_token'];
  }

  getUserAccessToken() {
    this.desosuserinfo = JSON.parse(localStorage.getItem(this.tokenKey))['access_token'];
    return this.desosuserinfo.token;
  }
  isAuthenticated() {
    let token = localStorage.getItem(this.tokenKey);

    if (token) {
      return true;
    }
    else {
      return false;
    }
  }

  isGuestAuthenticated() {
    let token = localStorage.getItem(this.guesttokenKey);

    if (token) {
      return true;
    }
    else {
      return false;
    }
  }

  refreshToken() {
    this.token.exp = new Date((new Date().getDate() + 1));
    this.setToken(this.token);
  }

  removeToken() {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.reportKey);
  }

}
