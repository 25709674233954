<form [formGroup]="adminSearch">
  <div class="row">
    <div class="col-sm-6 dataentry-column">
      <mat-card class="dataentry-card">
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Individual#</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-input">
              <input matInput placeholder="Individual" formControlName="individualid" name="Individual" maxlength="10" type="number">
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Client Name</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-input">
              <input matInput placeholder="First Name" name="firstName" formControlName="firstname">
            </mat-form-field>
            <mat-form-field class="mat-input" [style.width.px]="50">
              <input matInput placeholder="MI" name="middleName" maxlength="1" formControlName="middlename">
            </mat-form-field>
            <mat-form-field class="mat-input">
              <input matInput placeholder="Last name" name="lastname" formControlName="lastname">
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Program Name</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-dropdown">
              <mat-select placeholder="Select a DESOS Program" formControlName="program">
                <mat-option *ngFor="let program of programList" [value]="program.program_ID">
                  {{program.program_Name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('program', 'required')">Program is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">City</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-dropdown">
              <mat-select placeholder="Select a City" formControlName="city">
                <mat-option *ngFor="let item of cityList" [value]="item.id">
                  {{item.city}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">County Of Service</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-dropdown">
              <mat-select placeholder="Select a County" formControlName="county">
                <mat-option *ngFor="let item of countyList" [value]="item.id">
                  {{item.county}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">ZIP Code</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-input">
              <input matInput placeholder="ZIP Code" name="zipcode" maxlength="5" type="number" formControlName="zipcode">
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-sm-6 dataentry-column">
      <mat-card class="dataentry-card">
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Record#</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-input">
              <input matInput placeholder="Outcome ID" formControlName="outcomeid" name="Record" maxlength="10" type="number">
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Reporting Period</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-dropdown">
              <mat-select placeholder="Select a Period" formControlName="reportingperiod">
                <mat-option *ngFor="let item of periodList" [value]="item.id">
                  {{item.period}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Client Date of Birth</mat-label>
          </div>
          <div class="col-sm-4 p-0">
            <mat-form-field class="mat-dropdown">
              <mat-select placeholder="Select a Month" formControlName="dobmonth">
                <mat-option *ngFor="let item of monthList" [value]="item.id">
                  {{item.month}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-4">
            <mat-form-field class="mat-input" [style.width.px]="80">
              <input matInput placeholder="Year(YY)" name="dobmonth" maxlength="2" numeric-only formControlName="dobyear">
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Gender</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-dropdown">
              <mat-select placeholder="Select a Gender" formControlName="gender">
                <mat-option *ngFor="let item of genderList" [value]="item.id">
                  {{item.gender}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Race</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-form-field class="mat-dropdown">
              <mat-select placeholder="Select a Race" formControlName="race">
                <mat-option *ngFor="let item of raceList" [value]="item.id">
                  {{item.race}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row p-0 pb-3">
          <div class="col-sm-4 p-0">
            <mat-label class="text-primary">Services</mat-label>
          </div>
          <div class="col-sm-8 p-0">
            <mat-checkbox class="data-radio-button" name="chk1question19Selected" formControlName="services">Include Those No Longer Receiving Services</mat-checkbox>
          </div>
        </div>
        <mat-card-actions>
          <!--<button mat-raised-button [disabled]="!accountRequest.valid" color="primary">Save</button>-->
        </mat-card-actions>
      </mat-card>
    </div>
    <button mat-raised-button (click)="btnFindClick()" type="button" color="primary">Find</button>
  </div>
</form>
