import { NgModule } from '@angular/core';
import { SearchRecordsComponent } from './search-records.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';



const searchRoutes: Routes = [
  {
    path: 'searchrecords',
    canActivate: [AuthGuardService],
    component: SearchRecordsComponent,
    children: [
      {
        path: '',
        component: SearchRecordsComponent
      },
    ],
  },
];


@NgModule({
  imports: [
    RouterModule.forChild(searchRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SearchRoutingModule {
}
