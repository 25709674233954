import { Component, OnInit } from '@angular/core';
import { DESOS_UserInfo } from 'src/app/account/account';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-usersmenu',
    templateUrl: './usersmenu.component.html',
  styleUrls: ['./usersmenu.component.css'],
  providers: [AuthenticationService]
})
/** usersmenu component*/
export class UsersmenuComponent implements OnInit {
    /** usersmenu ctor */
  public desosuserinfo: DESOS_UserInfo;
  constructor(private authentication: AuthenticationService) {

  }

  ngOnInit() {
    this.desosuserinfo = this.authentication.getAccessToken();
    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);
    //}
  }
}
