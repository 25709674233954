<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<div class="row p-3">
  <form class="col-sm-12 p-0 shadow-lg dataentry-card rounded  dataentry-column" [formGroup]="passwordReset">
    <div class="headding_account">
      <h4 class="text-md-left text-white pl-2">Reset Password</h4>
    </div>
    <p *ngIf="warningmessage" class="text-danger">{{warningmessage}}</p>
    <div class="form-group pl-3 pr-3"><h6 class="text-primary">Please enter the email address for your account, then click "Reset Password" . An email will be sent to your address with a new password. If you can't remember your account's email address, send a general request and a DESOS administrator try to help you. </h6></div>
    <div class="form-group pl-3 pr-3">
      <mat-form-field class="example-full-width p-2">
        <input matInput placeholder="Email" name="email" formControlName="email" required>
        <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
        <mat-error *ngIf="hasError('email', 'email')">Valid Email is required</mat-error>
      </mat-form-field>
    </div>
    <div class="example-button-row p-2">
      <button [disabled]="!passwordReset.valid" mat-raised-button color="primary" type="button" (click)="btnResetClick()">Reset Password</button>
      <button mat-raised-button color="primary" (click)="onBack()">Back</button>
    </div>
    <div class="example-button-row p-2">
      <button mat-button color="primary" type="button" [routerLink]="['/account/login']">Log In</button>
      <button mat-button color="primary" type="button" [routerLink]="['/account/accountrequest']">Request an account?</button>
      <button mat-button color="primary" type="button" [routerLink]="['/account/generalrequest']">General request</button>
    </div>
  </form>
</div>
