import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { iDESOS_Users, UserLoginModel, DESOS_Users, Login } from '../admin/program';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';



@Injectable()
export class AccountService {
  private apiURL: string = "";
  private loguser: UserLoginModel;
  constructor(private _http: HttpClient, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string ) {
    this.apiURL = baseUrl;
  }

  getUser(inusername: string, inpassword: string): Observable<iDESOS_Users[]> {
    let params = new HttpParams()
      .set('username', inusername)
      .set('password', inpassword);

    const login: Login = new Login();
    login.username = inusername;
    login.password = inpassword;

   //this.loguser = UserLoginModel();
   // this.loguser.username = username;
   // this.loguser.password = password;
   // const loginuser: UserLoginModel = { username: inusername, password: inpassword} as UserLoginModel;

    var body = {  username: inusername, password: inpassword  };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
      })
    };

    return this.http.post<iDESOS_Users[]>(this.apiURL + 'api/DefaultData/PostLoggedInUser', login, httpOptions)
      .pipe(retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  loginUser(username: string, password: string): Observable<string> {
    let params = new HttpParams()
      .set('username', username)
      .set('password', password);
      this.loguser = new UserLoginModel();
      this.loguser.username = username;
      this.loguser.password = password;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
      })
    };

    return this.http.post<string>(this.baseUrl + 'api/User/UserLogIn', this.loguser);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
