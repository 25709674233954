<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<div class="row p-3">
  <form class="col-lg-12 p-0 shadow-lg dataentry-card rounded  dataentry-column" [class.mat-elevation-z8]="true" [formGroup]="loginFormControl">
    <div class="headding_account">
      <h4 class="text-md-left text-white pl-2">User LogIn</h4>
    </div>
    <p *ngIf="warningmessage" class="text-danger">{{warningmessage}}</p>
    <div class="form-group pl-3 pr-3 col-12">
      <mat-form-field class="example-full-width p-2">
        <input matInput placeholder="Email" name="username" formControlName="username" class="text-primary" required>
        <mat-error *ngIf="hasError('username', 'required')">User Name/Email is required</mat-error>
        <mat-error *ngIf="hasError('username', 'email')">Enter valid email!</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group pl-3 pr-3 col-sm-12">
      <mat-form-field class="example-full-width p-2">
        <input matInput placeholder="Password" name="password" formControlName="password" type="password" class="text-primary" required>
        <mat-error *ngIf="hasError('password', 'required')">Password is required!</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group pl-4 pr-3 col-sm-5 p-2">
      <button mat-raised-button color="primary" [disabled]="!loginFormControl.valid" (click)="OnLogin()">Log In</button>
    </div>
    <div class="example-button-row p-2">
      <button mat-button color="primary"  type="button"  [routerLink]="['/account/forgetpassword']">Forgot Password?</button>
      <button mat-button color="primary"  type="button"  [routerLink]="['/account/accountrequest']">Request an account?</button>
      <button mat-button color="primary"  type="button"  [routerLink]="['/account/generalrequest']">General request</button>
    </div>
  </form>
</div>

