import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportsComponent } from './reports.component';
import { ReportDashboardComponent } from './report-dashboard/report-dashboard.component';
import { ReportParamComponent } from './report-param/report-param.component';
import { ReportViewComponent } from './report-view/report-view.component';
import { AuthGuardService } from '../services/auth-guard.service';




const reportsRoutes: Routes = [
  {
    path: 'reports',
    canActivate: [AuthGuardService],
    component: ReportsComponent,
    children: [
      {
        path: 'dashboard',
        component: ReportDashboardComponent
      },
      {
        path: 'reportparams',
        component: ReportParamComponent
      },
      {
        path: 'reportparams/:id',
        component: ReportParamComponent
      },
      {
        path: 'reportview',
        component: ReportViewComponent
      },
      {
        path: '',
        component: ReportDashboardComponent
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(reportsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ReportsRoutingModule {
}
