import { Component, Inject, OnInit } from '@angular/core';
import { DESOS_Records_List, DESOS_Search_Fields } from 'src/app/dataentry/dataentry';
import { DESOS_Programs_List } from 'src/app/admin/program';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DESOS_Reporting_Periods, DESOS_Period_List } from 'src/app/search/default';
import { DESOS_UserInfo } from 'src/app/account/account';
import { DESOS_Reports_Param } from '../reports';
import { Location } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TempDataService } from 'src/app/services/temp-data.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-report-param',
  templateUrl: './report-param.component.html',
  styleUrls: ['./report-param.component.css'],
  providers: [AuthenticationService, TempDataService],
})
/** report-param component*/
export class ReportParamComponent implements OnInit {
  /** report-param ctor */
  private apiURL: string;
  private resultsArray: DESOS_Records_List[];
  private recordsList: DESOS_Records_List[];
  public programList: DESOS_Programs_List[];
  public desosreportinfo: DESOS_Reporting_Periods;
  public desosuserinfo: DESOS_UserInfo;
  public reportparam: FormGroup;
  public periodList: DESOS_Period_List[];
  public reportid: string;
  constructor(private location: Location, private route: ActivatedRoute, private router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private matDialog: MatDialog, private authentication: AuthenticationService, private tempdata: TempDataService, private APIServices : ReportService) {
    this.apiURL = baseUrl;
    //this.getRecordsListById("110");
    let observable = new Observable(this.myObservableTwo);
    this.showProgressSpinner(observable);
    if (this.route.snapshot.params.id != null)
      this.reportid = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.reportparam = new FormGroup({
      program: new FormControl('', [Validators.required]),
      reportingperiod: new FormControl('', [Validators.required])
    }, { updateOn: 'blur' });

    this.desosreportinfo = this.authentication.getReport();

    this.desosuserinfo = this.authentication.getAccessToken();
    //if (sessionStorage.desosreportinfo != null) {
    //  this.desosreportinfo = JSON.parse(sessionStorage.desosreportinfo);
    //}

    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);
    //}

    this.loadDefaults();



    if (!this.desosuserinfo.blnIsAdmin) {
      this.reportparam.controls["program"].setValue(this.desosuserinfo.strProgram);
      this.reportparam.controls["program"].disable();
      // this.reportparam.controls["reportingperiod"].disable();
    }
    else {
      if (this.reportid == "11" ) {
        this.reportparam.controls["program"].setValue("-1");
        this.reportparam.controls["program"].disable();
      }
    }
    this.reportparam.controls["reportingperiod"].setValue(this.desosreportinfo.mm + this.desosreportinfo.dd);

  }

  myObservableTwo(observer) {
    setTimeout(() => {
      observer.next("done waiting for 1 sec");
      observer.complete();
    }, 1000);
  }

  myObservableThree(observer) {
    setTimeout(() => {
      observer.next("done waiting for 3 sec");
      observer.complete();
    }, 3000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }


  onChangeProgram(event: MatSelectChange) {
    //alert(event.value);
    //let observable = new Observable(this.myObservableThree);
    // this.showProgressSpinner(observable);
    const searchfields: DESOS_Reports_Param = new DESOS_Reports_Param();
    searchfields.program_ID = event.value;
    searchfields.reporting_Period =  this.reportparam.controls["reportingperiod"].value;
    searchfields.id = this.reportid;
    //searchfields.services = (this.reportparam.controls["services"].value == true ? "1" : "");
    //if (this.desosreportinfo != null) {
    //  search.reporting_Period = this.rosterlist.controls["period"].value;
    //}

    // sessionStorage.setItem("searchfields", JSON.stringify(searchfields));
    //this.getRecordsList(searchfields);
    //this.selectedprogramid = event.value; 
  }

  onChangePeriod(event: MatSelectChange) {
    const searchfields: DESOS_Reports_Param = new DESOS_Reports_Param();
    searchfields.program_ID = this.reportparam.controls["program"].value;
    searchfields.reporting_Period = event.value;
    searchfields.id = this.reportid;
    //sessionStorage.setItem("searchfields", JSON.stringify(searchfields));
    //this.getRecordsList(searchfields);
  }

  loadDefaults() {


    //this.http.get<DESOS_Programs_List[]>(this.apiURL + 'api/Program/GetProgramSelectList').subscribe(result => {
      this.APIServices.getProgramList().subscribe(result => {
      this.programList = result;
    }, error => console.error(error));

    if (!this.desosuserinfo.blnIsAdmin) {
      //this.http.get<DESOS_Period_List[]>(this.apiURL + 'api/DefaultData/GetActivePeriodSelectList').subscribe(result => {
      this.APIServices.getActiveReportingPeriodList().subscribe(result => {
        this.periodList = result;
      }, error => console.error(error));
    }
    else {
      //this.http.get<DESOS_Period_List[]>(this.apiURL + 'api/DefaultData/GetReportingPeriodSelectList').subscribe(result => {
      this.APIServices.getReportingPeriodList().subscribe(result => {
        this.periodList = result;
        console.log({ result });
      }, error => console.error(error));
    }

  }

  btnContinueClick() {
    const reportparams: DESOS_Reports_Param = new DESOS_Reports_Param();
    reportparams.program_ID = this.reportparam.controls["program"].value;
    reportparams.reporting_Period = this.reportparam.controls["reportingperiod"].value;;
    reportparams.id = this.reportid;
    if (reportparams.reporting_Period != "-1") {
      //sessionStorage.setItem("reportparams", JSON.stringify(reportparams));
      this.tempdata.setReportparams(reportparams);
      this.router.navigate(['/reports/reportview']);
    }
    else {
      alert("Reporting Period!");
    }
  }

  btnBackClick() {
    //this.router.navigate(['/admin/programlist']);
    this.location.back();
  }
}
