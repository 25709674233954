export class dataentry_tab8_quest21to23 {
  public question21Text: string = "";
  public optquestion21Selected: string = "";

  public opt5question21Selected: boolean = false;
  public opt6question21Selected: boolean = false;

  public opt4question21ASelected: boolean = false;
  public optquestion21ASelected: string = "";
  public optquestion21AOtherDes: string = "";

  public opt7question21BSelected: boolean = false;
  public optquestion21BSelected: string = "";
  public optquestion21BOtherDes: string = "";

  public question22Text: string = "";
  public strquestion22Hours: string = "";
  public strquestion22Minutes: string = "";

  public question23Text: string = "";
  public strquestion23Pay: string = "";

  public opt5or6question21BSelected: boolean = false;
}
