export class dataentry_tab6_quest13to17 {
  public question13Text: string = "";
  public strquestion13Selected: string = "";

  public question14Text: string = "";
  public strquestion14Selected: string = "";

  public question15Text: string = "";
  public strquestion15Selected: string = "";

  public question16Text: string = "";
  public strquestion16Selected: string = "";
  public opt1question16Selected: boolean = false;

  public chk1question17Selected: boolean = false;
  public chk2question17Selected: boolean = false;
  public chk3question17Selected: boolean = false;
  public chk4question17Selected: boolean = false;
  public chk5question17Selected: boolean = false;
  public chk6question17Selected: boolean = false;
  public strquestion17OtherDes: string = "";

}
