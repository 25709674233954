<div class="register-photo" style="height: 100vh;">
  <div class="container p-1 rounded" style="background:url(./assets/images/home_page_logo_NO.gif);background-size: cover;">
    <div class="row shadow-lg p-0 rounded">
      <div class="col-md-3 col-lg-3">
      </div>
      <div class="col-md-9 col-lg-9">
        <img src="./assets/images/home_page_logo.gif" />
      </div>
    </div>
    <div class="row p-1">
      <div class="col-sm-5">
        <div class="col-sm-12 pt-4">
          <div id="carouselExampleIndicators" class="carousel slide col-sm-12" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner pt-3 col-md-12 rounded">
              <div class=" carousel-item active">
                <img src="./assets/images/rotate_home_0.jpg" class="img-responsive img1" style="width: 100%;height: 300px;" alt="...">
              </div>
              <div class=" carousel-item">
                <img src="./assets/images/rotate_home_1.jpg" class="img-responsive img1" style="width: 100%;height: 300px;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="./assets/images/rotate_home_2.jpg" class="img-responsive img1" style="width: 100%;height: 300px;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="./assets/images/rotate_home_3.jpg" class="img-responsive img1" style="width: 100%;height: 300px;" alt="...">
              </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm-7 p-4">
        <router-outlet #routerOutlet="outlet"></router-outlet>
      </div>
    </div>
  </div>
  <div class="features-boxed">
    <div class="container">
      <div class="row justify-content-center features">
        <div class="col-sm-6 col-md-5 col-lg-4 item shadow-lg p-1 mb-5 rounded  dataentry-card">
          <div class="box p-0">
            <h3 class="name">{{homepageText?.fieldBox1Title || 'Default Title'}}</h3>
            <p class="description">{{homepageText?.fieldBox1Description || 'Default Description'}}</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-5 col-lg-4 item shadow-lg p-1 mb-5  rounded  dataentry-card">
          <div class="box p-0">
            <h3 class="name">{{homepageText?.fieldBox2Title || 'Default Title'}}</h3>
            <p class="description">{{homepageText?.fieldBox2Description || 'Default Description'}}</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-5 col-lg-4 item shadow-lg p-1 mb-5 rounded  dataentry-card">
          <div class="box p-0">
            <h3 class="name">Contact </h3>
            <p class="description">Any problems or questions please contact:</p><a href="mailto:desos@indiana.edu" class="learn-more">desos@indiana.edu</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
