import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangePassword, DESOS_Users, iDESOS_Users } from 'src/app/admin/program';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatCard } from '@angular/material/card';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Location } from '@angular/common';
import { FormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { DESOS_UserInfo } from '../account';
import { DESOS_StrResult } from 'src/app/dataentry/dataentry';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-userpassword-reset',
  templateUrl: './userpassword-reset.component.html',
  styleUrls: ['./userpassword-reset.component.css'],
  providers: [AuthenticationService, UserService]
})
/** userpassword-reset component*/
export class UserpasswordResetComponent {
  /** changepassword ctor */
  private changepassword: ChangePassword;
  public accountRequest: FormGroup;
  private apiURL: string;
  public warningmessage: string = "";
  private userName: string;
  private desosUser: iDESOS_Users;
  private desosuserinfo: DESOS_UserInfo;
  private blnLogInValid: boolean = false;
  private strPattern: string = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}$";
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  private desosResult: DESOS_StrResult;
  private desosResult1: DESOS_StrResult;
  constructor(private location: Location, private route: ActivatedRoute, private router: Router,
    private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private matDialog: MatDialog, private authentication: AuthenticationService, private APIServices: UserService) {
    this.apiURL = baseUrl;
    let observable = new Observable(this.myObservableOne);
    this.showProgressSpinner(observable);
    this.getUserAccess();
  }

  ngOnInit() {

    this.accountRequest = new FormGroup({
      oldpassword: new FormControl('', [Validators.required]),
      newpassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(this.strPattern)]),
      repeatpassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(this.strPattern)]),
      resetpassflag: new FormControl(''),
      username: new FormControl(''),
    });
  }

  getUserAccess() {
    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);
    //}
    this.desosuserinfo = this.authentication.getAccessToken();
  }

  hasError = (controlName: string, errorName: string) => {
    return this.accountRequest.controls[controlName].hasError(errorName);
  }

  myObservableOne(observer) {
    setTimeout(() => {
      observer.next("done waiting for 1 sec");
      observer.complete();
    }, 1000);
  }

  myObservableTwo(observer) {
    setTimeout(() => {
      observer.next("done waiting for 2 sec");
      observer.complete();
    }, 2000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  startPasswordReseting(observable: Observable<any>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        this.passwordReset();
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }

  async oldPasswordChecking() {
      if (this.desosuserinfo != null) {
        const strusername: string = this.desosuserinfo.strUserName;
        const strpassword: string = this.accountRequest.controls["oldpassword"].value;
        let params = new HttpParams()
          .set('username', strusername)
          .set('password', strpassword);

       // this.desosResult =   await this.http.get<DESOS_StrResult>(this.baseUrl + 'api/User/CheckLogInUser', { params: params }).toPromise();
        this.desosResult = await this.APIServices.checkUserLoggedIn(params).toPromise();
        
      }
      else {
        alert("User login session expired! Please try again.")
      }
  }

  passwordReset() {
      try {
        if (this.desosResult != null) {
          if (this.desosResult.strOutput == "Success") {
            if (this.accountRequest.controls["newpassword"].value == this.accountRequest.controls["repeatpassword"].value) {
              this.changepassword = new ChangePassword();
              this.changepassword.username = this.desosuserinfo.strUserName;
              this.changepassword.oldpassword = this.accountRequest.controls["oldpassword"].value;
              this.changepassword.newpassword = this.accountRequest.controls["newpassword"].value;
              this.changepassword.repeatpassword = this.accountRequest.controls["repeatpassword"].value;
              this.changepassword.resetpassflag = "0";
              //this.http.post<DESOS_StrResult>(this.apiURL + 'api/User/ChangeUserPassword', this.changepassword)
              this.APIServices.changeUserPassword(this.changepassword)
                .subscribe(result => {
                  this.desosResult1 = JSON.parse(JSON.stringify(result));
                  if (this.desosResult1 != null) {
                    alert("Password reset success...")
                    this.warningmessage = "Password reset success...";
                    this.delay(1000).then(any => {
                      this.userNavigation();
                    });
                  }
                });
            }
            else {
              alert("New Password and Repeat New Password are not matching!")
            }
          }
          else {
            alert("Old Password is incorrect!")
          }
        }
        else {
          alert("Old Password is incorrect results!")
        }
      }
      catch (Error) {
      }
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("Login"));
  }

  btnSaveClick() {
    let observable = new Observable(this.myObservableOne);
    this.showProgressSpinner(observable);
    this.getUserAccess();
    this.delay(1000).then(any => { this.oldPasswordChecking() });
    this.delay(2000).then(any => { this.passwordReset(); });

  }

  userNavigation() {
    if (this.desosResult1 != null) {
      if (this.desosResult1.strOutput == "Success") {
        if (this.desosuserinfo.blnIsAdmin) {
          this.router.navigate(['/admin']);
        }
        else {
          this.router.navigate(['/dataentry/rosterlist']);
        }
      }
    }
  }

  btnBackClick() {
    this.router.navigate(['/admin']);
  }
}
