<app-progress-dialog *ngIf="progressSpinner"></app-progress-dialog>
<div class="row p-3">
  <form class="col-sm-12 p-0 shadow-lg dataentry-card dataentry-column rounded" [formGroup]="accountVerification">
    <div class="headding_account">
      <h4 class="text-md-left text-white pl-2">Verify Manager</h4>
    </div>
    <p *ngIf="warningmessage" class="text-danger">{{warningmessage}}</p>
    <section>
      <div class="form-group pl-3 pr-3 col-sm-12">
        <mat-form-field class="example-full-width">
          <input matInput type="text" name="programName" placeholder="Program Name" formControlName="managerprogram" readonly>
        </mat-form-field>
      </div>
      <div class="form-group pl-3 pr-3 col-sm-12">
        <mat-form-field class="example-full-width">
          <input matInput type="text" name="desoS_Manager_Name" placeholder="DESOS Manager Name" formControlName="managername" readonly>
        </mat-form-field>
      </div>
      <div class="form-group pl-3 pr-3 col-sm-12">
        <mat-form-field class="example-full-width">
          <input matInput type="text" name="desoS_Manager_Email" placeholder="DESOS Manager Email" formControlName="manageremail" readonly>
        </mat-form-field>
      </div>
      <div class="form-group pl-3 pr-3 col-sm-12">
        <section [formGroup]="accountInfo">
          <mat-radio-group class="data-radio-group" formControlName="option">
            <mat-radio-button class="data-radio-button" color="primary" [disabled]="disableForm" (change)="onChange($event)" value="1">YES, the information is correct.</mat-radio-button>
            <mat-radio-button class="data-radio-button" color="primary" [disabled]="disableForm" (change)="onChange($event)" value="2">NO, the information is incorrect.</mat-radio-button>
          </mat-radio-group>
        </section>
      </div>
      <div class="form-group pl-3 pr-3 col-sm-12" [hidden]="!hideEdit">
        <button mat-raised-button color="primary" (click)="btnSubmitVerification()" [disabled]="!accountInfo.valid">Submit the Verification</button>
      </div>
    </section>
    <section [hidden]="hideEdit">
      <div class="form-group pl-3 pr-3 col-sm-12">
        <mat-form-field class="example-full-width p-2">
          <input matInput type="text" name="desoS_Manager_Name" formControlName="newmanagername" placeholder="DESOS Manager Name" required>
          <!--<mat-error *ngIf="hasError('newmanagername', 'required')">Manager Name is required</mat-error>-->
        </mat-form-field>
      </div>
      <div class="form-group pl-3 pr-3 col-sm-12">
        <mat-form-field class="example-full-width p-2">
          <input matInput placeholder="Email" name="email" formControlName="newmanageremail" required>
          <!--<mat-error *ngIf="hasError('newmanageremail', 'required')">Manager Email is required</mat-error>-->
        </mat-form-field>
        <mat-error class="error_warning" *ngIf="hasError('newmanageremail', 'email')">Valid Email is required</mat-error>
      </div>
      <div class="form-group pl-3 pr-3 col-sm-12">
        <button mat-raised-button color="primary" (click)="btnChangeSubmitVerification()" [disabled]="!accountVerification.valid">Change and Submit the Verification</button>
      </div>
    </section>
    <!--</div>-->
  </form>
</div>
<div class="popup-overlay" *ngIf="isPopupOpen">
  <div class="popup-content">
    <p>{{ popupMessage }}</p>
    <button (click)="redirect()">OK</button>
  </div>
</div>
