<p *ngIf="!dataSource"></p>
<div class="row col-sm-12 p-0">
  <div class="col-sm-4 pt-2">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <button mat-raised-button color="primary">Search</button>
      </div>
      <input type="text" (keyup)="applyFilter($event.target.value)" class="form-control" id="idSearch" placeholder="Search Here">
    </div>
  </div>
</div>
<div class="mat-elevation-z8 pb-1 example-table-container">
  <table mat-table [dataSource]="dataSource" style="width:100%"  matSort>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <h6 class="text-primary">Action</h6> </th>
      <td mat-cell *matCellDef="let element" class="flex-nowrap p-0">
        <div class="row col-sm-12">
          <div class="col-sm-6 p-2" *ngIf="element.reporting_Period === desosreportinfo.mm + desosreportinfo.dd">
            <button (click)="editOutComes(element.id, element.individual_ID)" color="primary" mat-stroked-button routerLinkActive="active">Edit</button>
          </div>
          <div class="col-sm-6 p-2" *ngIf="element.reporting_Period !== desosreportinfo.mm + desosreportinfo.dd">
            <button (click)="newOutComes(element.id, element.individual_ID)" color="primary" mat-stroked-button routerLinkActive="active">New Outcome for {{ desosreportinfo.mm + '/' + desosreportinfo.dd }} </button>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><h6 class="text-primary"> Name </h6> </th>
      <td mat-cell *matCellDef="let element"> {{element.lastName + ' ' + element.firstName}} </td>
    </ng-container>
    <!-- Symbol Column -->
    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><h6 class="text-primary"> City </h6> </th>
      <td mat-cell *matCellDef="let element"> {{element.city}} </td>
    </ng-container>
    <ng-container matColumnDef="county">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <h6 class="text-primary">County </h6> </th>
      <td mat-cell *matCellDef="let element"> {{element.county}} </td>
    </ng-container>
    <ng-container matColumnDef="dateOfBirth">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><h6 class="text-primary"> DOB </h6> </th>
      <td mat-cell *matCellDef="let element"> {{element.dateOfBirth}} </td>
    </ng-container>
    <ng-container matColumnDef="gender">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><h6 class="text-primary"> Gender </h6> </th>
      <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
    </ng-container>
    <ng-container matColumnDef="race">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><h6 class="text-primary"> Race </h6> </th>
      <td mat-cell *matCellDef="let element"> {{element.race}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

