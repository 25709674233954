import { Component, OnInit, Inject } from '@angular/core';
import { getLocaleDateTimeFormat, Time } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DESOS_StrResult } from './dataentry/dataentry';
import { AuthenticationService } from './services/authentication.service';
import { DefaultService } from './services/default.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit { 
  title = 'DESOS';
  private apiURL: string;
  public DESOSresults: DESOS_StrResult;
  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private authentication: AuthenticationService,
    private APIdefaults: DefaultService,
    private bnIdle: BnNgIdleService,
    private router: Router)
  {
    this.apiURL = baseUrl;
  }

  ngOnInit() {
    this.GetAccessToken();


   // //Start watching for user inactivity.
   // this.userIdle.startWatching();

   // // Start watching when user idle is starting.
   //this.userIdle.onTimerStart().subscribe(count => console.log(count));

   // //this.userIdle.onTimerStart().subscribe(count => {
   // //  var eventList = ["click", "mouseover", "keydown", "DOMMouseScroll", "mousewheel","mousedown", "touchstart", "touchmove", "scroll", "keyup"];
   // //  for (let event of eventList) {
   // //    document.body.addEventListener(event, () => this.userIdle.resetTimer());
   // //  }
   // //});

   //// alert(navigator.platform + "" + navigator.userAgent + "" + navigator.appVersion + "" + navigator.vendor + " --- " + window.navigator.userAgent);

   // //if (this.isIE()) {
   // //  alert("This web application does not support Internet Explorer, Please user Edge, Chrome, Firfox, Chromium");
   // //}

   // // Start watch when time is up.
   // this.userIdle.onTimeout().subscribe(() => this.restart());
  }

  GetAccessToken() {
    this.APIdefaults.getToken().subscribe((result) => {
      this.DESOSresults = JSON.parse(JSON.stringify(result));
      if (this.DESOSresults != null) {
        if (this.DESOSresults.strOutput != "") {
          this.authentication.setGuestToken(this.DESOSresults.strOutput);
        }
      }
    });
  }

  private isIE() {
    const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
    let isIE = false;

    if (match !== -1) {
      isIE = true;
    }

    return isIE;
  }

  stop() {
    //this.userIdle.stopTimer();
  }

  stopWatching() {
    //this.userIdle.stopWatching();
  }

  startWatching() {
    //this.userIdle.startWatching();
  }

  restart() {
    //this.userIdle.resetTimer();
    //console.log(new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds());
    this.WEBAPIUserSessionActivate();
  }

  WEBAPIUserSessionActivate() { 
    this.http.get<DESOS_StrResult>(this.apiURL + 'api/DefaultData/UserSessionActivate').subscribe(result => {
      this.DESOSresults = result;
    }, error => console.error(error));
  }

}
