import { Component, OnInit, EventEmitter, Output, AfterViewChecked } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { helpText, DESOS_Individuals, DESOS_Outcomes } from '../dataentry';
import { dataentry_tab8_quest21to23 } from '../dataentry-tab8';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TempDataService } from 'src/app/services/temp-data.service';
import { DataentryService } from '../../services/dataentry.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DESOS_UserInfo } from 'src/app/account/account';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-dataentry-tab8',
  templateUrl: './dataentry-tab8.component.html',
  styleUrls: ['./dataentry-tab8.component.css'],
  providers: [TempDataService]
})
/** dataentry-tab8 component*/
export class DataentryTab8Component implements OnInit, AfterViewChecked {

  public desosuserinfo: DESOS_UserInfo;
  questionData: any;

  /** dataentry-tab8 ctor */
  helpMessage: helpText;
  public DEtab8Quest: dataentry_tab8_quest21to23;
  private curEditOutComes: DESOS_Outcomes;
  public tab8FormGroup: FormGroup;
  @Output() q21change = new EventEmitter<string>();
  public paidMax: string;
  public paidMin: string;
  public primary: number; 
  public isNone: boolean;
  public isDisable21: boolean = false;
  public isDisable22: boolean = false;
  public isDisable23: boolean = false;
  public isDisable24: boolean = false;
  constructor(private snackBar: MatSnackBar, private tempdata: TempDataService, private APIservices: DataentryService, private authentication: AuthenticationService) {
    this.helpMessage = new helpText();
    this.DEtab8Quest = new dataentry_tab8_quest21to23();
  }

  ngOnInit() {

    //if (sessionStorage.editoutcomes != null) {
    //  this.curEditOutComes = JSON.parse(sessionStorage.editoutcomes);
    //}

    this.curEditOutComes = this.tempdata.getCurrentoutcome();

    this.tab8FormGroup = new FormGroup({
      q23val: new FormControl('', [Validators.required]),
      q23subval3: new FormControl(''),
      q23subval4: new FormControl(''),
      q23subval5: new FormControl(''),
      q23subval6: new FormControl(''),
      q24valh: new FormControl('', [Validators.required, Validators.max(40), Validators.min(0)]),
      q24valm: new FormControl('', [Validators.required]),
      q25val: new FormControl('', [Validators.required]),
      q26valh: new FormControl('', [Validators.required, Validators.max(40), Validators.min(0)]),
      q26valm: new FormControl('', [Validators.required]),
    });

    this.fetchQuestionWithOptions();
  }
  async fetchQuestionWithOptions() {
    try {
      // Define the range of question identifiers
      const startQuestionIndex = 22;
      const endQuestionIndex = 24;
      const questionPrefix = '';

      // Initialize an object to hold the data for each question
      this.questionData = {};

      // Fetch questions within the range
      for (let questionIndex = startQuestionIndex; questionIndex <= endQuestionIndex; questionIndex++) {
        const questionNumber = `${questionPrefix}${questionIndex}`;
        const inparam = new HttpParams().set('questionNumber', questionNumber);
        this.questionData[questionNumber] = await this.APIservices.getQuestionWithOptions(inparam).toPromise();
      }
      let additionalNumber = '21-0';
      let additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      additionalNumber = '21-1';
      additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();

      additionalNumber = '21-2';
      additionalInparam = new HttpParams().set('questionNumber', additionalNumber);
      this.questionData[additionalNumber] = await this.APIservices.getQuestionWithOptions(additionalInparam).toPromise();
      // Check if data for all questions is retrieved
      const allDataReceived = Object.keys(this.questionData).length >= (endQuestionIndex - startQuestionIndex + 1 + 3 ) &&
        Object.values(this.questionData).every(data => !!data);

      if (allDataReceived) {
        console.log('Data retrieved:', this.questionData['21-0']); // Example log for the first question
        this.curEditOutComes = this.tempdata.getCurrentoutcome();
        console.log('curEditOutComes:', this.curEditOutComes);

        this.initFormGroup();

      } else {
        console.log('No data received');
      }
    } catch (error) {
      console.error('Failed to fetch question options:', error);
    }
  };
  ngAfterViewChecked() {
    //this.initFormGroup();
    //console.log("checked-tab8",this.curEditOutComes);
  }

  hasError = (controlName: string, errorName: string) => {
    return this.tab8FormGroup.controls[controlName].hasError(errorName);
  }

  initFormGroup() {

    this.DEtab8Quest.optquestion21Selected = this.curEditOutComes.q23val;
    this.tab8FormGroup.controls["q23val"].setValue(this.curEditOutComes.q23val);
    this.q23SubValidationUpdate(this.curEditOutComes.q23val);
    this.DEtab8Quest.opt5question21Selected = (this.DEtab8Quest.optquestion21Selected == "4" ? true : false);
    this.DEtab8Quest.opt6question21Selected = (this.DEtab8Quest.optquestion21Selected == "5" ? true : false);

    this.primary = parseInt(this.curEditOutComes.q1val);

    this.DEtab8Quest.optquestion21ASelected = this.curEditOutComes.q23subval3;
    this.tab8FormGroup.controls["q23subval3"].setValue(this.curEditOutComes.q23subval3);
    this.DEtab8Quest.opt4question21ASelected = (this.DEtab8Quest.optquestion21ASelected == "3" ? true : false);
    this.DEtab8Quest.optquestion21AOtherDes = this.curEditOutComes.q23subval4;
    this.tab8FormGroup.controls["q23subval4"].setValue(this.curEditOutComes.q23subval4);

    this.DEtab8Quest.optquestion21BSelected = this.curEditOutComes.q23subval5;
    this.tab8FormGroup.controls["q23subval5"].setValue(this.curEditOutComes.q23subval5);
    this.DEtab8Quest.opt7question21BSelected = (this.DEtab8Quest.optquestion21BSelected == "5" ? true : false);
    this.DEtab8Quest.optquestion21BOtherDes = this.curEditOutComes.q23subval6;
    this.tab8FormGroup.controls["q23subval6"].setValue(this.curEditOutComes.q23subval6);

    if (this.curEditOutComes.q24val.length > 0) {
      var splitString24 = this.curEditOutComes.q24val.split('.');
      //this.DEtab8Quest.strquestion22Hours = splitString24[0];
      //this.DEtab8Quest.strquestion22Minutes = splitString24[1];
      this.tab8FormGroup.controls["q24valh"].setValue(splitString24[0]);
      if (splitString24.length > 1)
        this.tab8FormGroup.controls["q24valm"].setValue(splitString24[1]);
      else {
        this.tab8FormGroup.controls["q24valm"].setValue("0");
      }
    }

    if (this.curEditOutComes.q26val.length > 0) {
      var splitString26 = this.curEditOutComes.q26val.split('.');
      this.tab8FormGroup.controls["q26valh"].setValue(splitString26[0]);
      if (splitString26.length > 1)
        this.tab8FormGroup.controls["q26valm"].setValue(splitString26[1]);
      else {
        this.tab8FormGroup.controls["q26valm"].setValue("0");
      }
    }

    this.DEtab8Quest.strquestion23Pay = this.curEditOutComes.q25val
    this.tab8FormGroup.controls["q25val"].setValue(this.curEditOutComes.q25val);

    console.log("input1: ", this.curEditOutComes.q1val, "inp: " , this.tempdata.getCurrentoutcome());

  }

  getEntries(curNewOutComes: DESOS_Outcomes): DESOS_Outcomes {
    try {

      curNewOutComes.q23val = this.tab8FormGroup.controls["q23val"].value;
      curNewOutComes.q23subval3 = this.tab8FormGroup.controls["q23subval3"].value;
      curNewOutComes.q23subval4 = this.tab8FormGroup.controls["q23subval4"].value;
      curNewOutComes.q23subval5 = this.tab8FormGroup.controls["q23subval5"].value;
      curNewOutComes.q23subval6 = this.tab8FormGroup.controls["q23subval6"].value;
      curNewOutComes.q24val = (this.tab8FormGroup.controls["q24valh"].value != "" ?  this.tab8FormGroup.controls["q24valh"].value + "." + this.tab8FormGroup.controls["q24valm"].value : "0");
      curNewOutComes.q26val = (this.tab8FormGroup.controls["q26valh"].value != "" ? this.tab8FormGroup.controls["q26valh"].value + "." + this.tab8FormGroup.controls["q26valm"].value : "0");
      curNewOutComes.q25val = "" + (this.tab8FormGroup.controls["q25val"].value);

      //sessionStorage.setItem("editoutcomes", JSON.stringify(this.curEditOutComes));
    }
    catch (Error) {
      console.log("Tab8:" + Error.message);
    }

    return curNewOutComes;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
    });
  }

  q23SubValidationUpdate(q23val: string) {
    console.log("Q21:", q23val);
    this.isDisable23 = false;
    this.isDisable24 = false;
    if (q23val == "4") {
      this.tab8FormGroup.controls["q23subval3"].setValidators(Validators.required);
      this.tab8FormGroup.controls["q23subval3"].updateValueAndValidity();
      this.tab8FormGroup.controls["q23subval5"].setValidators(null);
      this.tab8FormGroup.controls["q23subval5"].updateValueAndValidity();
      this.tab8FormGroup.controls["q25val"].disable();
      this.tab8FormGroup.controls["q26valh"].disable();
      this.tab8FormGroup.controls["q26valm"].disable();
      this.isDisable23 = true;
      this.isDisable24 = true;

    } else if (q23val == "5") {
      this.tab8FormGroup.controls["q23subval5"].setValidators(Validators.required);
      this.tab8FormGroup.controls["q23subval5"].updateValueAndValidity();
      this.tab8FormGroup.controls["q23subval3"].setValidators(null);
      this.tab8FormGroup.controls["q23subval3"].updateValueAndValidity();
      this.tab8FormGroup.controls["q25val"].disable();
      this.tab8FormGroup.controls["q26valh"].disable();
      this.tab8FormGroup.controls["q26valm"].disable();
      this.isDisable23 = true;
      this.isDisable24 = true;

    } else if (q23val == "6") {
      this.tab8FormGroup.controls["q23subval5"].setValidators(null);
      this.tab8FormGroup.controls["q23subval3"].setValidators(null);
      this.tab8FormGroup.controls["q23subval3"].updateValueAndValidity();
      this.tab8FormGroup.controls["q23subval5"].updateValueAndValidity();
      this.tab8FormGroup.controls["q25val"].disable();
    }
    else {
      this.tab8FormGroup.controls["q23subval5"].setValidators(null);
      this.tab8FormGroup.controls["q23subval3"].setValidators(null);
      this.tab8FormGroup.controls["q23subval3"].updateValueAndValidity();
      this.tab8FormGroup.controls["q23subval5"].updateValueAndValidity();
      this.tab8FormGroup.controls["q25val"].enable();
    }
    this.setPaidLimit(q23val);

    this.tab8FormGroup.controls["q23subval3"].setValue("");
    this.tab8FormGroup.controls["q23subval4"].setValue("");
    this.tab8FormGroup.controls["q23subval5"].setValue("");
    this.tab8FormGroup.controls["q23subval6"].setValue("");
    this.tab8FormGroup.controls["q25val"].setValue("");
    this.tab8FormGroup.controls["q24valh"].setValue("");
    this.tab8FormGroup.controls["q24valm"].setValue("");
    this.tab8FormGroup.controls["q26valh"].setValue("");
    this.tab8FormGroup.controls["q26valm"].setValue("");
  }
  setPaidLimit(strValue: string) {
    if (strValue == "0") {              //Facility-Based (Sheltered Employment) I Cent — 30.00$ 
      this.paidMin = "0.01";
      this.paidMax = "30";
      this.tab8FormGroup.controls["q25val"].setValidators([Validators.required, Validators.min(0.01), Validators.max(30)]);
      this.tab8FormGroup.controls["q25val"].updateValueAndValidity();
    } else if (strValue == "1") {       //Offsite Group I Cent — 30.00$
      this.paidMin = "0.05";
      this.paidMax = "30";
      this.tab8FormGroup.controls["q25val"].setValidators([Validators.required, Validators.min(0.05), Validators.max(30)]);
      this.tab8FormGroup.controls["q25val"].updateValueAndValidity();
    } else if (strValue == "2") {
      this.paidMin = "7.25";
      this.paidMax = "30";
      this.tab8FormGroup.controls["q25val"].setValidators([Validators.required, Validators.min(7.25), Validators.max(30)]);
      this.tab8FormGroup.controls["q25val"].updateValueAndValidity();
    } else if (strValue == "3") {       //Self-Employment 1 Cent - 30.00$
      this.paidMin = "0.05";
      this.paidMax = "30";
      this.tab8FormGroup.controls["q25val"].setValidators([Validators.required, Validators.min(0.05), Validators.max(30)]);
      this.tab8FormGroup.controls["q25val"].updateValueAndValidity();
    } else {
      this.paidMin = "0.05";
      this.paidMax = "20";
      this.tab8FormGroup.controls["q25val"].setValidators([Validators.required, Validators.min(0.05), Validators.max(20)]);
      this.tab8FormGroup.controls["q25val"].updateValueAndValidity();
    }
  }

  //setPaidLimit(strValue: string) {
  //  if (strValue == "0") {
  //    this.paidMin = "0.01";
  //    this.paidMax = "8";
  //    this.tab8FormGroup.controls["q25val"].setValidators([Validators.required, Validators.min(0.01), Validators.max(8)]);     
  //    this.tab8FormGroup.controls["q25val"].updateValueAndValidity();
  //  } else if (strValue == "2") {
  //    this.paidMin = "7.25";
  //    this.paidMax = "30";
  //    this.tab8FormGroup.controls["q25val"].setValidators([Validators.required, Validators.min(7.25), Validators.max(20)]);     
  //    this.tab8FormGroup.controls["q25val"].updateValueAndValidity();
  //  } else {
  //    this.paidMin = "0.05";
  //    this.paidMax = "20";
  //    this.tab8FormGroup.controls["q25val"].setValidators([Validators.required, Validators.min(0.05), Validators.max(20)]);     
  //    this.tab8FormGroup.controls["q25val"].updateValueAndValidity();
  //  }
  //}

  closeSnackBar() {
    //this.snackBar.dismiss();
  }


  setOtherSub4(strValue: string) {
    if (strValue == "3") {
      this.tab8FormGroup.controls["q23subval4"].setValidators(Validators.required);
      this.tab8FormGroup.controls["q23subval4"].updateValueAndValidity();
    }
    else {
      this.tab8FormGroup.controls["q23subval4"].setValidators(null);
      this.tab8FormGroup.controls["q23subval4"].updateValueAndValidity();
    }
  }

  setOtherSub6(strValue: string) {
    if (strValue == "5") {
      this.tab8FormGroup.controls["q23subval6"].setValidators(Validators.required);
      this.tab8FormGroup.controls["q23subval6"].updateValueAndValidity();
    }
    else {
      this.tab8FormGroup.controls["q23subval6"].setValidators(null);
      this.tab8FormGroup.controls["q23subval6"].updateValueAndValidity();
    }
  }

  onChangeQuest21A(event: MatRadioChange) {
    if (event.value == "3") {
      this.DEtab8Quest.opt4question21ASelected = true;
    }
    else {
      this.DEtab8Quest.opt4question21ASelected = false;
    }
    this.setOtherSub4(event.value );
  }

  onChangeQuest21B(event: MatRadioChange) {
    if (event.value == "5") {
      this.DEtab8Quest.opt7question21BSelected = true;
    }
    else {
      this.DEtab8Quest.opt7question21BSelected = false;
    }
    this.setOtherSub6(event.value);
  }

  onChangePrimary(val) {
    if (this.primary != val) {
      this.refresh(val);
    }
    this.primary = val;
    console.log("Primary8: ", this.primary, val);
  }

  refresh($value) {
    if (this.primary != $value) {
      this.tab8FormGroup.controls["q23val"].setValue("");
      this.tab8FormGroup.controls["q23subval3"].setValue("");
      this.tab8FormGroup.controls["q23subval4"].setValue("");
      this.tab8FormGroup.controls["q23subval5"].setValue("");
      this.tab8FormGroup.controls["q23subval6"].setValue("");
      this.tab8FormGroup.controls["q25val"].setValue("");
      this.tab8FormGroup.controls["q24valh"].setValue("");
      this.tab8FormGroup.controls["q24valm"].setValue("");
      this.tab8FormGroup.controls["q26valh"].setValue("");
      this.tab8FormGroup.controls["q26valm"].setValue("");
    }
  }

  OnQuest21OptSelected(event: MatRadioChange) {
    this.setOtherSub4("0");
    this.setOtherSub6("0");
    this.isDisable23 = false;
    this.isDisable24 = false;
    if (event.value == "4") {
      this.DEtab8Quest.opt5question21Selected = true;
      this.DEtab8Quest.opt6question21Selected = false;
      this.DEtab8Quest.opt4question21ASelected = false;
      this.DEtab8Quest.opt7question21BSelected = false;
      this.DEtab8Quest.opt5or6question21BSelected = true;
      this.q23SubValidationUpdate("4");
      this.tab8FormGroup.controls["q25val"].disable();
      this.tab8FormGroup.controls["q26valh"].disable();
      this.tab8FormGroup.controls["q26valm"].disable();
      this.isDisable23 = true;
      this.isDisable24 = true;
    }
    else if (event.value == "5") {
      this.DEtab8Quest.opt5question21Selected = false;
      this.DEtab8Quest.opt6question21Selected = true;
      this.DEtab8Quest.opt4question21ASelected = false;
      this.DEtab8Quest.opt7question21BSelected = false;
      this.DEtab8Quest.opt5or6question21BSelected = true;
      this.q23SubValidationUpdate("5");
      this.tab8FormGroup.controls["q25val"].disable();
      this.tab8FormGroup.controls["q26valh"].disable();
      this.tab8FormGroup.controls["q26valm"].disable();
      this.isDisable23 = true;
      this.isDisable24 = true;
    }
    else {
      this.DEtab8Quest.opt5question21Selected = false;
      this.DEtab8Quest.opt6question21Selected = false;
      this.DEtab8Quest.opt4question21ASelected = false;
      this.DEtab8Quest.opt7question21BSelected = false;
      this.DEtab8Quest.opt5or6question21BSelected = false;

      this.q23SubValidationUpdate(event.value);

      this.tab8FormGroup.controls["q25val"].enable();
      this.tab8FormGroup.controls["q24valh"].enable();
      this.tab8FormGroup.controls["q24valm"].enable();
      this.tab8FormGroup.controls["q26valh"].enable();
      this.tab8FormGroup.controls["q26valm"].enable();
    }
    this.tab8FormGroup.controls["q23subval3"].setValue("");
    this.tab8FormGroup.controls["q23subval4"].setValue("");
    this.tab8FormGroup.controls["q23subval5"].setValue("");
    this.tab8FormGroup.controls["q23subval6"].setValue("");
    this.tab8FormGroup.controls["q25val"].setValue("");
    this.tab8FormGroup.controls["q24valh"].setValue("");
    this.tab8FormGroup.controls["q24valm"].setValue("");
    this.tab8FormGroup.controls["q26valh"].setValue("");
    this.tab8FormGroup.controls["q26valm"].setValue("");
    this.isNone = false;

    //None
    if(event.value == "6")
    {
      this.DEtab8Quest.opt5question21Selected = false;
      this.DEtab8Quest.opt6question21Selected = false;
      this.DEtab8Quest.opt4question21ASelected = false;
      this.DEtab8Quest.opt7question21BSelected = false;
      this.DEtab8Quest.opt5or6question21BSelected = false;
      this.q23SubValidationUpdate("-1");
      this.tab8FormGroup.controls["q25val"].disable();
      this.tab8FormGroup.controls["q24valh"].disable();
      this.tab8FormGroup.controls["q24valm"].disable();
      this.tab8FormGroup.controls["q26valh"].disable();
      this.tab8FormGroup.controls["q26valm"].disable();
      this.isNone = true;
    }
    //this.q21change.emit(event.value);
  }
}
