import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { DESOS_Individuals, DESOS_Outcomes, DESOS_RosterData, DESOS_StrRosterResult, DESOS_StrResult } from '../dataentry/dataentry';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { DefaultService } from './default.service';
import { DESOS_Period_List, DESOS_Reporting_Periods } from '../search/default';
import { AuthenticationService } from './authentication.service';
import { DESOS_Manager_Verify } from '../admin/program';
import { DESOS_Search_Fields, DESOS_Records_List } from 'src/app/dataentry/dataentry';
import { DESOS_Programs_List } from '../admin/program';

@Injectable({ providedIn: 'root' })
export class VerificationService {
  private apiURL: string;
  private token: string;
  private strResults: DESOS_StrResult;
  private curRosterData: DESOS_RosterData;
  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient, private APIdefaults: DefaultService, private authentication: AuthenticationService) {
    this.apiURL = baseUrl;
    this.GetAccessToken();

  }

  getHeader() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Authorization': this.token })
    };
    return httpOptions;
  }

  getUserHeader() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Authorization': this.authentication.getUserAccessToken() })
    };
    return httpOptions;
  }

  getUserHeaderOption(inparam: HttpParams) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Authorization': this.authentication.getUserAccessToken() }),
      params: inparam
    };
    return httpOptions;
  }

  processAll(verificationIn: DESOS_Manager_Verify) {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/Program/ProcessAndEmailAll', verificationIn, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('process and send email to all'))
    );
  }

  generateVerificationList() {
    return this.http.get<DESOS_StrResult>(this.apiURL + 'api/Program/GenerateVerificationList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('Generate Verification List'))
    );
  }

  clearVerificationList() {
    return this.http.get<DESOS_StrResult>(this.apiURL + 'api/Program/ClearVerificationList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('Clear Verification List'))
    );
  }

  loadVerificationList(strAPIurl: string) {
    return this.http.get<DESOS_Manager_Verify[]>(strAPIurl, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Manager_Verify[]>('Load Verification List'))
    );
  }

  updateVerification(verificationIn: DESOS_Manager_Verify) {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/Program/ChangeVerificationInfo', verificationIn, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('update Verification'))
    );
  }

  deleteVerification(verificationIn: DESOS_Manager_Verify) {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/Program/DeleteVerificationInfo', verificationIn, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('delete Verification'))
    );
  }

  deleteVerificationMultiple(verificationIn: DESOS_Manager_Verify[]) {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/Program/DeleteVerificationInfoMultiple', verificationIn, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('delete Verification multiple'))
    );
  }

  sendEmailToVerification(verificationIn: DESOS_Manager_Verify) {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/Program/SendEmailSingleVerification', verificationIn, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('send Email To Verification'))
    );
  }

  getRosterListByReportingPeriod(inSearchFields: DESOS_Search_Fields): Observable<DESOS_Records_List[]> {
    return this.http.post<DESOS_Records_List[]>(this.apiURL + 'api/DataEntry/GetRosterListByReportingPeriod', inSearchFields, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Records_List[]>('get roster list'))
    );
  }

  getProgramList(): Observable<DESOS_Programs_List[]> {
    return this.http.get<DESOS_Programs_List[]>(this.apiURL + 'api/Program/GetProgramSelectList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Programs_List[]>('get program list'))
    );
  }

  getCurrentReportingPeriod(): Observable<DESOS_Reporting_Periods> {
    return this.http.get<DESOS_Reporting_Periods>(this.apiURL + 'api/DefaultData/GetCurrentReportingPeriod', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Reporting_Periods>('get current reporting period'))
    );
  }

  getReportingPeriodList(): Observable<DESOS_Period_List[]> {
    return this.http.get<DESOS_Period_List[]>(this.apiURL + 'api/DefaultData/GetReportingPeriodList', this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_Period_List[]>('get reporting period list'))
    );
  }

  copyEntries(copyOutcomes: DESOS_Records_List[]) {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/DataEntry/CopyEntries', copyOutcomes, this.getUserHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('copy entries'))
    );
  }
  
  
  GetAccessToken() {
    this.APIdefaults.getToken().subscribe((result) => {
      this.strResults = JSON.parse(JSON.stringify(result));
      if (this.strResults != null) {
        if (this.strResults.strOutput != "") {
          this.token = this.strResults.strOutput;
        }
      }
      else {
        this.token = "";
      }
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.error(message);
    // this.messageService.add(`HeroService: ${message}`);
  }
}
