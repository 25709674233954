  <mat-sidenav-container class="example-container" #sidenavContainer (backdropClick)="close('backdrop')">
    <mat-sidenav class="example-sidenav col-sm-12 p-0" #sidenav (keydown.escape)="close('escape')" disableClose>
      <div class="input-group-prepend p-2" mat-raised-button color="primary">
        <button mat-raised-button color="primary" (click)="close('toggle button')">Back To Roster List</button>      
      </div>
      <app-newentry #newEntryForm  [action]="action"></app-newentry>
    </mat-sidenav>
    <mat-sidenav-content class="example-sidenav-content col-sm-12 p-0">
      <div class="input-group-prepend p-2 example-button-row" mat-raised-button color="primary">
        <!--<button mat-raised-button color="primary" (click)="sidenav.open()">Back To Data Entry</button>-->
        <button mat-raised-button color="primary" (click)="btnNewOutcome()">New Outcome</button>
      </div>
      <app-userroster-list (outcomeAction)="onDataEntry($event)"></app-userroster-list>    
    </mat-sidenav-content>
  </mat-sidenav-container>
