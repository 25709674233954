import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DESOS_Reports } from '../reports';
import { DESOS_UserInfo } from 'src/app/account/account';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ProgressDialogComponent } from 'src/app/nav-menu/progress-dialog/progress-dialog.component';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
    selector: 'app-report-dashboard',
    templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.css'],
  providers: [AuthenticationService]
})
/** report-dashboard component*/
export class ReportDashboardComponent implements OnInit {
    /** report-dashboard ctor */
  public reportsList: DESOS_Reports[];
  private resultsArray: DESOS_Reports[];
  private desosuserinfo: DESOS_UserInfo;
  public dataSource: MatTableDataSource<DESOS_Reports>;
  private apiURL: string;
  private dialog: MatDialogRef<ProgressDialogComponent>;
  public progressSpinner: false;
  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private matDialog: MatDialog, private authentication: AuthenticationService, private APIServices: ReportService) {
    this.apiURL = baseUrl;
    let observable = new Observable(this.myObservableTwo);
    this.showProgressSpinner(observable);
  }

  ngOnInit() {
    this.desosuserinfo = this.authentication.getAccessToken();

    //if (sessionStorage.desosuserinfo != null) {
    //  this.desosuserinfo = JSON.parse(sessionStorage.desosuserinfo);

      if (this.desosuserinfo.strProgram == "FullAccess") {
        this.desosuserinfo.strProgram = "";
      }
      //this.userlistChild.programChanged(this.desosuserinfo.strProgram);
      if (this.desosuserinfo.blnIsAdmin)
        this.loadAdminReportList();
      else
        this.loadUserReportList();
    //}
  }

  async loadAdminReportList() {

    //this.resultsArray = await this.http.get<DESOS_Reports[]>(this.apiURL + "api/Reports/GetAdminReports").toPromise();
    this.resultsArray = await this.APIServices.getAdminReports().toPromise();
    if (this.resultsArray != null) {
      this.reportsList = JSON.parse(JSON.stringify(this.resultsArray));
    }
  }

  async loadUserReportList() {

   // this.resultsArray = await this.http.get<DESOS_Reports[]>(this.apiURL + "api/Reports/GetUserReports").toPromise();
    this.resultsArray = await this.APIServices.getUserReports().toPromise();
    if (this.resultsArray != null) {
      this.reportsList = JSON.parse(JSON.stringify(this.resultsArray));
    }
  }

  myObservableTwo(observer) {
    setTimeout(() => {
      observer.next("done waiting for 1 sec");
      observer.complete();
    }, 1000);
  }

  showProgressSpinner(observable: Observable<Object>) {
    let dialogRef: MatDialogRef<ProgressDialogComponent> = this.matDialog.open(ProgressDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    let subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        //handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        //handle error
        dialogRef.close();
      }
    );
  }
}
