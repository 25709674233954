import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { helpText, DESOS_Individuals, DESOS_Outcomes } from '../dataentry';
import { dataentry_tab5_quest8to12 } from '../dataentry-tab5';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TempDataService } from 'src/app/services/temp-data.service';
import { DataentryService } from '../../services/dataentry.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DESOS_UserInfo } from 'src/app/account/account';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-dataentry-tab5',
  templateUrl: './dataentry-tab5.component.html',
  styleUrls: ['./dataentry-tab5.component.css'],
  providers: [TempDataService]
})
/** dataentry-tab5 component*/
export class DataentryTab5Component implements OnInit, AfterViewInit {

  public desosuserinfo: DESOS_UserInfo;
  questionData: any;

  q11ChunkNum: number = 3;
  q12ChunkNum: number = 3;

  /** dataentry-tab5 ctor */
  helpMessage: helpText;
  public DEtab5Quest8to12: dataentry_tab5_quest8to12;
  public isDisable8: boolean = false;
  public isDisable9: boolean = false;
  public isDisable10: boolean = false;
  public isDisable11: boolean = false;
  public isDisable12: boolean = false;
  public primary: number;
  private curEditOutComes: DESOS_Outcomes;
  public tab5FormGroup: FormGroup;
  public paidMax: string;
  public paidMin: string;
  constructor(private snackBar: MatSnackBar, private tempdata: TempDataService, private APIservices: DataentryService, private authentication: AuthenticationService) {
    this.helpMessage = new helpText();
    this.DEtab5Quest8to12 = new dataentry_tab5_quest8to12();
  }

  ngOnInit() {

    //if (sessionStorage.editoutcomes != null) {
    //  this.curEditOutComes = JSON.parse(sessionStorage.editoutcomes);
    //}
    this.desosuserinfo = this.authentication.getAccessToken();

    this.curEditOutComes = this.tempdata.getCurrentoutcome();

    this.tab5FormGroup = new FormGroup({
      communication: new FormControl(''),
      q9valh: new FormControl('', [Validators.required, Validators.max(40), Validators.min(0)]),
      q9valm: new FormControl('', [Validators.required]),
      q10valh: new FormControl('', [Validators.required,Validators.max(40), Validators.min(0)]),
      q10valm: new FormControl('', [Validators.required]),
      q11val: new FormControl('', [Validators.required]),
      q12val: new FormControl('', [Validators.required]),
      q12subval: new FormControl(''),
      q13val: new FormControl('', [Validators.required]),
    });

    this.fetchQuestionWithOptions();
  }


  async fetchQuestionWithOptions() {
    try {
      // Define the range of question identifiers
      const startQuestionIndex = 8;
      const endQuestionIndex = 12;
      const questionPrefix = '';

      // Initialize an object to hold the data for each question
      this.questionData = {};

      // Fetch questions within the range
      for (let questionIndex = startQuestionIndex; questionIndex <= endQuestionIndex; questionIndex++) {
        const questionNumber = `${questionPrefix}${questionIndex}`;
        const inparam = new HttpParams().set('questionNumber', questionNumber);
        this.questionData[questionNumber] = await this.APIservices.getQuestionWithOptions(inparam).toPromise();
      }

      // Check if data for all questions is retrieved
      const allDataReceived = Object.keys(this.questionData).length >= (endQuestionIndex - startQuestionIndex + 1) &&
        Object.values(this.questionData).every(data => !!data);

      if (allDataReceived) {
        console.log('Data retrieved:', this.questionData[`${questionPrefix}${endQuestionIndex}`]); // Example log for the first question
        this.curEditOutComes = this.tempdata.getCurrentoutcome();
        console.log('curEditOutComes:', this.curEditOutComes);

        this.initFormGroup();
        this.q1AnswerDependent();

      } else {
        console.log('No data received');
      }
    } catch (error) {
      console.error('Failed to fetch question options:', error);
    }
  };

  ngAfterViewInit() {

  }

  q1AnswerDependent() {
    if (this.tempdata.getRosterAction() != "new") {
      if (this.curEditOutComes.q1val == "4" || this.curEditOutComes.q1val == "5") {
        this.disableA(true);
      }
    }
    this.primary = parseInt(this.curEditOutComes.q1val);
    this.setPaidLimit(this.curEditOutComes.q1val);
  }

  initFormGroup() {
    if (this.curEditOutComes.q9val.length > 0) {
      var splitString9 = this.curEditOutComes.q9val.split('.');
      this.DEtab5Quest8to12.strquestion8Hours = splitString9[0];
     
      this.tab5FormGroup.controls["q9valh"].setValue(splitString9[0]);
      if (splitString9.length > 1) {
        this.tab5FormGroup.controls["q9valm"].setValue(splitString9[1]);
        this.DEtab5Quest8to12.strquestion8Minutes = splitString9[1];
      }
      else {
        this.tab5FormGroup.controls["q9valm"].setValue("0");
        this.DEtab5Quest8to12.strquestion8Minutes = "0";
      }
     
    }

    if (this.curEditOutComes.q9val.length > 0) {
      var splitString10 = this.curEditOutComes.q10val.split('.');;
      this.DEtab5Quest8to12.strquestion9Hours = splitString10[0];   
      this.tab5FormGroup.controls["q10valh"].setValue(splitString10[0]);
      if (splitString10.length > 1) {
        this.tab5FormGroup.controls["q10valm"].setValue(splitString10[1]);
        this.DEtab5Quest8to12.strquestion9Minutes = splitString10[1];
      }
      else {
        this.tab5FormGroup.controls["q10valm"].setValue("0");
        this.DEtab5Quest8to12.strquestion9Minutes = "0";
      }
     
    }
    this.DEtab5Quest8to12.strquestion10Pay = this.curEditOutComes.q11val;
    this.tab5FormGroup.controls["q11val"].setValue(this.curEditOutComes.q11val);

    this.Q11Selected(this.curEditOutComes.q12val);
    this.tab5FormGroup.controls["q12val"].setValue(this.curEditOutComes.q12val);

    this.Q12Selected(this.curEditOutComes.q13val);
    this.tab5FormGroup.controls["q13val"].setValue(this.curEditOutComes.q13val);

  }

  hasError = (controlName: string, errorName: string) => {
    return this.tab5FormGroup.controls[controlName].hasError(errorName);
  }

  getEntries(curNewOutComes: DESOS_Outcomes): DESOS_Outcomes {
    try {
      curNewOutComes.q9val = (this.tab5FormGroup.controls["q9valh"].value != ""? this.tab5FormGroup.controls["q9valh"].value + "." + this.tab5FormGroup.controls["q9valm"].value : "0");
      curNewOutComes.q10val = (this.tab5FormGroup.controls["q10valh"].value != ""? this.tab5FormGroup.controls["q10valh"].value + "." + this.tab5FormGroup.controls["q10valm"].value : "0");
      curNewOutComes.q11val = "" + (this.tab5FormGroup.controls["q11val"].value);
      curNewOutComes.q12val =  this.getq12val();
      curNewOutComes.q13val =  this.getq13val();
      curNewOutComes.q12subval = this.tab5FormGroup.controls["q12subval"].value;

      //sessionStorage.setItem("editoutcomes", JSON.stringify(this.curEditOutComes));
    }
    catch (Error) {
      console.log("Tab5:" + Error.message);
    }

    return curNewOutComes;
  }

  Q11Selected(inValue: string) {

    if (inValue.indexOf('ckEBR_M') >= 0) {
      this.DEtab5Quest8to12.chk1question11Selected = true;
    }
    if (inValue.indexOf('ckEBR_PSL') >= 0) {
      this.DEtab5Quest8to12.chk2question11Selected = true;
    }
    if (inValue.indexOf('ckEBR_PV') >= 0) {
      this.DEtab5Quest8to12.chk3question11Selected = true;
    }
    if (inValue.indexOf('ckEBR_R') >= 0) {
      this.DEtab5Quest8to12.chk4question11Selected = true;
    }
    if (inValue.indexOf('ckEBR_none') >= 0) {
      this.DEtab5Quest8to12.chk5question11Selected = true;
    }
    if (inValue.indexOf('ckEBR_Other') >= 0) {
      this.DEtab5Quest8to12.chk6question11Selected = true;
      this.DEtab5Quest8to12.strquestion11OtherDes = this.curEditOutComes.q12subval;
      this.tab5FormGroup.controls["q12subval"].setValue(this.curEditOutComes.q12subval);
    }

  }

  getq12val() : string {
    var strResults = "";

    if (this.DEtab5Quest8to12.chk1question11Selected) {
      strResults = "ckEBR_M,"
    }
    if (this.DEtab5Quest8to12.chk2question11Selected) {
      strResults = strResults + "ckEBR_PSL,"
    }
    if (this.DEtab5Quest8to12.chk3question11Selected) {
      strResults = strResults + "ckEBR_PV,"
    }
    if (this.DEtab5Quest8to12.chk4question11Selected) {
      strResults = strResults + "ckEBR_R,"
    }
    if (this.DEtab5Quest8to12.chk5question11Selected) {
      strResults = strResults + "ckEBR_none,"
    }
    if (this.DEtab5Quest8to12.chk6question11Selected) {
      strResults = strResults + "ckEBR_Other,"
    }
    return strResults;
  }

  Q12Selected(inValue: string) {

    if (inValue.indexOf('ckGB_SSI') >= 0) {
      this.DEtab5Quest8to12.chk1question12Selected = true;
    }
    if (inValue.indexOf('ckGB_SSDI') >= 0) {
      this.DEtab5Quest8to12.chk2question12Selected = true;
    }
    if (inValue.indexOf('ckGB_Medicaid') >= 0) {
      this.DEtab5Quest8to12.chk3question12Selected = true;
    }
    if (inValue.indexOf('ckGB_Medicare') >= 0) {
      this.DEtab5Quest8to12.chk4question12Selected = true;
    }
    if (inValue.indexOf('ckGB_TANF') >= 0) {
      this.DEtab5Quest8to12.chk5question12Selected = true;
    }
    if (inValue.indexOf('ckGB_NA') >= 0) {
      this.DEtab5Quest8to12.chk6question12Selected = true;
    }

  }

  getq13val(): string {
    var strResults = "";
    if (this.DEtab5Quest8to12.chk1question12Selected) {
      strResults = "ckGB_SSI,";
    }
    if (this.DEtab5Quest8to12.chk2question12Selected) {
      strResults = strResults + "ckGB_SSDI,";
    }
    if (this.DEtab5Quest8to12.chk3question12Selected) {
      strResults = strResults + "ckGB_Medicaid,";
    }
    if (this.DEtab5Quest8to12.chk4question12Selected) {
      strResults = strResults + "ckGB_Medicare,";
    }
    if (this.DEtab5Quest8to12.chk5question12Selected) {
      strResults = strResults + "ckGB_TANF,";
    }
    if (this.DEtab5Quest8to12.chk6question12Selected) {
      strResults = strResults + "ckGB_NA,";
    }

    return strResults;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
    });
  }

  closeSnackBar() {
    //this.snackBar.dismiss();
  }

  setOtherQ11(blnValue: boolean) {
    if (blnValue) {
      this.tab5FormGroup.controls["q12subval"].setValidators(Validators.required);
      this.tab5FormGroup.controls["q12subval"].updateValueAndValidity();
    }
    else {
      this.tab5FormGroup.controls["q12subval"].setValidators(null);
      this.tab5FormGroup.controls["q12subval"].updateValueAndValidity();
    }
  }

  onChangeQuest11(event: MatCheckboxChange) {
    if (event.source.name == "chk6question11Selected" && event.checked == true) {
      this.DEtab5Quest8to12.chk1question11Selected = false;
      this.DEtab5Quest8to12.chk2question11Selected = false;
      this.DEtab5Quest8to12.chk3question11Selected = false;
      this.DEtab5Quest8to12.chk4question11Selected = false;
      this.DEtab5Quest8to12.chk5question11Selected = false;
      this.setOtherQ11(true);
    } else if (event.source.name == "chk5question11Selected" && event.checked == true) {
      this.DEtab5Quest8to12.chk1question11Selected = false;
      this.DEtab5Quest8to12.chk2question11Selected = false;
      this.DEtab5Quest8to12.chk3question11Selected = false;
      this.DEtab5Quest8to12.chk4question11Selected = false;
      this.DEtab5Quest8to12.chk6question11Selected = false;
      this.DEtab5Quest8to12.strquestion11OtherDes = "";
      this.setOtherQ11(false);
    }
    else {
      this.DEtab5Quest8to12.chk6question11Selected = false;
      this.DEtab5Quest8to12.chk5question11Selected = false;
      this.DEtab5Quest8to12.strquestion11OtherDes = "";
      this.setOtherQ11(false);
    }
    this.tab5FormGroup.controls["q12val"].setValue(this.getq12val());
  }

  onChangeQuest12(event: MatCheckboxChange) {
    if (event.source.name == "chk6question12Selected" && event.checked == true) {
      this.DEtab5Quest8to12.chk1question12Selected = false;
      this.DEtab5Quest8to12.chk2question12Selected = false;
      this.DEtab5Quest8to12.chk3question12Selected = false;
      this.DEtab5Quest8to12.chk4question12Selected = false;
      this.DEtab5Quest8to12.chk5question12Selected = false;
    } 
    else {
      this.DEtab5Quest8to12.chk6question12Selected = false;
    }
    this.tab5FormGroup.controls["q13val"].setValue(this.getq13val());
  }

  setPaidLimit(strValue: string) {
    if (strValue == "0") {              //Facility-Based (Sheltered Employment) I Cent — 30.00$ 
      this.paidMin = "0.01";
      this.paidMax = "30";
      this.tab5FormGroup.controls["q11val"].setValidators([Validators.required, Validators.min(0.01), Validators.max(30)]);
      this.tab5FormGroup.controls["q11val"].updateValueAndValidity();
    } else if (strValue == "1") {       //Offsite Group I Cent — 30.00$
      this.paidMin = "0.05";
      this.paidMax = "30";
      this.tab5FormGroup.controls["q11val"].setValidators([Validators.required, Validators.min(0.05), Validators.max(30)]);
      this.tab5FormGroup.controls["q11val"].updateValueAndValidity();
    } else if (strValue == "2") {
      this.paidMin = "7.25";
      this.paidMax = "30";
      this.tab5FormGroup.controls["q11val"].setValidators([Validators.required, Validators.min(7.25), Validators.max(30)]);
      this.tab5FormGroup.controls["q11val"].updateValueAndValidity();
    } else if (strValue == "3") {       //Self-Employment 1 Cent - 30.00$
      this.paidMin = "0.05";
      this.paidMax = "30";
      this.tab5FormGroup.controls["q11val"].setValidators([Validators.required, Validators.min(0.05), Validators.max(30)]);
      this.tab5FormGroup.controls["q11val"].updateValueAndValidity();
    } else {
      this.paidMin = "0.05";
      this.paidMax = "20";
      this.tab5FormGroup.controls["q11val"].setValidators([Validators.required, Validators.min(0.05), Validators.max(20)]);
      this.tab5FormGroup.controls["q11val"].updateValueAndValidity();
    }

    //console.log("MinMax", this.paidMin, this.paidMax);
  }


  disableA(blnDisable) {
    if (blnDisable) {
      this.tab5FormGroup.controls["q9valh"].disable();
      this.tab5FormGroup.controls["q9valm"].disable();
      this.tab5FormGroup.controls["q10valh"].disable();
      this.tab5FormGroup.controls["q10valm"].disable();
      this.tab5FormGroup.controls["q11val"].disable();
      this.tab5FormGroup.controls["q12val"].setValue("");
      this.DEtab5Quest8to12.chk1question11Selected = false;
      this.DEtab5Quest8to12.chk2question11Selected = false;
      this.DEtab5Quest8to12.chk3question11Selected = false;
      this.DEtab5Quest8to12.chk4question11Selected = false;
      this.DEtab5Quest8to12.chk5question11Selected = false;
      this.DEtab5Quest8to12.chk6question11Selected = false;
      this.setOtherQ11(false);
      this.tab5FormGroup.controls["q12val"].setValidators(null);
      this.tab5FormGroup.controls["q12val"].updateValueAndValidity();
    } else {
      this.tab5FormGroup.controls["q9valh"].enable();
      this.tab5FormGroup.controls["q9valm"].enable();
      this.tab5FormGroup.controls["q10valh"].enable();
      this.tab5FormGroup.controls["q10valm"].enable();
      this.tab5FormGroup.controls["q11val"].enable();
      this.tab5FormGroup.controls["q12val"].setValue("");
      this.DEtab5Quest8to12.chk1question11Selected = false;
      this.DEtab5Quest8to12.chk2question11Selected = false;
      this.DEtab5Quest8to12.chk3question11Selected = false;
      this.DEtab5Quest8to12.chk4question11Selected = false;
      this.DEtab5Quest8to12.chk5question11Selected = false;
      this.DEtab5Quest8to12.chk6question11Selected = false;
      this.setOtherQ11(false);
      this.tab5FormGroup.controls["q12val"].setValidators([Validators.required]);
     this.tab5FormGroup.controls["q12val"].updateValueAndValidity();
    }

    this.tab5FormGroup.controls["q9valh"].setValue("");
    this.tab5FormGroup.controls["q9valm"].setValue("");
    this.tab5FormGroup.controls["q10valh"].setValue("");
    this.tab5FormGroup.controls["q10valm"].setValue("");
    this.tab5FormGroup.controls["q11val"].setValue("");
    this.tab5FormGroup.controls["q12val"].setValue("");

    this.isDisable8 = blnDisable;
    this.isDisable9 = blnDisable;
    this.isDisable10 = blnDisable;
    this.isDisable11 = blnDisable;
  }

  refresh($value) {
    if (this.primary != $value) {
      this.tab5FormGroup.controls["q13val"].setValue("");
      this.tab5FormGroup.controls["q13val"].disable();
      this.tab5FormGroup.controls["q13val"].enable();
      this.DEtab5Quest8to12.chk1question12Selected = false;
      this.DEtab5Quest8to12.chk2question12Selected = false;
      this.DEtab5Quest8to12.chk3question12Selected = false;
      this.DEtab5Quest8to12.chk4question12Selected = false;
      this.DEtab5Quest8to12.chk5question12Selected = false;
      this.DEtab5Quest8to12.chk6question12Selected = false;
      this.tab5FormGroup.controls["q9valh"].setValue("");
      this.tab5FormGroup.controls["q9valm"].setValue("");
      this.tab5FormGroup.controls["q10valh"].setValue("");
      this.tab5FormGroup.controls["q10valm"].setValue("");
      this.tab5FormGroup.controls["q11val"].setValue("");
      this.DEtab5Quest8to12.chk1question11Selected = false;
      this.DEtab5Quest8to12.chk2question11Selected = false;
      this.DEtab5Quest8to12.chk3question11Selected = false;
      this.DEtab5Quest8to12.chk4question11Selected = false;
      this.DEtab5Quest8to12.chk5question11Selected = false;
      this.DEtab5Quest8to12.chk6question11Selected = false;
      this.tab5FormGroup.controls["q12val"].setValue("");
    }
  }
}
