<div>
  <button mat-icon-button mat-mini-fab color="info" [matMenuTriggerFor]="menu">
    <mat-icon color="primary">person</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <mat-icon color="primary">person</mat-icon>
      <span>{{desosuserinfo.strUserName}}</span>
    </button>
    <div class="dropdown-item">
      <span>First Name : {{desosuserinfo.strFirstName}}</span>
    </div>
    <div class="dropdown-item">
      <span>Last Name : {{desosuserinfo.strLastName}}</span>
    </div>
    <div class="dropdown-item">
      <span>Access Role : {{desosuserinfo.strAccessRole}}</span>
    </div>
    <div class="dropdown-item">
      <span>Reporting Period : {{desosreportinfo.mm + "/" + desosreportinfo.dd}}</span>
    </div>
    <div class="dropdown-item" *ngIf="desosreportinfo.mm !== 'NO'">
      <span>Reporting Period Type: {{(desosreportinfo.isannual === "True") ? 'Annual' : 'Quarterly'}}</span>
    </div>
    <button mat-menu-item [routerLink]="['/admin/changepassword']">
      <mat-icon color="primary">security</mat-icon>
      <span>Change Password</span>
    </button>
    <button mat-menu-item [routerLink]="['/account/login','logout']">
      <mat-icon color="primary">lock</mat-icon>
      <span>Log out</span>
    </button>
  </mat-menu>
</div>
