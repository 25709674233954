import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, of } from 'rxjs';
import { DESOS_StrResult } from '../dataentry/dataentry';
import { catchError } from 'rxjs/operators';
import { DESOS_Programs_List, DESOS_Users, DESOS_Manager_Verify, Login } from '../admin/program';
import { AuthenticationService } from './authentication.service';
import { DESOS_Account_Request } from '../account/account';
import { General_Request } from '../account/general-request/general-request.component';
import { DESOS_Reporting_Periods } from '../search/default';
import { DESOS_Homepage_Text } from '../models/desos-homepage-text';

@Injectable({ providedIn: 'root' })
export class DefaultService {
  private apiURL: string;
  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient, private authentication: AuthenticationService ) {
    this.apiURL = baseUrl;
  }

  getToken(): Observable<DESOS_StrResult> {
    return this.http.get<DESOS_StrResult>(this.apiURL + 'api/DefaultData/GetUserToken').pipe(
      catchError(this.handleError<DESOS_StrResult>('get user token'))
    );
  }
  getHomepageText(): Observable<DESOS_Homepage_Text> {
    return this.http.get<DESOS_Homepage_Text>(this.apiURL + 'api/DefaultData/GetHomepageText', this.getGuestHeader()).pipe(
      catchError(this.handleError<DESOS_Homepage_Text>('get homepage text'))
    );
  }  
  getGuestHeader() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Authorization': this.authentication.getGuestToken() })
    };
    return httpOptions;
  }

  getGuestHeaderOption(inparam: HttpParams) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Authorization': this.authentication.getGuestToken() }),
      params: inparam
    };
    return httpOptions;
  }

  getProgramList(): Observable<DESOS_Programs_List[]> {
    return this.http.get<DESOS_Programs_List[]>(this.apiURL + 'api/DefaultData/GetProgramSelectList', this.getGuestHeader()).pipe(
      catchError(this.handleError<DESOS_Programs_List[]>('get program list'))
    );
  }

  getCurrentReportingPeriod(): Observable<DESOS_Reporting_Periods> {
    return this.http.get<DESOS_Reporting_Periods>(this.apiURL + 'api/DefaultData/GetCurrentReportingPeriod', this.getGuestHeader()).pipe(
      catchError(this.handleError<DESOS_Reporting_Periods>('get current reporting period'))
    );
  }

  newAccountRequest(newAccountRequest: DESOS_Account_Request): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/DefaultData/NewAccountRequest', newAccountRequest, this.getGuestHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('new account request'))
    );
  }

  newGeneralRequest(newGeneralRequest: General_Request): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/DefaultData/GeneralRequest', newGeneralRequest, this.getGuestHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('new account request'))
    );
  }

  getUserInfo(inparam: HttpParams): Observable<DESOS_Users> {
    return this.http.get<DESOS_Users>(this.apiURL + 'api/DefaultData/GetUserByUserName', this.getGuestHeaderOption(inparam) ).pipe(
      catchError(this.handleError<DESOS_Users>('get account info'))
    );
  }

  resetPasswordAndSendEmail(passwordReset: DESOS_Users): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/DefaultData/PasswordReset', passwordReset, this.getGuestHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('password reset request'))
    );
  }

  getUserLoggedInfo(inparam: HttpParams): Observable<DESOS_Users> {
    return this.http.get<DESOS_Users>(this.apiURL + 'api/DefaultData/GetLoggedInUser', this.getGuestHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_Users>('get looged in account info'))
    );
  }

  postUserLoggedInfo(login: Login): Observable<DESOS_Users> {
    return this.http.post<DESOS_Users>(this.apiURL + 'api/DefaultData/PostLoggedInUser', login, this.getGuestHeader()).pipe(
      catchError(this.handleError<DESOS_Users>('get looged in account info'))
    );
  }

  getVerificationByKey(inparam: HttpParams): Observable<DESOS_Manager_Verify> {
    return this.http.get<DESOS_Manager_Verify>(this.apiURL + 'api/DefaultData/GetVerificationByKey', this.getGuestHeaderOption(inparam)).pipe(
      catchError(this.handleError<DESOS_Manager_Verify>('get verification info'))
    );
  }

  desosManagerVerification(managerVerify: DESOS_Manager_Verify): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/DefaultData/DESOSManagerVerification', managerVerify, this.getGuestHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('DESOS Manager Verification'))
    );
  }

  newDESOSManagerVerification(managerVerify: DESOS_Manager_Verify): Observable<DESOS_StrResult> {
    return this.http.post<DESOS_StrResult>(this.apiURL + 'api/DefaultData/NewDESOSManagerVerification', managerVerify, this.getGuestHeader()).pipe(
      catchError(this.handleError<DESOS_StrResult>('New DESOS Manager Verification'))
    );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      if (operation == 'get verification info') {
        window.location.reload();
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.error(message);
    // this.messageService.add(`HeroService: ${message}`);
  }
}
