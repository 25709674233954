export class dataentry_tab5_quest8to12 {
  public question8Text: string = "";
  public strquestion8Hours: string = "";
  public strquestion8Minutes: string = "";

  public question9Text: string = "";
  public strquestion9Hours: string = "";
  public strquestion9Minutes: string = "";

  public question10Text: string = "";
  public strquestion10Pay: string = "";

  public chk1question11Selected: boolean = false;
  public chk2question11Selected: boolean = false;
  public chk3question11Selected: boolean = false;
  public chk4question11Selected: boolean = false;
  public chk5question11Selected: boolean = false;
  public chk6question11Selected: boolean = false;
  public strquestion11OtherDes: string = "";

  public chk1question12Selected: boolean = false;
  public chk2question12Selected: boolean = false;
  public chk3question12Selected: boolean = false;
  public chk4question12Selected: boolean = false;
  public chk5question12Selected: boolean = false;
  public chk6question12Selected: boolean = false;

}
